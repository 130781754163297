<template>
    <div id="root">
        <div class="header-section">
            <div class="title">
                <h2>Editar perfil</h2>
            </div>
        </div>


        <div class="content-section">

            <form autocomplete="off" class="form-editar-usuario"
                  v-on:submit="guardarPerfil">

                <picture-input
                        ref="pictureInput"
                        width="200"
                        @change="addImage"
                        height="200"
                        accept="image/jpeg,image/png"
                        size="10"
                        radius="100"
                        :hideChangeButton="true"
                        :customStrings="{
                            upload: '<h1>Bien!</h1>',
                            drag: 'Agrega una imagen de perfil'
                          }">
                </picture-input>

                <FormInput :valueInput.sync="usuario.name" :required="true"
                           name="nombre_formulario" title="Nombre" />


                <a @click="changePassword = !changePassword" class="btn-change-pass">
                    Cambiar contraseña
                    <span v-show="!changePassword">&nbsp;<i class="fas fa-caret-down"></i></span>
                    <span v-show="changePassword">&nbsp;<i class="fas fa-caret-up"></i></span>
                </a>

                <div v-if="changePassword">
                    <FormInput title="Contraseña"
                               :required="true"
                               type="password"
                               name="old_password"
                               :valueInput.sync="usuario.old_password"/>

                    <FormInput title="Nueva contraseña"
                               type="password"
                               :required="true"
                               placeholder="Nueva contraseña"
                               name="new_password"
                               :valueInput.sync="usuario.new_password"/>

                    <FormInput title="Repetir contraseña"
                               type="password"
                               :required="true"
                               placeholder="Repetir contraseña"
                               name="repeat_password"
                               :valueInput.sync="usuario.password_confirmation"/>

                    <br>
                </div>
                <ButtonLoader ref="buttonUsuario" type="submit" title="Guardar" />

            </form>
        </div>

    </div>
</template>

<script>

    import FormInput from '@/components/Form/FormInput'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import PictureInput from 'vue-picture-input'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'HomeScreen',
        components: {
            PictureInput,
            FormInput,
            ButtonLoader
        },
        data: function() {
            return {
                changePassword: false,
                usuario: Object.assign({}, this.$store.getters.user)
            }
        },
        mounted() {

        },
        methods: {
            addImage(img){
                this.usuario.image = img;
            },
            guardarPerfil(e){
                e.preventDefault();
                this.$refs.buttonUsuario.setShowLoader(true);
                let vue = this;

                $.post(this.API_URL + "/api/account/edit-profile", this.usuario, function (response) {
                    vue.$refs.buttonUsuario.setShowLoader(false);
                    if(response.success){
                        vue.$store.commit("user", Object.assign({}, response.user));
                        vue.usuario = Object.assign({}, response.user);
                        vue.changePassword = false;
                        swal('Perfil actualizado correctamente', '', 'success');
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    vue.$refs.buttonUsuario.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });

            }
        }
    }
</script>

<style lang="scss">
    .picture-input{
        .picture-preview, .picture-inner{
            z-index: 100 !important;
        }
    }
</style>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .form-editar-usuario{
            padding: 25px 15px;
            background: #fff;
            border-radius: 10px;
            border: 1px solid #dfe0eb;
            box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);


            .btn-change-pass{
                display: block;
                margin-bottom: 15px;
            }

        }

    }



</style>
