<template>
    <div class="date-group">
        <label v-if="title != null">{{ title }}</label>
        <div class="date-item">
            <i v-if="icons" class="far fa-calendar"></i>
            <div :class="icons ? 'icons' : 'no-icons'">
                <v-menu v-model="showInicio"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :nudge-right="-175"
                        offset-y
                        min-width="290px">
                    <template v-slot:activator="{ on }">
                        <div class="input-date" v-on="on" >
                            <input :value="dateFormatInicio" :class="styleClass === undefined ? 'filter' : styleClass" type="text" :placeholder="placeholderInicio"
                                   readonly>
                        </div>
                    </template>
                    <v-date-picker
                            v-model="dateInicio"
                            locale="es-MX"
                            :type="type"
                            @change="changeInicio"
                    ></v-date-picker>
                </v-menu>
            </div>
        </div>
        <div class="date-item">
            <i v-if="icons" class="far fa-calendar"></i>
            <div :class="icons ? 'icons' : 'no-icons'">
                <v-menu v-model="showFin"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :nudge-right="-175"
                        offset-y
                        min-width="290px">
                    <template v-slot:activator="{ on }">
                        <div class="input-date" v-on="on" >
                            <input :value="dateFormatFin" :class="styleClass === undefined ? 'filter' : styleClass" type="text" :placeholder="placeholderFin"
                                   readonly>
                        </div>
                    </template>
                    <v-date-picker
                            v-model="dateFin"
                            locale="es-MX"
                            :min="dateInicio"
                            :type="type"
                            @change="changeFin"
                    ></v-date-picker>
                </v-menu>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment';
moment.locale('es');
export default {
    name: 'DateRangePicker',
    props: {
        title: String,
        type: String,
        placeholderInicio: String(''),
        placeholderFin: String(''),
        icons: Boolean,
        format: null,
        styleClass: null
    },
    filters: {
        dateFormat: function (value) {
            return moment(value).format("DD-MM-YYYY");
        },
    },
    data(){
        return{
            dateInicio: "",
            fija: Boolean,
            dateFin: "",
            dateFormatInicio: "",
            dateFormatFin: "",
            showInicio: false,
            showFin: false
        }
    },
    mounted(){
        if(this.fija){
            this.fechaInicio = "2018-01-01"
        }
    },
    watch: {
        dateInicio: function () {
            if(this.dateInicio == ""){
                this.dateFormatInicio = ""
            }
            else{
                this.dateFormatInicio = this.format != null && this.format !== undefined  ?
                    moment(this.dateInicio).format(this.format) : this.dateInicio;
            }
        },
        dateFin: function () {
            if(this.dateFin == ""){
                this.dateFormatInicio = ""
            }
            else{
                this.dateFormatFin = this.format != null && this.format !== undefined  ?
                    moment(this.dateFin).format(this.format) : this.dateFin;
            }
        }
    },
    methods: {
        changeInicio: function (e) {
            this.showInicio = false;
            if(moment(this.dateInicio).isAfter(this.dateFin )){
                this.dateFin = this.dateInicio;
                this.changeFin();
            }

            this.$emit("update:dateInicio", this.dateInicio);
            this.$emit("change", e)
        },
        changeFin: function (e) {
            this.showFin = false;
            this.$emit("update:dateFin", this.dateFin);
            this.$emit("change", e)
        },
        setDateInicio: function (date) {
            this.dateInicio = date;
        },
        setDateFin: function (date) {
            this.dateFin = date;
        },
        setDates: function (inicio, fin) {
            this.dateInicio = inicio;
            this.dateFin = fin;
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .date-group{
        border-radius: 50px;
        background: #fff;
        display: block;
        border: 1px solid #dcdde1;

        &:hover{
            cursor: pointer;
        }

        .date-item:first-child{
            border-radius: 50px 0 0 50px;
            border-right: 1px solid #dcdde1;
        }

        .date-item:last-child{
            border-radius: 0 50px 50px 0;
        }

        .date-item {
            position: relative;
            color: #1f1f21;
            width: 50%;
            display: inline-block;

            svg{
                position: absolute;
                left: 13px;
                top: 50%;
                transform: translateY(-50%);
            }

            & > div{
                //width: 120px;

                .input-date{
                    input{
                        text-transform: capitalize;
                    }
                    .filter{
                        outline: none;
                        box-shadow: none;
                        width: 100%;
                        height: 32px;
                        font-size: 12px;
                        padding: 0 15px;
                        transition-duration: 0.3s;

                        &:focus{
                            outline: none;
                            box-shadow: none;

                        }

                        &::placeholder {
                            color: #1f1f21;
                            opacity: 1; /* Firefox */
                        }

                        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                            color: #1f1f21;
                        }

                        &::-ms-input-placeholder { /* Microsoft Edge */
                            color: #1f1f21;
                        }
                    }
                }

            }

            .icons{
                .input-date {
                    .filter {
                        padding-left: 35px;
                    }
                }
            }

        }

        /*<!--.date-item:first-child{-->*/
            /*<!--.input-date {-->*/
                /*<!--.filter {-->*/
                    /*<!--border-radius: 50px 0 0 50px;-->*/
                    /*<!--border-right: 1px solid #dcdde1;-->*/
                /*<!--}-->*/
            /*<!--}-->*/
        /*<!--}-->*/

        /*<!--.date-item:last-child{-->*/
            /*<!--.input-date {-->*/
                /*<!--.filter {-->*/
                    /*<!--border-radius: 0 50px 50px 0;-->*/
                /*<!--}-->*/
            /*<!--}-->*/
        /*<!--}-->*/

        /*<!--.input-date{-->*/
            /*<!--//padding-left: 35px;-->*/
            /*<!--border-radius: 0;-->*/
            /*<!--background: transparent;-->*/
            /*<!--.filter{-->*/
                /*<!--outline: none;-->*/
                /*<!--box-shadow: none;-->*/
                /*<!--border: none;-->*/
                /*<!--width: 100%;-->*/
                /*<!--height: 32px;-->*/
                /*<!--font-size: 12px;-->*/
                /*<!--border-radius: 0;-->*/
                /*<!--padding: 0 15px;-->*/
                /*<!--background: #ffffff;-->*/
                /*<!--transition-duration: 0.3s;-->*/

                /*<!--&:focus{-->*/
                    /*<!--outline: none;-->*/
                    /*<!--box-shadow: none;-->*/

                /*<!--}-->*/

                /*<!--&::placeholder {-->*/
                    /*<!--color: #1f1f21;-->*/
                    /*<!--opacity: 1; !* Firefox *!-->*/
                /*<!--}-->*/

                /*<!--&:-ms-input-placeholder { !* Internet Explorer 10-11 *!-->*/
                    /*<!--color: #1f1f21;-->*/
                /*<!--}-->*/

                /*<!--&::-ms-input-placeholder { !* Microsoft Edge *!-->*/
                    /*<!--color: #1f1f21;-->*/
                /*<!--}-->*/

            /*<!--}-->*/

            /*<!--.form-control{-->*/
                /*<!--border-radius: 50px;-->*/
                /*<!--margin-bottom: 1rem;-->*/
            /*<!--}-->*/

            /*<!--.form-control::placeholder { !* Chrome, Firefox, Opera, Safari 10.1+ *!-->*/
                /*<!--opacity: 0.7; !* Firefox *!-->*/
            /*<!--}-->*/

            /*<!--.form-control[readonly]{-->*/
                /*<!--background: #fff;-->*/
            /*<!--}-->*/

            /*<!--.form-control:-ms-input-placeholder { !* Chrome, Firefox, Opera, Safari 10.1+ *!-->*/
                /*<!--opacity: 0.7; !* Firefox *!-->*/
            /*<!--}-->*/

            /*<!--.form-control::-ms-input-placeholder  { !* Chrome, Firefox, Opera, Safari 10.1+ *!-->*/
                /*<!--opacity: 0.7; !* Firefox *!-->*/
            /*<!--}-->*/


        /*}*/

    }

    /*.content-date{*/
        /*position: relative;*/

        /*.date-picker{*/
            /*position: absolute;*/
            /*right: 0;*/
            /*z-index: 20;*/
            /*top: 100%;*/
        /*}*/

    /*}*/



</style>
