<template>
  <div class="form-group content-button">
    <button :type="type == null ? 'button' : type" class="btn btn-primary btn-block btn-form" v-on:click="click">
        <span :class="showLoader ? 'hidden' : ''">
            {{ title }}
        </span>
    </button>
    <div v-if="showLoader" class="loader">Loading...</div>
  </div>
</template>

<script>
    export default {
        name: 'ButtonLoader',
        props: {
            title: String,
            type: null,
        },
        data(){
            return{
                showLoader: false
            }
        },
        methods: {
            click: function () {
                if(!this.showLoader){
                    this.$emit("click", this)
                }

            },
            setShowLoader: function (showLoader) {
                this.showLoader = showLoader;
            },
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .content-button{
        overflow: hidden;
        //padding-top: 15px;
        margin: 0;
        position: relative;

        button.btn-form{
            outline: none;
            box-shadow: none;
            padding-top: 0;
            padding-bottom: 0;
            transition-duration: 0.3s;

            color: #fff;
            border-radius: 50px;

            border-color: rgba(46, 30, 41, 1);
            background: rgba(46, 30, 41, 1);

            span{
                line-height: 30px;
                font-size: 15px;
            }

        }

        .hidden{
            visibility: hidden;
        }

        .loader {
            position: absolute;
            left: calc(50% - 15px);
            top: calc(50% - 15px);


            font-size: 10px;
            margin: 0;
            text-indent: -9999em;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #ffffff;
            background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
            background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
            background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
            background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
            background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
            -webkit-animation: load3 1.4s infinite linear;
            animation: load3 1.4s infinite linear;

            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
        }
        .loader:before {
            width: 50%;
            height: 50%;
            background: #ffffff;
            border-radius: 100% 0 0 0;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
        }
        .loader:after {
            background: rgba(46, 30, 41, 1);
            width: 75%;
            height: 75%;
            border-radius: 50%;
            content: '';
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
        @-webkit-keyframes load3 {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        @keyframes load3 {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }


    }






</style>
