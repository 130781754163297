<template>
    <div class="separator"></div>
</template>

<script>


export default {
    name: 'MenuItemSeparator',
    model: {

    },
    props: {
        link: null,
        title: null,
        icon: null,
    },
    data: function(){
        return {
            openMenu: '',
        }
    },
    mounted() {

    },
    methods: {
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /*NAVIGATION BAR */

  .separator{
      position: relative;
      height: 30px;

      &:before{
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          background: rgba(255,255,255,0.05);
          width: 100%;
          height: 1px;
      }

  }


</style>
