<template>
    <div id="root">
        <div :class="this.$store.getters.auth_token != null ? 'with-menu' : 'without-menu'">
            <span>
                Lo sentimos, la página que estás buscando no se pudo encontrar.
            </span>
            <router-link to="/empresas" >
                Ir a inicio
            </router-link>
        </div>
    </div>
</template>

<script>


    export default {
        name: 'PageNotfoundScreen',
    }
</script>

<style scoped lang="scss">


    #root{
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        div{
            position: absolute;
            top: 50%;
            width: 100%;
            padding: 15px;
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 23px;

            &.with-menu{
                left: calc(50%);
            }

            &.without-menu{
                left: 50%;
            }

            span{
                color: #444;
            }

            a{
                color: #c4c086;
                margin-top: 10px;
                font-weight: 600;
                font-size: 20px;
                display: block;
                &:hover{
                    text-decoration: none;
                }
            }

            @media all and (max-width: 1000px){
                left: 50%;
            }

        }


    }



</style>