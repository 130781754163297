<template>
    <div id="root">
        <div class="header-section">
            <div class="title">
                <h2>Comisiones <span v-if="comision != null">{{ comision.fecha_inicio | mesFormat }}</span></h2>
            </div>
        </div>
        <div class="content-section">
            <div v-for="(grupoComision, grupoIndex) in gruposComisiones" :key="grupoIndex" class="comision-item-grupo">
                <div class="data-section">
                    <div class="header">
                        <h2>{{ grupoComision.grupo.name }}</h2>
                    </div>
                    <div class="body-table">
                        <CustomTable csclass="simple">
                            <template v-slot:header>
                                <div class="col-5 col-sm-5">Usuario</div>
                                <div class="col-2 col-sm-2 text-center">Comisión</div>
                                <div class="col-2 col-sm-2 text-center">Total</div>
                                <div class="col-2 col-sm-2 text-center"></div>
                            </template>
                            <template v-slot:body>
                                <div v-if="grupoComision.usuarios.length == 0"  class="col-12 text-center">
                                    Sin resultados
                                </div>
                                <TableRow v-for="(usuario, index) in grupoComision.usuarios"
                                          :dropDown="true"
                                          :key="index"
                                          :id="usuario.id"
                                          :ref="`row-${usuario.id}`">
                                    <template v-slot:option>
                                        <div class="col-5 col-sm-5 nombre minimizar">
                                            {{ usuario.name }}
                                        </div>
                                        <div class="col-2 col-sm-2 text-center minimizar">
                                            {{ usuario.comision | toCurrency }}
                                        </div>
                                        <div class="col-2 col-sm-2 text-center minimizar">
                                            {{ usuario.comision_total | toCurrency }}
                                        </div>
                                        <div class="col-2 col-sm-2 text-center">
                                            <button v-if="usuario.recibo_id == null &&
                                                    checkPrivilege('comisiones.recibos')"
                                                    @click="generarRecibo(usuario)" class="btn btn-aplicar">
                                                Generar recibo
                                            </button>
                                            <button v-else-if="checkPrivilege('comisiones.cancelar')" @click="cancelarRecibo(usuario, usuario.recibo_id)" class="btn btn-aplicar cancelar">
                                                Eliminar recibo
                                            </button>
                                            <button v-if="usuario.recibo_id != null"
                                                    class="btn btn-aplicar imprimir"
                                                    @click="openRecibo(usuario.recibo_id)">
                                                Imprimir recibo
                                            </button>
                                        </div>
                                    </template>
                                    <template v-slot:suboptions>
                                        <div v-if="grupoComision.actos.length == 0" class="row row-item subs clickable">
                                            <div class="col-12 text-center">
                                                No hay registros
                                            </div>
                                        </div>
                                        <div v-for="(acto, index) in grupoComision.actos"
                                             :key="index"
                                             class="row row-item subs clickable">
                                            <div class="col-5 col-sm-5 nombre">
                                                <span >
                                                    {{ acto.nombre }}
                                                </span>
                                            </div>
                                            <div class="col-2 col-sm-2 text-center">
                                                {{ acto.precio | toCurrency }}
                                            </div>
                                            <div class="col-2 col-sm-2 text-center">
                                                {{ usuario.comision | toCurrency }}
                                            </div>
                                            <div class="col-2 col-sm-2 text-center minimizar">
                                                <span v-if="usuario.recibo_actos.length > 0 && usuario.recibo_actos.includes(acto.id)"
                                                      class="status active">Pagado</span>
                                            </div>
                                        </div>
                                    </template>
                                </TableRow>
                            </template>
                        </CustomTable>
                    </div>
                </div>
            </div>

        </div>

        <ModalForm ref="reciboForm"
                   title="Nuevo recibo"
                   v-on:submit="aplicarRecibo">
            <template v-slot:body>

                <ComboBoxSearch ref="cuentas" title="Cuentas"
                                placeholder="Seleccionar cuenta"/>

                <ComboBoxSearchSubtitle ref="categoriaGasto" title="Categoría"
                                        placeholder="Selecciona la categoría"/>

            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonRecibo" type="submit" title="Generar" />
                </div>
            </template>
        </ModalForm>

    </div>
</template>

<script>

    import CustomTable from '@/components/Table/CustomTable'
    import TableRow from '@/components/Table/TableRow'
    import ModalForm from '@/components/Modal/ModalForm'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ComboBoxSearchSubtitle from '@/components/Form/ComboBoxSearchSubtitle'
    import ComboBoxSearch from '@/components/Form/ComboBoxSearch'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'ComisionDetalleScreen',
        components: {
            ComboBoxSearch,
            CustomTable,
            TableRow,
            ModalForm,
            ButtonLoader,
            ComboBoxSearchSubtitle
        },
        data: function(){
            return {
                comisionId: this.$route.params.comisionId,
                comision: null,
                gruposComisiones: [],
                recibo: {},
                usuarioSeleccionado: null
            }
        },
        mounted() {
            this.$emit("showLoader");
            this.getComisionDetalle();

            if(this.checkPrivilege("comisiones.recibos")){
                this.getCuentas();
                this.getCategoriasGastos();
            }
        },
        methods: {
            openRecibo(reciboId){
                window.open(this.API_URL+"/recibo/"+reciboId);
            },
            getComisionDetalle(){
                let vue = this;
                $.get(this.API_URL + "/api/comisiones/"+this.comisionId, function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.comision = response.comision;
                        vue.gruposComisiones = response.grupos;
                    }
                    else{
                        if(response.abort){
                            vue.$router.push({ name: "PageNotfound" });
                        }
                        else{
                            swal(response.message, '', 'info')
                        }
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            aplicarRecibo(e){
                e.preventDefault();
                let vue = this;
                let cuenta = this.$refs.cuentas.getSelected();
                if(cuenta == null){
                    swal("Selecciona una cuenta", "", "info");
                    return;
                }
                this.recibo.cuenta_id = cuenta.key;
                let categoria = this.$refs.categoriaGasto.getSelected();
                if(categoria == null){
                    swal("Selecciona una categoría", "", "info");
                    return;
                }
                this.recibo.categoria_id = categoria.key;

                vue.$refs.buttonRecibo.setShowLoader(true);

                this.recibo.usuario_id = this.usuarioSeleccionado.id;
                this.recibo.comision_id = this.comision.id;

                $.post(vue.API_URL + "/api/comisiones/generar-recibo", this.recibo, function (response) {
                    vue.$refs.buttonRecibo.setShowLoader(false);
                    if(response.success){
                        vue.getComisionDetalle();
                        vue.$refs.reciboForm.hide();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    vue.$refs.buttonRecibo.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });

            },
            generarRecibo(usuario){
                this.recibo = {};
                this.usuarioSeleccionado = usuario;
                this.$refs.categoriaGasto.removeItem();
                this.$refs.cuentas.removeItem();
                this.ordenarCategoriasSugerencias();
                this.$refs.reciboForm.show();
            },
            cancelarRecibo(usuario, reciboId){
                let vue = this;
                swal({
                    text: '¿Realmente deseas cancelar el recibo?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    vue.$emit("showLoader");
                    let data = {usuario_id: usuario.id, recibo_id: reciboId, comision_id: vue.comision.id}
                    $.post(vue.API_URL + "/api/comisiones/cancelar-recibo", data, function (response) {
                        vue.$emit("hideLoader");
                        if(response.success){
                            swal("Recibo cancelado", "", "success");
                            vue.getComisionDetalle();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        vue.$emit("hideLoader");
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });
            },

            ordenarCategoriasSugerencias(){
                this.$refs.categoriaGasto.buscarSugerencias(this.recibo.descripcion);
            },
            getCuentas: function () {
                let vue = this;
                vue.cuentas = [];
                vue.$refs.cuentas.setCargando(true);
                $.get(this.API_URL + "/api/cuentas/getCuentas", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        for(let k in response.cuentas){
                            vue.cuentas[k] = {};
                            vue.cuentas[k].key = response.cuentas[k].id;
                            vue.cuentas[k].title = response.cuentas[k].nombre;
                            vue.cuentas[k].item = response.cuentas[k];
                            vue.cuentas[k].search = response.cuentas[k].search;
                        }
                        vue.$refs.cuentas.setData(vue.cuentas);
                        vue.$refs.cuentas.setCargando(false);

                        vue.$refs.cuentas.setData(vue.cuentas);
                        vue.$refs.cuentas.setCargando(false);

                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getCategoriasGastos: function () {
                let vue = this;
                vue.categoria = [];
                let data = {tipo: "gastos"};
                $.get(this.API_URL + "/api/categorias", data, function (response) {
                    if (response.success) {
                        vue.categorias = response.categorias;

                        var cats = [];
                        for(let k in vue.categorias){
                            cats[k] = {};
                            cats[k].key = vue.categorias[k].id;
                            cats[k].title = vue.categorias[k].nombre;
                            cats[k].subtitle = vue.categorias[k].descripcion;
                            cats[k].sugerencias = vue.categorias[k].sugerencias;
                            cats[k].search = vue.categorias[k].search;
                            cats[k].item = vue.categorias[k];
                        }

                        vue.$refs.categoriaGasto.setData(cats);
                        vue.$refs.categoriaGasto.setCargando(false);
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
        }
    }
</script>


<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;

        }

        .button-export-top{
            display: inline-block;
            margin-right: 10px;
            @media all and (max-width: 768px){
                margin: 0 0 0 10px;
            }
            @media all and (max-width: 530px) {
                padding-top: 5px;
            }
        }

        .estatus{
            text-transform: capitalize;
        }

        .btn-exportar{
            outline: none;
            display: inline-block;
            font-size: 13px;
            line-height: 29px;
            padding: 0 15px;
            color: #fff;
            border-color: #217344;
            background: #217344;
            border-radius: 50px;
            text-transform: uppercase;
            font-weight: bold;

            span{
                font-size: 11px;
            }

            &:hover{
                color: #fff;
            }
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .barcode-view{
            position: fixed;
            left: 220px;
            top: 0;
            width: calc(100% - 220px);
            height: 100%;
            z-index: 12;
            background: #000;
            @media all and (max-width: 1200px){
                left: 0;
                width: 100%;
            }

            .botones-camara{
                position: absolute;
                bottom: 20px;
                width: 100%;
                text-align: center;
                left: 50%;
                transform: translateX(-50%);
                z-index: 10;
                .btn-cancelar{
                    background: #999;
                    border-color: #999;
                    padding-right: 25px;
                    padding-left: 25px;
                    margin-right: 10px;
                }

                .btn-enviar{
                    margin-left: 10px;
                    padding-right: 25px;
                    padding-left: 25px;
                    background: #28a745;
                    border-color: #28a745;
                }
            }
        }

        .revisar-btn{
            button{
                background: rgb(0, 123, 255);
                border-color: rgb(0, 123, 255);
            }
        }

        .select-clientes{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;
        }

        .title{
            h2{
                span{
                    text-transform: capitalize;
                }
            }
        }

        .comision-item-grupo{
            margin-bottom: 30px;
            .header{
                position: relative;
                display: flex;
                border-bottom: 1px solid #dfe0eb;
                margin: 0;
                padding: 10px 15px;
                h2{
                    width: 100%;
                    font-size: 20px;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: left;
                    line-height: 32px;
                    margin: 0;
                }
            }
        }

        .btn-aplicar{
            color: #fff;
            background: #00acac;
            height: 30px;
            padding: 0 10px;
            font-size: 14px;
            margin: 5px;
            &:hover{
                color: #fff;
            }
            &.cancelar{
                background: rgb(255, 62, 9);
            }
            &.imprimir{
                background: rgb(0, 123, 255);
            }
        }

    }



</style>
