<template>
    <div id="root">
        <div class="header-section">
            <div class="title">
                <h2>Configuraciones</h2>
            </div>
            <div class="buttons">
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         @click="searchClick"
                                         table="categorias-gastos"
                                         title="Buscar" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <v-data-table
                    :headers="headers"
                    :items="configuracionesFiltradas"
                    hide-default-header
                    item-key="tabla-configs"
                    class="elevation-1" >

                <template v-slot:header="{ props: { headers } }">
                    <thead>
                    <tr>
                        <th v-for="header in headers"
                            :class="'text-left sortable ' + header.hide"
                            :key="header.value"
                            :width="header.width">
                            {{ header.text }}
                        </th>
                    </tr>
                    </thead>
                </template>


                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-if="items.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in items" v-on:click="detalleConfiguracion(item)" :key="index">
                        <td :class="headers[0].hide">{{ item.nombre }}</td>
                        <td :class="headers[1].hide">
                            <span v-if="item.tipo == 'date'">
                                {{ item.excerpt | dateFormat }}
                            </span>
                            <span v-else>{{ item.excerpt != undefined ? item.excerpt : item.description }}</span>
                        </td>
                        <td :class="headers[2].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>



        <ModalForm ref="configuracionForm"
                   title="Configuración"
                   v-on:submit="saveConfiguracion">
            <template v-slot:body>
                <input type="hidden" v-model="configuracion.id">

                <DatePicker v-show="configuracion.tipo == 'date'" styleClass="form-control" title="Fecha"
                            :dateInput.sync="configuracion.excerpt"
                            placeholder="dd-mm-yyyy"
                            format="DD-MM-YYYY"
                            ref="fecha" />

              <FormInput v-show="configuracion.tipo == 'number'"
                         :valueInput.sync="configuracion.excerpt"
                         type="number"
                         title="Valor"/>


            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonConfiguracion" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>

        <ModalDetail ref="configuracionDetalle" title="Configuración Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Nombre</b>
                        </td>
                        <td>
                            <span>{{ configuracion.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Valor</b>
                        </td>
                        <td>
                            <span v-if="configuracion.tipo == 'date'">
                                {{ configuracion.excerpt | dateFormat }}
                            </span>
                            <span v-else>{{ configuracion.excerpt != undefined ? configuracion.excerpt : configuracion.description }}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editConfiguracion"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>
            </template>
        </ModalDetail>

    </div>
</template>

<script>
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ModalForm from '@/components/Modal/ModalForm'
    import FormInput from '@/components/Form/FormInput'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import DatePicker from '@/components/DatePicker/DatePicker'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'ConfiguracionesScreen',
        components: {
            DatePicker,
            InputSearchComplete,
            FormInput,
            ModalForm,
            ModalDetail,
            ButtonLoader,
            ModalButtonFooter
        },
        data: function(){
            return {
                headers: [
                    {text: 'Nombre', align: 'left', value: 'nombre', width: '15%'},
                    {text: 'Valor', value: 'valor'},
                    {text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtroComputed: '',
                filtro: '',
                configuraciones: [],
                indexEdit: -1,
                configuracion: {}
            }
        },
        computed: {
            configuracionesFiltradas () {
                let vue = this;
                return this.configuraciones.filter(function (item) {
                    return item.search.toLowerCase().indexOf(vue.filtroComputed.toLowerCase()) > -1;
                });

            },
        },
        mounted() {
            this.$emit("showLoader");
            this.getConfiguraciones();
        },
        methods: {
            searchClick(){
                this.filtroComputed = this.filtro;
            },
            getConfiguraciones: function () {
                let vue = this;
                vue.configuraciones = [];
                $.get(this.API_URL + "/api/configuraciones", function (response) {
                    vue.$emit("hideLoader");
                    if (response.success) {
                        vue.configuraciones = response.configuraciones;
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            editConfiguracion: function () {
                this.$refs.configuracionDetalle.hide();
                this.$refs.fecha.setDate('');
                if(this.configuracion.tipo == "date"){
                    this.$refs.fecha.setDate(this.configuracion.excerpt);
                }
                this.openModalConfiguracion();
            },
            openModalConfiguracion: function () {
                this.$refs.configuracionForm.show();
            },
            closeModalConfiguracion: function(){
                this.$refs.configuracionForm.hide();
            },
            detalleConfiguracion: function (item) {
                this.indexEdit = this.configuraciones.indexOf(item);
                this.configuracion = Object.assign({}, item);

                this.$refs.configuracionDetalle.show();
            },
            saveConfiguracion: function (e) {
                e.preventDefault();

                let vue = this;

                vue.$refs.buttonConfiguracion.setShowLoader(true);
                $.post(vue.API_URL + "/api/configuraciones/save", vue.configuracion, function (response) {
                    vue.$refs.buttonConfiguracion.setShowLoader(false);
                    if(response.success){
                        vue.configuracion = response.record;

                        if (vue.indexEdit > -1) {
                            Object.assign(vue.configuraciones[vue.indexEdit], vue.configuracion)
                        } else {
                            vue.configuraciones.splice(0, 0, vue.configuracion)
                        }
                        vue.$emit("loadConfigs");
                        vue.configuracion = {};
                        vue.indexEdit = -1;
                        vue.closeModalConfiguracion();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }).fail(function (error) {
                    vue.$refs.buttonConfiguracion.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;

        }

        .button-export-top{
            display: inline-block;
            margin-right: 10px;
            @media all and (max-width: 768px){
                margin: 0 0 0 10px;
            }
            @media all and (max-width: 530px) {
                padding-top: 5px;
            }
        }

        .estatus{
            text-transform: capitalize;
        }

        .btn-exportar{
            outline: none;
            display: inline-block;
            font-size: 13px;
            line-height: 29px;
            padding: 0 15px;
            color: #fff;
            border-color: #217344;
            background: #217344;
            border-radius: 50px;
            text-transform: uppercase;
            font-weight: bold;

            span{
                font-size: 11px;
            }

            &:hover{
                color: #fff;
            }
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

    }



</style>
