<template>
    <li class="menu-option">
        <a href="#" v-on:click="toggleMenu" >
            <i :class="'icon fas ' + icon"></i>
            <span class="title-section">{{ title }}</span>
            <div class="baret"></div>
        </a>
        <div  :class="'cm-dropdown-menu ' + openMenu">
            <ul class="submenu-options">
                <slot></slot>
            </ul>
        </div>
    </li>
</template>

<script>

import * as $ from 'jquery';

export default {
    name: 'MenuGroup',
    model: {

    },
    props: {
        link: null,
        title: null,
        icon: null,
    },
    data: function(){
        return {
            openMenu: '',
        }
    },
    mounted() {
        if($(".submenu-options a[href='"+this.$router.currentRoute.path+"']").get(0) != null){
            this.openMenu = "open";
        }
    },
    methods: {
        toggleMenu: function (e) {
            e.preventDefault();
            if(this.openMenu === ''){
                this.openMenu = "open";
            }
            else{
                this.openMenu = "";
            }
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /*NAVIGATION BAR */

  a:hover, a:focus{
    text-decoration: none ;
  }

  .baret{
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 15px;
    vertical-align: middle;
    /*border-top: 4px dashed;*/
    border-top: 4px solid #fff;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-bottom: 4px solid transparent;
    margin-top: 2px;
    position: absolute;
    top: 50%;
    right: 0;
    transition-duration: 0.3s;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    &.open{
        border-top: 4px solid transparent;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        border-bottom: 4px solid #fff;
        margin-top: -2px;
    }

  }

  .menu-option{
      position: relative;


      a{
          display: block;
          position: relative;
          border-radius: 3px;
          color: #a4a6b3;
          padding: 7px 15px;
          font-size: 13px;
          /*border-top: 1px solid #333;*/

          &:hover {
              color: #c4c086;
              background-color: rgba(196,192,134,.05);
              &:before{
                  left: 0;
              }
          }

          &.router-link-active{
              color: #c4c086;
              background-color: rgba(196,192,134,.05);
              &:before{
                  left: 0;
              }
          }

          svg{
              width: 20px;
          }

          i{
              float: left;
              margin-right: 15px;
              line-height: 30px;
              width: 20px;
              text-align: center;
              color: #a9afbb;

              &:before{
                  font-size: 13px !important;
              }
          }

          span{
              margin: 0;
              line-height: 30px;
              font-size: 13px;
              position: relative;
              height: auto;
              padding-left: 15px;
              white-space: nowrap;
              font-weight: bold;
          }

          &:before{
              content: '';
              position: absolute;
              left: -4px;
              top: 0;
              width: 3px;
              height: 100%;
              background: #c4c086;
              transition-duration: 0.3s;
          }



      }



  }

  .cm-dropdown{
      position: relative;
  }

  .cm-dropdown-menu{
      overflow: hidden;
      position: relative;
      width: 100%;
      background-clip: padding-box;
      /*border: 1px solid rgba(0,0,0,.15);*/
      display: none;
      /*background: red;*/
      transition-duration: 2s;

      &.open{
          display: block;
      }

      .submenu-options{
          margin: 0;
          padding: 0;
          list-style: none;

          border-bottom: 1px solid rgba(86.7, 88.6, 100.0, 0.2);

          li{
              display: block;
              position: relative;

              a{
                  display: block;
                  padding: 10px 15px;
                  border-radius: 3px;
                  color: #a4a6b3;
                  font-size: 13px;

                  span{
                      margin: 0;
                      line-height: 30px;
                      font-size: 13px !important;
                      position: relative;
                      display: block;
                      height: auto;
                      white-space: nowrap;
                      font-weight: bold;
                  }


                  i{
                      float: left;
                      margin-right: 15px;
                      line-height: 30px;
                      width: 30px;
                      text-align: center;
                      color: #a9afbb;
                      &:before{
                          font-size: 8px !important;
                      }
                  }

              }

              &:hover a{
                  color: #dde2ff;
                  background-color: rgba(255,255,255,.05);
              }

          }


      }

  }


</style>
