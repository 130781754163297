<template>
    <div id="root">
        <div class="header-section">
            <div class="title">
                <h2>Cobranza</h2>
            </div>
            <div class="buttons">
              <div class="select-check-content">
                <SelectCheck ref="selectFiltro"
                             @change="filtrarChange"
                             placeholder="Todos los saldos"/>
              </div>
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         table="clientes"
                                         @click="searchClick"
                                         title="Buscar" />
                </div>
                <div class="button-export-top">
                    <button class="btn btn-exportar" v-on:click="exportCSV()">
                        <i class="fa fa-file-excel"></i>
                        &nbsp;
                        <span>
                            Exportar
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="content-section">
            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th v-for="(item, index) in headers" :key="index" :width="item.width"
                            :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                            {{ item.text }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="clientes.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in clientes" :key="index" @click="estadoCuenta(item)">
                        <td :class="headers[0].hide">
                            {{ item.cliente_nombre }} {{ item.cliente_apellidos }}
                        </td>
                        <td :class="headers[1].hide">
                          <span v-if="item.acto_id != undefined">
                            #{{ item.acto_id }} {{ item.empresa_nombre }}, {{ item.empresa_regimen }} -
                          {{ item.servicio_nombre }} - {{ item.fecha_inicio | dateFormat }} - {{ item.acto_precio | toCurrency }}
                          </span>
                        </td>
                        <td :class="headers[2].hide">
                            <p :class="'total-color' + (item.saldo_actual < 0 ? ' green_text' : '') + (item.saldo_actual > 0 ? ' red_text' : '')">
                              {{ item.saldo_actual > 0 ? '-' : '' }}{{ item.saldo_actual < 0 ? '+' : '' }}{{ item.saldo_actual | toCurrencyNormal }}
                            </p>
                        </td>
                        <td :class="headers[3].hide" style="width: 200px">
                            <span v-if="item.acto_id != undefined">
                                {{ item.limite_pago | dateFormat }} &nbsp;
                                <span :class="'status ' + (item.dias_faltantes > 0 ? 'warning' : 'urgente')">{{ item.dias_faltantes }}d</span>
                            </span>
                        </td>
                        <td :class="headers[4].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import SelectCheck from '@/components/Form/SelectCheck'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'CobranzaScreen',
        components: {
          SelectCheck,
            InputSearchComplete,
        },
        data: function(){
            return {
                page: 1,
                lastPage: 1,
                headers: [
                    { text: 'Cliente', align: 'left', value: 'folio', width: '20%'},
                    { text: 'Acto', align: 'left', value: 'empresa' },
                    { text: 'Saldo actual', value: 'fedatario', hide: 'text-center', width: '12%'},
                    { text: 'Fecha vencimiento', value: 'ultimo_evento', hide: 'text-center' },
                    { text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down' },
                ],
                filtro: '',
                clientes: [],
              selectFiltro: [
                {
                  key: "sp",
                  title: "Saldo por pagar",
                  checked: true
                },
                {
                  key: "sf",
                  title: "Saldo a favor",
                  checked: true
                },
                {
                  key: "ss",
                  title: "Sin saldo",
                  checked: true
                }
              ],
              filtroEstatus: [],
            }
        },
        mounted() {
            this.$emit("showLoader");
            this.$refs.selectFiltro.setData(this.selectFiltro);
            this.getClientes();
        },
        methods: {
            filtrarChange: function () {
              this.first(true);
            },
            urlfy(obj) {
              return Object
                  .keys(obj)
                  .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
                  .join('&');
            },
            exportCSV(){
                let data = {filtro: this.filtro, data: btoa(this.$store.getters.auth_token)};
                data = this.urlfy(data);
                window.open(this.API_URL + "/cobranza/exportar?"+data);
            },
            estadoCuenta(item){
              window.open("/cliente-cuenta_"+item.cliente_id);
            },
            searchClick(){
                this.first(true);
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.getClientes();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.getClientes();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.getClientes();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.getClientes();
                }
                else if(refresh){
                    this.page = 1;
                    this.getClientes();
                }
            },
            getClientes: function () {
                this.$emit("showLoader");
                this.filtroEstatus = this.$refs.selectFiltro.getSelecteds();
                let vue = this;
                vue.clientes = [];
                let data = {page: this.page, filtro: this.filtro, estatus: this.filtroEstatus};
                $.get(this.API_URL + "/api/cobranza/getPage", data, function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.page = response.clientes.current_page;
                        vue.lastPage = response.clientes.last_page;
                        vue.clientes = response.clientes.data;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

      .total-color{
        margin-bottom: 0;
        &.green_text{
          color: #29cc97;
        }
        &.red_text{
          color: #f12b2c;
        }
      }

      .active-message{
        color: #f12b2c;
        &.active{
          color: #29cc97;
        }
      }

        .select-clientes{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;
        }

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            width: 180px;
            vertical-align: bottom;
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px 90px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px 90px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

      .button-export-top{
        display: inline-block;
        .btn-exportar{
          outline: none;
          display: inline-block;
          font-size: 13px;
          line-height: 29px;
          padding: 0 15px;
          color: #fff;
          border-color: #217344;
          background: #217344;
          border-radius: 50px;
          text-transform: uppercase;
          font-weight: bold;

          span{
            font-size: 11px;
          }

          &:hover{
            color: #fff;
          }
        }

      }

    }



</style>
