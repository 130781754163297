import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
      auth_token: null,
      last_auth_token: null,
      user: null,
      fechaEdicion: null,
      contable_url: null
  },
  mutations: {  // syncronous
      auth_token(state, token){
          // localStorage.setItem("auth_token", token);
          state.auth_token = token;
      },
      last_auth_token(state, token){
          // localStorage.setItem("last_auth_token", token);
          state.last_auth_token = token;
      },
      user(state, user){
          // localStorage.setItem("user", user);
          state.user = user
      },
      fechaEdicion(state, fechaEdicion){
          state.fechaEdicion = fechaEdicion
      },
      contableUrl(state, contable_url){
          state.contable_url = contable_url
      }
  },
  actions: { // asyncronous
      auth_token(state, token){
          state.commit("auth_token", token);
      },
      last_auth_token(state, token){
          state.commit("last_auth_token", token);
      },
      user(state, user){
          state.commit("user", user);
      },
      fechaEdicion(state, fechaEdicion){
          state.commit("fechaEdicion", fechaEdicion);
      },
      contableUrl(state, contable_url){
          state.commit("contable_url", contable_url);
      }

  },
  getters: {
      auth_token(state){
          return state.auth_token;
      },
      last_auth_token(state){
          return state.last_auth_token;
      },
      user(state){
          return state.user;
      },
      fechaEdicion(state){
          return state.fechaEdicion;
      },
      contableUrl(state){
          return state.contable_url;
      }

  },
  plugins: [new VuexPersistence().plugin]
});
