<template>

    <div class="user">
        <div class="photo">
            <img v-if="this.$store.getters.user.image_url != undefined"
                 :src="this.$store.getters.user.image_url" alt="usuario">
            <img v-else src="../../assets/images/user.svg" alt="usuario">
        </div>
        <div class="cm-dropdown user-info">
            <a class="menu-option" v-on:click="toggleMenu" href="#">
                <span class="title-section">{{ this.$store.getters.user.name }}</span>
                <div :class="'baret ' + openMenu"></div>
            </a>
            <div :class="'cm-dropdown-menu full-width ' + openMenu">
                <ul class="submenu-options">
                    <li v-if="checkPrivilege('editar-perfil')">
                        <router-link to="/editar-perfil">
                            <i class="fas fa-pencil-alt"></i>
                            <span>Editar perfil</span>
                        </router-link>
                    </li>
                    <li>
                        <a href="#" v-on:click="logout">
                            <i class="fas fa-sign-out-alt"></i>
                            <span>Cerrar sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'MenuUser',
    model: {

    },
    props: {
    },
    data: function(){
        return {
            openMenu: '',
        }
    },
    methods: {
        toggleMenu: function (e) {
            e.preventDefault();
            if(this.openMenu === ''){
                this.openMenu = "open";
            }
            else{
                this.openMenu = "";
            }
        },
        logout: function (e) {
            e.preventDefault();

            // let data = {auth_token: this.$store.getters.auth_token};

            // $.post(this.API_URL + "/api/logout", data, function (res) {
            // }, 'json');

            this.$store.commit("auth_token", null);
            this.$store.commit("user", null);
            this.$router.replace({ name: "login" });
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


    .cm-dropdown{
        position: relative;

        .menu-option{
            position: relative;
        }

    }

    .cm-dropdown-menu{
        overflow: hidden;
        position: relative;
        width: 100%;
        background-clip: padding-box;
        /*border: 1px solid rgba(0,0,0,.15);*/
        display: none;
        /*background: red;*/
        transition-duration: 2s;

        &.open{
            display: block;
        }

        .submenu-options{
            margin: 0;
            padding: 10px 0 0 0;
            list-style: none;

            li{
                display: block;
                position: relative;
                color: #a4a6b3;

                a{
                    display: block;
                    border-radius: 3px;
                    color: #a4a6b3;
                    padding: 10px 15px;
                    font-size: 13px;
                    transition-duration: 0.3s;

                    span{
                        margin: 0;
                        line-height: 30px;
                        font-size: 13px !important;
                        position: relative;
                        height: auto;
                        padding-left: 15px;
                        white-space: nowrap;
                        font-weight: 300;
                    }


                    i{
                        float: left;
                        margin-right: 15px;
                        line-height: 30px;
                        width: 30px;
                        text-align: center;
                        color: #a9afbb;
                        &:before{
                            font-size: 8px !important;
                        }
                    }

                    &:before{
                        content: '';
                        position: absolute;
                        left: -4px;
                        top: 0;
                        width: 3px;
                        height: 100%;
                        background: #c4c086;
                        transition-duration: 0.3s;
                    }


                }

                &:hover a{
                    color: #c4c086;
                    background-color: rgba(196,192,134,.05);
                    &:before{
                        left: 0;
                    }
                }

            }


        }

    }


    .user{
        padding: 20px 0;
        margin: 0;
        position: relative;

        :hover{
            text-decoration: none;
        }

        .baret{
            display: inline-block;
            width: 0;
            height: 0;
            margin-right: 15px;
            vertical-align: middle;
            /*border-top: 4px dashed;*/
            border-top: 4px solid #fff;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            border-bottom: 4px solid transparent;
            margin-top: 2px;
            position: absolute;
            top: 50%;
            right: 0;
            transition-duration: 0.3s;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

            &.open{
                border-top: 4px solid transparent;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
                border-bottom: 4px solid #fff;
                margin-top: -2px;
            }

        }

        .photo{
            width: 34px;
            height: 34px;
            overflow: hidden;
            float: left;
            z-index: 5;
            margin-right: 11px;
            border-radius: 50%;
            margin-left: 15px;
            box-shadow: 0 16px 38px -12px rgba(0,0,0,.56), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);

            img{
                vertical-align: middle;
                border-style: none;
                width: 100%;
            }

        }

        .user-info > a{
            display: block;
            line-height: 24px;
            font-size: 14px;
            padding: .5rem 15px;
            white-space: nowrap;
            color: #a4a6b3;

            > span{
                display: block;
                position: relative;
                opacity: 1;
            }

            .title-section{
                display: block;
                max-width: 120px;
                overflow: hidden;
            }

            .email-user{
                display: block;
                font-size: 10px !important;
                margin-top: -7px;
                max-width: 150px;
                overflow: hidden;
                /*letter-spacing: 1px;*/
                color: #ccc !important;
            }

        }

        i{
            margin-top: -2px !important;

            &:before{
                font-size: 18px !important;
            }

        }

    }

</style>
