import { render, staticRenderFns } from "./CategoriasGastos.vue?vue&type=template&id=133fbf5c&scoped=true&"
import script from "./CategoriasGastos.vue?vue&type=script&lang=js&"
export * from "./CategoriasGastos.vue?vue&type=script&lang=js&"
import style0 from "./CategoriasGastos.vue?vue&type=style&index=0&id=133fbf5c&prod&lang=scss&"
import style1 from "./CategoriasGastos.vue?vue&type=style&index=1&id=133fbf5c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "133fbf5c",
  null
  
)

export default component.exports