<template>
    <div id="root">
        <div v-if="checkPrivilege('servicios.crear')" class="button-add-bottom">
            <ButtonFloat v-on:click="showNew"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Servicios</h2>
            </div>
            <div class="buttons">
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         table="servicios"
                                         @click="searchClick"
                                         title="Buscar" />
                </div>
                <div v-if="checkPrivilege('servicios.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="showNew" title="Nuevo +" />
                </div>
            </div>
        </div>
        <div class="content-section">

            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th v-for="(item, index) in headers" :key="index" :width="item.width"
                            :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                            {{ item.text }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="recordList.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in recordList" :key="index" v-on:click="showDetail(item)">
                        <td :class="headers[0].hide">{{ item.nombre }}</td>
                        <td :class="headers[1].hide">{{ item.precio | toCurrency }}</td>
                        <td :class="headers[2].hide">{{ item.duracion }}</td>
                        <td :class="headers[3].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <ModalForm id="editForm"
                   ref="editForm"
                   title="Servicio"
                   v-on:submit="saveRecord">
            <template v-slot:body>
                <input type="hidden" v-model="record.id">
                <FormInput :valueInput.sync="record.nombre" :required="true"
                           name="nombre" title="Nombre" />
                <FormInput :valueInput.sync="record.precio" type="number" name="precio" title="Precio" />
                <FormInput :valueInput.sync="record.duracion" type="number" name="duracion" title="Duración (días)" />
            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="btnConfirmSave" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>


        <ModalDetail ref="modalDetalle" title="Servicio">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Nombre</b>
                        </td>
                        <td>
                            <span>{{ record.nombre }}</span>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Precio</b>
                        </td>
                        <td>
                            <span>{{ record.precio | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Duración</b>
                        </td>
                        <td>
                            <span>{{ record.duracion }}</span>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="checkPrivilege('servicios.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="showEdit"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div v-if="checkPrivilege('servicios.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="showDelete"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>


    </div>
</template>

<script>

    import FormInput from '@/components/Form/FormInput'
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ModalForm from '@/components/Modal/ModalForm'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'ServiciosScreen',
        components: {
            ButtonFloat,
            FormInput,
            InputSearchComplete,
            ButtonAdd,
            ButtonLoader,
            ModalDetail,
            ModalForm,
            ModalButtonFooter
        },
        data: function(){
            return {
                page: 1,
                lastPage: 1,
                headers: [
                    {text: 'Nombre', align: 'left', value: 'nombre', width: '50%'},
                    {text: 'Precio', align: 'left', value: 'precio', width: '20%'},
                    {text: 'Duración', align: 'left', value: 'duracion', width: '13%', hide: 'text-center'},
                    { text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtro: '',
                recordList: [],
                record: {},
                indexEdit: -1
            }
        },
        // computed: {
        //     filteredRecords () {
        //         let vue = this;
        //         return this.recordList.filter(function (soc) {
        //             return soc.search.toLowerCase().indexOf(vue.filtro.toLowerCase()) > -1;
        //         });
        //     },
        // },
        mounted() {
            this.loadRecords();
        },
        methods: {
            searchClick(){
                this.first(true);
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.loadRecords();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.loadRecords();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.loadRecords();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.loadRecords();
                }
                else if(refresh){
                    this.page = 1;
                    this.loadRecords();
                }
            },
            loadRecords: function () {
                this.$emit("showLoader");
                let vue = this;
                vue.recordList = [];
                let data = {page: this.page, filtro: this.filtro};
                $.get(this.API_URL + "/api/servicios/getPage", data, function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.page = response.servicios.current_page;
                        vue.lastPage = response.servicios.last_page;
                        vue.recordList = response.servicios.data;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            showDelete() {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/servicios/delete", {id: vue.record.id}, function (response) {
                        if(response.success){
                            vue.recordList.splice(vue.indexEdit, 1);
                            vue.record = {};
                            vue.$refs.modalDetalle.hide();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });

            },
            saveRecord: function (e) {
                e.preventDefault();
                this.$refs.btnConfirmSave.setShowLoader(true);
                let vue = this;

                $.post(this.API_URL + "/api/servicios/save", vue.record, function (response) {
                    vue.$refs.btnConfirmSave.setShowLoader(false);

                    if(response.success){
                        vue.record = response.record;

                        if (vue.indexEdit > -1) {
                            Object.assign(vue.recordList[vue.indexEdit], vue.record)
                        } else {
                            vue.recordList.splice(0, 0, vue.record)
                        }
                        vue.record = {};
                        vue.indexEdit = -1;
                        vue.$refs.editForm.hide();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    vue.$refs.btnConfirmSave.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });

            },
            showDetail: function (item) {
                this.indexEdit = this.recordList.indexOf(item);
                this.record = Object.assign({}, item);
                this.$refs.modalDetalle.show();
            },
            showEdit: function () {
                this.$refs.modalDetalle.hide();
                this.$refs.editForm.show();
            },
            showNew: function () {
                this.record = {};
                this.indexEdit = -1;
                this.$refs.editForm.show();
            },
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .btn-group button {
            background-color: #4CAF50; /* Green background */
            border: 1px solid green; /* Green border */
            color: white; /* White text */
            padding: 10px 24px; /* Some padding */
            cursor: pointer; /* Pointer/hand icon */
            width: 50%; /* Set a width if needed */
            display: block; /* Make the buttons appear below each other */
        }

        .btn-group button:not(:last-child) {
            border-bottom: none; /* Prevent double borders */
        }

        /* Add a background color on hover */
        .btn-group button:hover {
            background-color: #3e8e41;
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }


    }



</style>
