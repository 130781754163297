<template>
    <div id="root">
        <div class="button-add-bottom">
            <ButtonFloat v-if="checkPrivilege('usuarios.crear')" v-on:click="nuevoUsuario"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Usuario</h2>
            </div>
            <div class="buttons">
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         @click="searchClick"
                                         table="usuarios"
                                         title="Buscar" />
                </div>
                <div v-if="checkPrivilege('usuarios.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="nuevoUsuario" title="Nuevo +" />
                </div>
            </div>
        </div>
        <div class="content-section">

            <v-data-table
                    :headers="headers"
                    :items="usuariosFiltrados"
                    hide-default-header
                    item-key="tabla-usuarios"
                    class="elevation-1" >

                <template v-slot:header="{ props: { headers } }">
                    <thead>
                    <tr>
                        <th v-for="header in headers"
                            :class="'text-left sortable ' + header.hide"
                            :key="header.value"
                            :width="header.width">
                            {{ header.text }}
                        </th>
                    </tr>
                    </thead>
                </template>


                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-if="items.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="item in items" :key="item.id" v-on:click="detalleUsuario(item)">
                        <td :class="headers[0].hide">{{ item.name }}</td>
                        <td :class="headers[1].hide">
                            <span v-if="item.profile != null">
                                {{ item.profile.name }}
                            </span>
                        </td>
                        <td :class="headers[2].hide">
                            <span v-if="item.grupo != null">
                                {{ item.grupo.name }}
                            </span>
                        </td>
                        <td :class="headers[3].hide">{{ item.username }}</td>
                        <td :class="headers[4].hide">
                            <span :class="'status ' + (item.status  === 'active' ? 'active' : 'inactive')" >
                                {{ item.status  === 'active' ? 'Activo' : 'Inactivo' }}
                            </span>
                        </td>
                        <td :class="headers[5].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>


        <ModalForm id="modalUsuario"
                   ref="usuarioForm"
                   title="Usuario"
                   v-on:submit="saveUsuario">
            <template v-slot:body>
                    <FormInput :valueInput.sync="usuario.name" :required="true"
                               name="nombre" title="Nombre" />

                    <FormSelect :disabled="usuario.id == $store.getters.user.id" :valueInput.sync="usuario.profile_id"
                                name="perfil" title="Perfil" >
                        <option value="">Selecciona una opción</option>

                        <option v-for="(perfil, index) in perfiles" :key="index" :value="perfil.id">
                            {{ perfil.name }}
                        </option>
                    </FormSelect>

                    <FormSelect :valueInput.sync="usuario.group_id"
                                name="grupo" title="Grupo" >
                        <option :value="null">Selecciona un grupo</option>
                        <option v-for="(grupo, index) in grupos" :key="index" :value="grupo.id">
                            {{ grupo.name }}
                        </option>
                    </FormSelect>

                    <FormInput v-show="usuario.group_id != null && usuario.group_id != ''"
                               :valueInput.sync="usuario.comision" :required="false"
                               type="number"
                               name="comision" title="Comisión ($)"
                               placeholder="$0.00"/>

                    <FormInput :valueInput.sync="usuario.username"
                               :required="true"
                               name="email" title="Correo electrónico" />


                    <FormInput title="Contraseña"
                               :required="usuario.id == undefined"
                               type="password"
                               name="password"
                               :valueInput.sync="usuario.password"/>

                    <FormInput title="Repetir contraseña"
                               :required="usuario.id == undefined"
                               type="password"
                               placeholder="Repetir contraseña"
                               name="password"
                               :valueInput.sync="usuario.password_confirmation"/>

                    <FormInput title="Código único"
                               type="text"
                               name="token"
                               :valueInput.sync="usuario.token"/>

                    <FormCheck v-model="usuario.status"
                               valueInput="active"
                               valueNotChecked="inactive"
                               :checked="usuario.status === 'active'"
                               name="activo"
                               ref="checkStatus"
                               title="activo" />

            </template>

            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonUsuario" type="submit" title="Guardar" />
                </div>
            </template>

        </ModalForm>

        <ModalDetail ref="usuarioDetalle" title="Usuario Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Nombre</b>
                        </td>
                        <td>
                            <span>{{ usuario.name }}</span>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Perfil</b>
                        </td>
                        <td>
                            <span v-if="usuario.profile != null">{{ usuario.profile.name }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Grupo</b>
                        </td>
                        <td>
                            <span v-if="usuario.grupo != null">{{ usuario.grupo.name }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Comisión</b>
                        </td>
                        <td>
                            <span v-if="usuario.comision != null">{{ usuario.comision | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Correo electrónico</b>
                        </td>
                        <td>
                            <span>{{ usuario.username }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Estatus</b>
                        </td>
                        <td>
                            <span :class="'status ' + (usuario.status  === 'active' ? 'active' : 'inactive')" >
                                {{ usuario.status  === 'active' ? 'Activo' : 'Inactivo' }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Código único</b>
                      </td>
                      <td>
                          {{ usuario.token }}
                      </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="checkPrivilege('usuarios.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editUsuario"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div v-if="checkPrivilege('usuarios.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteUsuario"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>

    </div>
</template>

<script>

    import FormInput from '@/components/Form/FormInput'
    import FormSelect from '@/components/Form/FormSelect'
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import FormCheck from '@/components/Form/FormCheck'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ModalForm from '@/components/Modal/ModalForm'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'UsuariosScreen',
        components: {
            FormInput,
            FormSelect,
            InputSearchComplete,
            ButtonFloat,
            ButtonLoader,
            ButtonAdd,
            FormCheck,
            ModalDetail,
            ModalForm,
            ModalButtonFooter
        },
        data: function(){
            return {
                headers: [
                    {text: 'Nombre', align: 'left', value: 'nombre', width: '35%'},
                    { text: 'Perfil', value: 'perfil', width: '20%' },
                    { text: 'Grupo', value: 'grupo', width: '20%' },
                    { text: 'Correo electrónico', value: 'email', width: '25%', hide: 'hidden-sm-and-down' },
                    { text: 'Estatus', value: 'estatus', width: '10%' },
                    { text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtroComputed: '',
                filtro: '',
                usuarios: [],
                perfiles: [],
                grupos: [],
                usuario: {group_id: null},
                indexEdit: -1
            }
        },
        computed: {
            usuariosFiltrados () {
                let vue = this;
                return this.usuarios.filter(function (usr) {
                    return usr.search.toLowerCase().indexOf(vue.filtroComputed.toLowerCase()) > -1;
                });
            },
        },
        mounted() {
            this.$emit("showLoader");
            this.getUsuarios();
            this.getPerfiles();
            this.getGrupos();
        },
        methods: {
            searchClick(){
                this.filtroComputed = this.filtro;
            },
            getUsuarios: function () {
                let vue = this;
                vue.usuarios = [];
                $.get(this.API_URL + "/api/users/get", function (response) {
                    if(response.success){
                        vue.usuarios = response.users;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getPerfiles: function () {
                let vue = this;
                vue.perfiles = [];
                $.get(this.API_URL + "/api/users/get-profiles", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.perfiles = response.profiles;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getGrupos: function () {
                let vue = this;
                vue.grupos = [];
                $.get(this.API_URL + "/api/grupos", function (response) {
                    if(response.success){
                        vue.grupos = response.grupos;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            deleteUsuario () {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/users/delete", vue.usuario, function (response) {
                        if(response.success){
                            vue.usuarios.splice(vue.indexEdit, 1);
                            vue.usuario = {};
                            vue.$refs.usuarioDetalle.hide();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });

            },
            saveUsuario: function (e) {
                e.preventDefault();
                this.$refs.buttonUsuario.setShowLoader(true);
                let vue = this;

                if(this.usuario.status == "active"){
                    this.usuario.status = true;
                }

                $.post(this.API_URL + "/api/users/save", this.usuario, function (response) {
                   vue.$refs.buttonUsuario.setShowLoader(false);
                   if(response.success){
                       vue.usuario = response.record;

                       if (vue.indexEdit > -1) {
                           Object.assign(vue.usuarios[vue.indexEdit], vue.usuario)
                       } else {
                           vue.usuarios.splice(0, 0, vue.usuario)
                       }
                       vue.usuario = {};
                       vue.indexEdit = -1;
                       vue.closeModalUsuario();
                   }
                   else{
                       swal(response.message, "", "info")
                   }
                }, "json").fail(function (error) {
                    vue.$refs.buttonUsuario.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });

            },
            detalleUsuario: function (usuario) {
                this.indexEdit = this.usuarios.indexOf(usuario);
                this.usuario = Object.assign({}, usuario);

                this.$refs.usuarioDetalle.show();

            },
            editUsuario: function () {
                this.$refs.usuarioDetalle.hide();
                this.openModalUsuario();
            },
            nuevoUsuario: function () {
                this.usuario = {group_id: null};
                this.indexEdit = -1;
                this.openModalUsuario();
            },
            openModalUsuario: function () {
                this.$refs.checkStatus.setChecked(this.usuario.status === 'active'  || this.usuario.id === undefined);
                $("#modalUsuario").modal("show");
                this.$refs.usuarioForm.show();
            },
            closeModalUsuario: function () {
                this.$refs.usuarioForm.hide();
            }
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .btn-group button {
            background-color: #4CAF50; /* Green background */
            border: 1px solid green; /* Green border */
            color: white; /* White text */
            padding: 10px 24px; /* Some padding */
            cursor: pointer; /* Pointer/hand icon */
            width: 50%; /* Set a width if needed */
            display: block; /* Make the buttons appear below each other */
        }

        .btn-group button:not(:last-child) {
            border-bottom: none; /* Prevent double borders */
        }

        /* Add a background color on hover */
        .btn-group button:hover {
            background-color: #3e8e41;
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

    }



</style>
