<template>
    <div class="form-group">
        <label v-if="title != null">{{ title }}</label>
        <div class="content-file">
            <i  class="fas fa-file-upload"></i>
            <span v-if="fileName != ''">
                {{ fileName }}
            </span>
            <span v-else>
                {{ placeholder == null ? 'Selecciona ' + title.toLowerCase() : placeholder }}
            </span>
            <input ref="input" :required="required != null ? required : false" type="file" class="form-control"
                   @input="update"
                   :placeholder="placeholder == null ? 'Selecciona ' + title.toLowerCase() : placeholder"
                   :name="name != null ? name : ''">
        </div>
    </div>
</template>

<script>
import * as $ from 'jquery';
    export default {
        name: 'FormFile',
        model: {

        },
        props: {
            title: String,
            name: null,
            required: Boolean,
            placeholder: null,
            valueInput: String('')
        },
        data: function(){
            return {
                fileName: ''
            }
        },
        methods: {
            update: function (e) {
                let fileData =  e.target.files[0];
                this.$emit('update:valueInput', fileData);
                this.fileName = fileData.name;
            },
            reset: function () {
                this.fileName = '';
                $(this.$refs.input).val('');
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .content-file{
        background: #fff;
        border-radius: 50px;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        border: 1px solid #ced4da;
        position: relative;
        overflow: hidden;

        span{
            padding-left: 5px;
            color: #aaa;
            max-width: 100%;
        }

        input{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }

    }

    .form-control{
        border-radius: 50px;
    }

    .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 0.7; /* Firefox */
    }

    .form-control:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 0.7; /* Firefox */
    }

    .form-control::-ms-input-placeholder  { /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 0.7; /* Firefox */
    }

    .form-control.currency{
        /*background: red;*/
    }

</style>
