<template>
    <div id="root">
        <div v-if="camaraPermiso" class="barcode-view">
            <stream-barcode-reader @decode="onDecode"
                                   @loaded="onLoaded"
                                   @error="onError"></stream-barcode-reader>


            <div class="botones-camara">
                <button class="btn btn-primary btn-cancelar" @click="cancelarRevision">Cerrar cámara</button>
            </div>

        </div>

        <div class="header-section">
            <div class="title">
                <h2>Revisión actos</h2>
            </div>
            <div class="buttons">
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         @click="agregarActo()"
                                         title="Agregar código" />
                </div>
                <div class="button-export-top revisar-btn">
                    <button class="btn btn-exportar" @click="cameraStart">
                        <i class="fa fa-camera"></i>
                        &nbsp;
                        <span>
                            Usar cámara
                        </span>
                    </button>
                </div>
                <div class="button-add-top">
                    <ButtonAdd v-on:click="finalizarRevisionModal" title="Aplicar" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th v-for="(item, index) in headers" :width="item.width"
                            :key="index"
                            :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                            {{ item.text }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="actos.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in actos" :key="index" v-on:click="detalleActo(item)">
                        <td :class="headers[0].hide">
                            <span v-if="item.acto != null">{{ item.acto.nombre }}</span>
                        </td>
                        <td :class="headers[1].hide">
                            {{ item.updated_at | dateTimeFormatMX }}
                        </td>
                        <td :class="headers[2].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <ModalDetail ref="actoDetalle" title="Acto Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Acto</b>
                        </td>
                        <td>
                            <span v-if="acto.acto != null">{{ acto.acto.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Última actualización</b>
                        </td>
                        <td>
                            {{ acto.updated_at | dateTimeFormatMX }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>
                <div class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteActo"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Remover"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>

        <ModalForm ref="aplicarActosForm"
                   title="Aplicar cambios"
                   v-on:submit="finalizarRevision">
            <template v-slot:body>

                <FormSelect :valueInput.sync="aplicarActos.campo"
                            name="campo" title="Campo *" :required="true">
                    <option :value="null">Selecciona una opción</option>
                    <option value="fecha_archivo">Fecha archivo</option>
                    <option value="fecha_entrega">Fecha de entrega</option>
                    <option value="fecha_pago">Fecha de pago</option>
                    <option value="fecha_registro">Fecha de registro</option>
                    <option value="fecha_constitucion">Fecha de constitución</option>
                    <option value="fecha_apartado">Fecha de apartado</option>
                    <option value="fecha_cita_fiel">Fecha cita fiel</option>
                    <option value="fecha_comision">Fecha de comisión</option>

                </FormSelect>

                <DatePicker styleClass="form-control" title="Fecha"
                            placeholder="dd-mm-yyyy"
                            :dateInput.sync="aplicarActos.fecha"
                            format="DD-MM-YYYY" />
            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonAplicarActos" type="submit" title="Aplicar" />
                </div>
            </template>
        </ModalForm>

    </div>
</template>

<script>
import moment from "moment";

var lastCodeCamera = null;

import InputSearchComplete from '@/components/Filter/InputSearchComplete'
import ButtonAdd from '@/components/Filter/ButtonAdd'
import ModalDetail from '@/components/Modal/ModalDetail'
import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
import { StreamBarcodeReader } from "vue-barcode-reader";
import ModalForm from '@/components/Modal/ModalForm';
import ButtonLoader from '@/components/Form/ButtonLoader';
import DatePicker from '@/components/DatePicker/DatePicker';
import FormSelect from '@/components/Form/FormSelect';
import * as $ from 'jquery';
import swal from 'sweetalert2';


export default {
    name: 'RevisionActosScreen',
    components: {
        InputSearchComplete,
        ButtonAdd,
        ModalDetail,
        ModalButtonFooter,
        StreamBarcodeReader,
        ModalForm,
        ButtonLoader,
        FormSelect,
        DatePicker
    },
    data: function(){
        return {
            filtro: "",
            headers: [
                {text: 'Acto', align: 'left', value: 'acto'},
                {text: 'Última actualización', value: 'actualizacion', width: '200px', hide: 'text-center'},
                {text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
            ],

            aplicarActos: {},

            actos: [],
            indexEdit: -1,
            acto: {},

            page: 1,
            lastPage: 1,

            camaraPermiso: false,
        }
    },
    mounted() {
        this.$emit("showLoader");
        this.getActos();
    },
    methods: {
        finalizarRevisionModal(){
            let date = new Date();
            var options = {campo: null}
            options.fecha = moment(date.toISOString()).format("YYYY-MM-DD");

            this.aplicarActos = Object.assign({}, options);
            this.$refs.aplicarActosForm.show();
        },
        finalizarRevision(e){
            e.preventDefault();
            let vue = this;
            swal({
                text: '¿Realmente deseas aplicar los cambios?',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                type: 'warning'
            }).then(function () {
                vue.$refs.buttonAplicarActos.setShowLoader(true);

                $.post(vue.API_URL + "/api/revision-actos/aplicar", vue.aplicarActos, function (response) {
                    vue.$refs.buttonAplicarActos.setShowLoader(false);
                    if(response.success){
                        vue.page = 1;
                        vue.getActos();
                        vue.$refs.aplicarActosForm.hide();
                        swal(response.message, "", "success")
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    vue.$refs.buttonAplicarActos.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            });
        },
        agregarActo(actoId = null){
            let vue = this;

            let data = {actoId: this.filtro}

            if(actoId != null){
                lastCodeCamera = actoId;
                data.actoId = actoId;
            }
            $.post(vue.API_URL + "/api/revision-actos/agregar", data, function (response) {
                if(response.success){
                    if(actoId == null){
                        vue.filtro = "";
                    }
                    vue.getActos();
                    swal("Acto agregado", "", "success");
                }
                else{
                    lastCodeCamera = null;
                    swal(response.message, "", "info")
                }
            }, "json").fail(function (error) {
                vue.$emit("hideLoader");
                lastCodeCamera = null;
                if(error.status === 401){
                    swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                    vue.$emit("logout");
                }
                else{
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                }

            });

        },
        getActos: function () {
            let vue = this;
            vue.actos = [];
            let data = {page: this.page};
            $.get(this.API_URL + "/api/revision-actos", data, function (response) {
                vue.$emit("hideLoader");
                if (response.success) {
                    vue.page = response.actos.current_page;
                    vue.lastPage = response.actos.last_page;
                    vue.actos = response.actos.data;
                }
                else {
                    swal(response.message, '', 'info')
                }
            }, "json").fail(function (error) {
                vue.$emit("hideLoader");
                if (error.status === 401) {
                    swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                    vue.$emit("logout");
                }
                else{
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                }
            });
        },
        detalleActo: function (item) {
            this.indexEdit = this.actos.indexOf(item);
            this.acto = Object.assign({}, item);

            this.$refs.actoDetalle.show();
        },
        deleteActo: function () {
            let vue = this;
            swal({
                text: '¿Realmente deseas eliminar el registro?',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                type: 'warning'
            }).then(function () {
                $.post(vue.API_URL + "/api/revision-actos/eliminar", vue.acto, function (response) {
                    if(response.success){
                        vue.getActos();
                        vue.$refs.actoDetalle.hide();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            });
        },
        next: function () {
            this.page += 1;
            if(this.page > this.lastPage){
                this.page = this.lastPage;
            }
            else{
                this.getActos();
            }
        },
        prev: function () {
            this.page -= 1;
            if(this.page < 1){
                this.page = 1;
            }
            else{
                this.getActos();
            }

        },
        last: function () {
            if(this.page != this.lastPage) {
                this.page = this.lastPage;
                this.getActos();
            }
        },
        first: function (refresh) {
            if(this.page != 1) {
                this.page = 1;
                this.getActos();
            }
            else if(refresh){
                this.page = 1;
                this.getActos();
            }
        },
        onDecode (result) {
            if(lastCodeCamera != result){
                this.agregarActo(result);
            }
        },
        onLoaded(){

        },
        onError(){},
        stopCamara(){
            let videoElem = document.querySelector(".barcode-view video");
            const stream = videoElem.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach((track) => {
                track.stop();
            });
            videoElem.srcObject = null;
            this.camaraPermiso = false;
        },
        cancelarRevision(){
            this.stopCamara();
        },
        cameraStart(){
            this.camaraPermiso = true;
        }
    }
}
</script>

<style lang="scss">
.barcode-view{
    video{
        width: 100%;
        height: 100vh;
    }
}
</style>

<style scoped lang="scss">


#root{
    background: #f7f8fc;
    padding: 30px 25px;
    width: 100%;
    height: 100%;

    .select-check-content{
        display: inline-block;
        margin-right: 10px;
        width: 160px;
        vertical-align: bottom;

    }

    .button-export-top{
        display: inline-block;
        margin-right: 10px;
        @media all and (max-width: 768px){
            margin: 0 0 0 10px;
        }
        @media all and (max-width: 530px) {
            padding-top: 5px;
        }
    }

    .estatus{
        text-transform: capitalize;
    }

    .btn-exportar{
        outline: none;
        display: inline-block;
        font-size: 13px;
        line-height: 29px;
        padding: 0 15px;
        color: #fff;
        border-color: #217344;
        background: #217344;
        border-radius: 50px;
        text-transform: uppercase;
        font-weight: bold;

        span{
            font-size: 11px;
        }

        &:hover{
            color: #fff;
        }
    }

    .button-save-content{
        width: 120px;
    }

    @media all and (max-width: 768px){
        padding: 15px 15px;
    }

    @media all and (max-width: 576px){
        padding: 10px 5px;
    }

    .btn-more{
        color: #aaa;
        &:hover{
            color: #333;
        }
    }

    .barcode-view{
        position: fixed;
        left: 220px;
        top: 0;
        width: calc(100% - 220px);
        height: 100%;
        z-index: 12;
        background: #000;
        @media all and (max-width: 1200px){
            left: 0;
            width: 100%;
        }

        .botones-camara{
            position: absolute;
            bottom: 20px;
            width: 100%;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
            .btn-cancelar{
                background: #999;
                border-color: #999;
                padding-right: 25px;
                padding-left: 25px;
                margin-right: 10px;
            }

            .btn-enviar{
                margin-left: 10px;
                padding-right: 25px;
                padding-left: 25px;
                background: #28a745;
                border-color: #28a745;
            }
        }
    }

    .revisar-btn{
        button{
            background: rgb(0, 123, 255);
            border-color: rgb(0, 123, 255);
        }
    }

    .button-add-top{
        @media all and (max-width: 768px) {
            margin-left: 20px;
            display: inline-block !important;
        }
    }

}



</style>
