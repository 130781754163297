<template>
    <div id="root">
        <div v-if="checkPrivilege('socios.crear') || checkPrivilege('proveedores.crear')" class="button-add-bottom">
            <ButtonOptionsFloat>
                <ButtonOptionItem v-if="checkPrivilege('proveedores.crear')" v-on:click="nuevoProveedor" title="Proveedor" icon="fa-exchange-alt" />
                <ButtonOptionItem v-if="checkPrivilege('socios.crear')" v-on:click="nuevoSocio" title="Socio" icon="fa-money-check-alt" />
            </ButtonOptionsFloat>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Proveedores y Socios</h2>
            </div>
            <div class="buttons">
                <div v-if="checkPrivilege('actos.ignorar-grupo')" class="select-filtro select-grupo">
                    <FiltroSelect :valueInput.sync="grupo_id" name="grupo_id"
                        @change="getProveedores">
                        <option :value="null">Todos los grupos</option>
                        <option v-for="(item, index) in grupos" :key="index" :value="item.id">{{ item.name }}</option>
                    </FiltroSelect>
                </div>
                <div class="select-filtro">
                    <FiltroSelect :valueInput.sync="filtroSocios" name="filtro" >
                        <option value="0">Todos</option>
                        <option value="1">Disponibles</option>
                    </FiltroSelect>
                </div>
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         @click="searchClick"
                                         table="socios"
                                         title="Buscar" />
                </div>
                <div v-if="checkPrivilege('socios.crear') || checkPrivilege('proveedores.crear')" class="button-add-top">
                    <ButtonOptions title="Nuevo +" >
                        <ButtonOptionItem v-if="checkPrivilege('proveedores.crear')" v-on:click="nuevoProveedor" title="Proveedor" icon="fa-user" />
                        <ButtonOptionItem v-if="checkPrivilege('socios.crear')" v-on:click="nuevoSocio" title="Socio" icon="fa-user" />
                    </ButtonOptions>
                </div>
            </div>
        </div>
        <div class="content-section">
            <CustomTable>
                <template v-slot:header>
                    <div class="col-8 col-sm-8">Nombre</div>
                    <div class="col-3 col-sm-3 text-center">Empresas</div>
                </template>
                <template v-slot:body>
                    <div v-if="proveedoresFiltrados.length == 0"  class="col-12 text-center">
                        Sin resultados
                    </div>
                    <TableRow v-for="(item, index) in proveedoresFiltrados" :key="index" :dropDown="item.socios.length > 0"
                              :proveedor="true"
                              v-on:option="detalleProveedor(item)"
                              :id="item.id"
                              :ref="`row-${item.id}`">
                        <template v-slot:option>
                            <div class="col-8 col-sm-8 nombre minimizar">
                                {{ item.nombre }} {{ item.apellidos }}
                            </div>
                            <div class="col-3 col-sm-3 text-center minimizar">-</div>
                        </template>
                        <template v-slot:suboptions>
                            <div v-for="(socio, index) in sociosFiltrados(item)" :key="index" class="row row-item subs clickable"
                                 v-on:click="detalleSocio(item, socio)">
                                <div class="col-8 col-sm-8 nombre">
                                    {{ socio.nombre }} {{ socio.apellidos }}
                                </div>
                                <div class="col-3 col-sm-3 text-center">{{ socio.num_empresas }}</div>
                                <div class="col-1 text-center actions">
                                    <div>
                                        <i class="fa fa-eye btn-more"></i>
                                    </div>
                                    <div>
                                        &nbsp;
                                    </div>
                                    <!--<i class="fa fa-chevron-up" v-else></i>-->
                                </div>
                            </div>
                        </template>
                    </TableRow>
                </template>
            </CustomTable>

        </div>

        <vue-easy-lightbox
                :visible="visible"
                :imgs="imgs"
                :index="index"
                @hide="handleHide"
        ></vue-easy-lightbox>

        <ModalForm id="modalSocio"
                   ref="socioForm"
                   title="Socio"
                   v-on:submit="saveSocio">
            <template v-slot:body>
                <input type="hidden" v-model="socio.id">

                <ComboBoxSearch ref="proveedores" title="Proveedor"
                                placeholder="Seleccionar proveedor"/>

                <FormInput :valueInput.sync="socio.nombre" :required="true"
                           name="nombre" title="Nombre" />
                <FormInput :valueInput.sync="socio.apellidos" :required="true"
                           name="apellidos" title="Apellidos" />

                <FormFile :required="false"
                          ref="ineFile"
                          :valueInput.sync="socio.ine"
                          name="ine" title="INE"/>

                <FormFile :valueInput.sync="socio.rfc" :required="false"
                          ref="rfcFile"
                          name="rfc" title="RFC" />

                <FormFile :required="false"
                          ref="comprobanteFile"
                          :valueInput.sync="socio.comprobante"
                          name="ine" title="Comprobante de domicilio"/>


            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonSocio" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>

        <ModalForm id="modalProveedor"
                   ref="proveedorForm"
                   title="Proveedor"
                   v-on:submit="saveProveedor">
            <template v-slot:body>
                <input type="hidden" v-model="proveedor.id">

                <FormSelect v-show="$store.getters.user.group_id == null || checkPrivilege('actos.ignorar-grupo')"
                            :valueInput.sync="proveedor.grupo_id"
                            name="grupo" title="Grupo" :required="true">
                    <option :value="null">Selecciona un grupo</option>
                    <option v-for="(grupo, grupoIndex) in grupos" :key="grupoIndex" :value="grupo.id">
                        {{ grupo.name }}
                    </option>
                </FormSelect>

                <FormInput :valueInput.sync="proveedor.nombre" :required="true"
                           name="nombre" title="Nombre" />
                <FormInput :valueInput.sync="proveedor.apellidos" :required="true"
                           name="apellidos" title="Apellidos" />

                <FormInput :valueInput.sync="proveedor.email"
                           type="email"
                           name="email" title="Correo electrónico" />
                <FormInput :valueInput.sync="proveedor.telefono"
                           name="telefono" title="Teléfono" />

            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonProveedor" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>


        <ModalDetail ref="socioDetalle" title="Socio">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Nombre</b>
                        </td>
                        <td>
                            <span>{{ socio.nombre }}</span>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Apellidos</b>
                        </td>
                        <td>
                            <span>{{ socio.apellidos }}</span>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Empresas</b>
                        </td>
                        <td>
                            <span :class="'empresas ' + (socio.num_empresas >= 4 ? 'exceso' : '')">
                                {{ socio.num_empresas }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>INE</b>
                        </td>
                        <td>
                            <a v-if="socio.ine_imagen_url != null" :href="socio.ine_imagen_url" target="_blank" class="btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>RFC</b>
                        </td>
                        <td>
                            <a v-if="socio.rfc_url != null"  :href="socio.rfc_url" target="_blank" class="btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Comprobante de domicilio</b>
                        </td>
                        <td>
                            <a v-if="socio.comprobante_domicilio_imagen_url != null"  :href="socio.comprobante_domicilio_imagen_url" target="_blank" class="btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="checkPrivilege('socios.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editSocio"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div v-if="checkPrivilege('socios.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteSocio"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>

        <ModalDetail ref="proveedorDetalle" title="Proveedor">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr v-if="checkPrivilege('actos.ignorar-grupo')">
                        <td>
                            <b>Grupo</b>
                        </td>
                        <td>
                            <span v-if="proveedor.grupo != null">{{ proveedor.grupo.name }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Nombre</b>
                        </td>
                        <td>
                            <span>{{ proveedor.nombre }}</span>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Apellidos</b>
                        </td>
                        <td>
                            <span>{{ proveedor.apellidos }}</span>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Teléfono</b>
                        </td>
                        <td>
                            <span>{{ proveedor.telefono }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Correo electrónico</b>
                        </td>
                        <td>
                            <span>{{ proveedor.email }}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="checkPrivilege('proveedores.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editProveedor"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div v-if="checkPrivilege('proveedores.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteProveedor"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>


    </div>
</template>

<script>

    import FormInput from '@/components/Form/FormInput'
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import FiltroSelect from '@/components/Filter/FiltroSelect'
    import FormFile from '@/components/Form/FormFile'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ModalForm from '@/components/Modal/ModalForm'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ButtonOptionsFloat from '@/components/ButtonFloat/ButtonOptionsFloat'
    import ButtonOptions from '@/components/ButtonOption/ButtonOptions'
    import ButtonOptionItem from '@/components/ButtonOption/ButtonOptionItem'
    import CustomTable from '@/components/Table/CustomTable'
    import TableRow from '@/components/Table/TableRow'
    import ComboBoxSearch from '@/components/Form/ComboBoxSearch'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';
    import FormSelect from '@/components/Form/FormSelect'

    export default {
        name: 'SociosScreen',
        components: {
            FormSelect,
            FormFile,
            FiltroSelect,
            CustomTable,
            TableRow,
            ComboBoxSearch,
            ButtonOptionsFloat,
            ButtonOptions,
            ButtonOptionItem,
            FormInput,
            InputSearchComplete,
            ButtonLoader,
            ModalDetail,
            ModalForm,
            ModalButtonFooter
        },
        data: function(){
            return {
                imgs: '',
                index: 0,
                visible: false,
                filtroSocios: "1",
                headers: [
                    {text: 'Nombre', align: 'left', value: 'nombre'},
                    {text: 'Empresas', align: 'left', value: 'empresas', width: '10%', hide: 'text-center'},
                    { text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtro: '',
                filtroComputed: '',
                socios: [],
                socio: {},
                proveedores: [],
                grupos: [],
                proveedor: {},
                indexEdit: -1,
                indexEditSocio: -1,
                tableKey: 1,
                grupo_id: null
            }
        },
        computed: {
            proveedoresFiltrados () {
                let vue = this;
                return this.proveedores.filter(function (pro) {
                    return pro.search.toLowerCase().indexOf(vue.filtroComputed.toLowerCase()) > -1;
                });
            },
        },
        mounted() {
            this.$emit("showLoader");
            this.getProveedores();

            if(this.checkPrivilege("actos.ignorar-grupo")){
                this.getGrupos();
            }
        },
        methods: {
            searchClick(){
                this.filtroComputed = this.filtro;
            },
            sociosFiltrados (proveedor) {
                let vue = this;

                let socios = proveedor.socios.filter(function (socio) {
                    let show = false;

                    if(vue.filtroSocios == '1' && socio.num_empresas < 4){
                        show = true;
                    }
                    else if(vue.filtroSocios == '0'){
                        show = true;
                    }

                    if(!(socio.search.toLowerCase().indexOf(vue.filtroComputed.toLowerCase()) > -1)){
                        show = false;
                    }

                    return show;
                });

                return socios;
            },
            showImage: function (url) {
                this.$refs.socioDetalle.hide();
                this.visible = true;
                this.imgs = url;
            },
            handleHide() {
                this.visible = false;
                this.$refs.socioDetalle.show();
            },
            loadComboProveedores: function () {
                let data = [];
                for(let k in this.proveedores){
                    data[k] = {};
                    data[k].key = this.proveedores[k].id;
                    let apellidos = this.proveedores[k].apellidos == null ?
                        "" : this.proveedores[k].apellidos;
                    data[k].title = this.proveedores[k].nombre + " " + apellidos;
                    data[k].search = this.proveedores[k].search;
                }
                if(this.$refs.proveedores != undefined){
                  this.$refs.proveedores.setData(data);
                  this.$refs.proveedores.setCargando(false);
                }
            },
            getProveedores: function () {
                this.$emit("showLoader");
                let vue = this;
                vue.proveedores = [];
                $.get(this.API_URL + "/api/proveedores/get", {grupo_id: this.grupo_id}, function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.proveedores = response.proveedores;
                        vue.loadComboProveedores();
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            deleteSocio() {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/socios/delete", vue.socio, function (response) {
                        if(response.success){
                            vue.proveedores[vue.indexEdit].socios.splice(vue.indexEditSocio, 1);
                            vue.socio = {};
                            vue.indexEdit = -1;
                            vue.indexEditSocio = -1;
                            vue.$refs.socioDetalle.hide();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });

            },
            deleteProveedor() {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/proveedores/delete", vue.proveedor, function (response) {
                        if(response.success){
                            vue.proveedores.splice(vue.indexEdit, 1);
                            vue.proveedor = {};
                            vue.$refs.proveedorDetalle.hide();
                            vue.loadComboProveedores();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });

            },
            saveSocio: function (e) {
                e.preventDefault();

                let vue = this;

                let pro = this.$refs.proveedores.getSelected();

                if(pro === undefined || pro === null){
                    swal("Selecciona un proveedor", "", "info");
                    return;
                }


                for(let i in this.proveedores){
                    if(this.proveedores[i].id == pro.key){
                        this.indexEdit = i;
                        break
                    }
                }

                this.socio.proveedor_id = pro.key;
                this.$refs.buttonSocio.setShowLoader(true);

                let data = new FormData();
                if(this.socio.id != undefined){
                    data.append("id", this.socio.id);
                }
                if(this.socio.nombre != undefined) {
                    data.append("nombre", this.socio.nombre);
                }
                if(this.socio.apellidos != undefined) {
                    data.append("apellidos", this.socio.apellidos);
                }
                if(this.socio.proveedor_id != undefined) {
                    data.append("proveedor_id", this.socio.proveedor_id);
                }
                if(this.socio.rfc != undefined) {
                    data.append("rfc", this.socio.rfc);
                }
                if(this.socio.comprobante != undefined) {
                    data.append("comprobante", this.socio.comprobante);
                }
                if(this.socio.ine != undefined) {
                    data.append("ine", this.socio.ine);
                }

                $.ajax({
                    url: this.API_URL + "/api/socios/save",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    type: 'POST', // For jQuery < 1.9
                    success: function(response){
                        vue.$refs.buttonSocio.setShowLoader(false);
                        if(response.success){
                            vue.socio = response.record;
                            vue.socio = {};
                            vue.indexEdit = -1;
                            vue.indexEditSocio = -1;
                            vue.closeModalSocio();
                            vue.getProveedores();

                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }
                }).fail(function (error) {
                    vue.$refs.buttonSocio.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });

            },
            saveProveedor: function (e) {
                e.preventDefault();
                this.$refs.buttonProveedor.setShowLoader(true);
                let vue = this;

                $.post(this.API_URL + "/api/proveedores/save", this.proveedor, function (response) {
                    if(response.success){
                        vue.$refs.buttonProveedor.setShowLoader(false);
                        vue.proveedor = response.record;

                        if (vue.indexEdit > -1) {
                            Object.assign(vue.proveedores[vue.indexEdit], vue.proveedor)
                        } else {
                            vue.proveedores.splice(0, 0, vue.proveedor)
                        }
                        vue.proveedor = {};
                        vue.indexEdit = -1;
                        vue.closeModalProveedor();
                        vue.loadComboProveedores();

                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    vue.$refs.buttonProveedor.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });

            },
            detalleSocio: function (proveedor, socio) {
                this.indexEdit = this.proveedores.indexOf(proveedor);
                this.indexEditSocio = proveedor.socios.indexOf(socio);
                this.socio = Object.assign({}, socio);
                this.$refs.socioDetalle.show();
            },
            detalleProveedor: function (proveedor) {
                this.indexEdit = this.proveedores.indexOf(proveedor);
                this.proveedor = Object.assign({}, proveedor);
                this.$refs.proveedorDetalle.show();
            },
            editSocio: function () {

                let pro = {
                    key: this.proveedores[this.indexEdit].id,
                    title: this.proveedores[this.indexEdit].nombre + " " + this.proveedores[this.indexEdit].apellidos
                };

                this.$refs.proveedores.setSelected(pro);

                this.$refs.socioDetalle.hide();
                this.openModalSocio();
            },
            editProveedor: function () {
                this.$refs.proveedorDetalle.hide();
                this.openModalProveedor();
            },
            nuevoProveedor: function () {
                this.proveedor = {};
                this.indexEdit = -1;
                this.openModalProveedor();
            },
            nuevoSocio: function () {
                this.socio = {};
                this.indexEdit = -1;
                this.$refs.proveedores.removeItem();
                this.openModalSocio();
            },
            openModalSocio: function () {
                this.$refs.rfcFile.reset();
                this.$refs.ineFile.reset();
                this.$refs.comprobanteFile.reset();

                this.$refs.socioForm.show();
            },
            openModalProveedor: function () {
                this.$refs.proveedorForm.show();
            },
            closeModalSocio: function () {
                this.$refs.socioForm.hide();
            },
            closeModalProveedor: function () {
                this.$refs.proveedorForm.hide();
            },
            getGrupos: function () {
                let vue = this;
                vue.grupos = [];
                $.get(this.API_URL + "/api/grupos", function (response) {
                    if(response.success){
                        vue.grupos = response.grupos;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .select-filtro.select-grupo{
            width: 140px;
        }

        .actions{
            display: flex;
            div{
                width: 100%;
            }
        }

        .btn-group button {
            background-color: #4CAF50; /* Green background */
            border: 1px solid green; /* Green border */
            color: white; /* White text */
            padding: 10px 24px; /* Some padding */
            cursor: pointer; /* Pointer/hand icon */
            width: 50%; /* Set a width if needed */
            display: block; /* Make the buttons appear below each other */
        }

        .btn-group button:not(:last-child) {
            border-bottom: none; /* Prevent double borders */
        }

        /* Add a background color on hover */
        .btn-group button:hover {
            background-color: #3e8e41;
        }

        .button-save-content{
            width: 120px;
        }

        .select-filtro{
            margin-right: 10px;
            width: 120px;
            vertical-align: bottom;
            display: inline-block;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }


    }



</style>
