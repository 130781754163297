import { render, staticRenderFns } from "./Cobranza.vue?vue&type=template&id=77f74002&scoped=true&"
import script from "./Cobranza.vue?vue&type=script&lang=js&"
export * from "./Cobranza.vue?vue&type=script&lang=js&"
import style0 from "./Cobranza.vue?vue&type=style&index=0&id=77f74002&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f74002",
  null
  
)

export default component.exports