<template>
    <div class="form-group combobox-search">
        <label v-if="title != null">{{ title }}</label>
        <div class="content"
             v-click-outside="onClose">
            <input v-model="filtro" type="text" class="form-control"
                   :placeholder="!cargando ? placeholder : 'Cargando...'"
                   :readonly="selected !== null"
                   ref="input"
                   @click="show = selected === null"/>
            <ul v-show="show"
                :class="'items-list'">
                <li v-if="elementsFiltrados.length == 0" class="item">
                    <div>
                        <p>
                            No hay resultados
                        </p>
                    </div>
                </li>
                <li v-else v-for="dato in elementsFiltrados" :class="'item ' +
                 (selected !== null && dato.key === selected.key ? 'active' : 'inactive')" v-on:click="setSelected(dato)"
                v-bind:key="dato.key">
                    <div class="item-option" :class="{sugerida: dato.order > 0}">
                        <p class="title-option">
                            {{ dato.title }}
                        </p>
                        <p v-if="dato.subtitle != null" class="subtitle-option">
                            {{ dato.subtitle }}
                        </p>
                        <span></span>
                    </div>
                </li>
            </ul>
            <button v-if="selected != null" type="button" v-on:click="removeClick" class="btn-buscar">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ComboBoxSearchSubtitle',
        props: {
            title: String,
            customRemove: Boolean,
            placeholder: null
        },
        data: function(){
            return {
                cargando: true,
                show: false,
                filtro: '',
                datos: [],
                selected: null,
                orderSearch: "",
            }
        },
        mounted() {
            //this.update();
        },
        methods: {
            onClose () {
                if(this.show){
                    this.show = false;
                    this.filtro = this.selected !== null ? this.selected.title : "";
                }
            },
            removeData: function () {
                this.datos = [];
                this.removeItem();
            },
            setData: function (data) {
                this.datos = data;
            },
            setSelected: function (selected) {
                this.show = false;
                this.selected = selected;
                this.filtro = this.selected.title;
                this.change();
            },
            getSelected: function () {
                return this.selected
            },
            change: function () {
                this.$emit("change");
            },
            removeClick(){
                if(this.customRemove){
                    this.$emit("removeItem");
                }
                else {
                    this.removeItem();
                }
            },
            removeItem: function () {
                this.selected = null;
                this.filtro = "";
                this.change();
            },
            setCargando (cargando){
                this.cargando = cargando;
            },
            buscarSugerencias(sugerencias){
                this.orderSearch = sugerencias;
            }
        },
        computed: {
            elementsFiltrados: function(){
                let vue = this;
                let filtred = this.datos.filter(function (dt) {
                    return dt.search.toLowerCase().indexOf(vue.filtro.toLowerCase()) !== -1
                });

                filtred = filtred.map(function (item) {
                    item.order = 0;
                    return item;
                });

                if(this.orderSearch != "" && this.orderSearch != null){
                    filtred = filtred.map(function (item) {
                        if(item.sugerencias != null){
                            let words = item.sugerencias.split(",");
                            for(let index in words){
                                if(vue.orderSearch.toLowerCase().includes(words[index].toLowerCase().trim()) > -1) {
                                    item.order += 1;
                                }
                            }
                        }
                        return item;
                    });
                }

                filtred = filtred.sort((itemA, itemB) => itemA.order > itemB.order ? -1 : 1);

                return filtred;
            }
        }


    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .combobox-search{

        .content{
            position: relative;
            .form-control{
                outline: none;
                box-shadow: none;
                border-radius: 50px;
                padding-right: 90px;

            }





            .btn-buscar{
                border-radius: 0 50px 50px 0;
                outline: none;
                background: #2e1e29;
                position: absolute;
                right: 0;
                top: 0;
                color: #fff;
                font-size: 16px;
                width: 60px;
                height: 38px;
                text-transform: uppercase;
                padding: 0 20px 0 15px;
                font-weight: bold;
                border: none;
            }

            .items-list{
                /*opacity: 0;*/
                /*visibility: hidden;*/
                margin: 0;
                position: absolute;
                top: 100%;
                left: 50%;
                max-height: 350px;
                transform: translateX(-50%);
                border: 1px solid #f0f0f0;
                border-radius: 10px;
                overflow-y: auto;
                width: 100%;
                background: #fff;
                padding: 0;
                list-style: none;
                z-index: 2;
                transition-duration: 0.3s;

                -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
                -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
                box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);

                &.show{
                    visibility: visible;
                    opacity: 1;
                }


                &::-webkit-scrollbar {
                    width: 12px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: rgba(0,0,0,0.2);
                    border-radius: 0 50px 50px 0;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #555;
                    border-radius: 0 50px 50px 0;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #444;
                }


                .item{

                    cursor: pointer;
                    /*&:first-child{*/
                        /*div{*/
                            /*border-radius: 10px 10px 0 0;*/
                        /*}*/
                    /*}*/

                    /*&:last-child{*/
                        /*div{*/
                            /*border-radius: 0 0 10px 10px;*/
                        /*}*/
                    /*}*/

                    div{
                        cursor: pointer;
                        padding: 5px 10px;
                        transition-duration: 0.2s;
                        border-bottom: 1px solid #f0f0f0;

                        p{
                            margin: 0;
                            padding: 0;
                            transition-duration: 0.2s;
                        }

                    }

                    &.active{
                        div{
                            color: #fff;
                            background: rgba(196,192,134);
                            p{
                                color: #fff;
                            }
                        }
                    }

                    &:hover{
                        div{
                            color: #fff;
                            background: rgba(196,192,134);
                            p{
                                color: #fff;
                            }
                        }
                    }
                }

            }

        }

        .item-option{
            &.sugerida{
                background: rgba(0, 255, 0, 0.2);
            }
            .title-option{
                font-weight: 500;
            }
            .subtitle-option{
                font-size: 13px;
                color: #777;
            }
        }

    }

</style>
