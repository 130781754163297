<template>
    <div id="root">
        <div v-if="checkPrivilege('perfiles.crear')" class="button-add-bottom">
            <ButtonFloat v-on:click="nuevoPerfil"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Perfiles</h2>
            </div>
            <div class="buttons">
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         @click="searchClick"
                                         table="perfiles"
                                         title="Buscar" />
                </div>
                <div v-if="checkPrivilege('perfiles.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="nuevoPerfil" title="Nuevo +" />
                </div>
            </div>
        </div>
        <div class="content-section">

            <v-data-table
                    :headers="headers"
                    :items="perfilesFiltrados"
                    hide-default-header
                    item-key="tabla-perfiles"
                    class="elevation-1" >

                <template v-slot:header="{ props: { headers } }">
                    <thead>
                    <tr>
                        <th v-for="header in headers"
                            :class="'text-left sortable ' + header.hide"
                            :key="header.value"
                            :width="header.width">
                            {{ header.text }}
                        </th>
                    </tr>
                    </thead>
                </template>


                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-if="items.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in items" :key="index" v-on:click="detallePerfil(item)">
                        <td :class="headers[0].hide">{{ item.name }}</td>
                        <td :class="headers[1].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>


        <ModalForm id="modalPerfil"
                   ref="perfilForm"
                   title="Perfil"
                   v-on:submit="savePerfil">
            <template v-slot:body>
                <FormInput :valueInput.sync="perfil.name" :required="true"
                           name="nombre" title="Nombre" />


                <label>Permisos</label>
                <div class="privilegios-contenedor">
                    <ul v-for="(item, indexItem) in privilegiosArray" :key="indexItem" class="privilegios-primero">
                        <li>
                            <label>
                                <input :checked="privilegiosArray[indexItem].checked"
                                       type="checkbox" :value="true"
                                       @click="permisoChecked(indexItem)">
                                {{ item.titulo }}
                            </label>
                        </li>
                        <li v-if="item.opciones != undefined" v-show="privilegiosArray[indexItem].checked"
                            class="subprivilegio">
                            <ul v-for="(opc, indexOpc) in item.opciones" :key="indexOpc" class="privilegios-segundo">
                                <li>
                                    <label>
                                        <input :checked="privilegiosArray[indexItem].opciones[indexOpc].checked"
                                               type="checkbox" :value="true"
                                               @click="permisoChecked(indexItem, indexOpc)">
                                        {{ opc.titulo }}
                                    </label>
                                </li>
                                <li v-if="opc.opciones != undefined" v-show="privilegiosArray[indexItem].opciones[indexOpc].checked"
                                    class="subprivilegio">
                                    <ul v-for="(opc2, indexOpc2) in opc.opciones" :key="indexOpc2" class="privilegios-tercero">
                                        <li>
                                            <label>
                                                <input :checked="privilegiosArray[indexItem].opciones[indexOpc].opciones[indexOpc2].checked"
                                                       type="checkbox" :value="true"
                                                       @click="permisoChecked(indexItem, indexOpc, indexOpc2)">
                                                {{ opc2.titulo }}
                                            </label>
                                        </li>
                                        <li v-if="opc2.opciones != undefined" v-show="privilegiosArray[indexItem].opciones[indexOpc].opciones[indexOpc2].checked"
                                            class="subprivilegio">
                                            <ul v-for="(opc3, indexOpc3) in opc2.opciones" :key="indexOpc3" class="privilegios-tercero">
                                                <li>
                                                    <label>
                                                        <input :checked="privilegiosArray[indexItem].opciones[indexOpc].opciones[indexOpc2].opciones[indexOpc3].checked"
                                                               type="checkbox" :value="true"
                                                               @click="permisoChecked(indexItem, indexOpc, indexOpc2, indexOpc3)">
                                                        {{ opc3.titulo }}
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>


            </template>

            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonPerfil" type="submit" title="Guardar" />
                </div>
            </template>

        </ModalForm>

        <ModalDetail ref="perfilDetalle" title="Perfil Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Nombre</b>
                        </td>
                        <td>
                            <span>{{ perfil.name }}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="perfil.id != 1 && checkPrivilege('perfiles.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editPerfil"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div v-if="perfil.id != 1 && checkPrivilege('perfiles.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deletePerfil"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>


    </div>
</template>

<script>

    import FormInput from '@/components/Form/FormInput'
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ModalForm from '@/components/Modal/ModalForm'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'PerfilesScreen',
        components: {
            FormInput,
            InputSearchComplete,
            ButtonFloat,
            ButtonLoader,
            ButtonAdd,
            ModalDetail,
            ModalForm,
            ModalButtonFooter
        },
        data: function(){
            return {
                headers: [
                    {text: 'Nombre', align: 'left', value: 'nombre'},
                    { text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtroComputed: '',
                filtro: '',
                perfiles: [],
                perfil: {},
                indexEdit: -1,
                privilegios: [],
                privilegiosArray: []
            }
        },
        computed: {
            perfilesFiltrados () {
                let vue = this;
                return this.perfiles.filter(function (per) {
                    return per.name.toLowerCase().indexOf(vue.filtroComputed.toLowerCase()) > -1;
                });
            },
        },
        mounted() {
            this.$emit("showLoader");
            this.getPerfiles();
            this.getPermisosListado();
        },
        methods: {
            searchClick(){
                this.filtroComputed = this.filtro;
            },
            getPermisosListado: function () {
                let vue = this;
                vue.privilegios = [];
                $.get(this.API_URL + "/api/perfiles/listado", function (response) {
                    if(response.success){
                        vue.privilegios = response.listado;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getPerfiles: function () {
                let vue = this;
                vue.perfiles = [];
                $.get(this.API_URL + "/api/perfiles", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.perfiles = response.profiles;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            deletePerfil () {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    delete vue.perfil.perfil_permiso;
                    $.post(vue.API_URL + "/api/perfiles/delete", vue.perfil, function (response) {
                        if(response.success){
                            vue.perfiles.splice(vue.indexEdit, 1);
                            vue.perfil = {};
                            vue.$refs.perfilDetalle.hide();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });

            },
            savePerfil: function (e) {
                e.preventDefault();
                let privilegios = [];
                this.privilegiosArray.forEach((item) => {
                    if(item.checked){
                        if(item.privilegios != undefined) {
                            privilegios = [...privilegios ,...item.privilegios];
                        }
                        if(item.privilegios_extra != undefined){
                            privilegios = [...privilegios ,...item.privilegios_extra];
                        }
                        if(item.opciones != undefined){
                            item.opciones.forEach((opc) => {
                                if(opc.checked){
                                    if(opc.privilegios != undefined) {
                                        privilegios = [...privilegios ,...opc.privilegios];
                                    }
                                    if(opc.privilegios_extra != undefined){
                                        privilegios = [...privilegios ,...opc.privilegios_extra];
                                    }
                                    if(opc.opciones != undefined) {
                                        opc.opciones.forEach((opc2) => {
                                            if(opc2.checked) {
                                                if (opc2.privilegios != undefined) {
                                                    privilegios = [...privilegios ,...opc2.privilegios];
                                                }
                                                if(opc2.privilegios_extra != undefined){
                                                    privilegios = [...privilegios ,...opc2.privilegios_extra];
                                                }
                                                if(opc2.opciones != undefined) {
                                                    opc2.opciones.forEach((opc3) => {
                                                        if(opc3.checked) {
                                                            if (opc3.privilegios != undefined) {
                                                                privilegios = [...privilegios ,...opc3.privilegios];
                                                            }
                                                            if(opc3.privilegios_extra != undefined){
                                                                privilegios = [...privilegios ,...opc3.privilegios_extra];
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    }
                });

                privilegios = [...new Set(privilegios)];

                if(privilegios.length == 0){
                    swal("Agregar permisos al perfil", "", "info");
                    return
                }

                this.$refs.buttonPerfil.setShowLoader(true);
                let vue = this;

                this.perfil.privilegios = privilegios;
                delete this.perfil.perfil_permiso;

                $.post(this.API_URL + "/api/perfiles/save", this.perfil, function (response) {
                   vue.$refs.buttonPerfil.setShowLoader(false);
                   if(response.success){
                       vue.getPerfiles();
                       vue.perfil = {};
                       vue.indexEdit = -1;
                       vue.closeModalPerfil();
                   }
                   else{
                       swal(response.message, "", "info")
                   }
                }, "json").fail(function (error) {
                    vue.$refs.buttonPerfil.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });

            },
            detallePerfil: function (itemPerfil) {
                this.indexEdit = this.perfiles.indexOf(itemPerfil);
                this.perfil = Object.assign({}, itemPerfil);
                let data = JSON.stringify(this.privilegios);

                this.privilegiosArray = Object.assign([], JSON.parse(data));

                if(itemPerfil.id != undefined){
                    let vueApp = this;
                    this.privilegiosArray.forEach((item, indexItem) => {
                        if(item.privilegios != undefined){
                            for(let i = 0; i < itemPerfil.perfil_permiso.length; i++){
                                if(itemPerfil.perfil_permiso[i].privilege != 'admin' && item.privilegios.includes(itemPerfil.perfil_permiso[i].privilege)){
                                    vueApp.privilegiosArray[indexItem].checked = true;
                                    break;
                                }
                            }
                        }
                        if(item.opciones != undefined){
                            item.opciones.forEach((opc, indexOpc) => {
                                if(opc.privilegios != undefined){
                                    for(let i = 0; i < itemPerfil.perfil_permiso.length; i++){
                                        if(opc.privilegios.includes(itemPerfil.perfil_permiso[i].privilege)){
                                            vueApp.privilegiosArray[indexItem].opciones[indexOpc].checked = true;
                                            break;
                                        }
                                    }
                                }
                                if(opc.opciones != undefined) {
                                    opc.opciones.forEach((opc2, indexOpc2) => {
                                        if(opc2.privilegios != undefined){
                                            for(let i = 0; i < itemPerfil.perfil_permiso.length; i++){
                                                if(opc2.privilegios.includes(itemPerfil.perfil_permiso[i].privilege)){
                                                    vueApp.privilegiosArray[indexItem].opciones[indexOpc].opciones[indexOpc2].checked = true;
                                                    break;
                                                }
                                            }
                                        }
                                        if(opc2.opciones != undefined) {
                                            opc2.opciones.forEach((opc3, indexOpc3) => {
                                                if(opc3.privilegios != undefined){
                                                    for(let i = 0; i < itemPerfil.perfil_permiso.length; i++){
                                                        if(opc3.privilegios.includes(itemPerfil.perfil_permiso[i].privilege)){
                                                            vueApp.privilegiosArray[indexItem].opciones[indexOpc].opciones[indexOpc2].opciones[indexOpc3].checked = true;
                                                            break;
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                this.$refs.perfilDetalle.show();
            },
            editPerfil: function () {
                this.$refs.perfilDetalle.hide();
                this.openModalPerfil();
            },
            nuevoPerfil: function () {
                this.perfil = Object.assign({}, {});

                let data = JSON.stringify(this.privilegios);

                this.privilegiosArray = Object.assign([], JSON.parse(data));
                this.indexEdit = -1;
                this.openModalPerfil();
            },
            openModalPerfil: function () {
                $("#modalPerfil").modal("show");
                this.$refs.perfilForm.show();
            },
            closeModalPerfil: function () {
                this.$refs.perfilForm.hide();
            },
            permisoChecked(indexItem, indexOpc = undefined, indexOpc2 = undefined, indexOpc3 = undefined){
                if(indexOpc3 != undefined){
                    let item = Object.assign({}, this.privilegiosArray[indexItem].opciones[indexOpc].opciones[indexOpc2].opciones[indexOpc3]);
                    if(item.checked){
                        item.checked = false;
                    }
                    else{
                        item.checked = true;
                    }
                    this.privilegiosArray[indexItem].opciones[indexOpc].opciones[indexOpc2].opciones[indexOpc3] = Object.assign({}, item);
                }
                else if(indexOpc2 != undefined){
                    let item = Object.assign({}, this.privilegiosArray[indexItem].opciones[indexOpc].opciones[indexOpc2]);
                    if(item.checked){
                        item.checked = false;
                    }
                    else{
                        item.checked = true;
                    }
                    this.privilegiosArray[indexItem].opciones[indexOpc].opciones[indexOpc2] = Object.assign({}, item);
                }
                else if(indexOpc != undefined){
                    let item = Object.assign({}, this.privilegiosArray[indexItem].opciones[indexOpc]);
                    if(item.checked){
                        item.checked = false;
                    }
                    else{
                        item.checked = true;
                    }
                    this.privilegiosArray[indexItem].opciones[indexOpc] = Object.assign({}, item);
                }
                else{
                    let item = Object.assign({}, this.privilegiosArray[indexItem]);
                    if(item.checked){
                        item.checked = false;
                    }
                    else{
                        item.checked = true;
                    }
                    this.privilegiosArray[indexItem] = Object.assign({}, item);
                }

                this.privilegiosArray = Object.assign([], this.privilegiosArray);
                this.$forceUpdate();
            },
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .btn-group button {
            background-color: #4CAF50; /* Green background */
            border: 1px solid green; /* Green border */
            color: white; /* White text */
            padding: 10px 24px; /* Some padding */
            cursor: pointer; /* Pointer/hand icon */
            width: 50%; /* Set a width if needed */
            display: block; /* Make the buttons appear below each other */
        }

        .btn-group button:not(:last-child) {
            border-bottom: none; /* Prevent double borders */
        }

        /* Add a background color on hover */
        .btn-group button:hover {
            background-color: #3e8e41;
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .privilegios-primero, .privilegios-segundo, .privilegios-tercero{
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .privilegios-segundo, .privilegios-tercero{
            padding-left: 20px;
        }

        .privilegios-primero{
            //margin-bottom: 10px;
            li{
                font-weight: bold;
                color: var(--color-primary);
                margin-bottom: 10px;
                label{
                    cursor: pointer;
                    display: block;
                    width: 100%;
                    margin: 0;
                    padding: 10px 15px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                }
                &.subprivilegio{
                    border: none;
                    padding-top: 0;
                    padding-right: 0;
                }
            }
        }
        .privilegios-segundo{
            li{
                font-weight: bold;
                color: #444;
            }
        }
        .privilegios-tercero{
            li{
                font-weight: 400;
                color: #444;
            }
        }



    }



</style>
