import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './components/Views/Home.vue'
import Login from './components/Views/Login.vue'
import ForgotPassword from './components/Views/ForgotPassword.vue'
import Empresas from './components/Views/Empresas.vue'
import Actos from './components/Views/Actos.vue'
import ActosRevision from './components/Views/ActosRevision.vue'
import ActosFinalizados from './components/Views/ActosFinalizados.vue'
// import ActosEmpresa from './components/Views/ActosEmpresa.vue'
import ActosConceptos from './components/Views/ActoConceptos.vue'
import ActoEventos from './components/Views/ActoEventos.vue'
import Notarios from './components/Views/Notarios.vue'
import Clientes from './components/Views/Clientes.vue'
import CuentaCliente from './components/Views/CuentaCliente.vue'
import Socios from './components/Views/Socios.vue'
import Servicios from './components/Views/Servicios.vue'
import Cuentas from './components/Views/Cuentas.vue'

import Gastos from './components/Views/Gastos.vue'

import Denominaciones from './components/Views/Denominaciones.vue'
import Inventarios from './components/Views/Inventarios.vue'
import RevisionesInventarios from './components/Views/RevisionesInventarios.vue'
import RevisionesInventariosDetalle from './components/Views/RevisionesInventariosDetalle.vue'

import CategoriaConceptos from './components/Views/CategoriasConceptos.vue'
import CategoriasGastos from './components/Views/CategoriasGastos.vue'
import CategoriasIngresos from './components/Views/CategoriasIngresos.vue'

import Configuraciones from './components/Views/Configuraciones.vue'

import Comisiones from './components/Views/Comisiones.vue'
import ComisionDetalle from './components/Views/ComisionDetalle.vue'

import Recibos from './components/Views/Recibos.vue'
import NuevoRecibo from './components/Views/NuevoRecibo.vue'

import Cobranza from "./components/Views/Cobranza";

import CuentaMovimientos from './components/Views/CuentaMovimientos.vue'
import Usuarios from './components/Views/Usuarios.vue'

import Perfiles from './components/Views/Perfiles.vue'
import Grupos from './components/Views/Grupos.vue'

import EditarPerfil from './components/Views/EditarPerfil.vue'
import PageNotFound from './components/Views/PageNotFound.vue'

import store from './store'

import auth from './middleware/auth';
import notAuth from './middleware/notAuth';
import middlewarePipeline from './middleware/middlewareVerify';



Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                title: 'Iniciar sesión',
                middleware: [
                    notAuth
                ]
            }
        },
        {
            path: '/login_:token',
            name: 'loginToken',
            component: Login,
            meta: {
                title: 'Iniciar sesión'
            }
        },
        {
            path: '/recuperar-:token',
            name: 'forgotPassword',
            component: ForgotPassword,
            meta: {
                title: 'Restablecer contraseña',
                middleware: [
                    notAuth
                ]
            }
        },
        {
            path: '/inicio',
            name: 'inicio',
            component: Home,
            meta: {
                privilege: 'inicio',
                title: 'Inicio',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/editar-perfil',
            name: 'editarPerfil',
            component: EditarPerfil,
            meta: {
                privilege: 'editar-perfil',
                title: 'Editar Perfil',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/',
            redirect: {
                name: "inicio",
            }
        },
        {
            path: '/empresas',
            name: 'empresas',
            component: Empresas,
            meta: {
                privilege: 'empresas',
                title: 'Empresas',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/actos',
            name: 'actos',
            component: Actos,
            meta: {
                privilege: 'actos',
                title: 'Actos',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/revision-actos',
            name: 'RevisionActos',
            component: ActosRevision,
            meta: {
                privilege: 'actos.revision',
                title: 'Revisión actos',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/reporte-actos',
            name: 'reporteActos',
            component: ActosFinalizados,
            meta: {
                privilege: 'actos.finalizados',
                title: 'Reporte de Actos',
                middleware: [
                    auth
                ]
            }
        },
        // {
        //     path: '/actos-finalizados',
        //     name: 'actosFinalizados',
        //     component: ActosFinalizados,
        //     meta: {
        //         privilege: 'actos.finalizados',
        //         title: 'Actos Finalizados',
        //         middleware: [
        //             auth
        //         ]
        //     }
        // },
        {
            path: '/actos-conceptos-utilidad_:actoId?',
            component: ActosConceptos,
            meta: {
                tipo: 'u',
                privilege: 'actos.conceptos',
                title: 'Acto Conceptos - Utilidad',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/actos-conceptos-gastos_:actoId?',
            component: ActosConceptos,
            meta: {
                tipo: 's',
                privilege: 'actos.conceptos',
                title: 'Acto Conceptos - Gastos',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/actos-conceptos-ingresos_:actoId?',
            component: ActosConceptos,
            meta: {
                tipo: 'i',
                privilege: 'actos.conceptos',
                title: 'Acto Conceptos - Ingresos',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/actos-empresa_:empresaId?',
            component: Actos,
            meta: {
                privilege: 'empresas.actos',
                title: 'Actos Empresa',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/movimientos-categoria_:categoriaId?',
            component: CategoriaConceptos,
            meta: {
                privilege: 'categorias.movimientos',
                title: 'Movimientos categoría',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/cliente-cuenta_:clienteId?',
            component: CuentaCliente,
            meta: {
                privilege: 'clientes.cuenta',
                title: 'Cuenta cliente',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/acto-eventos_:actoId?',
            component: ActoEventos,
            meta: {
                privilege: 'eventos',
                title: 'Acto Eventos',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/fedatarios',
            name: 'fedatarios',
            component: Notarios,
            meta: {
                privilege: 'notarios',
                title: 'Fedatarios',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/clientes',
            name: 'clientes',
            component: Clientes,
            meta: {
                privilege: 'clientes',
                title: 'Clientes',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/proveedores-socios',
            name: 'provedoresSocios',
            component: Socios,
            meta: {
                privilege: 'proveedores-socios',
                title: 'Proveedores y Socios',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/servicios',
            name: 'servicios',
            component: Servicios,
            meta: {
                privilege: 'servicios',
                title: 'Servicios',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/cuentas',
            name: 'cuentas',
            component: Cuentas,
            meta: {
                privilege: 'cuentas',
                title: 'Cuentas',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/gastos-predeterminados',
            name: 'gastos_predeterminados',
            component: Gastos,
            meta: {
                privilege: 'gastos',
                title: 'Gastos Predeterminados',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/cuenta-movimientos_:cuentaId?',
            component: CuentaMovimientos,
            meta: {
                privilege: 'cuenta-movimientos',
                title: 'Cuenta Movimiento',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/usuarios',
            name: 'usuarios',
            component: Usuarios,
            meta: {
                privilege: 'usuarios',
                title: 'Usuarios',
                middleware: [
                    auth
                ]
            }

        },
        {
            path: '/perfiles',
            name: 'perfiles',
            component: Perfiles,
            meta: {
                privilege: 'perfiles',
                title: 'Perfiles',
                middleware: [
                    auth
                ]
            }

        },
        {
            path: '/grupos',
            name: 'grupos',
            component: Grupos,
            meta: {
                privilege: 'grupos',
                title: 'Grupos',
                middleware: [
                    auth
                ]
            }

        },

        {
            path: '/recibos',
            name: 'recibos',
            component: Recibos,
            meta: {
                privilege: 'recibos',
                title: 'Recibos',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/nuevo-recibo',
            name: 'nuevoRecibo',
            component: NuevoRecibo,
            meta: {
                privilege: 'recibos.crear',
                title: 'Nuevo recibo',
                middleware: [
                    auth
                ]
            }
        },

        {
            path: '/comisiones',
            name: 'comisiones',
            component: Comisiones,
            meta: {
                privilege: 'comisiones',
                title: 'Comisiones',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/comisiones_:comisionId',
            name: 'comisionDetalle',
            component: ComisionDetalle,
            meta: {
                privilege: 'comisiones',
                title: 'Comisión detalle',
                middleware: [
                    auth
                ]
            }
        },

        {
            path: '/cobranza',
            name: 'cobranza',
            component: Cobranza,
            meta: {
                privilege: 'cobranza',
                title: 'Cobranza',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/recibo_:id',
            name: 'editarRecibo',
            component: NuevoRecibo,
            meta: {
                privilege: 'recibos.editar',
                title: 'Editar recibo',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/denominaciones',
            name: 'denominaciones',
            component: Denominaciones,
            meta: {
                privilege: 'denominaciones',
                title: 'Denominaciones',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/existencias',
            name: 'inventarios',
            component: Inventarios,
            meta: {
                privilege: 'inventarios',
                title: 'Existencias',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/revisiones-inventario',
            name: 'revisionesInventario',
            component: RevisionesInventarios,
            meta: {
                privilege: 'revisiones',
                title: 'Revisiones inventario',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/revisiones-inventario_:revisionId?',
            name: 'revisionesInventarioDetalle',
            component: RevisionesInventariosDetalle,
            meta: {
                privilege: 'revisiones',
                title: 'Revisión inventario detalle',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/categorias-gastos',
            name: 'categoriasGastos',
            component: CategoriasGastos,
            meta: {
                privilege: 'categorias-gastos',
                title: 'Categorías de gastos',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/categorias-ingresos',
            name: 'categoriasIngresos',
            component: CategoriasIngresos,
            meta: {
                privilege: 'categorias-ingresos',
                title: 'Categorías de ingresos',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/configuraciones',
            name: 'configuraciones',
            component: Configuraciones,
            meta: {
                privilege: 'configuraciones',
                title: 'Configuraciones',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: "*",
            name: 'PageNotfound',
            component: PageNotFound,
            meta: {
                title: 'Error 404'
            }
        }
    ]
});


router.beforeEach((to, from, next) => {
    document.title = to.meta.title + " | Servicios Jurídicos";

    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    let privilege = to.meta.privilege;

    const context = {
        to,
        from,
        next,
        store,
        router,
        privilege
    }

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })



});




export default router
