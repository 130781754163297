<template>
    <div id="root">
        <div v-if="checkPrivilege('eventos.crear')" class="button-add-bottom">
            <ButtonFloat v-on:click="addEvento"/>
        </div>
        <div class="header-section">
            <div class="title subtitle">
                <h2>
                    Eventos
                    <br>
                    <span>
                        {{ acto.empresa != undefined ? acto.empresa.nombre : '' }}
                    </span>
                </h2>
            </div>
            <div class="buttons">

                <div class="date-range">
                    <DateRangePicker :icons="true"
                                     :dateInicio.sync="fechaInicio"
                                     :dateFin.sync="fechaFin"
                                     placeholderInicio="Fecha inicio"
                                     placeholderFin="Fecha fin"
                                     format="DD-MM-YYYY"
                                     ref="fechas"
                                     v-on:change="getEventos"/>
                </div>
                <div v-if="checkPrivilege('eventos.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="addEvento" title="Nuevo +" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <v-data-table
                    :headers="headers"
                    :items="eventos"
                    hide-default-header
                    item-key="tabla-movimiento"
                    class="elevation-1" >

                <template v-slot:header="{ props: { headers } }">
                    <thead>
                    <tr>
                        <th v-for="header in headers"
                            :class="'text-left sortable ' + header.hide"
                            :key="header.value">
                            {{ header.text }}
                        </th>
                    </tr>
                    </thead>
                </template>


                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-if="items.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in items" :key="index"
                        v-on:click="detalleEvento(item)">
                        <td :class="headers[0].hide">
                            {{ item.titulo }}
                        </td>
                        <td :class="headers[1].hide">
                            {{ item.notas }}
                        </td>
                        <td :class="headers[2].hide" style="width: 150px;">
                            {{ item.fecha | dateFormat }}
                        </td>
                        <td :class="headers[3].hide" style="width: 5%;">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>


        <ModalForm id="modalEvento"
                   ref="eventoForm"
                   title="Evento"
                   v-on:submit="saveEvento">
            <template v-slot:body>
                <input type="hidden" v-model="evento.id">

                <FormInput :valueInput.sync="evento.titulo"
                           :required="true"
                           name="titulo" title="Título"/>

                <!--<FormSelect :valueInput.sync="evento.tipo"-->
                            <!--name="tipo" title="Tipo" >-->
                    <!--<option value="">Selecciona el tipo</option>-->

                    <!--<option value="Constitutiva">-->
                        <!--Constitutiva-->
                    <!--</option>-->
                <!--</FormSelect>-->

                <DatePicker styleClass="form-control" title="Fecha" :date.sync="evento.fecha"
                            placeholder="dd-mm-yyyy"
                            :limitDate="!checkPrivilege('ignorar-fecha')"
                            format="DD-MM-YYYY"
                            ref="fecha" />

                <div class="form-group">
                    <label>Notas</label>
                    <textarea v-model="evento.notas" class="form-control"
                              rows="3"
                              placeholder="Ingresa notas"
                              name="notas"
                    ></textarea>
                </div>


            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonEvento" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>


        <ModalDetail ref="eventoDetalle" title="Evento Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Título</b>
                        </td>
                        <td>
                            <span>{{ evento.titulo }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha</b>
                        </td>
                        <td>
                            <span>{{ evento.fecha | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Notas</b>
                        </td>
                        <td>
                            <span>{{ evento.notas }}</span>
                        </td>
                    </tr>


                    </tbody>
                </table>
            </template>
            <template v-slot:footer>
                <div v-if="checkPrivilege('eventos.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editEvento"
                                       color="#fff"
                                       :disabled="!isEdicion()"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>
                <div v-if="checkPrivilege('eventos.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteEvento"
                                       color="#fff"
                                       :disabled="!isEdicion()"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>


    </div>


</template>

<script>

    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import FormInput from '@/components/Form/FormInput'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import DatePicker from '@/components/DatePicker/DatePicker'
    import DateRangePicker from '@/components/DatePicker/DateRangePicker'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalForm from '@/components/Modal/ModalForm'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';


    export default {
        name: 'ActoEventosScreen',
        components: {
            DatePicker,
            ButtonAdd,
            DateRangePicker,
            FormInput,
            ButtonLoader,
            ModalDetail,
            ModalButtonFooter,
            ModalForm,
            ButtonFloat
        },
        data: function(){
            return {
                filtro: '',
                fechaInicio: '2018-10-01',
                fechaFin: '',
                acto: {},
                acto_id: this.$route.params.actoId,
                evento: {},
                eventos: [],
                headers: [
                    {text: 'Título', value: 'titulo'},
                    {text: 'Notas', value: 'notas', hide: 'hidden-sm-and-down'},
                    {text: 'Fecha', value: 'fecha'},
                    {text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                indexEdit: -1
            }
        },
        mounted() {
            this.$emit("showLoader");
            this.getEventos();
        },
        methods: {
            isEdicion(){
                var d1 = Date.parse(this.evento.fecha);
                var d2 = Date.parse(this.$store.getters.fechaEdicion);
                if(this.checkPrivilege('ignorar-fecha')){
                    return true;
                }
                else{
                    return d1 >= d2;
                }
            },
            getEventos: function(){
                let vue = this;

                let data ={
                    acto_id: this.acto_id,
                    fecha_inicio: this.fechaInicio,
                    fecha_fin: this.fechaFin
                };

                vue.eventos = [];
                $.get(this.API_URL + "/api/eventos/get", data, function (response) {
                    vue.$emit("hideLoader");
                    vue.eventos = response.eventos;
                    if(response.acto == null || response.acto == undefined){
                        vue.$router.push({ name: "PageNotfound" });
                    }
                    else{
                        vue.acto = response.acto;
                    }
                    if(vue.fechaInicio === '' || vue.fechaFin === ''){
                        vue.$refs.fechas.setDates("2018-10-01", response.fecha_fin);
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            addEvento: function () {
                this.evento = {};
                this.indexEdit = -1;
                this.$refs.fecha.setDate('');


                this.openModalEvento();
            },
            editEvento: function () {
                this.$refs.eventoDetalle.hide();
                this.$refs.fecha.setDate(this.evento.fecha);

                this.openModalEvento();
            },
            detalleEvento: function (item) {
                this.indexEdit = this.eventos.indexOf(item);
                this.evento = Object.assign({}, item);
                this.$refs.eventoDetalle.show();
            },
            deleteEvento: function () {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/eventos/delete", vue.evento, function (response) {
                        if(response.success){
                            vue.eventos.splice(vue.indexEdit, 1);
                            vue.evento = {};
                            vue.indexEdit = -1;
                            vue.$refs.eventoDetalle.hide();

                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }

                    });
                });
            },
            openModalEvento: function(){
                this.$refs.eventoForm.show();
            },
            closeModalEvento: function(){
                this.$refs.eventoForm.hide();
            },
            saveEvento: function (e) {
                e.preventDefault();

                let vue = this;
                vue.$refs.buttonEvento.setShowLoader(true);
                this.evento.acto_id = this.acto.id;

                $.post(this.API_URL + "/api/eventos/save", this.evento, function (response) {
                    vue.$refs.buttonEvento.setShowLoader(false);
                    if(response.success){

                        vue.evento = response.evento;

                        if (vue.indexEdit > -1) {
                            Object.assign(vue.eventos[vue.indexEdit], vue.evento)
                        } else {
                            vue.eventos.splice(0, 0, vue.evento)
                        }


                        vue.evento = {};
                        vue.indexEdit = -1;
                        vue.closeModalEvento();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    vue.$refs.buttonEvento.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            }
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .date-picker{
            margin-right: 10px;
            & > .v-input{
                padding: 0;
                margin: 0;

                & > .v-input__control{
                    margin: 0;

                    & > .v-input__slot{
                        margin: 0;
                    }

                }

            }
        }

        .button-save-content{
           width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px 90px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px 90px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .float-saldo{
            position: fixed;
            right: 20px;
            bottom: 20px;
            padding: 10px 30px;
            background: #c4c086;
            border-radius: 10px;
            p{
                text-transform: uppercase;
                color: #fff;
                margin: 0;
                padding: 0;
                font-weight: 600;
                font-size: 18px;
            }
        }

    }



</style>
