<template>
    <div :class="'table-drop-down ' + csclass">
        <div class="row headers-table">
            <slot name="header"></slot>
            <div :class="'cursor-pointer text-center' + (imprimir ? ' col-2 col-sm-2' : ' col-1') "
                 v-on:click="hideAll" >
                <div v-show="!show" class="actions">
                    <div v-if="imprimir">&nbsp;</div>
                    <div v-if="imprimir">&nbsp;</div>
                    <div v-if="!imprimir">&nbsp;</div>
                    <div>
                        <i class="fa fa-chevron-down btn-more"></i>
                    </div>
                </div>
                <div v-show="show" class="actions">
                  <div v-if="imprimir">&nbsp;</div>
                  <div v-if="imprimir">&nbsp;</div>
                    <div v-if="!imprimir">&nbsp;</div>
                    <div>
                        <i class="fa fa-chevron-up btn-more"></i>
                    </div>
                </div>
            </div>
        </div>
        <slot name="body"></slot>
    </div>
</template>

<script>



export default {
    name: 'CustomTable',
    components: {
    },
    props: {
        csclass: String,
        imprimir: Boolean(false)
    },
    data: function(){
        return {
            show: false,
        }
    },
    methods: {
        changeShow: function () {
            this.show = false;
            for(let i in this.$children){
                if(this.$children[i].getShow()){
                    this.show = true;
                    break;
                }
            }
        },
        hideAll: function () {
            this.show = !this.show;
            for(let i in this.$children){
                this.$children[i].setHidden(this.show);
            }
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .table-drop-down{
        background: #fff;
        border-radius: 10px;
        padding-bottom: 10px;
        border: 1px solid #dfe0eb;
        box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);

        &.simple{
            box-shadow: none;
            border: none;
            padding-bottom: 0;
        }

        .actions{
            display: flex;
            div{
                width: 100%;
            }
        }

        .headers-table{
            margin: 0;
            width: 100%;
            color: rgba(0,0,0,.54);
            font-size: 14px;
            font-weight: bold;
            border-bottom: 2px solid rgb(226, 231, 235);
        }


        .row-table:last-child{
            .row-item:last-child {
                border-bottom: none;
                border-radius: 0 0 10px 10px;
            }
        }

        .row-table{
            margin: 0;
            font-weight: bold;
            font-size: 14px;

            &.clickable{
                cursor: pointer;
            }



            .row-item{
                padding-top: 0;
                border-bottom: 1px solid rgba(0, 0, 0, .12);
                margin: 0;

                padding-bottom: 0;

                &:hover{
                    background: #EEEEEE;
                }

                &.subs {
                    font-weight: normal;
                    background: #f6f6f6;
                    .nombre{
                        padding-left: 25px;

                        @media (max-width: 720px){
                            padding-left: 10px;
                        }
                    }

                }

            }


        }

        .cursor-pointer{
            cursor: pointer;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }


    }
</style>
