<template>
    <div id="root">
        <div class="header-section">
            <div v-if="categoria == null" class="title">
                <h2>Movimientos de Categoría</h2>
            </div>
            <div v-else class="title subtitle">
                <h2>
                    Movimientos de Categoría
                    <br>
                    <span>
                        {{ categoria.nombre }}
                    </span>
                </h2>
            </div>
            <div class="buttons">
                <div class="date-range">
                    <DateRangePicker :icons="true"
                                     :fija="true"
                                     :dateInicio.sync="fechaInicio"
                                     :dateFin.sync="fechaFin"
                                     placeholderInicio="Fecha inicio"
                                     placeholderFin="Fecha fin"
                                     format="DD-MM-YYYY"
                                     ref="fechas"
                                     v-on:change="first(true)"/>
                </div>
                <div class="buscador-content">
                    <InputSearchComplete align="right" :valueInput.sync="filtro"
                                         table="empresas"
                                         @click="searchClick"
                                         title="Buscar" />
                </div>
                <div class="btn-imprimir">
                    <ButtonAdd v-on:click="pdfMovimientos" title="Imprimir" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th v-for="(item, index) in headers" :width="item.width"
                            :key="index"
                            :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                            {{ item.text }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="conceptos.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in conceptos" :key="index" v-on:click="detalleMovimiento(item)">
                        <td :class="headers[0].hide">
                            <span v-if="item.concepto != undefined && item.concepto.recibo != undefined">{{ item.concepto.recibo.folio_letra }}</span>
                            <span v-else>--</span>
                        </td>
                        <td :class="headers[1].hide">
                            <span v-if="item.concepto != undefined && item.concepto.acto != undefined">{{ item.concepto.acto.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                        <td :class="headers[2].hide">{{ item.descripcion }}</td>
                        <td :class="headers[3].hide">
                            <span v-if="item.concepto != undefined && item.concepto.cuenta != undefined">{{ item.concepto.cuenta.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                        <td :class="headers[4].hide">{{ item.importe | toCurrency }}</td>
                        <td :class="headers[5].hide">{{ item.fecha_movimiento | dateFormat }}</td>
                        <td :class="headers[6].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <div class="totales-float">
            <p>
                Total: {{ total | toCurrency }}
            </p>
        </div>

        <ModalDetail ref="movimientoDetalle" title="Movimiento Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr v-if="movimiento.concepto != undefined && movimiento.concepto.recibo != undefined">
                        <td>
                            <b>Recibo</b>
                        </td>
                        <td>
                            <span>{{ movimiento.concepto.recibo.folio_letra }}</span>
                        </td>
                    </tr>
                    <tr v-if="movimiento.concepto != undefined && movimiento.concepto.acto != undefined">
                        <td>
                            <b>Acto</b>
                        </td>
                        <td>
                            <span>{{ movimiento.concepto.acto.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Usuario</b>
                        </td>
                        <td>
                            <span v-if="movimiento.usuario != null">{{ movimiento.usuario.name }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Tipo</b>
                        </td>
                        <td>
                            {{ tipo(movimiento.tipo)  }}
                        </td>
                    </tr>
                    <tr v-if="movimiento.categoria != null">
                        <td>
                            <b>Categoría</b>
                        </td>
                        <td>
                            <span v-if="movimiento.categoria != null">{{ movimiento.categoria.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="movimiento.cuenta_salida != null">
                        <td>
                            <b>Cuenta Salida</b>
                        </td>
                        <td>
                            <span v-if="movimiento.cuenta_salida != null">{{ movimiento.cuenta_salida.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="movimiento.cuenta_salida != null">
                        <td>
                            <b>Saldo Cuenta Salida</b>
                        </td>
                        <td>
                            <span v-if="movimiento.cuenta_salida != null">{{ movimiento.saldo_cuenta_salida | toCurrency }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="movimiento.cuenta_entrada != null">
                        <td>
                            <b>Cuenta Entrada</b>
                        </td>
                        <td>
                            <span v-if="movimiento.cuenta_entrada != null">{{ movimiento.cuenta_entrada.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="movimiento.cuenta_entrada != null">
                        <td>
                            <b>Saldo Cuenta Entrada</b>
                        </td>
                        <td>
                            <span v-if="movimiento.cuenta_entrada != null">{{ movimiento.saldo_cuenta_entrada | toCurrency }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Importe</b>
                        </td>
                        <td>
                            <span>{{ movimiento.importe | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Descripción</b>
                        </td>
                        <td>
                            <span>{{ movimiento.descripcion }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha Movimiento</b>
                        </td>
                        <td>
                            <span>{{ movimiento.fecha_movimiento | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr v-if="movimiento.comprobante_url != null && movimiento.comprobante_url != undefined">
                        <td>
                            <b>Comprobante</b>
                        </td>
                        <td>
                            <a :href="movimiento.comprobante_url" target="_blank" class="btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
        </ModalDetail>

    </div>

</template>

<script>
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import DateRangePicker from '@/components/DatePicker/DateRangePicker'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'CuentaClienteScreen',
        components: {
            ButtonAdd,
            DateRangePicker,
            ModalDetail,
            InputSearchComplete,
        },
        data: function(){
            return {
                categoria: {},
                total: 0,
                categoria_id: this.$route.params.categoriaId,
                page: 1,
                lastPage: 1,
                fechaInicio: '2018-01-01',
                fechaFin: '',
                filtro: '',
                conceptos: [],
                movimiento: {},
                indexEdit: -1,
                refreshTable: 0,
                headers: [
                    { text: 'Recibo', align: 'left', value: 'recibo', width: '10%'},
                    { text: 'Acto', align: 'left', value: 'acto'},
                    { text: 'Descripción', align: 'left', value: 'descripcion' },
                    { text: 'Cuenta', align: 'left', value: 'cuenta', hide: 'hidden-sm-and-down' },
                    { text: 'Importe', value: 'importe' },
                    { text: 'Fecha', align: 'left', value: 'fecha', width: '8%', hide: 'hidden-sm-and-down' },
                    { text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down' },
                ],
            }
        },
        computed: {

        },
        mounted() {
            this.$emit("showLoader");
            this.getConceptos();
        },
        methods: {
            pdfMovimientos(){
                let parametros = "categoria_id="+this.categoria_id;
                parametros += "&fecha_inicio=" + this.fechaInicio;
                parametros += "&fecha_fin="+this.fechaFin;
                parametros += "&filtro="+this.filtro;

                window.open(this.API_URL+"/categoria/movimientos?"+parametros);
            },
            tipo: function (t) {
                var tipo = "Transferencia";
                if(t == "s"){
                    tipo = "Salida";
                }
                else if(t == "i"){
                    tipo = "Ingreso";
                }

                return tipo;
            },
            searchClick(){
                this.first(true);
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.getConceptos();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.getConceptos();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.getConceptos();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.getConceptos();
                }
                else if(refresh){
                    this.page = 1;
                    this.getConceptos();
                }
            },
            detalleMovimiento: function (item) {
                this.indexEdit = this.conceptos.indexOf(item);
                this.movimiento = Object.assign({}, item);
                this.$refs.movimientoDetalle.show();
            },
            getConceptos: function () {
                this.$emit("showLoader");
                let vue = this;
                vue.conceptos = [];
                let data = {
                    fecha_inicio: this.fechaInicio,
                    categoria_id: this.categoria_id,
                    page: this.page,
                    filtro: this.filtro,
                    fecha_fin: this.fechaFin
                };
                $.get(this.API_URL + "/api/categorias/conceptos", data, function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.total = response.total;
                        vue.categoria = response.categoria;
                        vue.page = response.movimientos.current_page;
                        vue.lastPage = response.movimientos.last_page;
                        vue.conceptos = response.movimientos.data;
                        if(vue.fechaInicio === '' || vue.fechaFin === ''){
                            vue.fechaFin = response.fecha_fin;
                            vue.$refs.fechas.setDates("2018-01-01", response.fecha_fin);
                        }
                    }
                    else{
                        if(response.redict){
                            vue.$router.push({ path: "/categorias-gastos"});
                        }
                        else{
                            swal(response.message, '', 'info')
                        }
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        padding-bottom: 100px;

        /*.header-section .buttons div.date-range{*/
        /*    margin-right: 0;*/
        /*}*/

        .totales-float{
            position: fixed;
            width: 100%;
            right: 20px;
            text-align: right;
            bottom: 20px;

            p{
                display: inline-block;
                text-transform: uppercase;
                color: #fff;
                padding: 10px 30px;
                background: #c4c086;
                border-radius: 10px;
                margin: 10px 10px 0 0;
                font-weight: 600;
                font-size: 16px;
            }

            @media all and (max-width: 768px) {
                left: 15px;
                right: inherit;
            }

            @media all and (max-width: 576px){
                left: 5px;
                p{
                    font-size: 12px;
                }
            }

        }

        .negative{
            color: #ff5b57;
        }
        .positive{
            color: #28a745;
        }

        .btn-footer-link{
            display: block;
            text-align: center;
            padding: 10px 0;
            width: 100%;
            font-weight: 500;
            font-size: 14px;
            color: #fff;
            background: #007bff;
            p{
                display: inline-block;
                padding: 0 10px;
                margin: 0;
            }

            @media all and (max-width: 768px){
                font-size: 12px;
                p{
                    display: block;
                }
            }
        }

        .btn-group button {
            background-color: #4CAF50; /* Green background */
            border: 1px solid green; /* Green border */
            color: white; /* White text */
            padding: 10px 24px; /* Some padding */
            cursor: pointer; /* Pointer/hand icon */
            width: 50%; /* Set a width if needed */
            display: block; /* Make the buttons appear below each other */
        }

        .btn-group button:not(:last-child) {
            border-bottom: none; /* Prevent double borders */
        }

        /* Add a background color on hover */
        .btn-group button:hover {
            background-color: #3e8e41;
        }

        .btn-imprimir{
            display: inline-block;
            button{
                background: rgb(0, 123, 255);
                border-color: rgb(0, 123, 255);
            }
        }

        .button-save-content{
            width: 120px;
        }

        .select-filtro{
            margin-right: 10px;
            width: 120px;
            vertical-align: bottom;
            display: inline-block;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
            padding-bottom: 100px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
            padding-bottom: 150px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .button-floting{
            position: fixed;
            left: 245px;
            bottom: 35px;

            @media all and (max-width: 1200px){
                left: 25px;
            }

            .btn-delete{
                outline: none;
                padding: 10px 15px;
                border-radius: 5px;
                font-weight: 500;
                font-size: 14px;
                background-color: #339AF0;
                color: rgb(255, 255, 255);
                p{
                    display: inline-block;
                    padding: 0 0 0 10px;
                    margin: 0;
                }

            }
        }

    }

</style>
