<template>
    <div id="root" class="recibos-section">
        <div v-if="checkPrivilege('recibos.crear')" class="button-add-bottom">
            <ButtonFloat v-on:click="nuevoRecibo"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Recibos</h2>
            </div>
            <div class="buttons">
                <div class="select-check-content">
                    <SelectCheck ref="selectFiltro"
                                 v-on:change="filtrarChange"
                                 placeholder="Todos los recibos"/>
                </div>
                <div class="date-range">
                    <DateRangePicker :icons="true"
                                     :fija="true"
                                     :dateInicio.sync="fechaInicio"
                                     :dateFin.sync="fechaFin"
                                     placeholderInicio="Fecha inicio"
                                     placeholderFin="Fecha fin"
                                     format="DD-MM-YYYY"
                                     ref="fechas"
                                     @change="first(true)"/>
                </div>
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         table="clientes-proveedores"
                                         @click="searchClick"
                                         title="Buscar" />
                </div>

                <div v-if="checkPrivilege('recibos.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="nuevoRecibo" title="Nuevo +" />
                </div>
            </div>
        </div>

        <div class="content-section">

            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th v-for="(item, index) in headers" :width="item.width"
                            :key="index"
                            :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                            {{ item.text }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="recibos.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in recibos" :key="index" v-bind:class="{selected: selected(item.id)}">
                        <td :class="headers[0].hide" @click="agregarRecibos(item)">{{ item.folio_letra }}</td>
                        <td :class="headers[1].hide" @click="agregarRecibos(item)">
                            {{ item.proveedor != undefined ? item.proveedor : item.cliente.nombre + " " + item.cliente.apellidos}}
                        </td>
                        <td :class="headers[2].hide" @click="agregarRecibos(item)">
                            {{ item.tipo == 'i' ? 'Ingreso' : 'Salida' }}
                        </td>
                        <td :class="headers[3].hide" @click="agregarRecibos(item)">{{ item.fecha | dateFormat }}</td>
                        <td :class="headers[4].hide" @click="agregarRecibos(item)">
                            {{ item.total | toCurrency }}
                        </td>
                        <td :class="headers[5].hide" @click="agregarRecibos(item)">
                            <span v-if="item.pagado == 0" class="status inactive">
                                Pendiente
                            </span>
                            <span v-if="item.pagado == 3" class="status warning" style="text-transform: uppercase;">
                                Revisión
                            </span>
                            <span v-if="item.pagado == 1" class="status active">
                                Aplicado
                            </span>
                        </td>
                        <td :class="headers[6].hide" v-on:click="detalleRecibo(item)">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <ModalDetail ref="reciboDetalle" title="Recibo Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Folio</b>
                        </td>
                        <td>
                            {{ recibo.folio_letra }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Usuario</b>
                        </td>
                        <td>
                            <span v-if="recibo.usuario != undefined">
                                {{ recibo.usuario.name }}
                            </span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Tipo</b>
                        </td>
                        <td>
                            {{ recibo.tipo == 'i' ? 'Ingreso' : 'Salida' }}
                        </td>
                    </tr>
                    <tr v-if="recibo.tipo == 'i'">
                        <td>
                            <b>Cliente</b>
                        </td>
                        <td>
                            <span v-if="recibo.cliente != undefined">
                                {{ recibo.cliente.nombre }} {{ recibo.cliente.apellidos }}
                            </span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-else>
                        <td>
                            <b>Proveedor</b>
                        </td>
                        <td>
                            <span>
                                {{ recibo.proveedor }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha</b>
                        </td>
                        <td>
                            <span>{{ recibo.fecha | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Total</b>
                        </td>
                        <td>
                            <span>{{ recibo.total | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Estatus</b>
                        </td>
                        <td>
                            <span v-if="recibo.pagado == 0" class="status inactive">
                                Pendiente
                            </span>
                            <span v-if="recibo.pagado == 3" class="status warning">
                                Revisión
                            </span>
                            <span v-if="recibo.pagado == 1" class="status active">
                                Aplicado
                            </span>
                        </td>
                    </tr>

                    <tr v-if="checkPrivilege('recibos.ver-comentarios') || checkPrivilege('recibos.revision')">
                        <td>
                            <b>Anotaciones</b>
                        </td>
                        <td>
                            <span>
                                {{ recibo.descripcion }}
                            </span>
                        </td>
                    </tr>


                    </tbody>
                </table>
                <table class="table table-responsive">
                    <thead>
                    <tr v-bind:class="{noregistros: recibo.conceptos == null || recibo.conceptos.length == 0}">
                        <th width="100%">
                            Acto
                        </th>
                        <th class="hidden-sm-and-down">
                            Categoría
                        </th>
                        <th class="hidden-sm-and-down">
                            Descripción
                        </th>
                        <th>
                            Cuenta
                        </th>
                        <th>
                            Importe
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="recibo.conceptos == null || recibo.conceptos.length == 0">
                        <td class="text-center" colspan="4">
                            No hay conceptos
                        </td>
                    </tr>
                    <tr v-for="(concepto, index) in recibo.conceptos" :key="index">
                        <td>
                            <span v-if="concepto.acto != undefined">
                                {{ concepto.acto.nombre }}
                            </span>
                            <span v-else>--</span>
                        </td>
                        <td>
                            <span v-if="concepto.categoria != undefined">
                                {{ concepto.categoria.nombre }}
                            </span>
                            <span v-else>--</span>
                        </td>
                        <td class="hidden-sm-and-down">
                            {{ concepto.descripcion }}
                        </td>
                        <td>
                            <span v-if="concepto.cuenta != undefined">
                                {{ concepto.cuenta.nombre }}
                            </span>
                            <span v-else>--</span>
                        </td>
                        <td>
                            {{ concepto.importe | toCurrency }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="pdfRecibo"
                                       color="#fff"
                                       background="#007bff"
                                       title="Imprimir"
                                       icon="fa-print" />
                </div>

                <div v-if="checkPrivilege('recibos.desaplicar')"
                     v-show="recibo.pagado == 1" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="desaplicarRecibo"
                                       color="#fff"
                                       background="#ff3e09"
                                       title="Desaplicar"
                                       icon="fa-times" />
                </div>

                <div v-if="checkPrivilege('recibos.aplicar')"
                     v-show="recibo.pagado != 1" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="aplicarRecibo"
                                       color="#fff"
                                       :disabled="recibo.pagado == 1"
                                       background="#28a745"
                                       title="Aplicar"
                                       icon="fa-check" />
                </div>

                <div v-if="!checkPrivilege('recibos.revision')"
                     v-show="recibo.pagado == 0" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="revisarRecibo"
                                       color="#fff"
                                       :disabled="recibo.pagado == 1"
                                       background="#28a745"
                                       title="Revisar"
                                       icon="fa-paper-plane" />
                </div>
                <div v-else-if="recibo.pagado == 3" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="pendienteRecibo"
                                       color="#fff"
                                       :disabled="recibo.pagado == 1"
                                       background="#ff3e09"
                                       title="Pendiente"
                                       icon="fa-times" />
                </div>


                <div v-if="checkPrivilege('recibos.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editarRecibo"
                                       color="#fff"
                                       :disabled="disableButton()"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div v-if="checkPrivilege('recibos.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteRecibo"
                                       color="#fff"
                                       :disabled="disableButton()"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>


        <div v-if="recibosSeleccionados.length > 0" class="button-floting">
            <button class="btn-delete" @click="eliminarSeleccionados">
                <i class="fas fa-trash"></i>
                <p>
                    Eliminar seleccionados
                </p>
            </button>
        </div>

    </div>
</template>

<script>

    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import SelectCheck from '@/components/Form/SelectCheck'
    import DateRangePicker from '@/components/DatePicker/DateRangePicker'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'RecibosScreen',
        components: {
            SelectCheck,
            DateRangePicker,
            ButtonFloat,
            InputSearchComplete,
            ButtonAdd,
            ModalDetail,
            ModalButtonFooter
        },
        data: function(){
            return {
                page: 1,
                lastPage: 1,
                headers: [
                    { text: 'Folio', value: 'folio_letra' },
                    { text: 'Cliente/Proveedor', value: 'clientes_proveedor', hide: 'hidden-sm-and-down'},
                    { text: 'Tipo', value: 'tipo' },
                    { text: 'Fecha', value: 'fecha' },
                    { text: 'Total', value: 'total' },
                    { text: 'Estatus', value: 'aplicado', width: '10%' },
                    { text: '', value: 'acciones', width: '5%'},
                ],
                selectFiltro: [
                    {
                        key: "s",
                        title: "Salida",
                        checked: true
                    },
                    {
                        key: "i",
                        title: "Ingreso",
                        checked: true
                    }
                ],
                recibosSeleccionados: [],
                filtroEstatus: [],
                fechaInicio: '2018-01-01',
                fechaFin: '',
                filtro: '',
                recibos: [],
                proveedores: [],
                clientes: [],
                recibo: {},
                delay: 250,
                clicks: 0,
                timer: null
            }
        },
        watch: {
            fechaInicio(newVal) {
                this.$router.push({ query: { ...this.$route.query, fecha_inicio: newVal } });
            },
            '$route.query.fecha_inicio': function(val) {
                this.fecha_inicio = val;
            },
            fechaFin(newVal) {
                this.$router.push({ query: { ...this.$route.query, fecha_fin: newVal } });
            },
            '$route.query.fecha_fin': function(val) {
                this.fecha_fin = val;
            },
            filtro(newVal) {
                this.$router.push({ query: { ...this.$route.query, filtro: newVal } });
            },
            '$route.query.filtro': function(val) {
                this.filtro = val;
            },
            filtroEstatus(newVal) {
                this.$router.push({ query: { ...this.$route.query, estatus: newVal } });
            },
            '$route.query.estatus': function(val) {
                this.filtroEstatus = val;
            },
            page(newVal) {
                this.$router.push({ query: { ...this.$route.query, pagina: newVal } });
            },
            '$route.query.pagina': function(val) {
                this.page = val;
            },
        },
        mounted() {
            if(this.$route.query.fecha_inicio != undefined){
                this.fechaInicio = this.$route.query.fecha_inicio;
            }
            if(this.$route.query.fecha_fin != undefined){
                this.fechaFin = this.$route.query.fecha_fin;
            }
            if(this.$route.query.filtro != undefined){
                this.filtro = this.$route.query.filtro;
            }
            if(this.$route.query.pagina != undefined){
                this.page = this.$route.query.pagina;
            }
            if(this.$route.query.estatus != undefined){
                this.filtroEstatus = this.$route.query.estatus;
                if(this.filtroEstatus.length > 0){
                    for(let i = 0; i < this.selectFiltro.length; i++){
                        this.selectFiltro[i].checked = this.filtroEstatus.includes(this.selectFiltro[i].key);
                    }
                }
            }

            this.$refs.selectFiltro.setData(this.selectFiltro);
            this.$emit("showLoader");
            this.getRecibos();
        },
        methods: {
            disableButton(){
                if(this.recibo.pagado == 1){
                    return true;
                }
                else if(this.recibo.pagado == 3 && this.checkPrivilege('recibos.revision')){
                    return false;
                }
                else if(this.recibo.pagado == 0 || !this.isEdicion()){
                    return false;
                }
                return true;
            },
            isEdicion(){
                var d1 = Date.parse(this.recibo.fecha);
                var d2 = Date.parse(this.$store.getters.fechaEdicion);
                if(this.checkPrivilege('ignorar-fecha')){
                    return true;
                }
                else{
                    return d1 >= d2;
                }
            },
            searchClick(){
                this.first(true);
            },
            selected(item){
                return this.recibosSeleccionados.indexOf(item) > -1;
            },
            agregarRecibos(item){
                if(!this.checkPrivilege('recibos.eliminar')){
                    return;
                }
                this.clicks++;
                let id = item.id;
                if(this.clicks === 1) {
                    var vue = this;
                    this.timer = setTimeout(function() {
                        vue.clicks = 0
                    }, this.delay);

                    var d1 = Date.parse(item.fecha);
                    var d2 = Date.parse(this.$store.getters.fechaEdicion);
                    if(!this.checkPrivilege('ignorar-fecha')){
                        if(d1 < d2 || item.pagado != 0){
                            let index = this.recibosSeleccionados.indexOf(id);
                            if(index > -1){
                                this.recibosSeleccionados.splice(index, 1);
                            }
                            return;
                        }
                    }

                    let index = this.recibosSeleccionados.indexOf(id);
                    if(index > -1){
                        this.recibosSeleccionados.splice(index, 1);
                    }
                    else{
                        this.recibosSeleccionados.splice(0, 0, id);
                    }
                } else{
                    clearTimeout(this.timer);
                    let index = this.recibosSeleccionados.indexOf(id);
                    if(index > -1){
                        this.recibosSeleccionados.splice(index, 1);
                    }
                    this.detalleRecibo(item);
                    this.clicks = 0;
                }

            },
            filtrarChange: function () {
                this.filtroEstatus = this.$refs.selectFiltro.getSelecteds();
                this.first(true);
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.getRecibos();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.getRecibos();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.getRecibos();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.getRecibos();
                }
                else if(refresh){
                    this.page = 1;
                    this.getRecibos();
                }
            },
            getRecibos: function () {
                this.$emit("showLoader");
                let vue = this;
                vue.recibos = [];
                let data = {
                    fecha_inicio: this.fechaInicio,
                    fecha_fin: this.fechaFin,
                    page: this.page,
                    filtro: this.filtro,
                    estatus: this.filtroEstatus
                };
                $.get(this.API_URL + "/api/recibos/getPage", data, function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.page = response.recibos.current_page;
                        vue.lastPage = response.recibos.last_page;
                        vue.recibos = response.recibos.data;
                        if(vue.$refs.fechas != undefined){
                            vue.$refs.fechas.setDates(response.fecha_inicio, response.fecha_fin);
                        }
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json")
                    .fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            eliminarSeleccionados(){
                let vue = this;
                swal({
                    text: '¿Realmente deseas eliminar los registros seleccionados?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    let data = {"data": JSON.stringify(vue.recibosSeleccionados)};
                    vue.$emit("showLoader");
                    $.post(vue.API_URL + "/api/recibos/delete-multiple", data, function (response) {
                        vue.$emit("hideLoader");
                        if(response.success){
                            vue.recibosSeleccionados = [];
                            vue.first(true);
                            swal("Registros eliminados", response.message, "success");
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        vue.$emit("hideLoader");
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });
            },
            deleteRecibo () {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    let data = {"data": JSON.stringify(vue.recibo)};
                    $.post(vue.API_URL + "/api/recibos/delete", data, function (response) {
                        if(response.success){
                            // vue.recibos.splice(vue.indexEdit, 1);
                            // vue.indexEdit = {};
                            vue.$refs.reciboDetalle.hide();
                            vue.getRecibos();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });

            },
            pendienteRecibo(){
                let vue = this;
                swal({
                    text: '¿Realmente deseas enviar a pendiente el recibo?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    vue.recibo.revisar = false;
                    let data = {"data": JSON.stringify(vue.recibo)};
                    $.post(vue.API_URL + "/api/recibos/revisar", data, function (response) {
                        if(response.success){
                            vue.$refs.reciboDetalle.hide();
                            vue.getRecibos();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });
            },
            revisarRecibo(){
                let vue = this;
                swal({
                    text: '¿Realmente deseas enviar a revisar el recibo?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    vue.recibo.revisar = true;
                    let data = {"data": JSON.stringify(vue.recibo)};
                    $.post(vue.API_URL + "/api/recibos/revisar", data, function (response) {
                        if(response.success){
                            vue.$refs.reciboDetalle.hide();
                            vue.getRecibos();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });
            },
            aplicarRecibo () {
                let vue = this;

                swal({
                    text: '¿Realmente deseas aplicar el recibo?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    vue.recibo.aplicar = true;
                    let data = {"data": JSON.stringify(vue.recibo)};
                    $.post(vue.API_URL + "/api/recibos/aplicar", data, function (response) {
                        if(response.success){
                            // vue.recibo = response.recibo;
                            // Object.assign(vue.recibos[vue.indexEdit], vue.recibo);
                            vue.$refs.reciboDetalle.hide();
                            vue.getRecibos();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });

            },
            desaplicarRecibo(){
                let vue = this;

                swal({
                    text: '¿Realmente deseas desaplicar el recibo?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    vue.recibo.aplicar = false;
                    vue.recibo.desaplicar = true;
                    let data = {"data": JSON.stringify(vue.recibo)};
                    $.post(vue.API_URL + "/api/recibos/aplicar", data, function (response) {
                        if(response.success){
                            // vue.recibo = response.recibo;
                            // Object.assign(vue.recibos[vue.indexEdit], vue.recibo);
                            vue.$refs.reciboDetalle.hide();
                            vue.getRecibos();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });
            },
            editarRecibo: function () {
                this.$refs.reciboDetalle.hide();
                this.$router.push({ name: "editarRecibo", params: {id: this.recibo.id, search: this.$route.query}});
            },
            nuevoRecibo: function () {
                this.$router.push({ name: "nuevoRecibo" , params: {search: this.$route.query}});
            },
            detalleRecibo: function (recibo) {
                this.indexEdit = this.recibos.indexOf(recibo);
                this.recibo = Object.assign({}, recibo);

                this.$refs.reciboDetalle.show();
            },
            pdfRecibo: function () {
                window.open(this.API_URL+"/recibo/"+this.recibo.id);
            }
        }
    }
</script>

<style lang="scss">
    @media (min-width: 576px){
        .recibos-section .modal .modal-dialog {
            max-width: 600px;
        }
    }
</style>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .button-floting{
            position: fixed;
            left: 245px;
            bottom: 35px;

            @media all and (max-width: 1200px){
                left: 25px;
            }

            .btn-delete{
                outline: none;
                padding: 10px 15px;
                border-radius: 5px;
                font-weight: 500;
                font-size: 14px;
                background-color: rgb(255, 91, 87);
                color: rgb(255, 255, 255);
                p{
                    display: inline-block;
                    padding: 0 0 0 10px;
                    margin: 0;
                }

            }
        }

        .content-section{
            .data-section{
                table.table-section{
                    .selected{
                        background: rgba(196, 192, 134, 0.5);
                        &:hover{
                            background: rgba(196, 192, 134, 0.5);
                        }
                    }
                }
            }
        }

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            top: -1px;
            position: relative;
            width: 160px;
            vertical-align: bottom;
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }


    }



</style>
