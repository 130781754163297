<template>
    <div class="form-group combobox-search">
        <label v-if="title != null">{{ title }}</label>
        <div class="content"
             v-click-outside="onClose">
            <input v-model="filtro" type="text" class="form-control"
                   :placeholder="placeholderText"
                   ref="input"
                   :title="placeholderText"
                   @click="show = true"/>
            <ul v-show="show"
                :class="'items-list'">
                <li v-if="elementsFiltrados.length == 0" class="item">
                    <div>
                        <p style="padding-left: 10px;">
                            No hay resultados
                        </p>
                    </div>
                </li>
                <li v-else v-for="dato in elementsFiltrados" :class="'item ' +
                 (dato.checked ? 'active' : 'inactive')"
                    v-on:click="setSelected(dato)" v-bind:key="dato.key">
                    <div>
                        <label class="checkbox-item">
                            <input :checked="dato.checked" type="checkbox"/>
                            <span></span>
                        </label>
                        <p>
                            {{ dato.title }}
                        </p>
                    </div>
                </li>
            </ul>
            <button v-if="selected != null" type="button" v-on:click="removeItem" class="btn-buscar">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ComboCheckSearch',
        model: {

        },
        props: {
            title: String,
            placeholder: null
        },
        data: function(){
            return {
                show: false,
                filtro: '',
                datos: [],
                selected: null,
                itemsSelected: [],
                placeholderText: this.placeholder,
                extraFilter: null
            }
        },
        mounted() {
            // for(let k in this.datos){
            //     this.datos[k].checked = false;
            // }
            // this.$forceUpdate();
        },
        methods: {
            reloadData(filters){
                this.extraFilter = filters;
            },
            onClose () {
                if(this.show){
                    this.show = false;
                    this.filtro = this.selected !== null ? this.selected.title : "";
                }
            },
            setData: function (data) {
                this.datos = data;
                this.generatePlaces();
            },
            change: function () {
                this.$emit("change");
            },
            setSelected: function (selected) {
                selected.checked = !selected.checked;
                this.itemsSelected = this.getSelecteds();
                this.generatePlaces();
                this.change();
                this.$forceUpdate();
            },
            setSelecteds: function (selecteds) {
                for(let k in selecteds){
                    for(let i in this.datos){
                        if(selecteds[k].key === this.datos[i].key){
                            this.datos[i].checked = true;
                            break
                        }
                    }
                }
                this.itemsSelected = this.getSelecteds();
                this.generatePlaces();
                this.$forceUpdate();
            },
            reset: function () {
                for(let i in this.datos){
                    this.datos[i].checked = false;
                }
                this.itemsSelected = this.getSelecteds();
                this.generatePlaces();
                this.$forceUpdate();
            },
            getSelecteds: function () {
                return this.datos.filter(function (dt) {
                   return dt.checked;
                }).map(function (dt) {
                    return dt.key;
                });
            },
            getSelectedsItems: function () {
                return this.datos.filter(function (dt) {
                    return dt.checked;
                });
            },
            generatePlaces: function () {
                this.placeholderText = this.datos.filter(function (dt) {
                    return dt.checked;
                }).reduce(function (place, dt) {
                    return place += "" + dt.title + ", ";
                }, "");



                if(this.placeholderText === ""){
                    this.placeholderText = this.placeholder;
                }
                else{
                    this.placeholderText = this.placeholderText.substring(0, this.placeholderText.length - 2);
                }
            },
        },
        computed: {
            elementsFiltrados: function(){
                let vue = this;
                return this.datos.filter(function (dt) {
                    if(vue.extraFilter != null && dt.grupo_id != vue.extraFilter){
                        if(!vue.itemsSelected.includes(dt.key)){
                            return false;
                        }
                    }
                    return dt.search.toLowerCase().indexOf(vue.filtro.toLowerCase()) !== -1
                });
            }
        }


    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .combobox-search{

        .content{
            position: relative;
            .form-control{
                outline: none;
                box-shadow: none;
                border-radius: 50px;

            }
            .btn-buscar{
                border-radius: 0 50px 50px 0;
                outline: none;
                background: #2e1e29;
                position: absolute;
                right: 0;
                top: 0;
                color: #fff;
                font-size: 16px;
                width: 60px;
                height: 38px;
                text-transform: uppercase;
                padding: 0 20px 0 15px;
                font-weight: bold;
                border: none;
            }

            .items-list{
                /*opacity: 0;*/
                /*visibility: hidden;*/
                margin: 0;
                position: absolute;
                top: 100%;
                left: 50%;
                max-height: 350px;
                transform: translateX(-50%);
                border: 1px solid #f0f0f0;
                border-radius: 10px;
                overflow-y: auto;
                width: 100%;
                background: #fff;
                padding: 0;
                list-style: none;
                z-index: 2;
                transition-duration: 0.3s;

                -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
                -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
                box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);

                &.show{
                    visibility: visible;
                    opacity: 1;
                }


                &::-webkit-scrollbar {
                    width: 12px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: rgba(0,0,0,0.2);
                    border-radius: 0 50px 50px 0;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #555;
                    border-radius: 0 50px 50px 0;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #444;
                }


                .item{

                    cursor: pointer;
                    div{
                        position: relative;
                        cursor: pointer;
                        transition-duration: 0.2s;
                        border-bottom: 1px solid #f0f0f0;

                        p{
                            position: relative;
                            z-index: 4;
                            display: block;
                            margin: 0;
                            padding: 5px 10px 5px 40px;
                        }

                    }

                    &.active{
                        div{
                            color: #fff;
                            background: rgba(196,192,134);
                        }
                    }

                    &:hover{
                        div{
                            color: #fff;
                            background: rgba(196,192,134);
                        }
                    }
                }

            }

            .checkbox-item {
                display: inline-block; // to make it easier to click
                color: #333;
                cursor: pointer;
                z-index: 2;
                position: absolute; // important
                left: 10px;
                top: 50%;
                transform: translateY(-50%);

                // Now we'll create the checkbox object

                span {
                    display: inline-block;
                    position: relative;
                    background-color: transparent;
                    width: 20px;
                    height: 20px;
                    transform-origin: center;
                    border: 2px solid rgba(46, 30, 41, 1);
                    border-radius: 50%;
                    vertical-align: -6px;
                    margin-right: 10px;
                    transition: background-color 150ms 200ms, transform 350ms cubic-bezier(.78,-1.22,.17,1.89); // custom ease effect for bouncy animation

                    // Now we'll create the "tick" using pseudo elements - those will be basically two lines that will be rotated to form the "tick"

                    &:before {
                        content: "";
                        width: 0px;
                        height: 2px;
                        border-radius: 2px; // so that the tick has nice rounded look
                        background: rgba(46, 30, 41, 1);
                        position: absolute;
                        transform: rotate(45deg);
                        top: 9px; // you'll need to experiment with placement depending on the dimensions you've chosen
                        left: 4px; // you'll need to experiment with placement depending on the dimensions you've chosen
                        transition: width 50ms ease 50ms;
                        transform-origin: 0% 0%;
                    }

                    &:after {
                        content: "";
                        width: 0;
                        height: 2px;
                        border-radius: 2px; // so that the tick has nice rounded look
                        background: rgba(46, 30, 41, 1);
                        position: absolute;
                        transform: rotate(305deg);
                        top: 12px; // you'll need to experiment with placement depending on the dimensions you've chosen
                        left: 6px; // you'll need to experiment with placement depending on the dimensions you've chosen
                        transition: width 50ms ease;
                        transform-origin: 0% 0%;
                    }
                }
                // Time to add some life to it

                &:hover {
                    span {
                        &:before {
                            width: 5px;
                            transition: width 100ms ease;
                        }

                        &:after {
                            width: 10px;
                            transition: width 150ms ease 100ms;
                        }
                    }
                }
            }

            input[type="checkbox"] {
                display: none; // hide the system checkbox

                // Let's add some effects after the checkbox is checked

                &:checked {
                    +
                    span {
                        background-color: rgba(46, 30, 41, 1);
                        //transform: scale(1.25); // enlarge the box

                        &:after {
                            width: 10px;
                            background: #ffffff;
                            transition: width 150ms ease 100ms; // enlarge the tick
                        }

                        &:before {
                            width: 5px;
                            background: #ffffff;
                            transition: width 150ms ease 100ms; // enlarge the tick
                        }
                    }

                    &:hover { // copy the states for onMouseOver to avoid flickering
                        span {
                            background-color: rgba(46, 30, 41, 1);
                            /*transform: scale(1.25); // enlarge the box*/

                            &:after {
                                width: 10px;
                                background: #fff;
                                transition: width 150ms ease 100ms; // enlarge the tick
                            }

                            &:before {
                                width: 5px;
                                background: #ffffff;
                                transition: width 150ms ease 100ms; // enlarge the tick
                            }
                        }
                    }
                }
            }

        }


    }

</style>
