<template>
    <div id="root">
        <div v-if="checkPrivilege('empresas.crear')" class="button-add-bottom">
            <ButtonFloat v-on:click="nuevaEmpresa"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Empresas</h2>
            </div>
            <div class="buttons">
                <div v-if="checkPrivilege('actos.ignorar-grupo')" class="select-filtro select-grupo">
                    <FiltroSelect :valueInput.sync="grupo_id" name="grupo_id"
                                  @change="searchClick">
                        <option :value="null">Todos los grupos</option>
                        <option v-for="(item, index) in grupos" :key="index" :value="item.id">{{ item.name }}</option>
                    </FiltroSelect>
                </div>
                <div class="buscador-content" v-bind:class="{'no-margin' : !checkPrivilege('empresas.crear')}">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         table="empresas"
                                         @click="searchClick"
                                         title="Buscar" />
                </div>

                <div v-if="checkPrivilege('empresas.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="nuevaEmpresa" title="Nuevo +" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th v-for="(item, index) in headers" :width="item.width"
                            :key="index"
                            :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                            {{ item.text }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="empresas.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in empresas" :key="index" v-on:click="detalleEmpresa(item)">
                        <td :class="headers[0].hide">{{ item.nombre_completo }}</td>
                        <td :class="headers[1].hide">
                            <ul class="list-material">
                                <li v-for="(socio, indexSocio) in item.socios" :key="indexSocio">
                                    {{ socio.nombre }} {{ socio.apellidos }}
                                </li>
                            </ul>
                        </td>
                        <!--<td :class="headers[2].hide">{{ item.capital | toCurrency }}</td>-->
                        <td :class="headers[2].hide">
                            {{ item.estado }}
                        </td>
                        <td :class="headers[3].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <ModalForm id="modalEmpresa"
                   ref="empresaForm"
                   title="Empresa"
                   v-on:submit="saveEmpresa">
            <template v-slot:body>
                <input type="hidden" v-model="empresaEdit.id">

                <ComboCheckSearch v-show="$store.getters.user.group_id == null || checkPrivilege('actos.ignorar-grupo')"
                                  ref="grupos"
                                  title="Grupos"
                                  placeholder="Selecciona grupos"/>

                <div class="cliente-content">
                    <div class="column">
                        <ComboBoxSearch ref="clientes" title="Cliente *"
                                        placeholder="Seleccionar cliente"/>
                    </div>
                    <div class="column column-check">
                        <FormCheck ref="clienteActivo" v-model="empresaEdit.cliente_activo"
                                   :valueInput="true"
                                   :valueNotChecked="false"
                                   name="cliente_activo"/>
                    </div>
                </div>

                <div class="cliente-content">
                    <div class="column">
                        <ComboBoxSearch ref="clientesAlternativos" title="Cliente alternativo"
                                        placeholder="Seleccionar cliente alternativo"/>
                    </div>
                    <div class="column column-check">
                        <FormCheck ref="clienteActivo1" v-model="empresaEdit.cliente_activo_1"
                                   :valueInput="true"
                                   :valueNotChecked="false"
                                   name="cliente_activo_1"/>
                    </div>
                </div>

                <div class="cliente-content">
                    <div class="column">
                        <ComboBoxSearch ref="clientesAlternativos2" title="Cliente alternativo"
                                        placeholder="Seleccionar cliente alternativo"/>
                    </div>
                    <div class="column column-check">
                        <FormCheck ref="clienteActivo2" v-model="empresaEdit.cliente_activo_2"
                                   :valueInput="true"
                                   :valueNotChecked="false"
                                   name="cliente_activo_2"/>
                    </div>
                </div>
                <FormInput :valueInput.sync="empresaEdit.nombre" :required="true"
                           name="dors" title="DORS *" />

                <FormInput :valueInput.sync="empresaEdit.giro" :required="false"
                           name="giro" title="Giro" />

                <ComboBoxSearch ref="regimenes" title="Regimen"
                                placeholder="Selecciona un regimen"/>

                <FormInput :valueInput.sync="empresaEdit.domicilio" :required="false"
                           name="domicilio" title="Domicilio" />


                <ComboCheckSearch ref="proveedores"
                                  v-on:change="loadSocios"
                                  title="Proveedores"
                                  placeholder="Selecciona proveedores"/>

                <ComboCheckSearch v-show="socios.length > 0" ref="socios"
                                  v-on:change="loadAgu"
                                  title="Socios"
                                  placeholder="Selecciona socios"/>

                <ComboBoxSearch v-show="sociosSelecteds.length > 0" ref="agu" title="AGU"
                                placeholder="Selecciona un socio"/>


                <div class="form-group">
                    <label>Notas</label>
                    <textarea v-model="empresaEdit.notas" class="form-control"
                              rows="3"
                              placeholder="Ingresa notas"
                              name="notas"
                    ></textarea>
                </div>


            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonEmpresa" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>

        <ModalForm ref="empresaFormEliminar"
                   title="Eliminar Empresa"
                   v-on:submit="deleteFormEmpresa">
            <template v-slot:body>
                <input type="hidden" v-model="empresaEdit.id">

                <ComboBoxSearchUrl ref="emrpesaTransfer" title="Empresas"
                                   :url="API_URL+'/api/empresas'"
                                   placeholder="No tranferir actos"/>

            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonElimnarEmpresa" type="submit" title="Eliminar" />
                </div>
            </template>
        </ModalForm>


        <ModalDetail ref="empresaDetalle" title="Empresa Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr v-if="checkPrivilege('actos.ignorar-grupo')">
                        <td>
                            <b>Grupos</b>
                        </td>
                        <td>
                            <span v-for="(grupo, index) in empresaEdit.grupos"
                            :key="index">
                                {{ grupo.name }}
                                {{ index < empresaEdit.grupos.length - 1 ? ", " : ""}}
                            </span>
                        </td>
                    </tr>
                    <tr v-for="(cliente, index) in empresaEdit.clientes" :key="index">
                        <td>
                            <b>Cliente {{ index > 0 ? "alternativo" : ""}}</b>
                        </td>
                        <td>
                            {{ cliente.nombre }} {{ cliente.apellidos }}
                            <span class="active-message" :class="{active: (cliente.pivot.activo == 1)}">
                  {{ cliente.pivot.activo == 1 ? "Activo" : "Inactivo" }}
                </span>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <b>DORS</b>
                        </td>
                        <td>
                            <span>{{ empresaEdit.nombre_completo }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Giro</b>
                        </td>
                        <td>
                            <span>{{ empresaEdit.giro }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Regimen</b>
                        </td>
                        <td>
                            <span v-if="empresaEdit.regimen != null">
                                {{ empresaEdit.regimen.nombre }}
                            </span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Domicilio</b>
                        </td>
                        <td>
                            <span>{{ empresaEdit.domicilio }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Último evento</b>
                        </td>
                        <td>
                            <span>{{ empresaEdit.estado }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>AGU</b>
                        </td>
                        <td>
                            <span v-if="empresaEdit.agu != null">
                                {{ empresaEdit.agu.nombre }} {{ empresaEdit.agu.apellidos }}
                            </span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Socios</b>
                        </td>
                        <td>
                            <ul v-if="empresaEdit.socios != null" class="list-material">
                                <li v-for="(socio, indexSocio) in empresaEdit.socios" :key="indexSocio">
                                    {{ socio.nombre }} {{ socio.apellidos }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Notas</b>
                        </td>
                        <td>
                            <span>{{ empresaEdit.notas }}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="checkPrivilege('empresas.actos')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="actosEmpresa"
                                       color="#fff"
                                       background="#00acac"
                                       title="Actos"
                                       icon="fa-lightbulb" />
                </div>

                <div v-if="checkPrivilege('empresas.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editEmpresa"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div v-if="checkPrivilege('empresas.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteEmpresa"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>


    </div>
</template>

<script>
import FormInput from '@/components/Form/FormInput'
import ComboBoxSearchUrl from '@/components/Form/ComboBoxSearchUrl'
import InputSearchComplete from '@/components/Filter/InputSearchComplete'
import ButtonAdd from '@/components/Filter/ButtonAdd'
import FormCheck from '@/components/Form/FormCheck'
import ComboBoxSearch from '@/components/Form/ComboBoxSearch'
import ButtonLoader from '@/components/Form/ButtonLoader'
import ComboCheckSearch from '@/components/Form/ComboCheckSearch'
import ModalDetail from '@/components/Modal/ModalDetail'
import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
import ModalForm from '@/components/Modal/ModalForm'
import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
import * as $ from 'jquery';
import swal from 'sweetalert2';
import FiltroSelect from "@/components/Filter/FiltroSelect";

export default {
    name: 'EmpresasScreen',
    components: {
        FiltroSelect,
        ComboBoxSearchUrl,
        ButtonFloat,
        FormInput,
        InputSearchComplete,
        ButtonAdd,
        FormCheck,
        ComboCheckSearch,
        ComboBoxSearch,
        ButtonLoader,
        ModalDetail,
        ModalForm,
        ModalButtonFooter
    },
    data: function(){
        return {
            barcodeValue: 'test',
            page: 1,
            lastPage: 1,
            headers: [
                { text: 'DORS', align: 'left', value: 'dors'},
                { text: 'Socios', value: 'socios', width: '30%', hide: 'hidden-sm-and-down'},
                // { text: 'Capital', value: 'capital', width: '10%' },
                { text: 'Último evento', value: 'ultimo_evento', width: '15%' },
                { text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down' },
            ],
            filtro: '',
            empresas: [],
            clientes: [],
            proveedores: [],
            socios: [],
            sociosSelecteds: [],

            grupos: [],
            gruposSelecteds: [],
            empresaEdit: {},
            indexEdit: -1,

            // grupos: [],
            grupo_id: null
        }
    },
    mounted() {
        this.$emit("showLoader");
        this.getEmpresas();
        this.getRegimenes();
        this.getProveedores();
        this.getClientes();

        if(this.checkPrivilege("actos.ignorar-grupo")) {
            this.getGrupos();
        }
    },
    methods: {
        actualizarProveedores(){
            let grupoId = null;
            if(this.empresaEdit.grupo_id > 0){
                grupoId = this.empresaEdit.grupo_id;
            }
            this.$refs.proveedores.reloadData(grupoId);
        },
        searchClick(){
            this.first(true);
        },
        next: function () {
            this.page += 1;
            if(this.page > this.lastPage){
                this.page = this.lastPage;
            }
            else{
                this.getEmpresas();
            }
        },
        prev: function () {
            this.page -= 1;
            if(this.page < 1){
                this.page = 1;
            }
            else{
                this.getEmpresas();
            }

        },
        last: function () {
            if(this.page != this.lastPage) {
                this.page = this.lastPage;
                this.getEmpresas();
            }
        },
        first: function (refresh) {
            if(this.page != 1) {
                this.page = 1;
                this.getEmpresas();
            }
            else if(refresh){
                this.page = 1;
                this.getEmpresas();
            }
        },
        actosEmpresa: function () {
            this.$refs.empresaDetalle.hide();
            this.$router.push({ path: "/actos-empresa_"+this.empresaEdit.id });
        },
        getEmpresas: function () {
            this.$emit("showLoader");
            let vue = this;
            vue.empresas = [];
            let data = {page: this.page, filtro: this.filtro, grupo_id: this.grupo_id};
            $.get(this.API_URL + "/api/empresas/getPage", data, function (response) {
                vue.$emit("hideLoader");
                if(response.success){
                    vue.page = response.empresas.current_page;
                    vue.lastPage = response.empresas.last_page;
                    vue.empresas = response.empresas.data;
                }
                else{
                    swal(response.message, '', 'info')
                }
            }, "json").fail(function (error) {
                vue.$emit("hideLoader");
                if(error.status === 401){
                    swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                    vue.$emit("logout");
                }
                else{
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                }

            });
        },
        getProveedores: function () {
            let vue = this;
            vue.proveedores = [];
            $.get(this.API_URL + "/api/proveedores/get", function (response) {
                if(response.success){
                    for(let k in response.proveedores){
                        vue.proveedores[k] = {};
                        vue.proveedores[k].key = response.proveedores[k].id;
                        let apellidos = response.proveedores[k].apellidos == null ?
                            "" : response.proveedores[k].apellidos;
                        vue.proveedores[k].title = response.proveedores[k].nombre + " " + apellidos;
                        vue.proveedores[k].checked = false;
                        vue.proveedores[k].search = response.proveedores[k].search;
                        vue.proveedores[k].grupo_id = response.proveedores[k].grupo_id;
                        vue.proveedores[k].item = response.proveedores[k];
                    }
                    if(vue.$refs.proveedores != undefined){
                        vue.$refs.proveedores.setData(vue.proveedores);
                    }

                }
                else{
                    swal(response.message, '', 'info')
                }
            }, "json").fail(function (error) {
                if(error.status === 401){
                    swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                    vue.$emit("logout");
                }
                else{
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                }
            });
        },
        getRegimenes: function () {
            let vue = this;
            vue.$refs.regimenes.setCargando(true);
            vue.regimenes = [];
            $.get(this.API_URL + "/api/regimenes/get", function (response) {
                if(response.success){
                    for(let k in response.regimenes){
                        vue.regimenes[k] = {};
                        vue.regimenes[k].key = response.regimenes[k].id;
                        vue.regimenes[k].search = response.regimenes[k].search;
                        vue.regimenes[k].title = response.regimenes[k].abreviacion +
                            " - " + response.regimenes[k].nombre;
                    }
                    if(vue.$refs.regimenes != undefined){
                        vue.$refs.regimenes.setData(vue.regimenes);
                        vue.$refs.regimenes.setCargando(false);
                    }
                }
                else{
                    swal(response.message, '', 'info')
                }
            }, "json").fail(function (error) {
                if(error.status === 401){
                    swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                    vue.$emit("logout");
                }
                else{
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                }
            });
        },
        loadSocios: function () {
            this.socios = [];
            this.$refs.agu.removeItem();
            let proveedoresSelected = this.$refs.proveedores.getSelectedsItems();
            for(let i in proveedoresSelected){
                this.socios = this.socios.concat(proveedoresSelected[i].item.socios);
            }

            var socios = [];

            for(let k in this.socios){
                socios[k] = {};
                socios[k].key = this.socios[k].id;
                let apellidos = this.socios[k].apellidos == null ?
                    "" : this.socios[k].apellidos;
                socios[k].title = this.socios[k].nombre + " " + apellidos;
                socios[k].checked = false;
                socios[k].search = this.socios[k].search;
                socios[k].item = this.socios[k];
            }

            if(this.$refs.socios != undefined){
                this.$refs.socios.setData(socios);
            }

            this.loadAgu();
        },
        loadAgu: function () {
            this.sociosSelecteds = [];
            this.$refs.agu.removeItem();
            let sociosSelected = this.$refs.socios.getSelectedsItems();
            for(let i in sociosSelected){
                this.sociosSelecteds = this.sociosSelecteds.concat(sociosSelected[i].item);
            }

            var selecteds = [];

            for(let k in this.sociosSelecteds){
                selecteds[k] = {};
                selecteds[k].key = this.sociosSelecteds[k].id;
                let apellidos = this.sociosSelecteds[k].apellidos == null ?
                    "" : this.sociosSelecteds[k].apellidos;
                selecteds[k].title = this.sociosSelecteds[k].nombre + " " + apellidos;
                selecteds[k].search = this.sociosSelecteds[k].search;

            }

            if(this.$refs.agu != undefined){
                this.$refs.agu.setData(selecteds);
                this.$refs.agu.setCargando(false);
            }

        },
        getClientes: function () {
            let vue = this;
            vue.$refs.clientes.setCargando(true);
            vue.$refs.clientesAlternativos.setCargando(true);
            vue.$refs.clientesAlternativos2.setCargando(true);
            vue.clientes = [];
            $.get(this.API_URL + "/api/clientes/get", function (response) {
                vue.$emit("hideLoader");
                if(response.success){
                    for(let k in response.clientes){
                        vue.clientes[k] = {};
                        vue.clientes[k].key = response.clientes[k].id;
                        let apellidos = response.clientes[k].apellidos == null ?
                            "" : response.clientes[k].apellidos;
                        vue.clientes[k].title = response.clientes[k].nombre + " " + apellidos;
                        vue.clientes[k].search = response.clientes[k].search;
                    }
                    if(vue.$refs.clientes != undefined){
                        vue.$refs.clientes.setData(Object.assign([], vue.clientes));
                    }
                    if(vue.$refs.clientesAlternativos != undefined){
                        vue.$refs.clientesAlternativos.setData(Object.assign([], vue.clientes));
                    }
                    if(vue.$refs.clientesAlternativos2 != undefined){
                        vue.$refs.clientesAlternativos2.setData(Object.assign([], vue.clientes));
                    }
                    vue.$refs.clientes.setCargando(false);
                    vue.$refs.clientesAlternativos.setCargando(false);
                    vue.$refs.clientesAlternativos2.setCargando(false);
                }
                else{
                    swal(response.message, '', 'info')
                }
            }, "json").fail(function (error) {
                vue.$emit("hideLoader");
                if(error.status === 401){
                    swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                    vue.$emit("logout");
                }
                else{
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                }
            });
        },
        saveEmpresa: function (e) {
            e.preventDefault();

            let grupos = this.$refs.grupos.getSelecteds();
            if(this.$store.getters.user.group_id == null || this.checkPrivilege('actos.ignorar-grupo')){
                if(grupos.length == 0){
                    swal("Selecciona un grupo", "", "info")
                    return;
                }
            }

            let client = this.$refs.clientes.getSelected();

            let regimen = this.$refs.regimenes.getSelected();

            let agu = this.$refs.agu.getSelected();

            if(client == undefined || client == null){
                swal("Selecciona un cliente", "", "info");
                return;
            }

            let clienteAlternativo = this.$refs.clientesAlternativos.getSelected();
            if(clienteAlternativo != undefined || clienteAlternativo != null){
                this.empresaEdit.cliente_id_1 = clienteAlternativo.key;
            }
            else{
                this.empresaEdit.cliente_id_1 = null;
            }

            let clienteAlternativo2 = this.$refs.clientesAlternativos2.getSelected();
            if(clienteAlternativo2 != undefined || clienteAlternativo2 != null){
                this.empresaEdit.cliente_id_2 = clienteAlternativo2.key;
            }
            else{
                this.empresaEdit.cliente_id_2 = null;
            }

            if(regimen != undefined || regimen != null){
                this.empresaEdit.regimen_id = regimen.key;
            }

            this.empresaEdit.socios = this.sociosSelecteds.map(function (item) {
                return item.id;
            });

            if(agu != undefined && agu != null){
                this.empresaEdit.agu = agu.key;
            }
            else{
                this.empresaEdit.agu = null;
            }

            this.empresaEdit.cliente_id = client.key;

            this.empresaEdit.cliente_activo = this.$refs.clienteActivo.getChecked();
            this.empresaEdit.cliente_activo_1 = this.$refs.clienteActivo1.getChecked();
            this.empresaEdit.cliente_activo_2 = this.$refs.clienteActivo2.getChecked();
            this.empresaEdit.grupos = grupos;

            let vue = this;

            this.$refs.buttonEmpresa.setShowLoader(true);

            $.post(this.API_URL + "/api/empresas/save", this.empresaEdit, function (response) {
                vue.$refs.buttonEmpresa.setShowLoader(false);
                if(response.success){
                    vue.empresaEdit = response.record;

                    if (vue.indexEdit > -1) {
                        Object.assign(vue.empresas[vue.indexEdit], vue.empresaEdit);
                    } else {
                        vue.empresas.splice(0, 0, vue.empresaEdit)
                    }
                    vue.empresaEdit = {};
                    vue.indexEdit = -1;
                    vue.closeModalEmpresa();

                }
                else{
                    swal(response.message, "", "info")
                }
            }, "json").fail(function (error) {
                vue.$refs.buttonEmpresa.setShowLoader(false);
                if(error.status === 401){
                    swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                    vue.$emit("logout");
                }
                else{
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                }

            });

        },
        deleteFormEmpresa(e){
            e.preventDefault();
            let vue = this;
            swal({
                text: '¿Realmente deseas eliminar el registro?',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                type: 'warning'
            }).then(function () {
                let data = {id: vue.empresaEdit.id};

                let empresaTrans = vue.$refs.emrpesaTransfer.getSelected();

                if(empresaTrans != undefined){
                    data.transfer_id = empresaTrans.key;
                }

                vue.$refs.buttonElimnarEmpresa.setShowLoader(true);

                $.post(vue.API_URL + "/api/empresas/delete", data, function (response) {
                    vue.$refs.buttonElimnarEmpresa.setShowLoader(false);
                    if(response.success){
                        vue.empresas.splice(vue.indexEdit, 1);
                        vue.empresaEdit = {};
                        vue.$refs.empresaFormEliminar.hide();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    vue.$refs.buttonElimnarEmpresa.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            });
        },
        deleteEmpresa () {
            this.$refs.empresaDetalle.hide();
            this.$refs.emrpesaTransfer.removeItem();
            let data = {};
            data.except = this.empresaEdit.id;
            this.$refs.emrpesaTransfer.setExtras(data);
            this.$refs.empresaFormEliminar.show();
        },
        detalleEmpresa: function (empresa) {
            this.indexEdit = this.empresas.indexOf(empresa);
            this.empresaEdit = Object.assign({}, empresa);
            this.actualizarProveedores();
            this.$refs.empresaDetalle.show();

        },
        editEmpresa: function () {
            this.$refs.empresaDetalle.hide();

            if(this.empresaEdit.clientes[0] != undefined){
                let apellidos = this.empresaEdit.clientes[0].apellidos == null ?
                    "" : this.empresaEdit.clientes[0].apellidos;
                let client = {
                    key: this.empresaEdit.clientes[0].id,
                    title: this.empresaEdit.clientes[0].nombre + " " + apellidos
                };

                this.$refs.clienteActivo.setChecked(this.empresaEdit.clientes[0].pivot.activo);
                this.$refs.clientes.setSelected(client);
            }
            else{
                this.$refs.clientes.removeItem();
                this.$refs.clienteActivo.setChecked(false);
            }

            if(this.empresaEdit.clientes[1] != undefined){
                let apellidos = this.empresaEdit.clientes[1].apellidos == null ?
                    "" : this.empresaEdit.clientes[1].apellidos;
                let client = {
                    key: this.empresaEdit.clientes[1].id,
                    title: this.empresaEdit.clientes[1].nombre + " " + apellidos
                };

                this.$refs.clienteActivo1.setChecked(this.empresaEdit.clientes[1].pivot.activo);
                this.$refs.clientesAlternativos.setSelected(client);
            }
            else {
                this.$refs.clientesAlternativos.removeItem();
                this.$refs.clienteActivo1.setChecked(false);
            }

            if(this.empresaEdit.clientes[2] != undefined){
                let apellidos = this.empresaEdit.clientes[2].apellidos == null ?
                    "" : this.empresaEdit.clientes[2].apellidos;
                let client = {
                    key: this.empresaEdit.clientes[2].id,
                    title: this.empresaEdit.clientes[2].nombre + " " + apellidos
                };

                this.$refs.clienteActivo2.setChecked(this.empresaEdit.clientes[2].pivot.activo);
                this.$refs.clientesAlternativos2.setSelected(client);
            }
            else {
                this.$refs.clientesAlternativos2.removeItem();
                this.$refs.clienteActivo2.setChecked(false);
            }

            let selecteds = [];
            for(let k in this.empresaEdit.socios){
                selecteds[k] = {};
                selecteds[k].key = this.empresaEdit.socios[k].id
            }

            let selectedsProveedores = [];
            for(let k in this.empresaEdit.proveedores){
                selectedsProveedores[k] = {};
                selectedsProveedores[k].key = this.empresaEdit.proveedores[k].id
            }

            this.$refs.grupos.reset();
            this.$refs.proveedores.setSelecteds(selectedsProveedores);
            this.$refs.socios.reset();
            this.loadSocios();
            this.$refs.socios.setSelecteds(selecteds);
            this.loadAgu();

            if(this.empresaEdit.agu != undefined && this.empresaEdit.agu != null){
                let apellidos =  this.empresaEdit.agu.apellidos == null ?
                    "" : this.empresaEdit.agu.apellidos;

                let agu = {
                    key: this.empresaEdit.agu.id,
                    title: this.empresaEdit.agu.nombre + " " + apellidos
                };

                this.$refs.agu.setSelected(agu);

            }
            else{
                this.$refs.agu.removeItem();
            }

            if(this.empresaEdit.regimen != undefined && this.empresaEdit.regimen != null){
                let regimen = {
                    key: this.empresaEdit.regimen.id,
                    title: this.empresaEdit.regimen.abreviacion + "-" +
                        this.empresaEdit.regimen.nombre
                };

                this.$refs.regimenes.setSelected(regimen);
            }
            else{
                this.$refs.regimenes.removeItem();
            }

            let selectedGrupos = [];
            for(let k in this.empresaEdit.grupos){
                selectedGrupos[k] = {};
                selectedGrupos[k].key = this.empresaEdit.grupos[k].id
            }

            this.$refs.grupos.setSelecteds(selectedGrupos);

            this.openModalEmpresa();
        },
        nuevaEmpresa: function () {
            this.empresaEdit = {};
            // let grupo = this.$store.getters.user.group_id;
            // if(grupo != null){
            //     this.empresaEdit.grupo_id = grupo;
            // }
            this.actualizarProveedores();
            this.indexEdit = -1;
            this.$refs.socios.reset();
            this.$refs.clientes.removeItem();
            this.$refs.clientesAlternativos.removeItem();
            this.$refs.clientesAlternativos2.removeItem();

            this.$refs.clienteActivo.setChecked(false);
            this.$refs.clienteActivo1.setChecked(false);
            this.$refs.clienteActivo2.setChecked(false);

            this.$refs.regimenes.removeItem();
            this.$refs.grupos.reset();
            this.$refs.proveedores.reset();
            this.$refs.agu.removeItem();
            this.loadSocios();
            this.loadAgu();
            this.openModalEmpresa();
        },
        openModalEmpresa: function () {

            this.$refs.empresaForm.show();
        },
        closeModalEmpresa: function () {
            this.$refs.empresaForm.hide();
        },
        getGrupos: function () {
            let vue = this;
            vue.grupos = [];
            $.get(this.API_URL + "/api/grupos", function (response) {
                if(response.success){
                    for(let k in response.grupos){
                        vue.grupos[k] = {};
                        vue.grupos[k].key = response.grupos[k].id;
                        vue.grupos[k].title = response.grupos[k].name;
                        vue.grupos[k].checked = false;
                        vue.grupos[k].search = response.grupos[k].name;
                        vue.grupos[k].item = response.grupos[k];
                    }
                    if(vue.$refs.grupos != undefined){
                        vue.$refs.grupos.setData(vue.grupos);
                    }

                    vue.grupos = response.grupos;
                }
                else{
                    swal(response.message, '', 'info')
                }
            }, "json").fail(function (error) {
                if(error.status === 401){
                    swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                    vue.$emit("logout");
                }
                else{
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                }
            });
        },
    },
    // computed: {
    //     empresasFiltradas () {
    //         let vue = this;
    //         return this.empresas.filter(function (empt) {
    //             return empt.search.toLowerCase().indexOf(vue.filtro.toLowerCase()) > -1;
    //         });
    //     },
    // }
}
</script>

<style scoped lang="scss">


#root{
    background: #f7f8fc;
    padding: 30px 25px;
    width: 100%;
    height: 100%;

    .active-message{
        color: #f12b2c;
        &.active{
            color: #29cc97;
        }
    }

    .cliente-content{
        display: table;
        width: 100%;
        .column{
            display: table-cell;
        }
        .column-check{
            padding-top: 12px;
            vertical-align: middle;
            text-align: right;
        }
    }

    .header-section .buttons .buscador-content.no-margin{
        margin: 0;
    }

    .button-save-content{
        width: 120px;
    }


    @media all and (max-width: 768px){
        padding: 15px 15px;
    }

    @media all and (max-width: 576px){
        padding: 10px 5px;
    }

    .btn-more{
        color: #aaa;
        &:hover{
            color: #333;
        }
    }

    .select-filtro{
        margin-right: 10px;
        width: 140px;
        vertical-align: bottom;
        display: inline-block;
    }

}



</style>
