<template>
    <div id="root">
        <div class="button-add-bottom">
            <ButtonOptionsFloat v-if="checkPrivilege('cuentas.crear') || checkPrivilege('cuenta-movimientos.crear')">
                <ButtonOptionItem v-if="checkPrivilege('cuentas.crear')" v-on:click="addCuenta" title="Cuenta" icon="fa-money-check-alt" />
                <ButtonOptionItem v-if="checkPrivilege('cuenta-movimientos.crear')" v-on:click="addMovimiento" title="Movimiento" icon="fa-exchange-alt" />
            </ButtonOptionsFloat>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Cuentas</h2>
            </div>

            <div class="buttons">
                <div class="buscador-content"  v-bind:class="{'no-margin' : !(checkPrivilege('cuentas.crear') || checkPrivilege('cuenta-movimientos.crear'))}">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         table="cuentas"
                                         @click="searchClick"
                                         title="Buscar" />
                </div>

                <div class="date-range" v-bind:class="{'no-margin' : !checkPrivilege('cuenta-movimientos.crear')}">
                    <DateRangePicker :icons="true"
                                     :dateInicio.sync="fechaInicio"
                                     :dateFin.sync="fechaFin"
                                     placeholderInicio="Fecha inicio"
                                     placeholderFin="Fecha fin"
                                     format="DD-MM-YYYY"
                                     ref="fechas"
                                     v-on:change="getCuentas"/>
                </div>

                <div class="button-add-top">
                    <ButtonOptions title="Nuevo +" v-if="checkPrivilege('cuentas.crear') || checkPrivilege('cuenta-movimientos.crear')">
                        <ButtonOptionItem v-if="checkPrivilege('cuentas.crear')" v-on:click="addCuenta" title="Cuenta" icon="fa-money-check-alt" />
                        <ButtonOptionItem v-if="checkPrivilege('cuenta-movimientos.crear')" v-on:click="addMovimiento" title="Movimiento" icon="fa-exchange-alt" />
                    </ButtonOptions>
                </div>
            </div>
        </div>
        <div class="content-section">
            <v-data-table
                    :headers="headers"
                    :items="cuentasFiltrados"
                    hide-default-header
                    item-key="tabla-cuentas"
                    class="elevation-1" >

                <template v-slot:header="{ props: { headers } }">
                    <thead>
                    <tr>
                        <th v-for="header in headers"
                            :class="'text-left sortable ' + header.hide"
                            :key="header.value"
                            :width="header.width">
                            {{ header.text }}
                        </th>
                    </tr>
                    </thead>
                </template>


                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-if="items.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in items" :key="index" v-on:click="detalleCuenta(item)">
                        <td :class="headers[0].hide">
                            <span v-if="item.bloqueada == 1" class="block-item">
                                <i class="fas fa-lock"></i>
                            </span>
                            {{ item.nombre }}
                        </td>
                        <td :class="headers[1].hide">{{ item.total | toCurrency }}</td>
                        <td :class="headers[2].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>

        <div class="modal fade" id="modalMovimiento">
            <div class="modal-dialog">
                <form v-on:submit="saveMovimiento" class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Movimiento</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>

                    <div>
                        <v-tabs v-model="tabMovimiento"
                                fixed-tabs
                                background-color="#f0f0f0">
                            <v-tab key="ingreso" >
                                Ingreso
                            </v-tab>
                            <v-tab key="salida" >
                                Salida
                            </v-tab>
                            <v-tab key="transferencia" >
                                Transferencia
                            </v-tab>
                        </v-tabs>

                        <div class="modal-body">

                            <ComboBoxSearch v-show="tabMovimiento === 2 || tabMovimiento === 1"
                                            ref="cuentaSalida" title="Cuenta de origen"
                                            placeholder="Selecciona cuenta de origen"/>

                            <ComboBoxSearch v-show="tabMovimiento === 2 || tabMovimiento === 0" ref="cuentaEntrada"
                                            title="Cuenta de destino"
                                            placeholder="Selecciona cuenta de destino"/>

                            <ComboBoxSearch v-show="tabMovimiento === 1"
                                            ref="categoriaGasto" title="Categoría"
                                            placeholder="Selecciona la categoría"/>

                            <ComboBoxSearch v-show="tabMovimiento === 0"
                                            ref="categoriaIngreso" title="Categoría"
                                            placeholder="Selecciona la categoría"/>


                            <FormInput :valueInput.sync="movimiento.importe"
                                       :required="true"
                                       type="number"
                                       name="importe" title="Importe"
                                       placeholder="0.0"/>

                            <FormInput :valueInput.sync="movimiento.descripcion"
                                       :required="false"
                                       name="descripcion" title="Descripción" />

                            <DatePicker styleClass="form-control" title="Fecha del movimiento" :date.sync="movimiento.fecha_movimiento"
                                        placeholder="yyyy-mm-dd"
                                        :limitDate="!checkPrivilege('ignorar-fecha')"
                                        ref="fechaMovimiento" />

                            <FormFile :required="false"
                                      ref="comprobanteFile"
                                      :valueInput.sync="movimiento.comprobante"
                                      name="ine" title="Comprobante"/>

                        </div>
                    </div>
                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <!--<button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>-->
                        <div class="button-save-content">
                            <ButtonLoader ref="buttonMovimiento" type="submit" title="Guardar" />
                        </div>
                    </div>

                </form>
            </div>
        </div>

        <ModalDetail ref="cuentaDetalle" title="Cuenta Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Nombre</b>
                        </td>
                        <td>
                            <span>{{ cuenta.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Saldo actual</b>
                        </td>
                        <td>
                            <span>{{(cuenta.total) | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Cuenta bloqueada</b>
                        </td>
                        <td>
                            <span>{{ cuenta.bloqueada == 1 ? "Si" : "No"}}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="checkPrivilege('cuenta-movimientos')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="movimientosCuenta" color="#fff"
                                       background="#00acac"
                                       title="Movimientos"
                                       icon="fa-exchange-alt" />
                </div>
                <div v-if="checkPrivilege('cuentas.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editCuenta"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>
                <div v-if="checkPrivilege('cuentas.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteCuenta"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"
                                        />
                </div>
            </template>
        </ModalDetail>


        <ModalForm id="modalCuenta"
                   ref="cuentaForm"
                   title="Cuenta"
                   v-on:submit="saveCuenta">
            <template v-slot:body>
                <input type="hidden" v-model="cuenta.id">
                <FormInput :valueInput.sync="cuenta.nombre" :required="true"
                           name="nombre" title="Nombre" />
                <FormInput v-show="cuenta.id === undefined" :valueInput.sync="cuenta.saldo" :required="true"
                           type="number" name="saldo" title="Saldo inicial" placeholder="0.0" />

                <FormCheck ref="checkStatus" v-model="cuenta.bloqueada"
                    :valueInput="true"
                    :valueNotChecked="false"
                    name="bloquedada"
                    title="bloqueada" />
            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonCuenta" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>

        <div class="float-saldo">
            <p>
                Saldo: {{ total | toCurrency }}
            </p>
        </div>

    </div>
</template>

<script>
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import FormInput from '@/components/Form/FormInput'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ButtonOptions from '@/components/ButtonOption/ButtonOptions'
    import ButtonOptionItem from '@/components/ButtonOption/ButtonOptionItem'
    import ComboBoxSearch from '@/components/Form/ComboBoxSearch'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ModalForm from '@/components/Modal/ModalForm'
    import DatePicker from '@/components/DatePicker/DatePicker'
    import ButtonOptionsFloat from '@/components/ButtonFloat/ButtonOptionsFloat'
    import FormFile from '@/components/Form/FormFile'
    import DateRangePicker from '@/components/DatePicker/DateRangePicker'
    import FormCheck from '@/components/Form/FormCheck'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'CuentasScreen',
        components: {
            FormCheck,
            FormFile,
            DateRangePicker,
            ButtonOptionsFloat,
            DatePicker,
            InputSearchComplete,
            FormInput,
            ButtonOptions,
            ButtonOptionItem,
            ComboBoxSearch,
            ButtonLoader,
            ModalDetail,
            ModalForm,
            ModalButtonFooter
        },
        data: function(){
            return {
                total: 0,
                tabMovimiento: 0,
                fechaInicio: '2018-10-01',
                fechaFin: '',
                headers: [
                    {text: 'Nombre', align: 'left', value: 'nombre'},
                    {text: 'Saldo', value: 'saldo', width: '15%'},
                    {text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtro: '',
                filtroComputed: '',
                cuentas: [],
                cuentasBox: [],
                cuenta: {},
                indexEdit: -1,
                movimiento: {},
                categorias: [],
                categoriaIngresos: [],
            }
        },
        mounted() {
            this.$emit("showLoader");
            this.getCuentas();
            this.getCategorias();
            this.getCategoriasIngresos();
        },
        computed: {
            cuentasFiltrados () {
                let vue = this;
                return this.cuentas.filter(function (cuenta) {
                    return cuenta.search.toLowerCase().indexOf(vue.filtroComputed.toLowerCase()) > -1;
                });
            },
        },
        methods: {
            searchClick(){
                this.filtroComputed = this.filtro;
            },
            getCategorias: function () {
                let vue = this;
                vue.categoria = [];
                let data = {tipo: "gastos"};
                $.get(this.API_URL + "/api/categorias", data, function (response) {
                    if (response.success) {
                        vue.categorias = response.categorias;

                        var cats = [];
                        for(let k in vue.categorias){
                            cats[k] = {};
                            cats[k].key = vue.categorias[k].id;
                            cats[k].title = vue.categorias[k].nombre;
                            cats[k].search = vue.categorias[k].search;
                        }
                        if(vue.$refs.categoriaGasto != undefined){
                          vue.$refs.categoriaGasto.setData(cats);
                          vue.$refs.categoriaGasto.setCargando(false);
                        }
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getCategoriasIngresos: function () {
                let vue = this;
                vue.categoriaIngresos = [];
                let data = {tipo: "ingresos"};
                $.get(this.API_URL + "/api/categorias", data, function (response) {
                    if (response.success) {
                        vue.categoriaIngresos = response.categorias;

                        var cats = [];
                        for(let k in vue.categoriaIngresos){
                            cats[k] = {};
                            cats[k].key = vue.categoriaIngresos[k].id;
                            cats[k].title = vue.categoriaIngresos[k].nombre;
                            cats[k].search = vue.categoriaIngresos[k].search;
                        }
                        if(vue.$refs.categoriaIngreso != undefined){
                          vue.$refs.categoriaIngreso.setData(cats);
                          vue.$refs.categoriaIngreso.setCargando(false);
                        }

                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getCuentas: function () {
                let vue = this;
                vue.cuentas = [];
                let data = {
                    fecha_inicio: this.fechaInicio,
                    fecha_fin: this.fechaFin
                };
                $.get(this.API_URL + "/api/cuentas/get", data, function (response) {
                    if(response.success){
                        vue.cuentas = response.cuentas;
                        vue.total = 0;
                        vue.cuentas.forEach( function(valor) {
                            vue.total += valor.total;
                        });

                        if(vue.fechaInicio == '' || vue.fechaFin == ''){
                            vue.$refs.fechas.setDates(response.fecha_inicio, response.fecha_fin);
                        }
                        vue.setDataComboBox();
                        vue.$emit("hideLoader");
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            setDataComboBox: function () {
                this.cuentasBox = [];
                this.$refs.cuentaEntrada.removeData();
                this.$refs.cuentaSalida.removeData();
                for(let k in this.cuentas){
                    if(this.cuentas[k].bloqueada != 1){
                        this.cuentasBox[k] = {};
                        this.cuentasBox[k].key = this.cuentas[k].id;
                        this.cuentasBox[k].title = this.cuentas[k].nombre;
                        this.cuentasBox[k].search = this.cuentas[k].search;
                    }
                }
                if(this.$refs.cuentaEntrada != undefined){
                  this.$refs.cuentaEntrada.setData(this.cuentasBox);
                  this.$refs.cuentaEntrada.setCargando(false);
                }
                if(this.$refs.cuentaSalida != undefined) {
                  this.$refs.cuentaSalida.setData(this.cuentasBox);
                  this.$refs.cuentaSalida.setCargando(false);
                }

            },
            addCuenta: function () {
                this.cuenta = {};
                this.$refs.checkStatus.setChecked(false);
                this.indexEdit = -1;
                this.openModalCuenta();
            },
            detalleCuenta: function (cuenta) {
                this.indexEdit = this.cuentas.indexOf(cuenta);
                this.cuenta = Object.assign({}, cuenta);
                this.$refs.cuentaDetalle.show();
            },
            movimientosCuenta: function () {
                this.$refs.cuentaDetalle.hide();
                this.$router.push({ path: "/cuenta-movimientos_"+this.cuenta.id });
            },
            editCuenta: function () {
                this.$refs.checkStatus.setChecked(this.cuenta.bloqueada);
                this.$refs.cuentaDetalle.hide();
                this.openModalCuenta();
            },
            openModalCuenta: function(){
                this.$refs.cuentaForm.show();
            },
            closeModalCuenta: function(){
                this.$refs.cuentaForm.hide();
            },
            deleteCuenta: function () {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/cuentas/delete", vue.cuenta, function (response) {
                        if(response.success){
                            vue.cuentas.splice(vue.indexEdit, 1);
                            vue.setDataComboBox();
                            vue.$refs.cuentaDetalle.hide();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }

                    });
                });


            },
            saveCuenta: function(e){
                e.preventDefault();

                let vue = this;

                vue.$refs.buttonCuenta.setShowLoader(true);

                $.post(this.API_URL + "/api/cuentas/save", this.cuenta, function (response) {
                    vue.$refs.buttonCuenta.setShowLoader(false);
                    if(response.success){
                        vue.cuenta = response.record;

                        if (vue.indexEdit > -1) {
                            Object.assign(vue.cuentas[vue.indexEdit], vue.cuenta)
                        } else {
                            vue.cuentas.splice(0, 0, vue.cuenta)
                        }
                        vue.setDataComboBox();
                        vue.cuenta = {};
                        vue.indexEdit = -1;
                        vue.closeModalCuenta();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    vue.$refs.buttonCuenta.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            addMovimiento: function () {
                this.movimiento = {};
                this.$refs.fechaMovimiento.setDate('');
                this.$refs.categoriaGasto.removeItem();
                this.$refs.categoriaIngreso.removeItem();
                this.$refs.cuentaEntrada.removeItem();
                this.$refs.cuentaSalida.removeItem();
                this.openModalMovimientos();
            },
            openModalMovimientos: function(){
                $("#modalMovimiento").modal("show");
            },
            closeModalMovimientos: function(){
                $("#modalMovimiento").modal("hide");
            },
            saveMovimiento: function (e) {
                e.preventDefault();

                let vue = this;

                if(this.tabMovimiento === 0 || this.tabMovimiento === 2){
                    let entrada = this.$refs.cuentaEntrada.getSelected();
                    if(entrada !== undefined && entrada != null){
                        this.movimiento.cuenta_entrada_id = entrada.key;
                    }
                    else{
                        swal("Selecciona la cuenta de destino", "", "info");
                        return;
                    }
                }

                if(this.tabMovimiento === 1 || this.tabMovimiento === 2){
                    let salida = this.$refs.cuentaSalida.getSelected();
                    if(salida !== undefined && salida != null){
                        this.movimiento.cuenta_salida_id = salida.key;
                    }
                    else{
                        swal("Selecciona la cuenta de origen", "", "info");
                        return;
                    }
                }

                if(this.tabMovimiento === 1){
                    let categoria = this.$refs.categoriaGasto.getSelected();
                    if(categoria != undefined && categoria != null){
                        this.movimiento.categoria_id = categoria.key;
                    }
                    else{
                        swal("Selecciona la categoría", "", "info");
                        return;
                    }
                }
                else if(this.tabMovimiento == 0){
                    let categoria = this.$refs.categoriaIngreso.getSelected();
                    if(categoria != undefined && categoria != null){
                        this.movimiento.categoria_id = categoria.key;
                    }
                    else{
                        swal("Selecciona la categoría", "", "info");
                        return;
                    }
                }

                vue.$refs.buttonMovimiento.setShowLoader(true);

                let data = new FormData();
                if(this.movimiento.id != undefined){
                    data.append("id", this.movimiento.id);
                }
                if(this.movimiento.cuenta_salida_id != undefined) {
                    data.append("cuenta_salida_id", this.movimiento.cuenta_salida_id);
                }
                if(this.movimiento.categoria_id != undefined) {
                    data.append("categoria_id", this.movimiento.categoria_id);
                }
                if(this.movimiento.cuenta_entrada_id != undefined) {
                    data.append("cuenta_entrada_id", this.movimiento.cuenta_entrada_id);
                }
                if(this.movimiento.importe != undefined) {
                    data.append("importe", this.movimiento.importe);
                }
                if(this.movimiento.descripcion != undefined) {
                    data.append("descripcion", this.movimiento.descripcion);
                }
                if(this.movimiento.fecha_movimiento != undefined) {
                    data.append("fecha_movimiento", this.movimiento.fecha_movimiento);
                }

                if(this.movimiento.comprobante != undefined) {
                    data.append("comprobante", this.movimiento.comprobante);
                }

                $.ajax({
                    url: this.API_URL + "/api/movimientos/save",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    type: 'POST', // For jQuery < 1.9
                    success: function(response){
                        vue.$refs.buttonMovimiento.setShowLoader(false);
                        if(response.success){
                            vue.movimiento = {};
                            vue.closeModalMovimientos();
                            vue.getCuentas();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }
                }).fail(function (error) {
                    vue.$refs.buttonMovimiento.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            }
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .header-section .buttons .buscador-content.no-margin{
            margin: 0;
        }

        .block-item{
            padding-right: 10px;
            color: #555;
        }

        .button-save-content{
           width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .float-saldo{
            position: fixed;
            right: 20px;
            bottom: 20px;
            padding: 10px 30px;
            background: #c4c086;
            border-radius: 10px;

            @media all and (max-width: 768px) {
                left: 15px;
                right: inherit;
            }

            @media all and (max-width: 576px){
                left: 5px;
            }

            p{
                text-transform: uppercase;
                color: #fff;
                margin: 0;
                padding: 0;
                font-weight: 600;
                font-size: 18px;
            }
        }


    }



</style>
