<template>
    <button type="button"  class="btn btn-nuevo" v-on:click="click">
        <!--//<span>+</span>-->
        <i class="fas fa-times"></i>
    </button>
</template>

<script>
    export default {
        name: 'ButtonFloat',
        props: {
            title: String
        },
        data(){
            return{

            }
        },
        methods: {
            click: function (e) {
                this.$emit("click", e)
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


    .btn-nuevo {
        cursor: pointer;
        position: relative;
        outline: none;
        width: 55px;
        height: 55px;
        color: #fff;
        border-color: rgba(46, 30, 41, 1);
        background: rgba(46, 30, 41, 1);
        border-radius: 50px;
        text-transform: uppercase;
        font-weight: bold;
        z-index: 10;

        -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);

        &:hover {
            color: #fff;
        }

        svg{
            transform: rotate(45deg);
        }

        span {
            line-height: 55px;
            font-size: 35px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }






</style>
