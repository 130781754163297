<template>
    <div id="root">
        <div v-if="checkPrivilege('actos.crear') || checkPrivilege('eventos.crear')" class="button-add-bottom">
            <ButtonOptionsFloat>
                <ButtonOptionItem v-if="checkPrivilege('actos.crear')" v-on:click="nuevoActo" title="Acto" icon="fa-lightbulb" />
                <ButtonOptionItem v-if="checkPrivilege('eventos.crear')" v-on:click="addEvento" title="Evento" icon="fa-calendar-check" />
            </ButtonOptionsFloat>
        </div>

        <div class="header-section">
            <div v-if="empresa == null" class="title">
                <h2>Actos</h2>
            </div>
            <div v-else class="title subtitle">
                <h2>
                    Actos
                    <br>
                    <span>
                        {{ empresa.nombre }}
                    </span>
                </h2>
            </div>
            <div class="buttons">
                <div v-if="checkPrivilege('actos.ignorar-grupo')" class="select-filtro select-grupo">
                    <FiltroSelect :valueInput.sync="grupo_id" name="grupo_id"
                                  @change="filtrarChange">
                        <option :value="null">Todos los grupos</option>
                        <option v-for="(item, index) in grupos" :key="index" :value="item.id">{{ item.name }}</option>
                    </FiltroSelect>
                </div>
                <div v-show="empresa == null" class="select-clientes">
                    <SelectComboBoxSearch ref="clientes"
                                          :small="true"
                                          placeholder="Todos los clientes"
                                          @change="filtrarChange"/>
                </div>
                <div class="select-check-content">
                    <SelectCheck ref="selectFiltro"
                                 v-on:change="filtrarChange"
                                 placeholder="Selecciona los estatus"/>
                </div>
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         table="empresas"
                                         @click="searchClick"
                                         title="Buscar" />
                </div>
                <div class="button-export-top">
                    <button class="btn btn-exportar" @click="exportarActosModal">
                        <i class="fa fa-file-excel"></i>
                        &nbsp;
                        <span>
                            Exportar
                        </span>
                    </button>
                </div>
                <div v-if="checkPrivilege('actos.crear') || checkPrivilege('eventos.crear')" class="button-add-top">
                    <ButtonOptions title="Nuevo +" >
                        <ButtonOptionItem v-if="checkPrivilege('actos.crear')" v-on:click="nuevoActo" title="Acto" icon="fa-lightbulb" />
                        <ButtonOptionItem v-if="checkPrivilege('eventos.crear')" v-on:click="addEvento" title="Evento" icon="fa-calendar-check" />
                    </ButtonOptions>
                </div>
            </div>
        </div>
        <div class="content-section">
            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th v-for="(item, index) in headers" :key="index" :width="item.width"
                            :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                            {{ item.text }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="actos.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in actos" :key="index" v-on:click="detalleActo(item)">
                        <td :class="headers[0].hide">
                            {{ item.id }}
                        </td>
                        <td :class="headers[1].hide">
                            <span v-if="item.empresa != null">{{ item.empresa.nombre_completo }}</span>
                            <span v-else>--</span>
                        </td>
                        <td :class="headers[2].hide">
                            <span v-if="item.notario != null">{{ item.notario.no_fedatario }}</span>
                            <span v-else>--</span>
                        </td>
                        <td :class="headers[3].hide">
                            <span v-if="item.servicio != null">{{ item.servicio.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                        <td :class="headers[4].hide">
                            <span v-if="item.estado != null">
                                {{ item.estado }}
                            </span>
                            <span v-else>
                                En proceso
                            </span>
                        </td>
                        <td :class="headers[5].hide">
                            <span v-show="item.fecha_fin != null" class="status normal">
                                <i class="fas fa-check"></i>
                            </span>
                            <span v-show="item.fecha_fin == null" :class="'status ' + getStyle(item.dias_faltantes)" >
                                {{ item.dias_faltantes }} d
                            </span>
                        </td>
                        <td :class="headers[6].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <ModalForm id="modalEvento"
                   ref="eventoForm"
                   title="Evento"
                   v-on:submit="saveEvento">
            <template v-slot:body>

                <ComboBoxSearch ref="actos" title="Acto"
                                placeholder="Selecciona el acto"/>

                <FormInput :valueInput.sync="evento.titulo"
                           :required="true"
                           name="titulo" title="Título"/>

                <DatePicker styleClass="form-control" title="Fecha" :dateInput.sync="evento.fecha"
                            placeholder="dd-mm-yyyy"
                            :limitDate="!checkPrivilege('ignorar-fecha')"
                            format="DD-MM-YYYY"
                            ref="fecha" />

                <div class="form-group">
                    <label>Notas</label>
                    <textarea v-model="evento.notas" class="form-control"
                              rows="3"
                              placeholder="Ingresa notas"
                              name="notas"
                    ></textarea>
                </div>


            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonEvento" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>

        <ModalForm id="modalActo"
                   ref="actoForm"
                   title="Acto"
                   v-on:submit="saveActo">
            <template v-slot:body>
                <input type="hidden" v-model="acto.id">

                <FormSelect :valueInput.sync="acto.tipo_id" name="tipo_id"
                            title="Tipo de acto"
                            :required="true">
                    <option :value="null">Selecciona un tipo</option>
                    <option v-for="(item, indexTipo) in actosTipos" :key="indexTipo"
                            :value="item.id">{{ item.nombre }}</option>
                </FormSelect>

                <FormSelect v-show="$store.getters.user.group_id == null || checkPrivilege('actos.ignorar-grupo')" :valueInput.sync="acto.grupo_id"
                            name="grupo" title="Grupo"
                            :disabled="acto.comision_pagada">
                    <option :value="null">Selecciona un grupo</option>
                    <option v-for="(grupo, grupoIndex) in grupos" :key="grupoIndex" :value="grupo.id">
                        {{ grupo.name }}
                    </option>
                </FormSelect>

                <ComboBoxSearchUrl v-if="empresa == null" ref="empresas" title="Empresa"
                                   :url="API_URL+'/api/empresas?precios=true'"
                                   placeholder="Selecciona una empresa"/>

                <ComboBoxSearch ref="notarios" title="Fedatario"
                                placeholder="Selecciona un fedatario"/>

                <ComboBoxSearch ref="servicios" title="Servicio"
                                v-on:change="changeServicio"
                                placeholder="Selecciona el servicio"/>

                <ComboBoxSearch ref="categorias" title="Categoría de ingresos"
                                placeholder="Selecciona una categoría"/>


                <DatePicker styleClass="form-control" title="Fecha de inicio" :dateInput.sync="acto.fecha_inicio"
                            placeholder="dd-mm-yyyy"
                            :limitDate="!checkPrivilege('ignorar-fecha')"
                            format="DD-MM-YYYY"
                            ref="fechaInicio" />

                <FormInput :valueInput.sync="acto.dias_estimados"
                           :required="true"
                           type="number"
                           ref="duracion"
                           name="dias" title="Días estimados"
                           placeholder="0"/>

                <FormInput v-if="checkPrivilege('actos.precios')"
                           :valueInput.sync="acto.precio"
                           :required="true"
                           type="number"
                           ref="precio"
                           name="precio" title="Precio"
                           placeholder="0.0"/>

                <DatePicker styleClass="form-control"
                            title="Fecha de archivo"
                            :dateInput.sync="acto.fecha_archivo"
                            placeholder="dd-mm-yyyy"
                            format="DD-MM-YYYY"/>

                <DatePicker styleClass="form-control"
                            title="Fecha de entrega"
                            :dateInput.sync="acto.fecha_entrega"
                            placeholder="dd-mm-yyyy"
                            format="DD-MM-YYYY"/>

                <div v-show="checkPrivilege('actos.pagar')">
                    <DatePicker styleClass="form-control"
                                title="Fecha de pago"
                                :dateInput.sync="acto.fecha_pago"
                                placeholder="dd-mm-yyyy"
                                format="DD-MM-YYYY"/>
                </div>


                <div v-show="checkPrivilege('actos.finalizar')" class="form-group">
                    <FormCheck ref="checkStatus" v-model="acto.finalizado"
                               :valueInput="true"
                               :valueNotChecked="false"
                               name="finalizado"
                               title="finalizado" />
                </div>

                <div v-show="acto.tipo_id == 1">
                    <hr>
                    <div class="form-group">
                        <FormInput :valueInput.sync="actoExtrasDatosInputs.objeto"
                                   name="objeto"
                                   :required="false" title="Objeto"/>

                        <DatePicker styleClass="form-control"
                                    title="Fecha de constitución"
                                    :dateInput.sync="actoExtrasDatosInputs.fecha_constitucion"
                                    placeholder="dd-mm-yyyy"
                                    name="fecha_constitucion"
                                    :required="false"
                                    format="DD-MM-YYYY"/>

                        <DatePicker styleClass="form-control"
                                    title="Fecha apartado"
                                    :dateInput.sync="actoExtrasDatosInputs.fecha_apartado"
                                    placeholder="dd-mm-yyyy"
                                    name="fecha_apartado"
                                    :required="false"
                                    format="DD-MM-YYYY"/>

                        <ComboBoxSearch ref="clientesApartado" title="Cliente apartado"
                                        placeholder="Selecciona un cliente"/>
                    </div>
                </div>


                <div v-show="acto.tipo_id == 2">
                    <hr>
                    <div class="form-group">
                        <FormInput :valueInput.sync="actoExtrasDatosInputs.numero_instrumento"
                                   name="numero_instrumento"
                                   :required="false" title="Número de instrumento"/>
                    </div>
                </div>

                <div v-show="acto.tipo_id == 1 || acto.tipo_id == 2">
                    <DatePicker styleClass="form-control"
                                title="Fecha de registro"
                                :dateInput.sync="actoExtrasDatosInputs.fecha_registro"
                                placeholder="dd-mm-yyyy"
                                name="fecha_registro"
                                :required="false"
                                format="DD-MM-YYYY"/>
                </div>

                <div v-show="acto.tipo_id == 1">
                    <DatePicker styleClass="form-control"
                                title="Fecha cita fiel"
                                :dateInput.sync="actoExtrasDatosInputs.fecha_cita_fiel"
                                placeholder="dd-mm-yyyy"
                                name="fecha_cita_fiel"
                                :required="false"
                                format="DD-MM-YYYY"/>

                    <FormCheck :valueInput.sync="actoExtrasDatosInputs.estatus_fiel"
                               :valueNotChecked="false"
                               ref="estatus_fiel"
                               name="estatus_fiel"
                               title="Estatus fiel" />
                </div>

                <div v-show="checkPrivilege('actos.comision')" style="padding-top: 10px;">
                    <DatePicker styleClass="form-control"
                                title="Fecha de comisión"
                                :dateInput.sync="acto.fecha_comision"
                                placeholder="dd-mm-yyyy"
                                :disabled="acto.comision_pagada"
                                :required="false"
                                format="DD-MM-YYYY"/>

                </div>


            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonActo" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>


        <ModalDetail ref="actoDetalle" title="Acto Detalle">
            <template v-slot:body>
                <div v-if="checkPrivilege('actos.gastos')" class="tab-header">
                    <button @click="tabActoDetalle = 'general'" :class="{active: tabActoDetalle == 'general'}"
                            type="button">General</button>
                    <button @click="tabActoDetalle = 'gastos'" :class="{active: tabActoDetalle == 'gastos'}"
                            type="button">Gastos</button>

                </div>


                <table v-if="tabActoDetalle == 'general'" class="table-detail">
                    <tbody>

                    <tr>
                        <td>
                            <b>Folio</b>
                        </td>
                        <td>
                            {{ acto.id }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Tipo</b>
                        </td>
                        <td>
                            <span v-if="acto.tipo != null">{{ acto.tipo.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('actos.ignorar-grupo')">
                        <td>
                            <b>Grupo</b>
                        </td>
                        <td>
                            <span v-if="acto.grupo != null">{{ acto.grupo.name }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Empresa</b>
                        </td>
                        <td>
                            <span v-if="acto.empresa != null">{{ acto.empresa.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-for="(cliente, index) in clientesEmpresa" :key="index">
                      <td>
                        <b>Cliente {{ index > 0 ? "alternativo" : ""}}</b>
                      </td>
                      <td>
                          {{ cliente.nombre }} {{ cliente.apellidos }}
                          <span class="active-message" :class="{active: (cliente.pivot.activo == 1)}">
                            {{ cliente.pivot.activo == 1 ? "Activo" : "Inactivo" }}
                          </span>
                      </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fedatario</b>
                        </td>
                        <td>
                            <span v-if="acto.notario != null">{{ acto.notario.no_fedatario }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Servicio</b>
                        </td>
                        <td>
                            <span v-if="acto.servicio != null">{{ acto.servicio.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="acto.categoria != null">
                        <td>
                            <b>Categoría de ingresos</b>
                        </td>
                        <td>
                            <span>{{ acto.categoria.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Días estimados</b>
                        </td>
                        <td>
                            <span>{{ acto.dias_estimados }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Estatus</b>
                        </td>
                        <td>
                            <span v-show="acto.fecha_fin != null" class="status normal">
                                <i class="fas fa-check"></i>
                            </span>
                            <span v-show="acto.fecha_fin == null" :class="'status ' + getStyle(acto.dias_faltantes)" >
                                {{ acto.dias_faltantes }} d
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha de comisión</b>
                        </td>
                        <td>
                            <span v-if="acto.fecha_comision != null">{{ acto.fecha_comision | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha de inicio</b>
                        </td>
                        <td>
                            <span>{{ acto.fecha_inicio | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha de fin</b>
                        </td>
                        <td>
                            <span>{{ acto.fecha_fin | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Último evento</b>
                        </td>
                        <td>
                            <span>
                                {{ acto.estado != null ? acto.estado : 'En proceso' }}
                            </span>
                        </td>
                    </tr>

                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Precio de venta</b>
                        </td>
                        <td>
                            <span>{{ acto.precio | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Total en abonos</b>
                        </td>
                        <td>
                            <span>{{ acto.abonos  | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Saldo por cobrar</b>
                        </td>
                        <td>
                            <span>{{ (acto.precio - acto.abonos)  | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Total en gastos</b>
                        </td>
                        <td>
                            <span>{{ acto.gastos | toCurrency }}</span>
                        </td>
                    </tr>

                    <tr v-if="acto.empresa != null">
                        <td>
                            <b>Código de barras</b>
                        </td>
                        <td>
                            <div class="text-center" style="display: inline-block;">
                                <vue-barcode v-bind:value="acto.id">
                                    No se pudo generar el código de barras
                                </vue-barcode>
                                <button style="font-size: 12px; margin-top: 10px;" class="btn btn-primary"
                                        @click="descargarSvg">Descargar</button>
                            </div>

                        </td>
                    </tr>

                    </tbody>
                </table>
                <div v-if="tabActoDetalle == 'gastos'">
                    <table class="table-detail">
                        <thead>
                            <tr>
                                <th>Descripción</th>
                                <th>Importe</th>
                                <th style="width: 150px;"></th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-if="(acto.gastos_tipos == null || acto.gastos_tipos.length == 0)
                                && (acto.gastos_actos == null || acto.gastos_actos.length == 0)">
                            <td class="text-center" colspan="3">No hay registros</td>
                        </tr>
                        <tr v-for="(item, indexGasto) in acto.gastos_tipos" :key="indexGasto">
                            <td>
                                {{ item.descripcion }}
                            </td>
                            <td>
                                <span v-if="item.importe != null">{{ item.importe | toCurrency }}</span>
                            </td>
                            <td class="text-right">
                                <div v-if="item.recibo_concepto == null">
                                    <button v-if="checkPrivilege('actos.gastos.aplicar')" class="btn btn-circle btn-aplicar"
                                            title="Aplicar" @click="aplicarGasto(item)">
                                        <i class="fas fa-file-invoice"></i>
                                    </button>
                                </div>
                                <span v-else class="status active">Aplicado</span>
                            </td>
                        </tr>
                        <tr v-for="(item, indexGasto) in acto.gastos_actos" :key="indexGasto">
                            <td>
                                {{ item.descripcion }}
                            </td>
                            <td>
                                <span v-if="item.importe != null">{{ item.importe | toCurrency }}</span>
                            </td>
                            <td class="text-right">
                                <div v-if="item.recibo_concepto == null">
                                    <button v-if="checkPrivilege('actos.gastos.aplicar')" class="btn btn-circle btn-aplicar"
                                            title="Aplicar" @click="aplicarGasto(item)">
                                        <i class="fas fa-file-invoice"></i>
                                    </button>
                                    <button v-if="checkPrivilege('actos.gastos.editar')" class="btn btn-circle btn-editar"
                                            title="Editar" @click="editGasto(item)">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                    <button v-if="checkPrivilege('actos.gastos.eliminar')" class="btn btn-circle btn-trash"
                                            title="Remover" @click="eliminarGasto(item, indexGasto)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                                <span v-else class="status active">Aplicado</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <template v-slot:footer>

                <div class="row" v-show="tabActoDetalle == 'general'" style="margin: 0;">
                    <div v-if="checkPrivilege('eventos')" class="col" style="padding: 0; margin: 0;">
                        <ModalButtonFooter v-on:click="eventosActo"
                                           color="#fff"
                                           background="#00acac"
                                           title="Eventos"
                                           icon="fa-calendar-day" />
                    </div>

                    <div v-if="checkPrivilege('actos.editar')" class="col" style="padding: 0; margin: 0;">
                        <ModalButtonFooter v-on:click="editActo"
                                           color="#fff"
                                           :disabled="!isEdicion()"
                                           background="#f59c1a"
                                           title="Editar"
                                           icon="fa-pencil-alt" />
                    </div>


                    <div v-if="checkPrivilege('actos.eliminar')" class="col" style="padding: 0; margin: 0;">
                        <ModalButtonFooter v-on:click="deleteActo"
                                           color="#fff"
                                           :disabled="!isEdicion()"
                                           background="#ff5b57"
                                           title="Eliminar"
                                           icon="fa-trash"/>
                    </div>
                </div>

                <div class="row" v-show="tabActoDetalle == 'gastos'" style="margin: 0;">
                    <div class="col" style="padding: 0; margin: 0;">
                        <ModalButtonFooter v-if="checkPrivilege('actos.gastos.crear')" v-on:click="nuevaGasto"
                                           color="#fff"
                                           background="#28a745"
                                           title="Nuevo"
                                           icon="fa-plus" />
                    </div>

                </div>

            </template>
        </ModalDetail>

        <ModalForm ref="exportarActoForm"
                   title="Exportar actos"
                   v-on:submit="exportarActos">
            <template v-slot:body>

                <ComboBoxSearch ref="clientesFiltro" title="Cliente"
                                placeholder="Selecciona un cliente"/>

                <div>
                    <label>Tipo de acto</label>
                    <select v-model="filtrosExportarActos.tipo_id" class="form-control select-style" required>
                        <option :value="null">Selecciona un tipo</option>
                        <option v-for="(item, indexTipo) in actosTipos" :key="indexTipo"
                                :value="item.id">{{ item.nombre }}</option>
                    </select>
                </div>

                <FormSelect v-show="$store.getters.user.group_id == null || checkPrivilege('actos.ignorar-grupo')" :valueInput.sync="filtrosExportarActos.grupo_id"
                            name="grupo" title="Grupo" >
                    <option :value="null">Selecciona una grupo</option>
                    <option v-for="(grupo, grupoIndex) in grupos" :key="grupoIndex" :value="grupo.id">
                        {{ grupo.name }}
                    </option>
                </FormSelect>

                <DatePicker styleClass="form-control" title="Fecha de inicio"
                            placeholder="dd-mm-yyyy"
                            :dateInput.sync="filtrosExportarActos.fecha_inicio"
                            format="DD-MM-YYYY" />

                <DatePicker styleClass="form-control" title="Fecha de fin"
                            placeholder="dd-mm-yyyy"
                            :dateInput.sync="filtrosExportarActos.fecha_fin"
                            format="DD-MM-YYYY" />

                <div v-show="filtrosExportarActos.tipo_id == 1">
                    <label>Fecha apartado</label>
                    <select v-model="filtrosExportarActos.fecha_apartado" name="fecha_apartado" class="form-control select-style">
                        <option value="">Todos</option>
                        <option value="true">Con fecha de apartado</option>
                        <option value="false">Sin fecha de apartado</option>
                    </select>

                    <label>Estatus fiel</label>
                    <select v-model="filtrosExportarActos.estatus_fiel" name="estatus_fiel" class="form-control select-style">
                        <option value="">Todos</option>
                        <option value="true">Con estatus fiel</option>
                        <option value="false">Sin estatus fiel</option>
                    </select>
                </div>
            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonExportarActo" type="submit" title="Exportar" />
                </div>
            </template>
        </ModalForm>

        <ModalForm ref="actoFormEliminar"
                   title="Eliminar acto"
                   v-on:submit="deleteFormActo">
            <template v-slot:body>
                <input type="hidden" v-model="acto.id">

                <ComboBoxSearchUrl ref="actoTransfer" title="Actos"
                                   :url="API_URL+'/api/actos'"
                                   placeholder="Selecciona un acto"/>

            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonEliminarActo" type="submit" title="Eliminar" />
                </div>
            </template>
        </ModalForm>

        <ModalForm ref="gastoForm"
                   title="Gasto"
                   v-on:submit="saveGasto"
                    @close="closeModalGasto">
            <template v-slot:body>
                <input type="hidden" v-model="gasto.id">

                <FormInput :valueInput.sync="gasto.descripcion"
                           name="descripcion" title="Descripción"
                           :required="true"
                           @input="ordenarCategoriasSugerencias()"/>

                <ComboBoxSearch ref="cuentas" title="Cuentas"
                                placeholder="Seleccionar cuenta"/>

                <ComboBoxSearchSubtitle ref="categoriaGasto" title="Categoría"
                                        placeholder="Selecciona la categoría"/>

                <FormInput :valueInput.sync="gasto.importe" :required="false"
                           type="number"
                           name="importe" title="Importe"
                           placeholder="$0.00"/>

            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonGasto" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>

        <ModalForm ref="aplicarGastoForm"
                   title="Aplicar gasto"
                   v-on:submit="sendGastoAplicar"
                   @close="closeModalAplicarGasto">
            <template v-slot:body>
                <input type="hidden" v-model="gasto.id">

                <FormInput :valueInput.sync="gasto.proveedor"
                           name="proveedor" title="Proveedor"
                           :required="true"/>

                <ComboBoxSearch v-show="mostrarCuentaAplicar" ref="cuentasAplicar" title="Cuentas"
                                placeholder="Seleccionar cuenta"/>

                <ComboBoxSearchSubtitle v-show="mostrarCategoriaAplicar" ref="categoriaGastoAplicar" title="Categoría"
                                        placeholder="Selecciona la categoría"/>

                <FormInput v-show="mostrarImporteAplicar" :valueInput.sync="gasto.importe" :required="true"
                           type="number"
                           name="importe" title="Importe"
                           placeholder="$0.00"/>

            </template>
            <template v-slot:footer>
                <div class="button-save-content aplicar-gastos-boton">
                    <ButtonLoader ref="buttonAplicarGasto" type="submit" title="Aplicar" />
                </div>
            </template>
        </ModalForm>


    </div>
</template>

<script>
    import FiltroSelect from '@/components/Filter/FiltroSelect'
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import FormInput from '@/components/Form/FormInput'
    import FormSelect from '@/components/Form/FormSelect'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import FormCheck from '@/components/Form/FormCheck'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ModalForm from '@/components/Modal/ModalForm'
    import ComboBoxSearch from '@/components/Form/ComboBoxSearch'
    import DatePicker from '@/components/DatePicker/DatePicker'
    import ButtonOptions from '@/components/ButtonOption/ButtonOptions'
    import ButtonOptionItem from '@/components/ButtonOption/ButtonOptionItem'
    import ButtonOptionsFloat from '@/components/ButtonFloat/ButtonOptionsFloat'
    import SelectCheck from '@/components/Form/SelectCheck'
    import SelectComboBoxSearch from '@/components/Form/SelectComboBoxSearch'
    import ComboBoxSearchUrl from '@/components/Form/ComboBoxSearchUrl'
    import ComboBoxSearchSubtitle from '@/components/Form/ComboBoxSearchSubtitle'
    import VueBarcode from 'vue-barcode';
    import * as $ from 'jquery';
    import swal from 'sweetalert2';
    import moment from 'moment';
    moment.locale('es');

    export default {
        name: 'ActosScreen',
        components: {
            FiltroSelect,
            SelectComboBoxSearch,
            ComboBoxSearchSubtitle,
            SelectCheck,
            ButtonOptions,
            ButtonOptionItem,
            FormCheck,
            ButtonOptionsFloat,
            InputSearchComplete,
            ModalDetail,
            ModalForm,
            ModalButtonFooter,
            ButtonLoader,
            ComboBoxSearch,
            DatePicker,
            FormInput,
            FormSelect,
            ComboBoxSearchUrl,
            VueBarcode
        },
        data: function(){
            return {
                extras_render: 1,
                page: 1,
                lastPage: 1,
                headers: [
                    { text: 'Folio', align: 'left', value: 'folio' },
                    { text: 'Empresa', align: 'left', value: 'empresa', width: '30%' },
                    { text: 'Fedatario', value: 'fedatario'},
                    { text: 'Servicio', value: 'servicio'},
                    { text: 'Último evento', value: 'ultimo_evento', width: '15%' },
                    { text: 'Estatus', value: 'estatus', hide: 'text-center'},
                    { text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down' },
                ],
                filtro: '',
                actos: [],
                evento: {},
                // empresas: [],
                notarios: [],
                servicios: [],
                categorias: [],
                actoTipo: undefined,
                acto: {tipo_id: null},
                indexEdit: -1,
                empresa: null,
                empresa_id: this.$route.params.empresaId,
                selectFiltro: [
                    {
                        key: "finalizados",
                        title: "Finalizados",
                        checked: true
                    },
                    {
                        key: "pendientes",
                        title: "Pendientes",
                        checked: true
                    }
                ],
                filtroEstatus: [],
                clientes: [],
                clienteSelected: undefined,
                clientesEmpresa: [],
                actosTipos: [],
                actoExtrasDatosInputs: {},
                filtrosExportarActos: {tipo_id: null, grupo_id: null, estatus_fiel: "", fecha_apartado: ""},

                tabActoDetalle: 'general',

                gasto: {},
                indexGastoEdit: -1,
                mostrarImporteAplicar: false,
                mostrarCuentaAplicar: false,
                mostrarCategoriaAplicar: false,

                grupos: [],
                grupo_id: null
            }
        },
        mounted() {
            this.$emit("showLoader");
            if(this.$refs.selectFiltro != undefined){
              this.$refs.selectFiltro.setData(this.selectFiltro);
            }
            this.getActosTipos();
            this.getActos();
            // this.getEmpresas();
            this.getNotarios();
            this.getServicios();
            this.getCategorias();
            this.getClientes();

            if(this.checkPrivilege("actos.ignorar-grupo")) {
                this.getGrupos();
            }

            if(this.checkPrivilege("actos.gastos.crear") ||
                this.checkPrivilege("actos.gastos.editar") ||
                this.checkPrivilege("actos.gastos.aplicar")){
                this.getCuentas();
                this.getCategoriasGastos();
            }
        },
        methods: {
            descargarSvg(){
                let name = "Acto "+this.acto.nombre+".svg";
                let svgEl = document.querySelector(".vue-barcode-element");
                svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
                var svgData = svgEl.outerHTML;
                var preface = '<?xml version="1.0" standalone="no"?>\r\n';
                var svgBlob = new Blob([preface, svgData], {type:"image/svg+xml;charset=utf-8"});
                var svgUrl = URL.createObjectURL(svgBlob);
                var downloadLink = document.createElement("a");
                downloadLink.href = svgUrl;
                downloadLink.download = name;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },
            getActosTipos(){
                let vue = this;
                vue.actosTipos = [];
                $.get(this.API_URL + "/api/actos/tipos", function (response) {
                    if(response.success){
                        vue.actosTipos = response.actos_tipos;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            isEdicion(){
                var d1 = Date.parse(this.acto.fecha_fin);
                var d2 = Date.parse(this.$store.getters.fechaEdicion);
                if(this.checkPrivilege('ignorar-fecha') || isNaN(d1)){
                    return true;
                }
                else{
                    return d1 >= d2;
                }
            },
            searchClick(){
                this.first(true);
            },
            filtrarChange: function () {
                this.filtroEstatus = this.$refs.selectFiltro.getSelecteds();
                let cliente = this.$refs.clientes.getSelected();
                if(cliente != undefined){
                    this.clienteSelected = cliente.key;
                }
                else{
                    this.clienteSelected = undefined;
                }

                this.first(true);
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.getActos();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.getActos();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.getActos();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.getActos();
                }
                else if(refresh){
                    this.page = 1;
                    this.getActos();
                }
            },
            loadActosCombo: function () {
                var actos = [];
                for(let k in this.actos){
                    actos[k] = {};
                    actos[k].key = this.actos[k].id;
                    actos[k].title = this.actos[k].nombre;
                    actos[k].search = this.actos[k].search;
                    actos[k].item = this.actos[k];
                }
                if(this.$refs.actos != undefined){
                    this.$refs.actos.setData(actos);
                    this.$refs.actos.setCargando(false);
                }
            },
            getStyle: function (dias) {
                var style = "normal-dias";
                if(dias < 8){
                    style = "urgente";
                }
                else if(dias < 15){
                    style = "warning";
                }

                return style;
            },
            getClientes: function () {
                let vue = this;
                vue.clientes = [];
                vue.$refs.clientes.setCargando(true);
                $.get(this.API_URL + "/api/clientes/get", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        for(let k in response.clientes){
                            vue.clientes[k] = {};
                            vue.clientes[k].key = response.clientes[k].id;
                            let apellidos = response.clientes[k].apellidos == null ?
                                "" : response.clientes[k].apellidos;
                            vue.clientes[k].title = response.clientes[k].nombre + " " + apellidos;
                            vue.clientes[k].search = response.clientes[k].search;
                        }
                        if(vue.$refs.clientesApartado != undefined){
                            vue.$refs.clientesApartado.setData(Object.assign([], vue.clientes));
                            vue.$refs.clientesApartado.setCargando(false);
                        }
                        if(vue.$refs.clientesFiltro != undefined){
                            vue.$refs.clientesFiltro.setData(Object.assign([], vue.clientes));
                            vue.$refs.clientesFiltro.setCargando(false);
                        }
                        if(vue.$refs.clientes != undefined){
                            vue.$refs.clientes.setData(vue.clientes);
                            vue.$refs.clientes.setCargando(false);
                        }
                    }
                    else{
                        swal(response.message, '', 'info')
                        vue.$refs.clientes.setCargando(false);
                        vue.$refs.clientesApartado.setCargando(false);
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    vue.$refs.clientes.setCargando(false);
                    vue.$refs.clientesApartado.setCargando(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            eventosActo: function () {
                this.$refs.actoDetalle.hide();
                this.$router.push({ path: "/acto-eventos_"+this.acto.id });
            },
            getNotarios: function () {
                let vue = this;
                vue.actos = [];
                vue.$refs.notarios.setCargando(true);
                $.get(this.API_URL + "/api/notarios/get", function (response) {
                    if(response.success){
                        for(let k in response.notarios){
                            vue.notarios[k] = {};
                            vue.notarios[k].key = response.notarios[k].id;
                            vue.notarios[k].title = response.notarios[k].no_fedatario;
                            vue.notarios[k].search = response.notarios[k].search;
                        }
                        if(vue.$refs.notarios != undefined){
                          vue.$refs.notarios.setData(vue.notarios);
                          vue.$refs.notarios.setCargando(false);
                        }
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            changeServicio: function () {
                let servicio = this.$refs.servicios.getSelected();
                if(servicio != undefined){
                    if(this.acto.dias_estimados == "" ||
                        this.acto.dias_estimados == undefined) {
                      this.$refs.duracion.setValue(servicio.duracion);
                    }
                    if(this.acto.precio == "" ||
                        this.acto.precio == undefined){

                        let empresa = {};
                        if(this.empresa == null){
                            empresa = this.$refs.empresas.getSelected();
                        }
                        else{
                            empresa = this.empresa;
                            empresa.item = empresa;
                        }

                        var precio = false;

                        if(empresa != null && empresa != undefined){
                            if(empresa.item.cliente != undefined){
                                for(let i in empresa.item.cliente.servicios_precio){
                                    if(servicio.key == empresa.item.cliente.servicios_precio[i].servicio_id){
                                        this.$refs.precio.setValue(empresa.item.cliente.servicios_precio[i].precio);
                                        precio = true;
                                        break;
                                    }
                                }
                            }

                        }

                        if(!precio){
                            this.$refs.precio.setValue(servicio.precio);
                        }
                    }

                }
            },
            getServicios: function () {
                let vue = this;
                vue.servicios = [];
                vue.$refs.servicios.setCargando(true);
                $.get(this.API_URL + "/api/servicios/get", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        for(let k in response.servicios){
                            vue.servicios[k] = {};
                            vue.servicios[k].key = response.servicios[k].id;
                            vue.servicios[k].title = response.servicios[k].nombre;
                            vue.servicios[k].search = response.servicios[k].search;
                            vue.servicios[k].precio = response.servicios[k].precio;
                            vue.servicios[k].duracion = response.servicios[k].duracion;
                        }
                        if(vue.$refs.servicios != undefined){
                          vue.$refs.servicios.setData(vue.servicios);
                          vue.$refs.servicios.setCargando(false);
                        }

                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },

            getCategorias: function () {
                let vue = this;
                vue.categorias = [];
                vue.$refs.categorias.setCargando(true);
                var data = {tipo: "ingresos"};
                $.get(this.API_URL + "/api/categorias", data, function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        for(let k in response.categorias){
                            vue.categorias[k] = {};
                            vue.categorias[k].key = response.categorias[k].id;
                            vue.categorias[k].title = response.categorias[k].nombre;
                            vue.categorias[k].search = response.categorias[k].search;
                        }
                        if(vue.$refs.categorias != undefined){
                          vue.$refs.categorias.setData(vue.categorias);
                          vue.$refs.categorias.setCargando(false);
                        }
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            getActos: function (loader = true) {
                let vue = this;
                if(loader){
                    this.$emit("showLoader");
                    vue.actos = [];
                }
                let data = {empresa_id : this.empresa_id, page: this.page, filtro: this.filtro,
                    estatus: this.filtroEstatus, gastos: true, grupo_id: this.grupo_id};

                if(this.clienteSelected != undefined){
                    data.cliente_id = this.clienteSelected
                }

                $.get(this.API_URL + "/api/actos/getOrderPage", data, function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.page = response.actos.current_page;
                        vue.lastPage = response.actos.last_page;
                        vue.actos = response.actos.data;

                        vue.empresa = response.empresa;
                        vue.loadActosCombo();
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            deleteFormActo(e){
                e.preventDefault();
                let vue = this;

                let actoTrans = vue.$refs.actoTransfer.getSelected();
                if(actoTrans == null){
                    swal("Selecciona un acto para transferir la información", "", "info");
                    return;
                }

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    let data = {id: vue.acto.id};
                    data.acto_transfer_id = actoTrans.key;

                    vue.$refs.buttonEliminarActo.setShowLoader(true);

                    $.post(vue.API_URL + "/api/actos/delete", data, function (response) {
                        vue.$refs.buttonEliminarActo.setShowLoader(false);
                        if(response.success){
                            vue.actos.splice(vue.indexEdit, 1);
                            vue.acto = {};
                            vue.$refs.actoFormEliminar.hide();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        vue.$refs.buttonEliminarActo.setShowLoader(false);
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }

                    });
                });
            },
            deleteActo () {
                this.$refs.actoDetalle.hide();
                this.$refs.actoTransfer.removeItem();

                let data = {};
                data.except = this.acto.id;
                this.$refs.actoTransfer.setExtras(data);
                this.$refs.actoFormEliminar.show();
            },
            saveEvento: function (e) {
                e.preventDefault();

                let acto = this.$refs.actos.getSelected();

                if(acto == undefined || acto == null){
                    swal("Selecciona un acto", "", "info");
                    return;
                }

                let vue = this;
                vue.$refs.buttonEvento.setShowLoader(true);

                this.evento.acto_id = acto.key;

                $.post(this.API_URL + "/api/eventos/save", this.evento, function (response) {
                    vue.$refs.buttonEvento.setShowLoader(false);
                    if(response.success){

                        vue.evento = {};
                        vue.indexEdit = -1;
                        vue.closeModalEvento();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    vue.$refs.buttonEvento.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });

            },
            addEvento: function(){
                this.evento = {};
                this.indexEdit = -1;
                this.$refs.actos.removeItem();
                // this.$refs.fecha.setDate('');
                this.$refs.eventoForm.show();
            },
            closeModalEvento: function(){
                this.$refs.eventoForm.hide();
            },
            saveActo: function (e) {
                e.preventDefault();
                let vue = this;

                let empresa = null;
                if(this.empresa == null){
                    empresa = this.$refs.empresas.getSelected();
                }
                else{
                    empresa = this.empresa;
                    empresa.key = empresa.id;
                }


                if(empresa === undefined || empresa === null){
                    swal("Selecciona una empresa", "", "info");
                    return;
                }

                let servicio = this.$refs.servicios.getSelected();

                if(servicio === undefined || servicio === null){
                    swal("Selecciona un servicio", "", "info");
                    return;
                }

                let notario = this.$refs.notarios.getSelected();

                if(notario != undefined || notario != null){
                    this.acto.notario_id = notario.key;
                }
                else{
                    this.acto.notario_id = null;
                }

                let categoria = this.$refs.categorias.getSelected();
                if(categoria != undefined || categoria != null){
                    this.acto.categoria_id = categoria.key;
                }
                else{
                    this.acto.categoria_id = null;
                }

                this.acto.empresa_id = empresa.key;
                this.acto.servicio_id = servicio.key;
                this.acto.cliente_apartado_id = null;

                if(this.acto.tipo_id == 1){
                    delete this.actoExtrasDatosInputs.numero_instrumento;
                    this.acto.extras = this.actoExtrasDatosInputs;
                    let apartado = this.$refs.clientesApartado.getSelected();
                    if(apartado != null){
                        this.acto.cliente_apartado_id = apartado.key;
                    }
                }
                else if(this.acto.tipo_id == 2){
                    delete this.actoExtrasDatosInputs.objeto;
                    delete this.actoExtrasDatosInputs.fecha_constitucion;
                    delete this.actoExtrasDatosInputs.fecha_apartado;
                    delete this.actoExtrasDatosInputs.fecha_cita_fiel;
                    delete this.actoExtrasDatosInputs.estatus_fiel;
                    this.acto.extras = this.actoExtrasDatosInputs;
                }
                else{
                    delete this.acto.extras;
                }

                this.$refs.buttonActo.setShowLoader(true);
                $.post(this.API_URL + "/api/actos/save", this.acto, function (response) {
                    vue.$refs.buttonActo.setShowLoader(false);
                    if(response.success){
                        vue.acto = response.record;

                        if (vue.indexEdit > -1) {
                            Object.assign(vue.actos[vue.indexEdit], vue.acto)
                        } else {
                            vue.actos.push(vue.acto)
                        }
                        vue.acto = {};
                        vue.indexEdit = -1;
                        vue.closeModalActo();
                        vue.getActos(false);
                        vue.loadActosCombo();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    vue.$refs.buttonActo.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });

            },
            detalleActo: function (acto) {
                this.tabActoDetalle = "general";
                if(acto.empresa != undefined && acto.empresa.clientes != undefined){
                  this.clientesEmpresa = Object.assign([], acto.empresa.clientes);
                }
                this.indexEdit = this.actos.indexOf(acto);
                this.acto = Object.assign({}, acto);

                this.$refs.checkStatus.setChecked(this.acto.finalizado);

                let extrasValuesActo = {};
                if(acto.extras != null){
                    extrasValuesActo = Object.assign({}, acto.extras.data);
                    if(extrasValuesActo != undefined){
                        extrasValuesActo.estatus_fiel = extrasValuesActo.estatus_fiel == "true";
                    }

                }
                this.actoExtrasDatosInputs = extrasValuesActo;
                let vue = this;
                $.get(this.API_URL + "/api/actos/"+this.acto.id, function (response) {
                        vue.$emit("hideLoader");
                        if(response.success){
                            vue.acto = response.acto;
                            vue.$refs.actoDetalle.show();
                        }
                        else{
                            swal(response.message, '', 'info')
                        }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });

            },
            editActo: function () {
                this.$refs.checkStatus.setChecked(this.acto.finalizado == '1');
                this.$refs.actoDetalle.hide();
                let empresa = {
                    key: this.acto.empresa.id,
                    title: this.acto.empresa.nombre
                };
                if(this.acto.notario != undefined && this.acto.notario != null){
                    let notario = {
                        key: this.acto.notario.id,
                        title: this.acto.notario.no_fedatario
                    };

                    this.$refs.notarios.setSelected(notario);
                }
                else{
                    this.$refs.notarios.removeItem();
                }

                let servicio = {
                    key: this.acto.servicio.id,
                    title: this.acto.servicio.nombre
                };
                if(this.acto.categoria != undefined){
                    let categoria = {
                        key: this.acto.categoria.id,
                        title: this.acto.categoria.nombre
                    };
                    this.$refs.categorias.setSelected(categoria);
                }
                else{
                    this.$refs.categorias.removeItem();
                }

                if(this.acto.cliente_apartado != null){
                    let apellidos = this.acto.cliente_apartado.apellidos == null ?
                        "" : this.acto.cliente_apartado.apellidos;
                    let client = {
                        key: this.acto.cliente_apartado.id,
                        title: this.acto.cliente_apartado.nombre + " " + apellidos
                    };
                    this.$refs.clientesApartado.setSelected(client);
                }
                else{
                    this.$refs.clientesApartado.removeItem();
                }

                this.$refs.servicios.setSelected(servicio);
                if(this.empresa == null){
                    this.$refs.empresas.setSelected(empresa);
                }
                this.openModalActo();
            },
            nuevoActo: function () {
                this.actoTipo = undefined;
                this.actoExtrasDatosInputs = {};
                this.acto = {tipo_id: null};
                this.$refs.notarios.removeItem();
                if(this.empresa == null) {
                    this.$refs.empresas.removeItem();
                }
                this.$refs.servicios.removeItem();
                this.$refs.categorias.removeItem();
                this.$refs.clientesApartado.removeItem();
                // this.$refs.fechaInicio.setDate('');

                this.indexEdit = -1;

                this.$refs.checkStatus.setChecked(false);
                this.$refs.estatus_fiel.setChecked(false);

                this.openModalActo();
            },
            openModalActo: function () {

                // if(this.empresa != undefined && this.empresa != null){
                //     let empresa = {
                //         key: this.empresa.id,
                //         title: this.empresa.nombre
                //     };
                //
                //     this.$refs.empresas.setSelected(empresa);
                //
                // }

                this.$refs.actoForm.show();
            },
            closeModalActo: function () {
                this.$refs.actoForm.hide();
            },
            exportarActosModal(){
                let date = new Date();
                if(this.filtrosExportarActos.fecha_fin == undefined){
                    this.filtrosExportarActos.fecha_fin = moment(date.toISOString()).format("YYYY-MM-DD");
                }
                date.setMonth(date.getMonth()-12);
                if(this.filtrosExportarActos.fecha_inicio == undefined){
                    this.filtrosExportarActos.fecha_inicio = moment(date.toISOString()).format("YYYY-MM-DD");
                }
                this.filtrosExportarActos = Object.assign({}, this.filtrosExportarActos);
                this.$refs.exportarActoForm.show();
            },
            exportarActos(e){
                e.preventDefault();
                this.filtrosExportarActos.data = btoa(this.$store.getters.auth_token);
                let cliente = this.$refs.clientesFiltro.getSelected();
                if(cliente != null){
                    this.filtrosExportarActos.cliente_id = cliente.key;
                }
                window.open(this.API_URL+"/actos/exportar?"+new URLSearchParams(this.filtrosExportarActos).toString());
            },
            nuevaGasto: function () {
                this.gasto = {};
                this.indexGastoEdit = -1;
                this.$refs.categoriaGasto.removeItem();
                this.$refs.cuentas.removeItem();
                this.ordenarCategoriasSugerencias();
                this.openModalGasto();
            },
            aplicarGasto(item){
                this.gasto = Object.assign({}, item);

                if(this.gasto.cuenta != null){
                    this.mostrarCuentaAplicar = false;
                    let cuenta = {
                        key: this.gasto.cuenta.id,
                        title: this.gasto.cuenta.nombre,
                        item: this.gasto.cuenta
                    };
                    this.$refs.cuentasAplicar.setSelected(cuenta);
                }
                else{
                    this.$refs.cuentasAplicar.removeItem();
                    this.mostrarCuentaAplicar = true;
                }

                if(this.gasto.categoria != null){
                    this.mostrarCategoriaAplicar = false;
                    let cat = {
                        key: this.gasto.categoria.id,
                        title: this.gasto.categoria.nombre,
                        search: this.gasto.categoria.search,
                        item: this.gasto.categoria
                    };

                    this.$refs.categoriaGastoAplicar.setSelected(cat);
                }
                else{
                    this.$refs.categoriaGastoAplicar.removeItem();
                    this.mostrarCategoriaAplicar = true;
                }

                this.mostrarImporteAplicar = this.gasto.importe == null;

                this.ordenarCategoriasSugerenciasAplicar();
                this.$refs.aplicarGastoForm.show();
                this.$refs.actoDetalle.hide();
            },
            closeModalAplicarGasto(){
                this.$refs.aplicarGastoForm.hide();
                this.$refs.actoDetalle.show();
            },
            sendGastoAplicar(e){
                let vue = this;
                this.gasto.acto_id = this.acto.id;
                if(e != null){
                    e.preventDefault();
                    if(this.mostrarCuentaAplicar){
                        let cuenta = this.$refs.cuentasAplicar.getSelected();
                        if(cuenta == null){
                            swal("Selecciona una cuenta", "", "info");
                            return;
                        }
                        this.gasto.cuenta_id = cuenta.key;
                    }
                    if(this.mostrarCategoriaAplicar){
                        let categoria = this.$refs.categoriaGastoAplicar.getSelected();
                        if(categoria == null){
                            swal("Selecciona una categoría", "", "info");
                            return;
                        }
                        this.gasto.categoria_id = categoria.key;
                    }
                }

                delete vue.gasto.cuenta;
                delete vue.gasto.categoria;

                vue.$refs.buttonAplicarGasto.setShowLoader(true);
                $.post(vue.API_URL + "/api/gastos/aplicar", vue.gasto, function (response) {
                    vue.$refs.buttonAplicarGasto.setShowLoader(false);
                    if(response.success){
                        vue.acto.gastos_actos = response.acto_gastos;
                        vue.acto.gastos_tipos = response.gastos;
                        vue.getActos(false);
                        vue.gasto = {};
                        vue.closeModalAplicarGasto();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }).fail(function (error) {
                    vue.$refs.buttonAplicarGasto.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });

            },
            editGasto: function (item) {
                this.indexGastoEdit = this.acto.gastos_actos.indexOf(item);
                this.gasto = Object.assign({}, item);

                this.$refs.categoriaGasto.removeItem();
                this.$refs.cuentas.removeItem();

                if(this.gasto.cuenta != null){
                    let cuenta = {
                        key: this.gasto.cuenta.id,
                        title: this.gasto.cuenta.nombre,
                        item: this.gasto.cuenta
                    };
                    this.$refs.cuentas.setSelected(cuenta);
                }

                if(this.gasto.categoria != null){
                    let cat = {
                        key: this.gasto.categoria.id,
                        title: this.gasto.categoria.nombre,
                        search: this.gasto.categoria.search,
                        item: this.gasto.categoria
                    };

                    this.$refs.categoriaGasto.setSelected(cat);
                }

                this.ordenarCategoriasSugerencias();
                this.openModalGasto();
            },
            openModalGasto: function () {
                this.$refs.gastoForm.show();
                this.$refs.actoDetalle.hide();
            },
            closeModalGasto(){
                this.$refs.gastoForm.hide();
                this.$refs.actoDetalle.show();
            },
            saveGasto: function (e) {
                e.preventDefault();
                let data = new FormData();
                let cuenta = this.$refs.cuentas.getSelected();
                let categoria = this.$refs.categoriaGasto.getSelected();

                if(this.gasto.id != null){
                    data.append("id", this.gasto.id);
                }
                data.append("acto_id", this.acto.id);
                data.append("descripcion", this.gasto.descripcion);
                if(cuenta != null){
                    data.append("cuenta_id", cuenta.key);
                }
                if(categoria != null){
                    data.append("categoria_id", categoria.key);
                }

                if(this.gasto.importe != null){
                    data.append("importe", this.gasto.importe);
                }
                let vue = this;

                vue.$refs.buttonGasto.setShowLoader(true);
                $.ajax({
                    url: this.API_URL + "/api/gastos/save",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    type: 'POST', // For jQuery < 1.9
                    success: function(response){
                        vue.$refs.buttonGasto.setShowLoader(false);
                        if(response.success){
                            vue.acto.gastos_actos = response.acto_gastos;
                            vue.getActos(false);
                            vue.gasto = {};
                            vue.indexGastoEdit = -1;
                            vue.closeModalGasto();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }
                }).fail(function (error) {
                    vue.$refs.buttonGasto.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            eliminarGasto(item){
                let vue = this;
                swal({
                    text: '¿Realmente deseas eliminar el gasto?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    let data = {id: item.id, acto_id: item.acto_id};
                    $.post(vue.API_URL + "/api/gastos/delete", data, function (response) {
                        if(response.success){
                            vue.acto.gastos_actos = response.acto_gastos;
                            vue.getActos(false);
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }

                    });
                });
            },
            ordenarCategoriasSugerencias(){
                this.$refs.categoriaGasto.buscarSugerencias(this.gasto.descripcion);
            },
            ordenarCategoriasSugerenciasAplicar(){
                this.$refs.categoriaGastoAplicar.buscarSugerencias(this.gasto.descripcion);
            },
            getCuentas: function () {
                let vue = this;
                vue.cuentas = [];
                vue.$refs.cuentas.setCargando(true);
                $.get(this.API_URL + "/api/cuentas/getCuentas", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        for(let k in response.cuentas){
                            vue.cuentas[k] = {};
                            vue.cuentas[k].key = response.cuentas[k].id;
                            vue.cuentas[k].title = response.cuentas[k].nombre;
                            vue.cuentas[k].item = response.cuentas[k];
                            vue.cuentas[k].search = response.cuentas[k].search;
                        }
                        vue.$refs.cuentas.setData(vue.cuentas);
                        vue.$refs.cuentas.setCargando(false);

                        vue.$refs.cuentasAplicar.setData(vue.cuentas);
                        vue.$refs.cuentasAplicar.setCargando(false);

                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getCategoriasGastos: function () {
                let vue = this;
                vue.categoria = [];
                let data = {tipo: "gastos"};
                $.get(this.API_URL + "/api/categorias", data, function (response) {
                    if (response.success) {
                        vue.categorias = response.categorias;

                        var cats = [];
                        for(let k in vue.categorias){
                            cats[k] = {};
                            cats[k].key = vue.categorias[k].id;
                            cats[k].title = vue.categorias[k].nombre;
                            cats[k].subtitle = vue.categorias[k].descripcion;
                            cats[k].sugerencias = vue.categorias[k].sugerencias;
                            cats[k].search = vue.categorias[k].search;
                            cats[k].item = vue.categorias[k];
                        }

                        vue.$refs.categoriaGasto.setData(cats);
                        vue.$refs.categoriaGasto.setCargando(false);

                        vue.$refs.categoriaGastoAplicar.setData(cats);
                        vue.$refs.categoriaGastoAplicar.setCargando(false);
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getGrupos: function () {
                let vue = this;
                vue.grupos = [];
                $.get(this.API_URL + "/api/grupos", function (response) {
                    if(response.success){
                        vue.grupos = response.grupos;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
        }
    }
</script>

<style lang="scss">
    .aplicar-gastos-boton{
        button{
            background: #28a745 !important;
            border-color: #28a745 !important;
        }
        .loader:after {
            background: #28a745 !important;
        }
    }
</style>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

      .active-message{
        color: #f12b2c;
        &.active{
          color: #29cc97;
        }
      }

        .select-clientes{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;
        }

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px 90px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px 90px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .button-export-top{
            display: inline-block;
            margin-right: 10px;
            @media all and (max-width: 768px){
                margin: 0 0 0 20px;
            }
            @media all and (max-width: 530px) {
                padding-top: 5px;
            }

            .btn-exportar{
                outline: none;
                display: inline-block;
                font-size: 13px;
                line-height: 29px;
                padding: 0 15px;
                color: #fff;
                border-color: #217344;
                background: #217344;
                border-radius: 50px;
                text-transform: uppercase;
                font-weight: bold;

                &:focus{
                    box-shadow: none;
                }

                span{
                    font-size: 11px;
                }

                &:hover{
                    color: #fff;
                }
            }
        }

        .select-style{
            display: block;
            width: 100%;
            border-radius: 50px;
            margin-bottom: 16px;
        }

        .tab-header{
            height: 45px;
            background-color: #f0f0f0;
            button{
                float: left;
                outline: none;
                cursor: pointer;
                padding: 0 16px;
                height: 45px;
                transition: 0.3s;
                font-size: 15px;
                font-weight: bold;
                border: none;
                border-radius: 5px 5px 0 0;
                &.active{
                    background: #fff;
                }
            }
        }

        .select-filtro{
            margin-right: 10px;
            width: 140px;
            vertical-align: bottom;
            display: inline-block;
        }

    }



</style>
