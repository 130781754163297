<template>
    <div id="root">
        <div class="button-add-bottom">
            <ButtonFloat v-on:click="nuevaCategoria"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Categorías de ingresos</h2>
            </div>
            <div class="buttons">
                <div v-if="filtroAnio != null" class="select-header">
                    <FormSelect :valueInput.sync="filtroAnioSelected"
                                @change="getCategorias()">
                        <option v-for="(item, index) in filtroAnio" :key="index"
                                :value="item.value">{{ item.title }}</option>
                    </FormSelect>
                </div>
                <div v-if="filtroAnio != null" class="select-header">
                    <FormSelect :valueInput.sync="filtroMesSelected"
                                @change="getCategorias()">
                        <option value="todos">Todos los meses</option>
                        <option value="01">Enero</option>
                        <option value="02">Febrero</option>
                        <option value="03">Marzo</option>
                        <option value="04">Abril</option>
                        <option value="05">Mayo</option>
                        <option value="06">Junio</option>
                        <option value="07">Julio</option>
                        <option value="08">Agosto</option>
                        <option value="09">Septiembre</option>
                        <option value="10">Octubre</option>
                        <option value="11">Noviembre</option>
                        <option value="12">Diciembre</option>
                    </FormSelect>
                </div>

                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         @click="searchClick"
                                         table="categorias-ingresos"
                                         title="Buscar" />
                </div>
                <div class="button-add-top">
                    <ButtonAdd v-on:click="nuevaCategoria" title="Nuevo +" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <v-data-table
                    :headers="headers"
                    :items="categoriasFiltradas"
                    hide-default-header
                    item-key="tabla-notarios"
                    class="elevation-1" >

                <template v-slot:header="{ props: { headers } }">
                    <thead>
                    <tr>
                        <th v-for="header in headers"
                            :class="'text-left sortable ' + header.hide"
                            :key="header.value"
                            :width="header.width">
                            {{ header.text }}
                        </th>
                    </tr>
                    </thead>
                </template>


                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-if="items.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in items" v-on:click="detalleCategoria(item)" :key="index">
                        <td :class="headers[0].hide">{{ item.nombre }}</td>
                        <td :class="headers[1].hide">{{ item.descripcion }}</td>
                        <td style="width: 200px;" :class="headers[2].hide">
                            <span v-if="item.total_presupuesto != null">{{ item.total_presupuesto | toCurrency }}</span>
                            <span v-else>{{ item.presupuesto_anio | toCurrency }}</span>
                        </td>
                        <td style="width: 200px;" :class="headers[3].hide">{{ item.gastos | toCurrency }}</td>
                        <td style="width: 200px;" :class="headers[4].hide">
                            <span v-if="item.total_presupuesto != null">{{ item.total_presupuesto - item.gastos | toCurrency }}</span>
                            <span v-else>{{ item.presupuesto_anio - item.gastos | toCurrency }}</span>
                        </td>
                        <td :class="headers[5].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>



        <ModalForm ref="categoriaForm"
                   title="Categoría de ingresos"
                   v-on:submit="saveCategoria">
            <template v-slot:body>
                <input type="hidden" v-model="categoria.id">

                <FormInput :valueInput.sync="categoria.nombre"
                           :required="true"
                           name="nombre" title="Nombre" />

                <FormArea :valueInput.sync="categoria.descripcion"
                          ref="descripcion"
                          title="Descripción" />

                <FormArea :valueInput.sync="categoria.sugerencias"
                          ref="sugerencias"
                          placeholder="Ingrese las palabras clave"
                          title="Palabras clave" />

                <div class="presupuestos">
                    <h5>Proyecciones</h5>
                    <div class="item-year">
                        <FormInput :required="false" :title="''+(currentYear - 1)"
                                   placeholder="Proyección"
                                   :valueInput.sync="presupuestos[currentYear - 1]"/>
                    </div>
                    <div class="item-year">
                        <FormInput :required="false" :title="''+currentYear"
                                   placeholder="Proyección"
                                   :valueInput.sync="presupuestos[currentYear]"/>
                    </div>
                    <div v-for="i in 4" :key="i" class="item-year">
                        <FormInput :required="false" :title="''+(currentYear + i)"
                                   placeholder="Proyección"
                                   :valueInput.sync="presupuestos[currentYear + i]"/>
                    </div>
                </div>

            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonCategoria" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>

        <ModalDetail ref="categoriaDetalle" title="Categoría de ingresos Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Nombre</b>
                        </td>
                        <td>
                            <span>{{ categoria.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Descripción</b>
                        </td>
                        <td>
                            <span>{{ categoria.descripcion }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Palabras clave</b>
                        </td>
                        <td>
                            <span>{{ categoria.sugerencias }}</span>
                        </td>
                    </tr>
                    <tr v-for="(item, index) in categoria.presupuestos" :key="index">
                        <td>
                            <b>Proyección de {{ item.anio }}</b>
                        </td>
                        <td>
                            <span>{{ item.presupuesto_anio | toCurrency }}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="checkPrivilege('categorias.movimientos')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="conceptosCategorias"
                                       color="#fff"
                                       background="#00acac"
                                       title="Movimientos"
                                       icon="fa-exchange-alt" />
                </div>

                <div class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editCategoria"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteCategoria"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>
    </div>
</template>

<script>
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ModalForm from '@/components/Modal/ModalForm'
    import FormInput from '@/components/Form/FormInput'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import FormSelect from '@/components/Form/FormSelect'
    import FormArea from '@/components/Form/FormArea'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'CategoriasIngresosScreen',
        components: {
            FormArea,
            FormSelect,
            ButtonFloat,
            InputSearchComplete,
            FormInput,
            ButtonAdd,
            ModalForm,
            ModalDetail,
            ButtonLoader,
            ModalButtonFooter
        },
        data: function(){
            return {
                filtroAnio: null,
                filtroAnioSelected: null,
                filtroMesSelected: null,
                currentYear: 0,
                headers: [
                    {text: 'Nombre', align: 'left', value: 'nombre'},
                    {text: 'Descripción', align: 'left', value: 'descripcion'},
                    {text: 'Proyección', value: 'presupuesto', hide: 'text-center'},
                    {text: 'Ingresos', value: 'gastos', hide: 'text-center'},
                    {text: 'Restante', value: 'disponible', hide: 'text-center'},
                    {text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtroComputed: '',
                filtro: '',
                categorias: [],
                indexEdit: -1,
                categoria: {},
                presupuestos: {}
            }
        },
        computed: {
            categoriasFiltradas () {
                let vue = this;
                return this.categorias.filter(function (item) {
                    return item.search.toLowerCase().indexOf(vue.filtroComputed.toLowerCase()) > -1;
                });

            },
        },
        mounted() {
            this.currentYear = new Date().getFullYear();
            this.$emit("showLoader");
            this.getCategorias();
        },
        methods: {
            conceptosCategorias(){
                this.$refs.categoriaDetalle.hide();
                this.$router.push({ path: "/movimientos-categoria_"+this.categoria.id });
            },
            searchClick(){
                this.filtroComputed = this.filtro;
            },
            getCategorias: function () {
                let vue = this;
                vue.categoria = [];
                let data = {tipo: "ingresos", filtroAnioSelected: this.filtroAnioSelected, filtroMesSelected: this.filtroMesSelected};
                $.get(this.API_URL + "/api/categorias/presupuestos", data, function (response) {
                    vue.$emit("hideLoader");
                    if (response.success) {
                        let firstYear = response.first_year;
                        let lastYear = response.last_year;
                        let reloadFiltroYear = true;
                        if(firstYear != null && lastYear != null){
                            vue.filtroAnio = [];
                            for(let i = firstYear; i <= lastYear; i++){
                                vue.filtroAnio.push({value: i, title: i});
                                if(reloadFiltroYear && i == vue.currentYear){
                                    reloadFiltroYear = false;
                                }
                            }
                        }
                        if(vue.filtroAnioSelected == null && vue.filtroAnio != null && vue.filtroAnio.length > 0 && reloadFiltroYear){
                            vue.filtroAnioSelected =  vue.filtroAnio[vue.filtroAnio.length - 1].value;
                            vue.filtroMesSelected = 'todos';
                            vue.getCategorias();
                        }
                        else{
                            if(vue.filtroAnioSelected == null){
                                vue.filtroAnioSelected = response.filtro_anio_selected;
                            }
                            if(vue.filtroMesSelected == null){
                                vue.filtroMesSelected = response.filtro_mes_selected;
                            }
                            vue.categorias = response.categorias;
                        }
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            nuevaCategoria: function () {
                this.categoria = {};
                this.indexEdit = -1;
                this.presupuestos = {};

                this.$refs.descripcion.setValue("");
                this.$refs.sugerencias.setValue("");

                this.openModalCategoria();
            },
            editCategoria: function () {
                this.$refs.categoriaDetalle.hide();

                this.$refs.descripcion.setValue(this.categoria.descripcion);
                this.$refs.sugerencias.setValue(this.categoria.sugerencias);

                this.openModalCategoria();
            },
            openModalCategoria: function () {
                this.$refs.categoriaForm.show();
            },
            closeModalCategoria: function(){
                this.$refs.categoriaForm.hide();
            },
            detalleCategoria: function (item) {
                let vue = this;
                this.indexEdit = this.categorias.indexOf(item);
                this.categoria = Object.assign({}, item);

                this.presupuestos = {};

                if(this.categoria.presupuestos != null){
                    this.categoria.presupuestos.forEach((item) => {
                        vue.presupuestos[item.anio] = item.presupuesto_anio;
                    });
                }

                this.$refs.categoriaDetalle.show();
            },
            saveCategoria: function (e) {
                e.preventDefault();

                let vue = this;

                vue.$refs.buttonCategoria.setShowLoader(true);
                vue.categoria.tipo = "ingresos";

                vue.categoria.presupuestos_edit = vue.presupuestos;

                $.post(vue.API_URL + "/api/categorias/save", vue.categoria, function (response) {
                    vue.$refs.buttonCategoria.setShowLoader(false);
                    if(response.success){
                        vue.categoria = response.record;

                        if (vue.indexEdit > -1) {
                            Object.assign(vue.categorias[vue.indexEdit], vue.categoria)
                        } else {
                            vue.categorias.splice(0, 0, vue.categoria)
                        }
                        vue.categoria = {};
                        vue.indexEdit = -1;
                        vue.closeModalCategoria();

                        if(response.reload){
                            vue.filtroAnioSelected = null;
                            vue.filtroMesSelected = null;
                        }

                        vue.getCategorias();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }).fail(function (error) {
                    vue.$refs.buttonCategoria.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            deleteCategoria: function () {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/categorias/delete", vue.categoria, function (response) {
                        if(response.success){
                            vue.categorias.splice(vue.indexEdit, 1);
                            vue.categoria = {};
                            vue.$refs.categoriaDetalle.hide();

                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }

                    });
                });
            },
        }
    }
</script>

<style lang="scss">
    .select-header{
        margin-right: 10px;
        display: inline-block;
        .form-group{
            margin-bottom: 0;
            .select-content{
                height: 32px;
                select{
                    background: transparent;
                    top: 0;
                    height: 31px;
                    padding-top: 0;
                    padding-bottom: 0;
                    font-size: 12px;
                }
            }
        }
    }
</style>

<style scoped lang="scss">

    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .presupuestos{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 0 10px;
            h5{
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                color: #2c3e50;
            }
            .item-year{
                flex: 1 1 calc(33.33% - 10px);
            }
        }

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;

        }

        .button-export-top{
            display: inline-block;
            margin-right: 10px;
            @media all and (max-width: 768px){
                margin: 0 0 0 10px;
            }
            @media all and (max-width: 530px) {
                padding-top: 5px;
            }
        }

        .estatus{
            text-transform: capitalize;
        }

        .btn-exportar{
            outline: none;
            display: inline-block;
            font-size: 13px;
            line-height: 29px;
            padding: 0 15px;
            color: #fff;
            border-color: #217344;
            background: #217344;
            border-radius: 50px;
            text-transform: uppercase;
            font-weight: bold;

            span{
                font-size: 11px;
            }

            &:hover{
                color: #fff;
            }
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

    }



</style>
