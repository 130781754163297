<template>
    <div id="root">
        <div class="button-add-bottom">
            <ButtonFloat v-on:click="nuevoConcepto"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>
                    {{ title }}
                </h2>
            </div>
            <div class="buttons">

                <div v-if="recibo.id != undefined" class="btn-imprimir">
                    <ButtonAdd v-on:click="pdfRecibo" title="Imprimir" />
                </div>

                <div v-if="checkPrivilege('recibos.aplicar') && recibo.pagado != 1" class="btn-aplicar">
                    <ButtonAdd v-on:click="aplicarRecibo" title="Aplicar" />
                </div>
                <div v-if="checkPrivilege('recibos.desaplicar') && recibo.pagado == 1" class="btn-desaplicar">
                    <ButtonAdd v-on:click="desaplicarRecibo" title="Desaplicar" />
                </div>
                <div v-if="!checkPrivilege('recibos.revision')" class="btn-revisar">
                    <ButtonAdd v-on:click="revisarRecibo" title="Revisar" />
                </div>
                <div v-else-if="recibo.pagado == 3" class="btn-pendiente">
                    <ButtonAdd v-on:click="pendienteRecibo" title="Pendiente" />
                </div>
                <ButtonAdd v-if="recibo.pagado != 1" v-on:click="guardarRecibo" title="Guardar" />
            </div>
        </div>

        <div class="content-section content-form">
            <div class="row">
                <div class="col-12 col-sm-6 no-padding">
                    <FormSelect :valueInput.sync="recibo.tipo"
                                name="tipo" title="Tipo" >
                        <option value="i">Ingreso</option>
                        <option value="s">Salida</option>
                    </FormSelect>
                </div>
                <div v-show="recibo.tipo == 'i'" class="col-12 col-sm-6 no-padding">
                    <ComboBoxSearch ref="clientes" title="Cliente"
                                    :customRemove="true"
                                    v-on:change="clienteChange"
                                    @removeItem="clienteRemover"
                                    placeholder="Seleccionar cliente"/>
                </div>
                <div v-show="recibo.tipo == 's'" class="col-12 col-sm-6 no-padding">
                    <CompleteSearchUrl ref="proveedores" title="Proveedor"
                                       :url="API_URL+'/api/completes'"
                                       :valueInput.sync="recibo.proveedor"
                                       v-on:delete="eliminarComplete"
                                       placeholder="Ingresa proveedor"/>
                </div>
                <div class="col-12 col-sm-6 no-padding">
                    <DatePicker styleClass="form-control" title="Fecha"
                                :limitDate="!checkPrivilege('ignorar-fecha')"
                                :date.sync="recibo.fecha"
                                placeholder="dd-mm-yyyy"
                                ref="fechaRecibo"
                                format="DD-MM-YYYY" />
                </div>

                <div v-if="checkPrivilege('recibos.comentarios')" class="col-12 no-padding">
                    <FormArea :valueInput.sync="recibo.descripcion"
                              ref="anotaciones"
                              title="Anotaciones" />
                </div>
                <div v-else-if="checkPrivilege('recibos.ver-comentarios')" class="col-12 no-padding annotaciones">
                    <h5>Anotaciones</h5>
                    <p>
                        {{ recibo.descripcion }}
                    </p>
                </div>
            </div>


        </div>
        <br>

        <div class="header-section">
            <div class="title">
                <h2>Conceptos</h2>
            </div>
            <div v-if="recibo.pagado != 1" class="buttons">
<!--                v-if="checkPrivilege('recibos.conceptos.predeterminados')"-->
                <div class="button-add-top gastos-predeterminados">
                    <ButtonAdd :disabled="recibo.tipo != 's'"
                               v-on:click="nuevoConceptoPredeterminado"
                               title="Gastos predeterminados" />
                </div>
                <div v-if="checkPrivilege('recibos.conceptos')" class="button-add-top agregar-concepto">
                    <ButtonAdd v-on:click="nuevoConcepto" title="Agregar concepto" />
                </div>
            </div>
        </div>

        <div class="content-section">
            <v-data-table
                    :headers="headers"
                    :items="conceptos"
                    hide-default-header
                    item-key="table-empresas"
                    class="elevation-1" >

                <template v-slot:header="{ props: { headers } }">
                    <thead>
                    <tr>
                        <th v-for="header in headers"
                            :class="'text-left sortable ' + header.hide"
                            :key="header.value">
                            {{ header.text }}
                        </th>
                    </tr>
                    </thead>
                </template>

                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-if="items.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in items" :key="index">
                        <td :class="headers[0].hide">
                            <span v-if="item.acto != undefined && item.acto.empresa != undefined">
                                {{ item.acto.nombre }}
                            </span>
                            <span v-else-if="item.acto != undefined">
                                {{ item.acto.title }}
                            </span>
                            <span v-else>
                                --
                            </span>
                        </td>
                        <td :class="headers[1].hide">
                            <span v-if="item.categoria != undefined">
                                {{ item.categoria.nombre }}
                            </span>
                            <span v-else>
                                --
                            </span>
                        </td>
                        <td :class="headers[2].hide">
                            {{ item.descripcion }}
                        </td>
                        <td :class="headers[3].hide">
                            <span v-if="item.cuenta != null">{{ item.cuenta.nombre }}</span>
                        </td>
                        <td :class="headers[4].hide">
                            {{ item.importe | toCurrency }}
                        </td>
                        <td :class="headers[5].hide">
                            <ButtonMore v-if="recibo.pagado != 1">
                                <ButtonOptionItem v-show="isEditable(item)" v-on:click="editarConcepto(item)" title="Editar" icon="fa-pencil-alt" />
                                <ButtonOptionItem v-on:click="eliminarConcepto(item)" title="Eliminar" icon="fa-trash-alt" />
                            </ButtonMore>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>

        </div>

        <ModalForm ref="conceptoForm"
                   title="Concepto"
                   v-on:submit="agregarConcepto">
            <template v-slot:body>

                <FormInput :valueInput.sync="concepto.descripcion"
                           name="descripcion" title="Descripción"
                            @input="ordenarCategoriasSugerencias()"/>

                <ComboBoxSearch ref="cuentas" title="Cuentas"
                                placeholder="Selecciona una cuenta"/>

                <ComboBoxSearchSubtitle v-show="recibo.tipo == 's'"
                                ref="categoriaGasto" title="Categoría"
                                placeholder="Selecciona una categoría"/>

                <ComboBoxSearchSubtitle v-show="recibo.tipo == 'i'"
                                ref="categoriaIngreso" title="Categoría"
                                @change="changeCategoria"
                                placeholder="Selecciona una categoría"/>

                <ComboBoxSearchUrl ref="actos" title="Acto"
                                   :url="API_URL+'/api/actos'"
                                   placeholder="Selecciona un acto"/>


                <FormInput :valueInput.sync="concepto.importe" :required="true"
                           type="number"
                           name="importe" title="Importe"
                           placeholder="$0.00"/>

                <!--<DatePicker styleClass="form-control" title="Fecha"-->
                            <!--:date.sync="concepto.fecha_movimiento"-->
                            <!--placeholder="dd-mm-yyyy"-->
                            <!--ref="fecha"-->
                            <!--format="DD-MM-YYYY" />-->

            </template>

            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonConcepto" type="submit" title="Agregar" />
                </div>
            </template>

        </ModalForm>

        <ModalForm ref="gastoPredeterminadoForm"
                   title="Gasto predeterminado"
                   v-on:submit="agregarConceptoPredeterminado">
            <template v-slot:body>

                <ComboBoxSearchUrl ref="actosGastos" title="Acto"
                                   :url="API_URL+'/api/actos'"
                                   @change="actualizarGastosPredeterminados"
                                   placeholder="Selecciona un acto"/>

                <ComboBoxSearchUrl ref="gastos" title="Gastos"
                                   :url="API_URL+'/api/gastos/json'"
                                   @change="gastoPredeterminadoSeleccionado"
                                   placeholder="Selecciona un gasto"/>

                <ComboBoxSearch v-show="concepto.showCuentas" ref="cuentasGastos" title="Cuentas"
                                placeholder="Seleccionar cuenta"/>

                <ComboBoxSearchSubtitle v-show="concepto.showCategorias" ref="categoriaGastoPredeterminado" title="Categoría"
                                        placeholder="Selecciona la categoría"/>

                <FormInput v-show="concepto.showImporte" :valueInput.sync="concepto.importe"
                           :required="concepto.showImporte"
                           type="number"
                           name="importe" title="Importe"
                           placeholder="$0.00"/>

            </template>

            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonConcepto" type="submit" title="Agregar" />
                </div>
            </template>

        </ModalForm>


        <div class="float-saldo">
            <p>
                Total: {{ total | toCurrency }}
            </p>
        </div>

    </div>
</template>

<script>

    import FormInput from '@/components/Form/FormInput'
    import FormArea from '@/components/Form/FormArea'
    import FormSelect from '@/components/Form/FormSelect'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ComboBoxSearch from '@/components/Form/ComboBoxSearch'
    import ComboBoxSearchSubtitle from '@/components/Form/ComboBoxSearchSubtitle'
    import ComboBoxSearchUrl from '@/components/Form/ComboBoxSearchUrl'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ModalForm from '@/components/Modal/ModalForm'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import DatePicker from '@/components/DatePicker/DatePicker'
    import ButtonMore from '@/components/ButtonFloat/ButtonMore'
    import ButtonOptionItem from '@/components/ButtonOption/ButtonOptionItem'
    import CompleteSearchUrl from '@/components/Form/CompleteSearchUrl'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'RecibosScreen',
        components: {
            FormArea,
            CompleteSearchUrl,
            ComboBoxSearchUrl,
            ButtonOptionItem,
            ButtonMore,
            DatePicker,
            ButtonFloat,
            FormInput,
            FormSelect,
            ButtonAdd,
            ComboBoxSearch,
            ComboBoxSearchSubtitle,
            ButtonLoader,
            ModalForm,
        },
        data: function(){
            return {
                title: "Nuevo recibo",
                headers:[
                    { text: 'Acto', align: 'left', value: 'nombre'},
                    { text: 'Categoría', align: 'left', value: 'categoria'},
                    { text: 'Descripción', align: 'left', value: 'descripcion', hide: 'hidden-sm-and-down'},
                    { text: 'Cuenta', align: 'left', value: 'cuenta', hide: 'hidden-sm-and-down'},
                    { text: 'Importe', value: 'importe', width: '10%' },
                    { text: '', value: 'acciones', width: '5%' },
                ],
                filtro: '',
                total: 0,
                categoriaIngresos: [],
                categoria: [],
                conceptos: [],
                concepto: {},
                clientes: [],
                cuentas: [],
                recibo: {id: null, tipo: 'i'},
                indexEdit: -1,
                indexEditConcepto: -1,
                cliente_id: null,
                actoGastoId: null
            }
        },
        computed: {
        },
        mounted() {
            this.$emit("showLoader");
            if(this.$route.params.id != undefined){
                this.getRecibo(this.$route.params.id);
            }
            this.getCategorias();
            this.getClientes();
            this.getCuentas();
        },
        methods: {
            isEditable(item){
                let editable = true;
                if(item.acto_gasto != null){
                    let actoGasto = item.acto_gasto;
                    if(actoGasto.cuenta_id != null && actoGasto.categoria_id != null
                    && actoGasto.importe != null){
                        editable = false;
                    }
                }
                return editable;
            },
            ordenarCategoriasSugerencias(){
                this.$refs.categoriaGasto.buscarSugerencias(this.concepto.descripcion);
                this.$refs.categoriaIngreso.buscarSugerencias(this.concepto.descripcion);
                this.$refs.categoriaGastoPredeterminado.buscarSugerencias(this.concepto.descripcion);
            },
            pdfRecibo(){
                window.open(this.API_URL+"/recibo/"+this.recibo.id);
            },
            clienteRemover(){
                if(this.conceptos.length > 0){
                    swal("Se deben eliminar los conceptos antes de remover al cliente", "", "info");
                    return;
                }
                else{
                    this.$refs.clientes.removeItem();
                }
            },
            clienteChange: function(){
                let cliente = this.$refs.clientes.getSelected();
                var extras = this.$refs.actos.getExtras();
                if(cliente != undefined && cliente != null){
                    extras.cliente_id = cliente.key;
                }
                else{
                    extras.cliente_id = undefined;
                }

                this.$refs.actos.setExtras(extras);

            },
            changeCategoria(){
                let extras = this.$refs.actos.getExtras();
                let categoria = this.$refs.categoriaIngreso.getSelected();
                if(categoria != undefined){
                    extras.categoria_id = categoria.key;
                }
                else{
                    extras.categoria_id = undefined;
                }
                this.$refs.actos.setExtras(extras);
            },
            getCategorias(){
                this.getCategoriasIngresos();
                this.getCategoriasGastos();
            },
            getCategoriasIngresos: function () {
                let vue = this;
                vue.categoriaIngresos = [];
                let data = {tipo: "ingresos"};
                $.get(this.API_URL + "/api/categorias", data, function (response) {
                    if (response.success) {
                        vue.categoriaIngresos = response.categorias;

                        var cats = [];
                        for(let k in vue.categoriaIngresos){
                            cats[k] = {};
                            cats[k].key = vue.categoriaIngresos[k].id;
                            cats[k].title = vue.categoriaIngresos[k].nombre;
                            cats[k].subtitle = vue.categoriaIngresos[k].descripcion;
                            cats[k].sugerencias = vue.categoriaIngresos[k].sugerencias;
                            cats[k].search = vue.categoriaIngresos[k].search;
                            cats[k].item = vue.categoriaIngresos[k];
                        }

                        vue.$refs.categoriaIngreso.setData(cats);
                        vue.$refs.categoriaIngreso.setCargando(false);
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getCategoriasGastos: function () {
                let vue = this;
                vue.categoria = [];
                let data = {tipo: "gastos"};
                $.get(this.API_URL + "/api/categorias", data, function (response) {
                    if (response.success) {
                        vue.categorias = response.categorias;

                        var cats = [];
                        for(let k in vue.categorias){
                            cats[k] = {};
                            cats[k].key = vue.categorias[k].id;
                            cats[k].title = vue.categorias[k].nombre;
                            cats[k].subtitle = vue.categorias[k].descripcion;
                            cats[k].sugerencias = vue.categorias[k].sugerencias;
                            cats[k].search = vue.categorias[k].search;
                            cats[k].item = vue.categorias[k];
                        }

                        vue.$refs.categoriaGasto.setData(cats);
                        vue.$refs.categoriaGasto.setCargando(false);

                        vue.$refs.categoriaGastoPredeterminado.setData(cats);
                        vue.$refs.categoriaGastoPredeterminado.setCargando(false);
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getRecibo: function (id) {
                let vue = this;
                $.get(this.API_URL + "/api/recibos/"+id, function (response) {
                    if(response.success){

                        vue.title = "Editar recibo";

                        vue.recibo = response.recibo;

                        if(vue.recibo.tipo == "i"){

                            let apellidos = vue.recibo.cliente.apellidos == null ?
                                "" : vue.recibo.cliente.apellidos;

                            let cliente = {
                                key: vue.recibo.cliente.id,
                                title: vue.recibo.cliente.nombre + " " + apellidos,
                            };

                            vue.$refs.clientes.setSelected(cliente);

                            let data = {};
                            data.cliente_id = vue.recibo.cliente.id;

                            vue.$refs.actos.setExtras(data);
                        }
                        else{
                            vue.$refs.actos.setExtras({});

                            vue.$refs.proveedores.setValue(vue.recibo.proveedor);
                        }

                        vue.$refs.fechaRecibo.setDate(vue.recibo.fecha);

                        if(vue.checkPrivilege('recibos.comentarios')){
                            vue.$refs.anotaciones.setValue(vue.recibo.descripcion);
                        }

                        vue.conceptos = vue.recibo.conceptos;

                        vue.calcularTotal();

                    }
                    else{
                        vue.$router.replace({ path: "/recibos", query: vue.$route.params.search});
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },

            getCuentas: function () {
                let vue = this;
                vue.cuentas = [];
                vue.$refs.cuentas.setCargando(true);
                $.get(this.API_URL + "/api/cuentas/getCuentas", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        for(let k in response.cuentas){
                            vue.cuentas[k] = {};
                            vue.cuentas[k].key = response.cuentas[k].id;
                            vue.cuentas[k].title = response.cuentas[k].nombre;
                            vue.cuentas[k].item = response.cuentas[k];
                            vue.cuentas[k].search = response.cuentas[k].search;
                        }
                        vue.$refs.cuentas.setData(vue.cuentas);
                        vue.$refs.cuentas.setCargando(false);

                        vue.$refs.cuentasGastos.setData(vue.cuentas);
                        vue.$refs.cuentasGastos.setCargando(false);

                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getClientes: function () {
                let vue = this;
                vue.clientes = [];
                vue.$refs.clientes.setCargando(true);
                $.get(this.API_URL + "/api/clientes/get", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        for(let k in response.clientes){
                            vue.clientes[k] = {};
                            vue.clientes[k].key = response.clientes[k].id;
                            let apellidos = response.clientes[k].apellidos == null ?
                                "" : response.clientes[k].apellidos;
                            vue.clientes[k].title = response.clientes[k].nombre + " " + apellidos;
                            vue.clientes[k].search = response.clientes[k].search;
                        }
                        vue.$refs.clientes.setData(vue.clientes);
                        vue.$refs.clientes.setCargando(false);
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            eliminarComplete: function (item) {
                let data = {key: item.key, value: item.title};
                this.$emit("deleteComplete", data);
            },
            guardarRecibo: function () {
                let cliente = this.$refs.clientes.getSelected();
                if(this.recibo.tipo == 'i' && (cliente == undefined || cliente == null)){
                    swal("Selecciona el cliente", '', 'info');
                    return
                }

                if(this.recibo.tipo == 's' && (this.recibo.proveedor == undefined || this.recibo.proveedor == null)){
                    swal("Selecciona el proveedor", '', 'info');
                    return;
                }

                if(this.recibo.proveedor != undefined && this.recibo.proveedor != null){
                    let complete = {key: "proveedor", value: this.recibo.proveedor};
                    this.$emit("saveComplete", complete);
                }

                if(cliente != undefined && cliente != null){
                    this.recibo.cliente_id = cliente.key;
                }

                this.recibo.fecha = this.$refs.fechaRecibo.getDate();

                if(this.recibo.fecha == undefined){
                  swal("Selecciona una fecha", '', 'info');
                  return
                }

                let vue = this;

                this.recibo.conceptos = this.conceptos;

                let data = {"data": JSON.stringify(vue.recibo)};

                $.post(this.API_URL + "/api/recibos/save", data, function (response) {

                    if(response.success){
                        swal(response.message, "", "success");
                        if(response.record != undefined){
                            if(vue.recibo.id == undefined){
                                document.title = "Editar recibo | Servicios Jurídicos";
                                window.history.replaceState('', 'Editar recibo', '/recibo_'+response.record.id);
                            }
                            vue.getRecibo(response.record.id);
                        }
                        else{
                            vue.$router.push({ path: "/recibos", query: vue.$route.params.search});
                        }

                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });

            },
            aplicarRecibo () {
                this.recibo.aplicar = true;
                this.guardarRecibo();
            },
            revisarRecibo(){
                this.recibo.revisar = true;
                this.guardarRecibo();
            },
            eliminarConcepto: function (concepto) {
                let vue = this;
                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    vue.conceptos.splice(vue.conceptos.indexOf(concepto), 1);
                    vue.calcularTotal();
                });
            },
            calcularTotal: function () {
                this.total = 0;
                for(let i in this.conceptos){
                    this.total += this.conceptos[i].importe
                }
            },
            editarConcepto: function (concepto) {
                this.indexEditConcepto = this.conceptos.indexOf(concepto);
                this.concepto = Object.assign({}, concepto);

                let showActoGasto = concepto.acto_gasto_id != null;

                if(concepto.acto != undefined && concepto.acto.empresa != undefined){
                    let acto = {
                        key: concepto.acto.id,
                        title: concepto.acto.nombre,
                        item: concepto.acto
                    };
                    if(showActoGasto){
                        this.$refs.actosGastos.setSelected(acto);
                    }
                    else{
                        this.$refs.actos.setSelected(acto);
                    }
                }

                if(showActoGasto){
                    let actoGasto = concepto.acto_gasto;
                    let actoGastoItem = {
                        key: actoGasto.id,
                        title: actoGasto.descripcion,
                        item: actoGasto
                    };
                    this.$refs.gastos.setSelected(actoGastoItem);
                }

                let cuenta = null;
                if(this.concepto.cuenta != null){
                    cuenta = {
                        key: this.concepto.cuenta.id,
                        title: this.concepto.cuenta.nombre,
                        item: this.concepto.cuenta
                    };
                }

                if(this.concepto.categoria == undefined){
                    this.$refs.categoriaGasto.removeItem();
                    this.$refs.categoriaIngreso.removeItem();
                }
                else{
                    let cat = {
                        key: this.concepto.categoria.id,
                        title: this.concepto.categoria.nombre,
                        search: this.concepto.categoria.search,
                        item: this.concepto.categoria
                    };
                    if(this.recibo.tipo == 's'){
                        this.$refs.categoriaIngreso.removeItem();
                        if(showActoGasto){
                            this.$refs.categoriaGastoPredeterminado.setSelected(cat);
                        }
                        else{
                            this.$refs.categoriaGasto.setSelected(cat);
                        }

                    }
                    else {
                        this.$refs.categoriaGasto.removeItem();
                        this.$refs.categoriaIngreso.setSelected(cat);
                    }
                }

                if(cuenta != null){
                    if(showActoGasto){
                        this.$refs.cuentasGastos.setSelected(cuenta);
                    }
                    else{
                        this.$refs.cuentas.setSelected(cuenta);
                    }
                }
                this.ordenarCategoriasSugerencias();

                if(showActoGasto){

                    this.$refs.gastoPredeterminadoForm.show();
                }
                else{
                    this.openModalConcepto();
                }

                console.log(this.concepto);
            },
            agregarConceptoPredeterminado(e){
                e.preventDefault();

                let gasto = this.$refs.gastos.getSelected();

                if(gasto === undefined || gasto == null){
                    swal("Selecciona un gasto", "", "info");
                    return
                }

                if(this.concepto.showCuentas){
                    let cuenta = this.$refs.cuentasGastos.getSelected();
                    if(cuenta === undefined || cuenta == null){
                        swal("Selecciona una cuenta", "", "info");
                        return
                    }
                    this.concepto.cuenta_id = cuenta.key;
                    this.concepto.cuenta = cuenta.item;
                }
                else{
                    this.concepto.cuenta = gasto.item.cuenta;
                    this.concepto.cuenta_id = gasto.item.cuenta_id;
                }

                if(this.concepto.showCategorias){
                    let cat = this.$refs.categoriaGastoPredeterminado.getSelected();
                    if(cat === undefined || cat == null){
                        swal("Selecciona una categoría", "", "info");
                        return
                    }
                    this.concepto.categoria = cat.item;
                    this.concepto.categoria_id = cat.key;
                }
                else{
                    this.concepto.categoria = gasto.item.categoria;
                    this.concepto.categoria_id = gasto.item.categoria_id;
                }

                if(!this.concepto.showImporte){
                    this.concepto.importe = parseFloat(gasto.item.importe);
                }
                else{
                    this.concepto.importe = parseFloat(this.concepto.importe);
                }

                let acto = this.$refs.actosGastos.getSelected();

                if(acto != null){
                    this.concepto.acto = acto;
                    this.concepto.acto_id = acto.key;
                }

                this.concepto.acto_gasto = gasto.item;
                this.concepto.acto_gasto_id = gasto.key;

                if(this.indexEditConcepto > -1){
                    Object.assign(this.conceptos[this.indexEditConcepto], this.concepto);
                }
                else{
                    this.conceptos.splice(0, 0, this.concepto)
                }

                this.concepto = {};
                this.indexEditConcepto = -1;
                this.calcularTotal();
                this.$refs.gastoPredeterminadoForm.hide();
                this.ordenarCategoriasSugerencias();
            },
            agregarConcepto: function (e) {
                e.preventDefault();
                let cuenta = this.$refs.cuentas.getSelected();
                if(cuenta === undefined || cuenta == null){
                    swal("Selecciona una cuenta", "", "info");
                    return
                }

                if(this.recibo.tipo == 's'){
                    let cat = this.$refs.categoriaGasto.getSelected();
                    if(cat === undefined || cat == null){
                        swal("Selecciona una categoría", "", "info");
                        return
                    }
                    else{
                        this.concepto.categoria = cat.item;
                        this.concepto.categoria_id = cat.key;
                    }
                }
                else if(this.recibo.tipo == 'i'){
                    let cat = this.$refs.categoriaIngreso.getSelected();
                    if(cat === undefined || cat == null){
                        swal("Selecciona una categoría", "", "info");
                        return
                    }
                    else{
                        this.concepto.categoria = cat.item;
                        this.concepto.categoria_id = cat.key;
                    }
                }

                let acto = this.$refs.actos.getSelected();
                if(acto != undefined || acto != null){
                    this.concepto.acto = acto;
                    this.concepto.acto_id = acto.key;
                }
                else{
                    this.concepto.acto_id = null;
                    this.concepto.acto = {};
                }

                this.concepto.cuenta_id = cuenta.key;

                this.concepto.cuenta = cuenta.item;


                this.concepto.importe = parseFloat(this.concepto.importe);


                if(this.indexEditConcepto > -1){
                    Object.assign(this.conceptos[this.indexEditConcepto], this.concepto);
                }
                else{
                    this.conceptos.splice(0, 0, this.concepto)
                }

                this.concepto = {};
                this.indexEditConcepto = -1;
                this.calcularTotal();
                this.closeModalConcepto();

                this.ordenarCategoriasSugerencias();

            },
            gastoPredeterminadoSeleccionado(){
                this.$refs.cuentasGastos.removeItem();
                this.$refs.categoriaGastoPredeterminado.removeItem();

                let gasto = this.$refs.gastos.getSelected();
                let concepto = Object.assign({}, this.concepto);

                let descripcion = null;

                let showCuentas = false;
                let showCategorias = false;
                let showImporte = false;

                if(gasto != undefined || gasto != null){
                    descripcion = gasto.item.descripcion;
                    showCuentas = gasto.item.cuenta_id == null;
                    showCategorias = gasto.item.categoria_id == null;
                    showImporte = gasto.item.importe == null;
                }

                concepto.descripcion = descripcion;
                concepto.showCuentas = showCuentas;
                concepto.showCategorias = showCategorias;
                concepto.showImporte = showImporte;

                this.concepto = Object.assign({}, concepto);

                this.ordenarCategoriasSugerencias();
            },
            actualizarGastosPredeterminados(){
                let acto = this.$refs.actosGastos.getSelected();
                let extras = this.$refs.gastos.getExtras();
                let excluirId = [];
                if(acto != undefined || acto != null){
                    if( extras.acto_id != acto.key){
                        extras.acto_id = acto.key;
                        this.$refs.gastos.removeItem();
                    }
                }
                else{
                    extras.acto_id = null;
                    this.$refs.gastos.removeItem();
                }
                excluirId = this.conceptos.map((item) => {
                    if(item.acto_gasto_id != null){
                        return item.acto_gasto_id;
                    }
                })
                extras.excluir_id = excluirId;
                this.$refs.gastos.setExtras(extras);
            },
            nuevoConceptoPredeterminado: function () {
                if(this.recibo.tipo != "s"){
                    swal("Seleccione salida en el tipo de recibo", "", "info");
                    return;
                }

                this.concepto = {};
                this.indexEditConcepto = -1;

                this.$refs.actosGastos.removeItem();
                this.$refs.gastos.removeItem();
                this.$refs.cuentasGastos.removeItem();
                this.$refs.categoriaGastoPredeterminado.removeItem();

                this.ordenarCategoriasSugerencias();
                this.$refs.gastoPredeterminadoForm.show();
            },
            nuevoConcepto: function () {

                if(this.recibo.tipo == "i" && this.$refs.clientes.getSelected() == undefined){
                    swal("Se necesita seleccionar un cliente", "", "info");
                    return;
                }

                this.concepto = {};
                this.indexEditConcepto = -1;

                this.$refs.categoriaGasto.removeItem();
                this.$refs.categoriaIngreso.removeItem();
                this.$refs.cuentas.removeItem();
                this.$refs.actos.removeItem();

                this.ordenarCategoriasSugerencias();
                this.openModalConcepto();
            },
            openModalConcepto: function () {
                this.$refs.conceptoForm.show();
            },
            closeModalConcepto: function () {
                this.$refs.conceptoForm.hide();
            },
            desaplicarRecibo(){
                let vue = this;
                swal({
                    text: '¿Realmente deseas desaplicar el recibo?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    vue.recibo.aplicar = false;
                    vue.recibo.desaplicar = true;
                    let data = {"data": JSON.stringify(vue.recibo)};
                    $.post(vue.API_URL + "/api/recibos/aplicar", data, function (response) {
                        if(response.success){
                            vue.getRecibo(vue.recibo.id);
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });
            },
            pendienteRecibo(){
                let vue = this;
                swal({
                    text: '¿Realmente deseas enviar a pendiente el recibo?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    vue.recibo.revisar = false;
                    let data = {"data": JSON.stringify(vue.recibo)};
                    $.post(vue.API_URL + "/api/recibos/revisar", data, function (response) {
                        if(response.success){
                            vue.getRecibo(vue.recibo.id);
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });
            },
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px 100px 25px;
        width: 100%;
        height: 100%;

        .annotaciones{
            h5{
                font-weight: 700;
                font-size: 18px;
            }
        }

        .btn-aplicar{
            margin-right: 10px;
            display: inline-block;
            button{
                background: #28a745;
                border-color: #28a745;
            }
        }

        .btn-revisar{
            margin-right: 10px;
            display: inline-block;
            button{
                background: #28a745;
                border-color: #28a745;
            }
        }

        .btn-desaplicar{
            margin-right: 0;
            display: inline-block;
            button{
                background: rgb(255, 62, 9);
                border-color: rgb(255, 62, 9);
            }
        }

        .btn-pendiente{
            margin-right: 10px;
            display: inline-block;
            button{
                background: rgb(255, 62, 9);
                border-color: rgb(255, 62, 9);
            }
        }

        .btn-imprimir{
            margin-right: 10px;
            display: inline-block;
            button{
                background: rgb(0, 123, 255);
                border-color: rgb(0, 123, 255);
            }
        }

        .content-form{
            background: #fff;
            padding: 16px;
            border-radius: 10px;
            border: 1px solid #dfe0eb;
            -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

            .no-padding{
                padding-top: 0;
                padding-bottom: 0;
            }
        }


        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px 100px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px 100px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .agregar-concepto{
            margin-left: 15px;
        }

        .gastos-predeterminados{

            button{
                background: #00acac;
                border-color: #00acac;
            }
        }

        .float-saldo{
            position: fixed;
            right: 20px;
            bottom: 20px;
            padding: 10px 30px;
            background: #c4c086;
            border-radius: 10px;

            @media all and (max-width: 768px) {
                left: 15px;
                right: inherit;
            }

            @media all and (max-width: 576px){
                left: 5px;
            }

            p{
                text-transform: uppercase;
                color: #fff;
                margin: 0;
                padding: 0;
                font-weight: 600;
                font-size: 18px;
            }
        }


    }



</style>
