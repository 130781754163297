<template>
    <div id="root">
        <div v-if="checkPrivilege('clientes.crear')" class="button-add-bottom">
            <ButtonFloat v-on:click="nuevoCliente"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Clientes</h2>
            </div>
            <div class="buttons">
                <div class="buscador-content" v-bind:class="{'no-margin' : !checkPrivilege('clientes.crear')}">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         @click="searchClick"
                                         table="clientes"
                                         title="Buscar" />
                </div>
                <div v-if="checkPrivilege('clientes.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="nuevoCliente" title="Nuevo +" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th v-for="(item, index) in headers" :key="index" :width="item.width"
                            :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                            {{ item.text }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="clientes.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in clientes" :key="index" v-on:click="detalleCliente(item)">
                        <td :class="headers[0].hide">{{ item.nombre }} {{ item.apellidos }}</td>
                        <td :class="headers[1].hide">{{ item.email }}</td>
                        <td :class="headers[2].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <ModalForm id="modalCliente"
                   ref="clienteForm"
                   title="Cliente"
                   v-on:submit="saveCliente">
            <template v-slot:body>
                <input type="hidden" v-model="cliente.id">
                <FormInput :valueInput.sync="cliente.nombre" :required="true"
                           name="nombre" title="Nombre" />
                <FormInput :valueInput.sync="cliente.apellidos" :required="true"
                           name="apellidos" title="Apellidos" />
                <FormInput :valueInput.sync="cliente.email"
                           type="email"
                           name="email" title="Correo electrónico" />
                <FormInput :valueInput.sync="cliente.telefono"
                           name="telefono" title="Teléfono" />

                <div v-if="checkPrivilege('clientes.precios')" class="v-data-table table-material">
                    <table>
                        <thead>
                        <tr>
                            <th>Servicio</th>
                            <th width="150" class="text-center">
                                Precio
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(serv, servIndex) in clienteServicios" :key="servIndex">
                            <td>
                                {{ serv.nombre }}
                            </td>
                            <td>
                                <label></label>
                                <FormInput :valueInput.sync="serv.precio" type="number" name="precio"
                                           placeholder="$0.00"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonCliente" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>


        <ModalDetail ref="clienteDetalle" title="Cliente Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Nombre</b>
                        </td>
                        <td>
                            <span>{{ cliente.nombre }}</span>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Apellidos</b>
                        </td>
                        <td>
                            <span>{{ cliente.apellidos }}</span>

                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('clientes.info')">
                        <td>
                            <b>Teléfono</b>
                        </td>
                        <td>
                            <span>{{ cliente.telefono }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Correo electrónico</b>
                        </td>
                        <td>
                            <span>{{ cliente.email }}</span>
                        </td>
                    </tr>
                    <tr v-for="(serv, index) in clienteServicios" :key="index">
                        <td v-if="checkPrivilege('clientes.precios') && serv.precio != ''">
                            <b>{{ serv.nombre }}</b>
                        </td>
                        <td v-if="checkPrivilege('clientes.precios') && serv.precio != ''">
                            <span>{{ serv.precio | toCurrency }}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="checkPrivilege('clientes.cuenta')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="cuentaCliente"
                                       color="#fff"
                                       background="#00acac"
                                       title="Estado de Cuenta"
                                       icon="fa-book"/>
                </div>

                <div v-if="checkPrivilege('clientes.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editCliente"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div v-if="checkPrivilege('clientes.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteCliente"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>


    </div>
</template>

<script>

    import FormInput from '@/components/Form/FormInput'
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ModalForm from '@/components/Modal/ModalForm'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'ClientesScreen',
        components: {
            ButtonFloat,
            FormInput,
            InputSearchComplete,
            ButtonAdd,
            ModalDetail,
            ModalForm,
            ModalButtonFooter,
            ButtonLoader
        },
        data: function(){
            return {
                page: 1,
                lastPage: 1,
                headers: [
                    {text: 'Nombre', align: 'left', value: 'nombre'},
                    { text: 'Correo electrónico', value: 'email'},
                    { text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtro: '',
                clientes: [],
                servicios: [],
                cliente: {cuenta:{}},
                clienteServicios: [],
                indexEdit: -1
            }
        },
        mounted() {
            this.$emit("showLoader");
            this.getClientes();
            this.getServicios();
        },
        // computed: {
        //     clientesFiltrados () {
        //         let vue = this;
        //         return this.clientes.filter(function (cli) {
        //             return cli.search.toLowerCase().indexOf(vue.filtro.toLowerCase()) > -1;
        //         });
        //     },
        // },
        methods: {
            searchClick(){
                this.first(true);
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.getClientes();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.getClientes();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.getClientes();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.getClientes();
                }
                else if(refresh){
                    this.page = 1;
                    this.getClientes();
                }
            },
            getClientes: function () {
                this.$emit("showLoader");
                let vue = this;
                vue.clientes = [];
                let data = {page: this.page, filtro: this.filtro};
                $.get(this.API_URL + "/api/clientes/getPage", data, function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.page = response.clientes.current_page;
                        vue.lastPage = response.clientes.last_page;
                        vue.clientes = response.clientes.data;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            getServicios: function () {
                let vue = this;
                vue.servicios = [];
                $.get(this.API_URL + "/api/servicios/get", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.servicios = response.servicios;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            cuentaCliente: function () {
                this.$refs.clienteDetalle.hide();
                this.$router.push({ path: "/cliente-cuenta_"+this.cliente.id });
            },
            deleteCliente () {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/clientes/delete", vue.cliente, function (response) {
                        if(response.success){
                            vue.clientes.splice(vue.indexEdit, 1);
                            vue.cliente = {cuenta:{}};
                            vue.$refs.clienteDetalle.hide();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }

                    });
                });

            },
            saveCliente: function (e) {
                e.preventDefault();
                this.$refs.buttonCliente.setShowLoader(true);
                let vue = this;

                this.cliente.servicios = this.clienteServicios;

                $.post(this.API_URL + "/api/clientes/save", this.cliente, function (response) {
                   vue.$refs.buttonCliente.setShowLoader(false);
                   if(response.success){
                       vue.cliente = response.record;

                       if (vue.indexEdit > -1) {
                           Object.assign(vue.clientes[vue.indexEdit], vue.cliente)
                       } else {
                           vue.clientes.splice(0, 0, vue.cliente)
                       }
                       vue.cliente = {cuenta:{}};
                       vue.indexEdit = -1;
                       vue.closeModalActo();
                   }
                   else{
                       swal(response.message, "", "info")
                   }
                }, "json").fail(function (error) {
                    vue.$refs.buttonCliente.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });

            },
            detalleCliente: function (cliente) {

                if(cliente.cuenta == null){
                    cliente.cuenta = {};
                }

                this.indexEdit = this.clientes.indexOf(cliente);
                this.cliente = Object.assign({}, cliente);

                this.clienteServicios = Object.assign([], this.servicios);

                for(let k in this.clienteServicios){
                    this.clienteServicios[k].precio = "";
                }

                for(let k in this.clienteServicios){
                    for(let i in this.cliente.servicios_precio){
                        if(this.cliente.servicios_precio[i].servicio_id == this.clienteServicios[k].id){
                            this.clienteServicios[k].precio = this.cliente.servicios_precio[i].precio;
                            break;
                        }
                    }
                }

                this.$refs.clienteDetalle.show();

            },
            editCliente: function () {
                this.$refs.clienteDetalle.hide();

                this.openModalActo();
            },
            nuevoCliente: function () {
                this.cliente = {cuenta:{}};

                this.clienteServicios = Object.assign([], this.servicios);

                for(let k in this.clienteServicios){
                    this.clienteServicios[k].precio = "";
                }

                this.indexEdit = -1;
                this.openModalActo();
            },
            openModalActo: function () {
                this.$refs.clienteForm.show();
            },
            closeModalActo: function () {
                this.$refs.clienteForm.hide();
            }
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .header-section .buttons .buscador-content.no-margin{
            margin: 0;
        }

        .btn-group button {
            background-color: #4CAF50; /* Green background */
            border: 1px solid green; /* Green border */
            color: white; /* White text */
            padding: 10px 24px; /* Some padding */
            cursor: pointer; /* Pointer/hand icon */
            width: 50%; /* Set a width if needed */
            display: block; /* Make the buttons appear below each other */
        }

        .btn-group button:not(:last-child) {
            border-bottom: none; /* Prevent double borders */
        }

        /* Add a background color on hover */
        .btn-group button:hover {
            background-color: #3e8e41;
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }


    }



</style>
