<template>
    <div id="root">
        <div v-if="checkPrivilege('grupos.crear')" class="button-add-bottom">
            <ButtonFloat v-on:click="nuevoGrupo"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Grupos</h2>
            </div>
            <div class="buttons">
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         @click="searchClick"
                                         table="grupos"
                                         title="Buscar" />
                </div>
                <div v-if="checkPrivilege('grupos.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="nuevoGrupo" title="Nuevo +" />
                </div>
            </div>
        </div>
        <div class="content-section">

            <v-data-table
                    :headers="headers"
                    :items="gruposFiltrados"
                    hide-default-header
                    item-key="tabla-grupos"
                    class="elevation-1" >

                <template v-slot:header="{ props: { headers } }">
                    <thead>
                    <tr>
                        <th v-for="header in headers"
                            :class="'text-left sortable ' + header.hide"
                            :key="header.value"
                            :width="header.width">
                            {{ header.text }}
                        </th>
                    </tr>
                    </thead>
                </template>


                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-if="items.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in items" :key="index" v-on:click="detalleGrupo(item)">
                        <td :class="headers[0].hide">{{ item.name }}</td>
                        <td :class="headers[1].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>

        <ModalForm id="modalGrupo"
                   ref="grupoForm"
                   title="Grupo"
                   v-on:submit="saveGrupo">
            <template v-slot:body>
                <FormInput :valueInput.sync="grupo.name" :required="true"
                           name="nombre" title="Nombre" />
            </template>

            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonGrupo" type="submit" title="Guardar" />
                </div>
            </template>

        </ModalForm>

        <ModalForm ref="usuariosGrupoForm"
                   title="Usuarios grupo"
                   v-on:submit="saveUsuariosGrupo">
            <template v-slot:body>

                <ul class="usuarios-lista">
                    <li v-for="(item, index) in usuariosEditar" :key="index">
                        <label class="usuario">
                            <div class="check-input">
                                <input v-model="item.agregado" type="checkbox" :value="true">
                            </div>
                            <div class="nombre-usuario">
                                <p>{{ item.name }}</p>
                            </div>
                            <div class="input-comision">
                                <FormInput :valueInput.sync="item.comision" :required="false"
                                           type="number"
                                           placeholder="$0.00"/>
                            </div>

                        </label>
                    </li>
                </ul>

            </template>

            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonUsuariosGrupo" type="submit" title="Guardar" />
                </div>
            </template>

        </ModalForm>

        <ModalDetail ref="grupoDetalle" title="Grupo Detalle">
            <template v-slot:body>
                <table class="table-detail" style="margin: 0;">
                    <tbody>
                    <tr>
                        <td>
                            <b>Nombre</b>
                        </td>
                        <td>
                            <span>{{ grupo.name }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td><b>Usuarios</b></td>
                        <td></td>
                    </tr>
                    <tr v-for="(item, index) in grupo.usuarios" :key="index">
                        <td>
                            <b>{{ item.name }}</b>
                        </td>
                        <td>
                            {{ item.comision | toCurrency }}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table class="table-detail">
                    <tbody>
                    <tr v-if="grupo.usuarios == null || grupo.usuarios.length == 0">
                        <td colspan="2" class="text-center">No hay usuarios</td>
                    </tr>

                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="checkPrivilege('grupos.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editUsuarios"
                                       color="#fff"
                                       background="#00acac"
                                       title="Usuarios"
                                       icon="fa-users" />
                </div>

                <div v-if="checkPrivilege('grupos.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editGrupo"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div v-if="checkPrivilege('grupos.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteGrupo"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>


    </div>
</template>

<script>

    import FormInput from '@/components/Form/FormInput'
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ModalForm from '@/components/Modal/ModalForm'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'GruposScreen',
        components: {
            FormInput,
            InputSearchComplete,
            ButtonFloat,
            ButtonLoader,
            ButtonAdd,
            ModalDetail,
            ModalForm,
            ModalButtonFooter
        },
        data: function(){
            return {
                headers: [
                    {text: 'Nombre', align: 'left', value: 'nombre'},
                    { text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtroComputed: '',
                filtro: '',
                grupos: [],
                grupo: {},
                indexEdit: -1,
                privilegios: [],
                privilegiosArray: [],

                usuarios: [],
                usuariosEditar: []
            }
        },
        computed: {
            gruposFiltrados () {
                let vue = this;
                return this.grupos.filter(function (per) {
                    return per.name.toLowerCase().indexOf(vue.filtroComputed.toLowerCase()) > -1;
                });
            },
        },
        mounted() {
            this.$emit("showLoader");
            this.getGrupos();
            this.getUsuarios()
        },
        methods: {
            searchClick(){
                this.filtroComputed = this.filtro;
            },
            getUsuarios: function () {
                let vue = this;
                vue.usuarios = [];
                $.get(this.API_URL + "/api/users/json", function (response) {
                    if(response.success){
                        vue.usuarios = response.users;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },

            getGrupos: function () {
                let vue = this;
                $.get(this.API_URL + "/api/grupos", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.grupos = response.grupos;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            deleteGrupo () {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/grupos/delete", vue.grupo, function (response) {
                        if(response.success){
                            vue.grupos.splice(vue.indexEdit, 1);
                            vue.grupo = {};
                            vue.$refs.grupoDetalle.hide();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });

            },
            saveUsuariosGrupo(e){
                e.preventDefault();
                let usuarios = this.usuariosEditar.filter((item) => {
                    return item.editado || item.agregado;
                });
                this.saveGrupo(e, usuarios);
            },
            saveGrupo: function (e, usuarios = null) {
                e.preventDefault();
                this.$refs.buttonGrupo.setShowLoader(true);
                this.$refs.buttonUsuariosGrupo.setShowLoader(true);
                let vue = this;

                if(usuarios != null){
                    this.grupo.agregar_usuarios = true;
                    this.grupo.usuarios = usuarios;
                }
                else{
                    this.grupo.usuarios = null;
                }

                $.post(this.API_URL + "/api/grupos/save", this.grupo, function (response) {
                   vue.$refs.buttonGrupo.setShowLoader(false);
                   vue.$refs.buttonUsuariosGrupo.setShowLoader(false);
                   if(response.success){
                       vue.getGrupos();
                       vue.grupo = {};
                       vue.indexEdit = -1;
                       vue.closeModalGrupo();
                   }
                   else{
                       swal(response.message, "", "info")
                   }
                }, "json").fail(function (error) {
                    vue.$refs.buttonGrupo.setShowLoader(false);
                    vue.$refs.buttonUsuariosGrupo.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });

            },
            detalleGrupo: function (item) {
                let vue = this;
                this.indexEdit = this.grupos.indexOf(item);
                this.grupo = Object.assign({}, item);
                let usuariosGrupo = this.grupo.usuarios;
                this.usuariosEditar = [];
                this.usuarios.forEach((item) => {
                    let usuario = Object.assign({}, item);
                    delete usuario.profile_id;
                    delete usuario.group_id;
                    delete usuario.username;
                    delete usuario.status;
                    delete usuario.image;
                    delete usuario.token;
                    delete usuario.profile;
                    delete usuario.search;
                    delete usuario.image_url;
                    let findIndex = usuariosGrupo.findIndex((item) => item.id == usuario.id);
                    usuario.agregado = findIndex > -1;
                    usuario.editado = findIndex > -1;
                    if(!usuario.agregado){
                        usuario.comision = null;
                    }
                    else{
                        usuario.comision = usuariosGrupo[findIndex].comision;
                    }
                    vue.usuariosEditar.push(usuario);
                });
                this.$refs.grupoDetalle.show();
                this.$forceUpdate();
            },
            editUsuarios(){
                this.$refs.usuariosGrupoForm.show();
                this.$refs.grupoDetalle.hide();
            },
            editGrupo: function () {
                this.$refs.grupoDetalle.hide();
                this.openModalGrupo();
            },
            nuevoGrupo: function () {
                this.grupo = Object.assign({}, {});
                this.indexEdit = -1;
                this.openModalGrupo();
            },
            openModalGrupo: function () {
                this.$refs.grupoForm.show();
            },
            closeModalGrupo: function () {
                this.$refs.grupoForm.hide();
                this.$refs.usuariosGrupoForm.hide();
            },
        }
    }
</script>

<style lang="scss">
    .input-comision{
        .form-group{
            .form-control{
                padding-top: 0;
                padding-bottom: 0;
                height: 30px;
            }
        }
    }
</style>

<style scoped lang="scss">

    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .btn-group button {
            background-color: #4CAF50; /* Green background */
            border: 1px solid green; /* Green border */
            color: white; /* White text */
            padding: 10px 24px; /* Some padding */
            cursor: pointer; /* Pointer/hand icon */
            width: 50%; /* Set a width if needed */
            display: block; /* Make the buttons appear below each other */
        }

        .btn-group button:not(:last-child) {
            border-bottom: none; /* Prevent double borders */
        }

        /* Add a background color on hover */
        .btn-group button:hover {
            background-color: #3e8e41;
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .privilegios-primero, .privilegios-segundo, .privilegios-tercero{
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .privilegios-segundo, .privilegios-tercero{
            padding-left: 10px;
        }

        .privilegios-primero{
            //margin-bottom: 10px;
            li{
                font-weight: bold;
                color: var(--color-primary);
                margin-bottom: 10px;
                label{
                    cursor: pointer;
                    display: block;
                    width: 100%;
                    margin: 0;
                    padding: 10px 15px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                }
                &.subprivilegio{
                    border: none;
                    padding-top: 0;
                    padding-right: 0;
                }
            }
        }
        .privilegios-segundo{
            li{
                font-weight: bold;
                color: #444;
            }
        }
        .privilegios-tercero{
            li{
                font-weight: 400;
                color: #444;
            }
        }

        .usuarios-lista{
            list-style: none;
            margin: 0;
            padding: 0;

            .usuario{
                cursor: pointer;
                display: inline-flex;
                width: 100%;
                align-items: center;
                margin-bottom: 10px;
                border: 1px solid #ccc;
                padding: 5px;
                border-radius: 5px;

                .check-input{
                    margin-left: 5px;
                    margin-right: 10px;
                }

                .nombre-usuario{
                    width: 100%;
                    p{
                        margin: 0;
                    }
                }

                .input-comision{
                    width: 120px;
                    .form-group{
                        margin: 0;
                        .form-control{
                            padding-top: 0;
                            padding-bottom: 0;
                            height: 30px;
                        }
                    }
                }
            }
        }

    }



</style>
