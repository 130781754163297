<template>
    <div class="form-group">
        <label v-if="title != null">{{ title }}</label>
        <div class="select-content" :class="{disabled: disabled}">
            <select :disabled="disabled" :required="required" :value="valueInput" class="form-control"
                    @input="update"
                    :name="name != null ? name : ''">
                <slot></slot>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormSelect',
        model: {

        },
        props: {
            title: String,
            name: null,
            required: Boolean(false),
            disabled: Boolean(false),
            valueInput: String('')
        },
        methods: {
            update: function (e) {
                this.$emit('update:valueInput', e.target.value);
                this.$emit('change', e);
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .select-content{

        border-radius: 50px;
        padding: 0 5px;
        height: calc(1.5em + .75rem + 2px);
        border: 1px solid #ced4da;
        background: #fff;

        &.disabled{
            background: #e9ecef;
        }

        .form-control[disabled]{
            background: #e9ecef;
        }

        .form-control{
            position: relative;
            top: 5px;
            padding-top: 0;
            padding-bottom: 0;
            outline: none;
            //height: ;
            height: calc(1.5em + .75rem - 10px);
            box-shadow: none;
            border: none;

        }
    }


</style>
