<template>
    <div id="root">
        <div v-if="checkPrivilege('notarios.crear')" class="button-add-bottom">
            <ButtonFloat v-on:click="nuevoNotario"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Fedatarios</h2>
            </div>
            <div class="buttons">
                <div v-if="filtroAnio != null" class="select-header">
                    <FormSelect :valueInput.sync="filtroAnioSelected"
                                @change="getNotarios()">
                        <option v-for="(item, index) in filtroAnio" :key="index"
                                :value="item.value">{{ item.title }}</option>
                    </FormSelect>
                </div>
                <div v-if="filtroAnio != null" class="select-header">
                    <FormSelect :valueInput.sync="filtroMesSelected"
                                @change="getNotarios()">
                        <option value="todos">Todos los meses</option>
                        <option value="01">Enero</option>
                        <option value="02">Febrero</option>
                        <option value="03">Marzo</option>
                        <option value="04">Abril</option>
                        <option value="05">Mayo</option>
                        <option value="06">Junio</option>
                        <option value="07">Julio</option>
                        <option value="08">Agosto</option>
                        <option value="09">Septiembre</option>
                        <option value="10">Octubre</option>
                        <option value="11">Noviembre</option>
                        <option value="12">Diciembre</option>
                    </FormSelect>
                </div>
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         @click="searchClick"
                                         table="fedatarios"
                                         title="Buscar" />
                </div>
                <div v-if="checkPrivilege('notarios.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="nuevoNotario" title="Nuevo +" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th style="width: 200px;">
                            No. Fedatario
                        </th>
                        <th>
                            Representante
                        </th>
                        <th class="table-column">
                            <h5>Proyección actos</h5>
                            <table>
                                <tr>
                                    <td>Constitutiva</td>
                                    <td>Protocolización</td>
                                </tr>
                            </table>
                        </th>
                        <th class="table-column">
                            <h5>Actos asignados</h5>
                            <table>
                                <tr>
                                    <td>Constitutiva</td>
                                    <td>Protocolización</td>
                                </tr>
                            </table>
                        </th>
                        <th class="table-column">
                            <h5>Actos restantes</h5>
                            <table>
                                <tr>
                                    <td>Constitutiva</td>
                                    <td>Protocolización</td>
                                </tr>
                            </table>
                        </th>
                        <th style="width: 20px;"></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-if="notarios.length === 0">
                            <td :colspan="headers.length" class="text-center">Sin resultados</td>
                        </tr>
                        <tr v-for="(item, index) in notarios" :key="index" v-on:click="detalleNotario(item)">
                            <td :class="headers[0].hide">{{ item.no_fedatario }}</td>
                            <td :class="headers[1].hide">{{ item.representante }}</td>
                            <td class="table-column table-info-list">
                                <table>
                                    <tr>
                                        <td>
                                            <span v-if="item.constitutiva_total != null">{{ item.constitutiva_total != null ? item.constitutiva_total : 0 }}</span>
                                            <span v-else>{{ item.actos_constitutiva_anio != null ? item.actos_constitutiva_anio : 0 }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item.protocolizacion_total != null">{{ item.protocolizacion_total != null ? item.protocolizacion_total : 0 }}</span>
                                            <span v-else>{{ item.actos_protocolizacion_anio != null ? item.actos_protocolizacion_anio : 0 }}</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="table-column table-info-list">
                                <table>
                                    <tr>
                                        <td>
                                            <span>{{ item.actos_constitutiva != null ? item.actos_constitutiva : 0 }}</span>
                                        </td>
                                        <td>
                                            <span>{{ item.actos_protocolizacion != null ? item.actos_protocolizacion : 0 }}</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td class="table-column table-info-list">
                                <table>
                                    <tr>
                                        <td>
                                            <span v-if="item.constitutiva_total != null">
                                                {{ item.constitutiva_total - item.actos_constitutiva | number }}
                                            </span>
                                            <span v-else>
                                                {{ item.actos_constitutiva_anio - item.actos_constitutiva | number }}
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="item.protocolizacion_total != null">
                                                {{ item.protocolizacion_total - item.actos_protocolizacion | number }}
                                            </span>
                                            <span v-else>
                                                {{ item.actos_protocolizacion_anio - item.actos_protocolizacion | number }}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td :class="headers[3].hide">
                                <a class="btn btn-circle btn-more">
                                    <i class="fas fa-eye"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>



        <ModalForm id="modalNotario"
                   ref="notarioForm"
                   title="Fedatario"
                   v-on:submit="saveNotario">
            <template v-slot:body>
                <input type="hidden" v-model="notario.id">

                <FormInput :valueInput.sync="notario.no_fedatario" :required="true"
                           name="no_fedatario" title="No. Fedatario" />

                <FormInput :valueInput.sync="notario.representante" :required="true"
                           name="representante"
                           title="Representante" />

                <FormInput :valueInput.sync="notario.telefono" :required="false"
                           name="telefono"
                           title="Teléfono" />

                <FormInput :valueInput.sync="notario.email" :required="false"
                           name="email"
                           type="email" title="Correo electrónico" />

                <div v-if="proyecciones[currentYear - 1] != undefined" class="presupuestos">
                    <hr>
                    <h5>Proyección de actos</h5>
                    <div class="group-year">
                        <h6>{{ (currentYear - 1) }}</h6>
                        <div class="item-year">
                            <FormInput :required="false" title="Constitutiva"
                                       placeholder="Proyección"
                                       :valueInput.sync="proyecciones[currentYear - 1].constitutiva"/>
                        </div>
                        <div class="item-year">
                            <FormInput :required="false" title="Protocolización"
                                       placeholder="Proyección"
                                       :valueInput.sync="proyecciones[currentYear - 1].protocolonizacion"/>
                        </div>
                    </div>
                    <div class="group-year">
                        <h6>{{ currentYear }}</h6>
                        <div class="item-year">
                            <FormInput :required="false" title="Constitutiva"
                                       placeholder="Proyección"
                                       :valueInput.sync="proyecciones[currentYear].constitutiva"/>
                        </div>
                        <div class="item-year">
                            <FormInput :required="false" title="Protocolización"
                                       placeholder="Proyección"
                                       :valueInput.sync="proyecciones[currentYear].protocolonizacion"/>
                        </div>
                    </div>
                    <div v-for="i in 3" :key="i" class="group-year">
                        <h6>{{ (currentYear + i) }}</h6>
                        <div class="item-year">
                            <FormInput :required="false" title="Constitutiva"
                                       placeholder="Proyección"
                                       :valueInput.sync="proyecciones[currentYear + i].constitutiva"/>
                        </div>
                        <div class="item-year">
                            <FormInput :required="false" title="Protocolización"
                                       placeholder="Proyección"
                                       :valueInput.sync="proyecciones[currentYear + i].protocolonizacion"/>
                        </div>
                    </div>
                </div>

                <div v-if="checkPrivilege('notarios.precios') || checkPrivilege('notarios.precios') ||
                            checkPrivilege('notarios.precios') || checkPrivilege('notarios.precios') ||
                            checkPrivilege('notarios.precios')">
                    <hr>
                    <FormInput v-if="checkPrivilege('notarios.precios')" :valueInput.sync="notario.costo_constitucion" :required="true"
                               name="costo_constitucion"
                               type="number" title="Costo constitución" placeholder="0.0" />

                    <FormInput v-if="checkPrivilege('notarios.precios')" :valueInput.sync="notario.costo_protocolizacion" :required="true"
                               name="costo_protocolizacion"
                               type="number" title="Costo protocolización" placeholder="0.0" />

                    <FormInput v-if="checkPrivilege('notarios.precios')" :valueInput.sync="notario.costo_poder" :required="true"
                               name="costo_poder"
                               type="number" title="Costo poder" placeholder="0.0" />

                    <FormInput v-if="checkPrivilege('notarios.precios')" :valueInput.sync="notario.costo_segundo_testimonio" :required="true"
                               name="costo_segundo_testimonio"
                               type="number" title="Costo segundo testimonio" placeholder="0.0" />

                    <FormInput v-if="checkPrivilege('notarios.precios')" :valueInput.sync="notario.costo_copias_certificadas" :required="true"
                               name="costo_copias_certificadas"
                               type="number" title="Costo copias certificadas" placeholder="0.0" />
                </div>



            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonNotario" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>

        <ModalDetail ref="notarioDetalle" title="Fedatario Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>No. Fedatario</b>
                        </td>
                        <td>
                            <span>{{ notario.no_fedatario }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Representante</b>
                        </td>
                        <td>
                            <span>{{ notario.representante }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Teléfono</b>
                        </td>
                        <td>
                            <span>{{ notario.telefono }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Correo Electrónico</b>
                        </td>
                        <td>
                            <span>{{ notario.email }}</span>
                        </td>
                    </tr>
                    <tr v-if="notario.proyecciones != null && notario.proyecciones.length > 0">
                        <td colspan="2">
                            <b>Proyección de actos</b>
                        </td>
                    </tr>
                    <tr v-for="(item, index) in notario.proyecciones" :key="index">
                        <td>
                            <b>Constitutiva {{ item.anio }}:</b> {{ item.actos_constitutiva_anio }}
                        </td>
                        <td>
                            <b>Protocolización {{ item.anio }}:</b> {{ item.actos_protocolizacion_anio }}
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('notarios.precios')">
                        <td>
                            <b>Costo constitución</b>
                        </td>
                        <td>
                            <span>{{ notario.costo_constitucion | toCurrency  }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('notarios.precios')">
                        <td>
                            <b>Costo protocolización</b>
                        </td>
                        <td>
                            <span>{{ notario.costo_protocolizacion | toCurrency  }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('notarios.precios')">
                        <td>
                            <b>Costo poder</b>
                        </td>
                        <td>
                            <span>{{ notario.costo_poder | toCurrency  }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('notarios.precios')">
                        <td>
                            <b>Costo segundo testimonio</b>
                        </td>
                        <td>
                            <span>{{ notario.costo_segundo_testimonio | toCurrency  }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('notarios.precios')">
                        <td>
                            <b>Costo copias certificadas</b>
                        </td>
                        <td>
                            <span>{{ notario.costo_copias_certificadas | toCurrency  }}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="checkPrivilege('notarios.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editNotario"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div v-if="checkPrivilege('notarios.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteNotario"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>

    </div>
</template>

<script>
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ModalForm from '@/components/Modal/ModalForm'
    import FormInput from '@/components/Form/FormInput'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import FormSelect from '@/components/Form/FormSelect'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'NotariosScreen',
        components: {
            FormSelect,
            ButtonFloat,
            InputSearchComplete,
            FormInput,
            ButtonAdd,
            ModalForm,
            ModalDetail,
            ButtonLoader,
            ModalButtonFooter
        },
        filters: {
            toCurrency: function (value) {
                if(value === null || value === undefined){
                    return "$ 0.00";
                }
                if (typeof value !== "number") {
                    return value;
                }
                var formatter = new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            }
        },
        data: function(){
            return {
                filtroAnio: null,
                filtroAnioSelected: null,
                filtroMesSelected: null,
                currentYear: 0,
                page: 1,
                lastPage: 1,
                headers: [
                    {text: 'No. Fedatario', align: 'left', value: 'fedatario'},
                    {text: 'Representante', align: 'left', value: 'representante'},
                    {text: 'Teléfono', value: 'telefono'},
                    // {text: 'Proyección', value: 'proyeccion', hide: 'text-center'},
                    // {text: 'Ingresos', value: 'ingresos', hide: 'text-center'},
                    // {text: 'Restante', value: 'restante', hide: 'text-center'},
                    {text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtro: '',
                notarios: [],
                indexEdit: -1,
                notario: {},
                proyecciones: {}
            }
        },
        mounted() {
            this.currentYear = new Date().getFullYear();
            this.getNotarios();
        },
        methods: {
            searchClick(){
                this.first(true);
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.getNotarios();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.getNotarios();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.getNotarios();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.getNotarios();
                }
                else if(refresh){
                    this.page = 1;
                    this.getNotarios();
                }
            },
            getNotarios: function () {
                let vue = this;
                vue.notarios = [];
                this.$emit("showLoader");
                let data = {page: this.page, filtro: this.filtro, filtroAnioSelected: this.filtroAnioSelected, filtroMesSelected: this.filtroMesSelected};
                $.get(this.API_URL + "/api/notarios/getPage", data, function (response) {
                    vue.$emit("hideLoader");
                    if (response.success) {
                        let firstYear = response.first_year;
                        let lastYear = response.last_year;
                        let reloadFiltroYear = true;
                        if(firstYear != null && lastYear != null){
                            vue.filtroAnio = [];
                            for(let i = firstYear; i <= lastYear; i++){
                                vue.filtroAnio.push({value: i, title: i});
                                if(reloadFiltroYear && i == vue.currentYear){
                                    reloadFiltroYear = false;
                                }
                            }
                        }
                        if(vue.filtroAnioSelected == null && vue.filtroAnio != null
                            && vue.filtroAnio.length > 0 && reloadFiltroYear){
                            vue.filtroAnioSelected =  vue.filtroAnio[vue.filtroAnio.length - 1].value;
                            vue.filtroMesSelected = 'todos';
                            vue.getNotarios();
                        }
                        else{
                            if(vue.filtroAnioSelected == null){
                                vue.filtroAnioSelected = response.filtro_anio_selected;
                            }
                            if(vue.filtroMesSelected == null){
                                vue.filtroMesSelected = response.filtro_mes_selected;
                            }
                            vue.page = response.notarios.current_page;
                            vue.lastPage = response.notarios.last_page;
                            vue.notarios = response.notarios.data;
                        }
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            nuevoNotario: function () {
                this.notario = {};
                this.indexEdit = -1;
                this.proyecciones = {};

                for(let i = -1; i <= 3; i++){
                    this.proyecciones[this.currentYear + i] = {};
                }

                this.proyecciones = Object.assign({}, this.proyecciones);

                this.openModalNotario();
            },
            editNotario: function () {
                this.$refs.notarioDetalle.hide();
                this.openModalNotario();
            },
            openModalNotario: function () {
                this.$refs.notarioForm.show();
            },
            closeModalNotario: function(){
                this.$refs.notarioForm.hide();
            },
            detalleNotario: function (item) {
                let vue = this;
                this.indexEdit = this.notarios.indexOf(item);
                this.notario = Object.assign({}, item);

                this.proyecciones = {};

                for(let i = -1; i <= 3; i++){
                    this.proyecciones[this.currentYear + i] = {};
                }

                if(this.notario.proyecciones != null){
                    Object.assign([], this.notario.proyecciones).forEach((item) => {
                        vue.proyecciones[item.anio].constitutiva = item.actos_constitutiva_anio;
                        vue.proyecciones[item.anio].protocolonizacion = item.actos_protocolizacion_anio;
                    });
                }

                this.proyecciones = Object.assign({}, this.proyecciones);

                this.$refs.notarioDetalle.show();
            },
            saveNotario: function (e) {
                e.preventDefault();

                let vue = this;

                vue.$refs.buttonNotario.setShowLoader(true);

                vue.notario.proyecciones_edit = vue.proyecciones;

                $.post(this.API_URL + "/api/notarios/save", this.notario, function (response) {
                    vue.$refs.buttonNotario.setShowLoader(false);
                    if(response.success){
                        vue.notario = response.record;

                        if (vue.indexEdit > -1) {
                            Object.assign(vue.notarios[vue.indexEdit], vue.notario)
                        } else {
                            vue.notarios.splice(0, 0, vue.notario)
                        }
                        vue.notario = {};
                        vue.indexEdit = -1;
                        vue.closeModalNotario();

                        if(response.reload){
                            vue.filtroAnioSelected = null;
                            vue.filtroMesSelected = null;
                        }

                        vue.getNotarios();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    vue.$refs.buttonNotario.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            deleteNotario: function () {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/notarios/delete", vue.notario, function (response) {
                        if(response.success){
                            vue.notarios.splice(vue.indexEdit, 1);
                            vue.notario = {};
                            vue.$refs.notarioDetalle.hide();

                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });
            },
        }
    }
</script>

<style lang="scss">
.select-header{
    margin-right: 10px;
    display: inline-block;
    .form-group{
        margin-bottom: 0;
        .select-content{
            height: 32px;
            select{
                background: transparent;
                top: 0;
                height: 31px;
                padding-top: 0;
                padding-bottom: 0;
                font-size: 12px;
            }
        }
    }
}
</style>

<style scoped lang="scss">

    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .button-save-content{
            width: 120px;
        }

        .presupuestos{
            width: 100%;
            h5{
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                color: #2c3e50;
            }
            h6{
                width: 100%;
                font-size: 12px;
                font-weight: bold;
                color: #2c3e50;
                margin-bottom: 2px;
            }
            .group-year{
                display: flex;
                flex-wrap: wrap;
                gap: 0 10px;
                .item-year{
                    flex: 1 1 calc(50% - 10px);
                }
            }
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .table-column{
            text-align: center;
            padding: 0 5px;
            width: 200px;
            h5{
                font-size: 12px;
                font-weight: bold;
                color: #000;
                margin-bottom: 0;
            }
            table{
                width: 200px;
                tr{
                    border: none;
                    td{
                        width: 50%;
                        font-size: 12px;
                        border: none;
                    }
                }
            }
            &.table-info-list{
                table{
                    tr{
                        td{
                            font-size: 14px;
                        }
                    }
                }
            }
        }

    }



</style>
