<template>
    <div>

        <div :class="'shadow-mask ' + openMenu" v-on:click="toggleMenu"></div>

        <div class="logo-menu">
            <div class="menu-toggle" v-on:click="toggleMenu">
                <div :class="'hamburger ' + openMenu">
                    <div class="hamburger-inner"></div>
                </div>
            </div>

            <span class="logo-title">
                <img src="@/assets/logo-web.png">
            </span>
        </div>

        <div :class="'nav-left ' + openMenu ">
            <div class="shadow"></div>
            <div class="nav-content">
                <slot></slot>
            </div>
        </div>

    </div>

</template>

<script>



export default {
    name: 'MenuLateral',
    components: {
    },
    model: {

    },
    props: {
        logo: null,
        color: null,
        textColor: null,
    },
    data: function(){
        return {
            openMenu: '',
        }
    },
    methods: {
        toggleMenu: function () {
            if(this.openMenu === ''){
                this.openMenu = "open";
            }
            else{
                this.openMenu = "";
            }
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /*NAVIGATION BAR */

  .overflow-y-hidden{
    overflow-y: hidden;
  }

  /* ---------- MENU TOP ----------- */

  .logo-menu{
      position: fixed;
      width: 220px;
      background: rgba(46, 30, 41, 1);
      height: 70px;
      left: 0;
      top: 0;
      z-index: 1023;
      padding: 0 10px;
      transition-duration: 0.3s;

      .logo-title{
        position: absolute;
        top: 50%;
        left: calc(50% - 5px);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: #fff;

          img{
              height: 45px;
          }

      }

      .menu-toggle{
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          padding: 10px;
          display: none;
          .hamburger {
              height: 23px;
              width: 30px;

              .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
                  background-color: #fff;
                  position: absolute;
                  width: 30px;
                  height: 3px;
                  border-radius: 5px;
                  content: '';
                  transition-timing-function: ease;
                  transition-duration: .2s;
                  transition-property: transform,opacity;
              }

              .hamburger-inner:before {
                  top: 10px;
              }

              .hamburger-inner:after {
                  top: 20px;
              }

          }

          .hamburger.open{
              .hamburger-inner {
                  transform: translate3d(0, 10px, 0) rotate(45deg);
              }
          }
          .hamburger.open {
              .hamburger-inner:after {
                  transform: translate3d(0,-20px,0) rotate(-90deg);
              }
          }
          .hamburger.open{
              .hamburger-inner:before {
                  transform: translate3d(0,-10px,0) rotate(90deg);
              }
          }
      }



      @media all and (max-width: 1200px) {
          width: 100%;
          height: 55px;

          .logo-title{
              img{
                  height: 40px;
              }
          }

          .menu-toggle{
              display: inline-block;
          }

      }

  }

  /* ---------- MENU LEFT ----------- */

  .shadow-mask{
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.4);
      z-index: 1022;
      visibility: hidden;
      opacity: 0;
      transition-duration: 0.5s;

      @media all and (max-width: 1200px) {
          &.open{
              opacity: 1;
              visibility: visible;
          }
      }

  }

  .nav-left{
    width: 220px;
    background: rgba(46, 30, 41, 1);
    position: fixed;
    left: 0;
    top: 0;
    height: calc(100% - 70px);
    font-family: 'Roboto', sans-serif;
    transition-duration: 0.3s;
    z-index: 1023;
    margin-top: 70px;
    /*padding-bottom: 140px;*/
    * {
        color: #fff;
    }

    .nav-options{
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .nav-content{
      width: 100%;
      height: 100%;
      max-height: 100%;
      padding-bottom: 20px;
      overflow-y: auto;

        &::-webkit-scrollbar {
            width: 12px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: rgba(0,0,0,0.2);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #555;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #444;
        }

    }

    @media all and (max-width: 1200px) {
        height: calc(100% - 55px);
        margin-top: 55px;
        left: -220px;
        &.open{
            left: 0;
        }
    }

  }
  /*------------ END KEYFRAMES  --------------*/
</style>
