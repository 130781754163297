<template>
  <div class="form-group">
    <div>
      <label :class="{margin: title != undefined}">
        <input v-model="check" type="checkbox"
               ref="checkboxInput"
               v-on:click="change"
               :name="name != null ? name : ''"/>
          <span><!-- This span is needed to create the "checkbox" element --></span>{{ title }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
    name: 'FormCheck',
    props: {
        title: String,
        name: null,
        required: null,
        valueInput: null,
        valueNotChecked: null,
        checked: null
    },
    data(){
        return{
          check: this.checked != undefined ? (this.checked != null ? this.checked : false) :
              (this.valueInput != null ? this.valueInput : false)
        }
    },
    watch: {
        valueInput: function () {
            this.check = this.valueInput;
        },
        // valor: function () {
        //     this.check = this.valor;
        // },
    },
    mounted() {
        this.$emit('input', this.check ? this.check : null);
        this.$emit('update:valueInput', this.valueInput);
    },
    methods: {
        handleInput () {
            this.$emit('input', this.valor)
        },
        change (){
            this.check = !this.check;
            if(this.check){
                this.$emit('input', this.check);
            }
            else{
                this.$emit('input', this.valueNotChecked != null ? this.valueNotChecked : null);
            }
            this.$emit('update:valueInput', this.check);
        },
        setChecked: function (check) {
            this.check = check;
        },
        getChecked(){
            return this.check;
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .form-group{
    display: inline-block;
    margin: 0;
  }

  label {
    display: inline-block; // to make it easier to click
    color: #333;
    cursor: pointer;
    position: relative; // important

    // Now we'll create the checkbox object

    &.margin{
      span{
        margin-right: 10px;
      }
    }

    span {
      display: inline-block;
      position: relative;
      background-color: transparent;
      width: 25px;
      height: 25px;
      transform-origin: center;
      border: 2px solid rgba(46, 30, 41, 1);
      border-radius: 50%;
      vertical-align: -6px;
      transition: background-color 150ms 200ms, transform 350ms cubic-bezier(.78,-1.22,.17,1.89); // custom ease effect for bouncy animation

      // Now we'll create the "tick" using pseudo elements - those will be basically two lines that will be rotated to form the "tick"

      &:before {
        content: "";
        width: 0px;
        height: 2px;
        border-radius: 2px; // so that the tick has nice rounded look
        background: rgba(46, 30, 41, 1);
        position: absolute;
        transform: rotate(45deg);
        top: 11px; // you'll need to experiment with placement depending on the dimensions you've chosen
        left: 6px; // you'll need to experiment with placement depending on the dimensions you've chosen
        transition: width 50ms ease 50ms;
        transform-origin: 0% 0%;
      }

      &:after {
        content: "";
        width: 0;
        height: 2px;
        border-radius: 2px; // so that the tick has nice rounded look
        background: rgba(46, 30, 41, 1);
        position: absolute;
        transform: rotate(305deg);
        top: 14px; // you'll need to experiment with placement depending on the dimensions you've chosen
        left: 8px; // you'll need to experiment with placement depending on the dimensions you've chosen
        transition: width 50ms ease;
        transform-origin: 0% 0%;
      }
    }
    // Time to add some life to it

    &:hover {
      span {
        &:before {
          width: 5px;
          transition: width 100ms ease;
        }

        &:after {
          width: 10px;
          transition: width 150ms ease 100ms;
        }
      }
    }
  }

  input[type="checkbox"] {
    display: none; // hide the system checkbox

    // Let's add some effects after the checkbox is checked

    &:checked {
      +
      span {
        background-color: rgba(46, 30, 41, 1);
        //transform: scale(1.25); // enlarge the box

        &:after {
          width: 10px;
          background: #ffffff;
          transition: width 150ms ease 100ms; // enlarge the tick
        }

        &:before {
          width: 5px;
          background: #ffffff;
          transition: width 150ms ease 100ms; // enlarge the tick
        }
      }

      &:hover { // copy the states for onMouseOver to avoid flickering
        span {
          background-color: rgba(46, 30, 41, 1);
          /*transform: scale(1.25); // enlarge the box*/

          &:after {
            width: 10px;
            background: #fff;
            transition: width 150ms ease 100ms; // enlarge the tick
          }

          &:before {
            width: 5px;
            background: #ffffff;
            transition: width 150ms ease 100ms; // enlarge the tick
          }
        }
      }
    }
  }

</style>
