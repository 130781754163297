<template>
    <div id="root" v-if="checkPrivilege('dashboard')">
        <div class="header-section">
            <div class="title">
                <h2>Dashboard</h2>
            </div>
        </div>


        <div v-if="checkPrivilege('dashboard.cuentas')" class="reporte-utilidad">
            <div class="header">
                <h2>Reporte de Utilidad</h2>
                <div class="buscador-content">
                    <DateRangePicker :icons="true"
                                     :fija="true"
                                     :dateInicio.sync="fechaInicio"
                                     :dateFin.sync="fechaFin"
                                     placeholderInicio="Fecha inicio"
                                     placeholderFin="Fecha fin"
                                     format="DD-MM-YYYY"
                                     ref="fechas"
                                     v-on:change="getCuentaCostos"/>
                </div>
            </div>

            <div id="utilidad_chart" style="width: 100%; height: 450px"></div>

            <div class="footer">
                <div class="totales-periodo">
                    <h5>Totales del periodo</h5>

                    <div class="row content-cuentas">
                        <div class="col-sm-4 item">
                            <div class="content">
                                <div class="title">
                                    <h2>Ingresos: <span>{{ ingresos | toCurrency }}</span></h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 item">
                            <div class="content">
                                <div class="title">
                                    <h2>Gastos: <span>{{ gastos | toCurrency }}</span></h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 item">
                            <div class="content">
                                <div class="title">
                                    <h2>Utilidad: <span>{{ (ingresos - gastos) | toCurrency }}</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="checkPrivilege('dashboard.cuentas')" class="reporte-utilidad" style="padding-bottom: 10px;">
            <div class="header">
                <h2>Gastos por categoría</h2>
                <div class="buscador-content">
                    <DateRangePicker :icons="true"
                                     :fija="true"
                                     :dateInicio.sync="fechaInicioGastos"
                                     :dateFin.sync="fechaFinGastos"
                                     placeholderInicio="Fecha inicio"
                                     placeholderFin="Fecha fin"
                                     format="DD-MM-YYYY"
                                     ref="fechasGastos"
                                     v-on:change="getCategoriasCostos"/>
                </div>
            </div>
            <div id="categorias_chart" style="width: 100%; height: 450px"></div>
            <div class="footer">
                <div class="totales-periodo">
                    <h5>Total en gastos: {{ gastosCategorias | toCurrency }}</h5>
                </div>

            </div>
        </div>

        <div v-if="checkPrivilege('dashboard.cuentas')" class="reporte-utilidad" style="padding-bottom: 10px;">
            <div class="header">
                <h2>Ingresos por categoría</h2>
                <div class="buscador-content">
                    <DateRangePicker :icons="true"
                                     :fija="true"
                                     :dateInicio.sync="fechaInicioIngresos"
                                     :dateFin.sync="fechaFinIngresos"
                                     placeholderInicio="Fecha inicio"
                                     placeholderFin="Fecha fin"
                                     format="DD-MM-YYYY"
                                     ref="fechasIngresos"
                                     v-on:change="getCategoriasIngresos"/>
                </div>
            </div>
            <div id="categorias_ingresos_chart" style="width: 100%; height: 450px"></div>
            <div class="footer">
                <div class="totales-periodo">
                    <h5>Total en ingresos: {{ ingresosCategorias | toCurrency }}</h5>
                </div>

            </div>
        </div>


        <div class="content-section">

            <div class="content-actos">

                <div class="header-caso">
                    <h2>
                        Actos sin precio
                    </h2>
                    <div class="buscador-content">
                        <InputSearchComplete :valueInput.sync="filtroPrecio"
                                             align="right"
                                             table="empresas"
                                             @click="firstPrecio(true)"
                                             title="Buscar" />
                    </div>
                </div>

                <div class="data-section with-header">
                    <table class="table-section clickeable">
                        <thead>
                        <tr>
                            <th v-for="(item, index) in headersActosPrecios" :width="item.width"
                                :key="index"
                                :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                                {{ item.text }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="actosPrecio.length === 0">
                            <td :colspan="headersActosPrecios.length" class="text-center">Sin resultados</td>
                        </tr>
                        <tr v-for="(item, index) in actosPrecio" :key="index" v-on:click="detalleActoPrecio(item)">
                            <td :class="headersActosPrecios[0].hide">
                                {{ item.nombre }}
                            </td>
                            <td :class="headersActosPrecios[1].hide">
                                <span v-if="item.notario != null">{{ item.notario.no_fedatario }}</span>
                                <span v-else>--</span>
                            </td>
                            <td :class="headersActosPrecios[2].hide">
                                <span v-if="item.estado != null">
                                    {{ item.estado }}
                                </span>
                                <span v-else>
                                    En proceso
                                </span>
                            </td>
                            <td :class="headersActosPrecios[3].hide">
                                <span v-if="item.fecha_fin != null">
                                    <i class="fas fa-check"></i>
                                </span>
                                <span v-else :class="'status ' + getStyle(item.dias_faltantes)" >
                                    {{ item.dias_faltantes }} d
                                </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="footer">
                        <div class="paginater">
                            <div class="text">
                                <p>
                                    {{ pagePrecio }} de {{ lastPagePrecio }}
                                </p>
                            </div>
                            <div class="buttons">
                                <button v-on:click="firstPrecio" class="first" v-bind:class="{inactive: pagePrecio == 1}">
                                    <i class="fas fa-angle-double-left"></i>
                                </button>
                                <button v-on:click="prevPrecio" v-bind:class="{inactive: pagePrecio == 1}">
                                    <i class="fas fa-angle-left"></i>
                                </button>
                                <button v-on:click="nextPrecio" v-bind:class="{inactive: pagePrecio >= lastPagePrecio}">
                                    <i class="fas fa-angle-right"></i>
                                </button>
                                <button v-on:click="lastPrecio" class="last" v-bind:class="{inactive: pagePrecio >= lastPagePrecio}">
                                    <i class="fas fa-angle-double-right"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>


            <div class="content-actos">

                <div class="header-caso">
                    <h2>
                        Actos pendientes
                    </h2>
                    <div class="buscador-content">
                        <InputSearchComplete :valueInput.sync="filtroPendientes"
                                             align="right"
                                             table="empresas"
                                             @click="first(true)"
                                             title="Buscar" />
                    </div>
                </div>

                <div class="data-section with-header">
                    <table class="table-section clickeable">
                        <thead>
                        <tr>
                            <th v-for="(item, index) in headersPendientes" :width="item.width"
                                :key="index"
                                :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                                {{ item.text }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="actosPendientes.length === 0">
                            <td :colspan="headersPendientes.length" class="text-center">Sin resultados</td>
                        </tr>
                        <tr v-for="(item, index) in actosPendientes" :key="index" v-on:click="detalleActo(item)">
                            <td :class="headersPendientes[0].hide">
                                {{ item.nombre }}
                            </td>
                            <td :class="headersPendientes[1].hide">
                                <span v-if="item.notario != null">{{ item.notario.no_fedatario }}</span>
                                <span v-else>--</span>
                            </td>
                            <td :class="headersPendientes[2].hide">
                                <span v-if="item.estado != null">
                                    {{ item.estado }}
                                </span>
                                <span v-else>
                                    En proceso
                                </span>
                            </td>
                            <td :class="headersPendientes[3].hide">
                                <span v-if="item.fecha_fin != null">
                                    <i class="fas fa-check"></i>
                                </span>
                                <span v-else :class="'status ' + getStyle(item.dias_faltantes)" >
                                    {{ item.dias_faltantes }} d
                                </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="footer">
                        <div class="paginater">
                            <div class="text">
                                <p>
                                    {{ page }} de {{ lastPage }}
                                </p>
                            </div>
                            <div class="buttons">
                                <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                    <i class="fas fa-angle-double-left"></i>
                                </button>
                                <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                    <i class="fas fa-angle-left"></i>
                                </button>
                                <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                    <i class="fas fa-angle-right"></i>
                                </button>
                                <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                    <i class="fas fa-angle-double-right"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>


            <div class="content-actos">
                <div class="header-caso">
                    <h2>
                        Últimos Actos finalizados
                    </h2>
                </div>
                <div class="data-section with-header">
                    <table class="table-section clickeable">
                        <thead>
                        <tr>
                            <th v-for="(item, index) in headersFinalizados" :width="item.width"
                                :key="index"
                                :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                                {{ item.text }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="actosFinalizados.length === 0">
                            <td :colspan="headersFinalizados.length" class="text-center">Sin resultados</td>
                        </tr>
                        <tr v-for="(item, index) in actosFinalizados" :key="index">
                            <td :class="headersFinalizados[0].hide" v-on:click="detalleActoFinalizado(item)">
                                <span v-if="item.empresa != null">{{ item.nombre }}</span>
                                <span v-else>--</span>
                            </td>

                            <td v-if="checkPrivilege('dashboard.precios')" :class="headersFinalizados[1].hide">
                                <a :href="'/actos-conceptos-ingresos_'+item.id" target="_blank" class="normal-link">
                                    {{ item.abonos | toCurrency }}
                                </a>
                            </td>

                            <td v-if="checkPrivilege('dashboard.precios')" :class="headersFinalizados[2].hide">
                                <a :href="'/actos-conceptos-gastos_'+item.id" target="_blank" class="normal-link">
                                    {{ item.gastos | toCurrency }}
                                </a>
                            </td>

                            <td v-if="checkPrivilege('dashboard.precios')" :class="headersFinalizados[3].hide">
                                <a :href="'/actos-conceptos-utilidad_'+item.id" target="_blank" class="normal-link">
                                    {{ (item.abonos - item.gastos) | toCurrency }}
                                </a>
                            </td>

                            <td v-if="!checkPrivilege('dashboard.precios')" :class="headersFinalizados[1].hide"
                                v-on:click="detalleActoFinalizado(item)">
                                <span v-if="item.notario != null">{{ item.notario.no_fedatario }}</span>
                                <span v-else>--</span>
                            </td>

                            <td v-if="!checkPrivilege('dashboard.precios')" :class="headersFinalizados[2].hide"
                                v-on:click="detalleActoFinalizado(item)">
                                {{ item.fecha_fin | dateFormat }}
                            </td>

                        </tr>
                        </tbody>
                    </table>
                    <div class="footer">
                        <div class="buttons-link">
                            <router-link :to="{name: 'reporteActos', params: {estatus: 'pendientes'}}">Ver todos</router-link >
                        </div>
                    </div>
                </div>
            </div>



        </div>


        <ModalDetail ref="actoFinalizado" title="Acto Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Empresa</b>
                        </td>
                        <td>
                            <span v-if="acto.empresa != null">{{ acto.empresa.nombre_completo }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fedatario</b>
                        </td>
                        <td>
                            <span v-if="acto.notario != null">{{ acto.notario.no_fedatario }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Servicio</b>
                        </td>
                        <td>
                            <span v-if="acto.servicio != null">{{ acto.servicio.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="acto.categoria != null">
                        <td>
                            <b>Categoría de ingresos</b>
                        </td>
                        <td>
                            <span>{{ acto.categoria.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha de inicio</b>
                        </td>
                        <td>
                            <span>{{ acto.fecha_inicio | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha de fin</b>
                        </td>
                        <td>
                            <span>{{ acto.fecha_fin | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Precio de venta</b>
                        </td>
                        <td>
                            <span>{{ acto.precio | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('dashboard.precios')">
                        <td>
                            <b>Ingresos</b>
                        </td>
                        <td>
                            <a :href="'/actos-conceptos-ingresos_'+acto.id" target="_blank" class="normal-link">
                                {{ acto.abonos | toCurrency }}
                            </a>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('dashboard.precios')">
                        <td>
                            <b>Gastos</b>
                        </td>
                        <td>
                            <a :href="'/actos-conceptos-gastos_'+acto.id" target="_blank" class="normal-link">
                                {{ acto.gastos | toCurrency }}
                            </a>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('dashboard.precios')">
                        <td>
                            <b>Utilidad</b>
                        </td>
                        <td>
                            <a :href="'/actos-conceptos-utilidad_'+acto.id" target="_blank" class="normal-link">
                                {{ (acto.abonos - acto.gastos) | toCurrency }}
                            </a>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </template>
        </ModalDetail>

        <ModalDetail ref="actoDetalle" title="Acto Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Empresa</b>
                        </td>
                        <td>
                            <span v-if="acto.empresa != null">{{ acto.empresa.nombre_completo }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fedatario</b>
                        </td>
                        <td>
                            <span v-if="acto.notario != null">{{ acto.notario.no_fedatario }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Servicio</b>
                        </td>
                        <td>
                            <span v-if="acto.servicio != null">{{ acto.servicio.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="acto.categoria != null">
                        <td>
                            <b>Categoría de ingresos</b>
                        </td>
                        <td>
                            <span>{{ acto.categoria.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Días estimados</b>
                        </td>
                        <td>
                            <span>{{ acto.dias_estimados }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Estatus</b>
                        </td>
                        <td>
                            <span v-if="acto.fecha_fin != null">
                                <i class="fas fa-check"></i>
                            </span>
                            <span v-else :class="'status ' + getStyle(acto.dias_faltantes)" >
                                {{ acto.dias_faltantes }} d
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha de inicio</b>
                        </td>
                        <td>
                            <span>{{ acto.fecha_inicio | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha de fin</b>
                        </td>
                        <td>
                            <span>{{ acto.fecha_fin | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Último evento</b>
                        </td>
                        <td>
                            <span>
                                {{ acto.estado != null ? acto.estado : 'En proceso' }}
                            </span>
                        </td>
                    </tr>

                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Precio de venta</b>
                        </td>
                        <td>
                            <span>{{ acto.precio | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Total en abonos</b>
                        </td>
                        <td>
                            <span>{{ acto.abonos  | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Saldo por cobrar</b>
                        </td>
                        <td>
                            <span>{{ acto.cobrar  | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Total en gastos</b>
                        </td>
                        <td>
                            <span>{{ acto.gastos | toCurrency }}</span>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </template>
        </ModalDetail>

        <ModalDetail ref="actoPrecioDetalle" title="Acto Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Empresa</b>
                        </td>
                        <td>
                            <span v-if="actoPrecio.empresa != null">{{ actoPrecio.empresa.nombre_completo }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fedatario</b>
                        </td>
                        <td>
                            <span v-if="actoPrecio.notario != null">{{ actoPrecio.notario.no_fedatario }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Servicio</b>
                        </td>
                        <td>
                            <span v-if="actoPrecio.servicio != null">{{ actoPrecio.servicio.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="actoPrecio.categoria != null">
                        <td>
                            <b>Categoría de ingresos</b>
                        </td>
                        <td>
                            <span>{{ actoPrecio.categoria.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Días estimados</b>
                        </td>
                        <td>
                            <span>{{ actoPrecio.dias_estimados }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Estatus</b>
                        </td>
                        <td>
                            <span v-if="actoPrecio.fecha_fin != null">
                                <i class="fas fa-check"></i>
                            </span>
                            <span v-else :class="'status ' + getStyle(actoPrecio.dias_faltantes)" >
                                {{ actoPrecio.dias_faltantes }} d
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha de inicio</b>
                        </td>
                        <td>
                            <span>{{ actoPrecio.fecha_inicio | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha de fin</b>
                        </td>
                        <td>
                            <span>{{ actoPrecio.fecha_fin | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Último evento</b>
                        </td>
                        <td>
                            <span>
                                {{ actoPrecio.estado != null ? actoPrecio.estado : 'En proceso' }}
                            </span>
                        </td>
                    </tr>

                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Precio de venta</b>
                        </td>
                        <td>
                            <span>{{ actoPrecio.precio | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Total en abonos</b>
                        </td>
                        <td>
                            <span>{{ actoPrecio.abonos  | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Saldo por cobrar</b>
                        </td>
                        <td>
                            <span>{{ actoPrecio.cobrar  | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Total en gastos</b>
                        </td>
                        <td>
                            <span>{{ actoPrecio.gastos | toCurrency }}</span>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </template>
            <template v-slot:footer>
                <div class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editActoPrecio"
                                       color="#fff"
                                       :disabled="!isEdicion()"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>
            </template>
        </ModalDetail>

        <ModalForm id="modalActo"
                   ref="actoForm"
                   title="Acto"
                   v-on:submit="saveActo">
            <template v-slot:body>
                <input type="hidden" v-model="actoPrecio.id">

                <ComboBoxSearchUrl ref="empresas" title="Empresa"
                                   :url="API_URL+'/api/empresas?precios=true'"
                                   placeholder="Selecciona una empresa"/>

                <ComboBoxSearch ref="notarios" title="Fedatario"
                                placeholder="Selecciona un fedatario"/>

                <ComboBoxSearch ref="servicios" title="Servicio"
                                v-on:change="changeServicio"
                                placeholder="Selecciona el servicio"/>


                <DatePicker styleClass="form-control" title="Fecha de inicio" :date.sync="actoPrecio.fecha_inicio"
                            placeholder="dd-mm-yyyy"
                            :limitDate="!checkPrivilege('ignorar-fecha')"
                            format="DD-MM-YYYY"
                            ref="fechaInicio" />

                <FormInput :valueInput.sync="actoPrecio.dias_estimados"
                           :required="true"
                           type="number"
                           ref="duracion"
                           name="dias" title="Días estimados"
                           placeholder="0"/>

                <FormInput v-if="checkPrivilege('actos.precios')" :valueInput.sync="actoPrecio.precio"
                           :required="true"
                           type="number"
                           ref="precio"
                           name="precio" title="Precio"
                           placeholder="0.0"/>

                <div v-show="checkPrivilege('actos.finalizar')">
                    <FormCheck ref="checkStatus" v-model="actoPrecio.finalizar"
                               :valueInput="true"
                               :valueNotChecked="false"
                               name="finalizado"
                               title="finalizado" />
                </div>


            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonActo" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>

    </div>
</template>

<script>

    import DateRangePicker from '@/components/DatePicker/DateRangePicker'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import FormInput from '@/components/Form/FormInput'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ModalForm from '@/components/Modal/ModalForm'
    import DatePicker from '@/components/DatePicker/DatePicker'
    import ComboBoxSearch from '@/components/Form/ComboBoxSearch'
    import FormCheck from '@/components/Form/FormCheck'
    import ComboBoxSearchUrl from '@/components/Form/ComboBoxSearchUrl'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'HomeScreen',
        components: {
            FormCheck,
            DatePicker,
            FormInput,
            ComboBoxSearch,
            ModalForm,
            ButtonLoader,
            ModalButtonFooter,
            InputSearchComplete,
            DateRangePicker,
            ModalDetail,
            ComboBoxSearchUrl
        },
        data: function() {
            return {
                filtroPendientes: '',
                filtroPrecio: '',
                dataCostos: [],
                dataCategorias: [],
                dataCategoriasIngresos: [],
                page: 1,
                lastPage: 1,
                pagePrecio: 1,
                lastPagePrecio: 1,
                headersPendientes: [
                    { text: 'Empresa', align: 'left', value: 'empresa' },
                    { text: 'Fedatario', value: 'fedatario', hide: 'hidden-sm-and-down'},
                    { text: 'Último evento', value: 'ultimo_evento', width: '15%', hide: 'hidden-sm-and-down' },
                    { text: 'Estatus', value: 'estatus', hide: 'text-center'}
                ],
                headersActosPrecios: [
                    { text: 'Empresa', align: 'left', value: 'empresa' },
                    { text: 'Fedatario', value: 'fedatario', hide: 'hidden-sm-and-down'},
                    { text: 'Último evento', value: 'ultimo_evento', width: '15%', hide: 'hidden-sm-and-down' },
                    { text: 'Estatus', value: 'estatus', hide: 'text-center'}
                ],
                headersFinalizados: [
                    { text: 'Empresa', align: 'left', value: 'empresa' },
                    { text: 'Fedatario', value: 'fedatario', hide: 'hidden-sm-and-down'},
                    // { text: 'Servicio', value: 'servicio', hide: 'hidden-sm-and-down'},
                    { text: 'Fecha fin', value: 'fecha_fin', hide: 'hidden-sm-and-down'}
                ],
                headersFinalizadosPrecios: [
                    { text: 'Empresa', align: 'left', value: 'empresa' },
                    // { text: 'Servicio', value: 'servicio', hide: 'hidden-sm-and-down'},
                    { text: 'Ingresos', value: 'ingreso', hide: 'text-center hidden-sm-and-down' },
                    { text: 'Gastos', value: 'gasto', hide: 'text-center hidden-sm-and-down' },
                    { text: 'Utilidad', value: 'utilidad', hide: 'text-center' }
                ],
                fechaInicio: '',
                fechaFin: '',
                fechaInicioGastos: '',
                fechaFinGastos: '',
                fechaInicioIngresos: '',
                fechaFinIngresos: '',
                ingresos: 0,
                gastos: 0,
                gastosCategorias: 0,
                ingresosCategorias: 0,
                actosPendientes: [],
                actosPrecio: [],
                actosFinalizados: [],
                acto: {},
                actoPrecio: {},
                indexEdit: -1,
                notarios: [],
                servicios: [],
            }
        },
        mounted() {
            if(this.checkPrivilege('dashboard')){
                this.$emit("showLoader");
                this.getNotarios();
                this.getServicios();
                this.getActosPendientes();
                this.getActosPrecio();
                this.getActosFinalizados();
                this.getCuentaCostos();
                this.getCategoriasCostos();
                this.getCategoriasIngresos();
                if(this.checkPrivilege('dashboard.precios')){
                    this.headersFinalizados = this.headersFinalizadosPrecios;
                }

                this.loadChart();
            }

        },
        methods: {
            isEdicion(){
                var d1 = Date.parse(this.actoPrecio.fecha_fin);
                var d2 = Date.parse(this.$store.getters.fechaEdicion);
                if(this.checkPrivilege('ignorar-fecha') || isNaN(d1)){
                    return true;
                }
                else{
                    return d1 >= d2;
                }
            },
            getNotarios: function () {
                let vue = this;
                vue.notarios = [];
                vue.$refs.notarios.setCargando(true);
                $.get(this.API_URL + "/api/notarios/get", function (response) {
                    if(response.success){
                        for(let k in response.notarios){
                            vue.notarios[k] = {};
                            vue.notarios[k].key = response.notarios[k].id;
                            vue.notarios[k].title = response.notarios[k].no_fedatario;
                            vue.notarios[k].search = response.notarios[k].search;
                        }
                        if(vue.$refs.notarios != undefined){
                            vue.$refs.notarios.setData(vue.notarios);
                            vue.$refs.notarios.setCargando(false);
                        }
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            getServicios: function () {
                let vue = this;
                vue.servicios = [];
                vue.$refs.servicios.setCargando(true);
                $.get(this.API_URL + "/api/servicios/get", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        for(let k in response.servicios){
                            vue.servicios[k] = {};
                            vue.servicios[k].key = response.servicios[k].id;
                            vue.servicios[k].title = response.servicios[k].nombre;
                            vue.servicios[k].search = response.servicios[k].search;
                            vue.servicios[k].precio = response.servicios[k].precio;
                            vue.servicios[k].duracion = response.servicios[k].duracion;
                        }
                        if(vue.$refs.servicios != undefined){
                            vue.$refs.servicios.setData(vue.servicios);
                            vue.$refs.servicios.setCargando(false);
                        }
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            saveActo: function (e) {
                e.preventDefault();
                let vue = this;


                let empresa = this.$refs.empresas.getSelected();

                if(empresa === undefined || empresa === null){
                    swal("Selecciona una empresa", "", "info");
                    return;
                }

                let servicio = this.$refs.servicios.getSelected();

                if(servicio === undefined || servicio === null){
                    swal("Selecciona un servicio", "", "info");
                    return;
                }

                let notario = this.$refs.notarios.getSelected();

                if(notario != undefined || notario != null){
                    this.actoPrecio.notario_id = notario.key;
                }
                else{
                    this.actoPrecio.notario_id = null;
                }

                this.actoPrecio.empresa_id = empresa.key;
                this.actoPrecio.servicio_id = servicio.key;

                this.actoPrecio.actualizar_precio = true;


                this.$refs.buttonActo.setShowLoader(true);
                $.post(this.API_URL + "/api/actos/save", this.actoPrecio, function (response) {
                    vue.$refs.buttonActo.setShowLoader(false);
                    if(response.success){
                        vue.actoPrecio = response.record;

                        if (vue.indexEdit > -1) {
                            vue.getActosPendientes();
                            vue.getActosPrecio();
                            vue.getActosFinalizados();
                        }
                        vue.actoPrecio = {};
                        vue.indexEdit = -1;
                        vue.closeModalActo();
                    }
                    else{
                        swal(response.message, "", "info")
                    }
                }, "json").fail(function (error) {
                    vue.$refs.buttonActo.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });

            },
            changeServicio: function () {
                let servicio = this.$refs.servicios.getSelected();
                if(servicio !== undefined || servicio !== null){
                    if(this.actoPrecio.dias_estimados == "" ||
                        this.actoPrecio.dias_estimados == undefined){
                        this.$refs.duracion.setValue(servicio.duracion);
                        this.$refs.precio.setValue(servicio.precio);
                    }
                    // if(this.actoPrecio.precio == "" ||
                    //     this.actoPrecio.precio == undefined){

                        // let empresa = this.$refs.empresas.getSelected();
                        // var precio = false;
                        //
                        // if(empresa != null && empresa.item != undefined){
                        //     if(empresa.item.clientes != undefined){
                        //         let clienteFiltro = empresa.item.clientes.find(item => {
                        //           return item.pivot.activo;
                        //         });
                        //         console.log(clienteFiltro);
                        //         if(clienteFiltro != undefined){
                        //           for(let i in clienteFiltro.servicios_precio){
                        //             if(servicio.key == clienteFiltro.servicios_precio[i].servicio_id){
                        //               this.$refs.precio.setValue(clienteFiltro.servicios_precio[i].precio);
                        //               precio = true;
                        //               break;
                        //             }
                        //           }
                        //         }
                        //     }
                        //
                        // }
                        //
                        //
                        // if(!precio){
                        //     this.$refs.precio.setValue(servicio.precio);
                        // }
                    // }

                }
            },
            editActoPrecio(){
                this.$refs.checkStatus.setChecked(this.actoPrecio.finalizado);
                this.$refs.actoDetalle.hide();

                let empresa = {
                    key: this.actoPrecio.empresa.id,
                    title: this.actoPrecio.empresa.nombre,
                    item: this.actoPrecio.empresa
                };

                if(this.actoPrecio.notario != undefined && this.actoPrecio.notario != null){
                    let notario = {
                        key: this.actoPrecio.notario.id,
                        title: this.actoPrecio.notario.no_fedatario
                    };

                    this.$refs.notarios.setSelected(notario);
                }
                else{
                    this.$refs.notarios.removeItem();
                }
                let servicio = {
                    key: this.actoPrecio.servicio.id,
                    title: this.actoPrecio.servicio.nombre
                };
                this.$refs.servicios.setSelected(servicio);
                this.$refs.empresas.setSelected(empresa);
                this.$refs.fechaInicio.setDate(this.actoPrecio.fecha_inicio);
                this.$refs.actoPrecioDetalle.hide();
                this.openModalActo();
            },
            openModalActo: function () {
                this.$refs.actoForm.show();
            },
            closeModalActo: function () {
                this.$refs.actoForm.hide();
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.getActosPendientes();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.getActosPendientes();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.getActosPendientes();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.getActosPendientes();
                }
                else if(refresh){
                    this.page = 1;
                    this.getActosPendientes();
                }
            },
            nextPrecio: function () {
                this.pagePrecio += 1;
                if(this.pagePrecio > this.lastPagePrecio){
                    this.pagePrecio = this.lastPagePrecio;
                }
                else{
                    this.getActosPrecio();
                }
            },
            prevPrecio: function () {
                this.pagePrecio -= 1;
                if(this.pagePrecio < 1){
                    this.pagePrecio = 1;
                }
                else{
                    this.getActosPrecio();
                }

            },
            lastPrecio: function () {
                if(this.pagePrecio != this.lastPagePrecio) {
                    this.pagePrecio = this.lastPagePrecio;
                    this.getActosPrecio();
                }
            },
            firstPrecio: function (refresh) {
                if(this.pagePrecio != 1) {
                    this.pagePrecio = 1;
                    this.getActosPrecio();
                }
                else if(refresh){
                    this.pagePrecio = 1;
                    this.getActosPrecio();
                }
            },
            getStyle: function (dias) {
                var style = "normal-dias";
                if(dias < 8){
                    style = "urgente";
                }
                else if(dias < 15){
                    style = "warning";
                }

                return style;
            },
            getCategoriasCostos(){
                let vue = this;
                vue.gastosCategorias = 0;
                vue.dataCategorias = [];
                let data = {
                    fecha_inicio: this.fechaInicioGastos,
                    fecha_fin: this.fechaFinGastos
                };
                $.get(this.API_URL + "/api/cuentas/categorias-costos", data, function (response) {
                    if(response.success){
                        vue.dataCategorias = response.categorias;
                        if(vue.fechaInicioGastos === '' || vue.fechaFinGastos === ''){
                            vue.fechaInicioGastos = response.fecha_inicio;
                            vue.fechaFinGastos = response.fecha_fin;
                            vue.$refs.fechasGastos.setDates(response.fecha_inicio, response.fecha_fin);
                        }
                        vue.drawChartCategorias();
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            getCategoriasIngresos(){
                let vue = this;
                vue.ingresosCategorias = 0;
                vue.dataCategoriasIngresos = [];
                let data = {
                    fecha_inicio: this.fechaInicioIngresos,
                    fecha_fin: this.fechaFinIngresos
                };
                $.get(this.API_URL + "/api/cuentas/categorias-ingresos", data, function (response) {
                    if(response.success){
                        vue.dataCategoriasIngresos = response.categorias;
                        if(vue.fechaInicioIngresos === '' || vue.fechaFinIngresos === ''){
                            vue.fechaInicioIngresos = response.fecha_inicio;
                            vue.fechaFinIngresos = response.fecha_fin;
                            vue.$refs.fechasIngresos.setDates(response.fecha_inicio, response.fecha_fin);
                        }
                        vue.drawChartCategoriasIngresos();
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            getCuentaCostos: function () {
                let vue = this;
                vue.ingresos = 0;
                vue.gastos = 0;
                vue.dataCostos = [];
                let data = {
                    fecha_inicio: this.fechaInicio,
                    fecha_fin: this.fechaFin
                };
                $.get(this.API_URL + "/api/cuentas/costos", data, function (response) {
                    if(response.success){
                        vue.dataCostos = response.data;
                        if(vue.fechaInicio === '' || vue.fechaFin === ''){
                            vue.fechaInicio = response.fecha_inicio;
                            vue.fechaFin = response.fecha_fin;
                            vue.$refs.fechas.setDates(response.fecha_inicio, response.fecha_fin);
                        }
                        vue.drawChartCostos();
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            getActosPendientes: function () {
                this.$emit("showLoader");
                let vue = this;
                let data = {
                    filtro: this.filtroPendientes,
                    page: this.page,
                };
                $.get(this.API_URL + "/api/actos/urgentesPage", data, function (response) {
                    vue.$emit("hideLoader");
                    vue.actosPendientes = [];
                    if(response.success){
                        vue.page = response.actos.current_page;
                        vue.lastPage = response.actos.last_page;
                        vue.actosPendientes = response.actos.data;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.actosPendientes = [];
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getActosPrecio: function () {
                this.$emit("showLoader");
                let vue = this;
                let data = {
                    filtro: this.filtroPrecio,
                    page: this.pagePrecio,
                };
                $.get(this.API_URL + "/api/actos/sin-precio", data, function (response) {
                    vue.$emit("hideLoader");
                    vue.actosPrecio = [];
                    if(response.success){
                        vue.pagePrecio = response.actos.current_page;
                        vue.lastPagePrecio = response.actos.last_page;
                        vue.actosPrecio = response.actos.data;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    vue.actosPrecio = [];
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getActosFinalizados: function () {
                this.$emit("showLoader");
                let vue = this;
                let data = {
                    page: 1,
                    notdates: true,
                };
                $.get(this.API_URL + "/api/actos/finalizadosPage", data, function (response) {
                    vue.$emit("hideLoader");
                    vue.actosFinalizados = [];
                    if(response.success){
                        vue.pageTwo = response.actos.current_page;
                        vue.lastPageTwo = response.actos.last_page;
                        vue.actosFinalizados = response.actos.data;
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    vue.actosFinalizados = [];
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            detalleActo: function (item) {
                this.acto = item;
                this.$refs.actoDetalle.show();
            },
            detalleActoPrecio: function (item) {
                this.indexEdit = this.actosPrecio.indexOf(item);
                this.actoPrecio = Object.assign({}, item);
                this.$refs.actoPrecioDetalle.show();
            },
            detalleActoFinalizado: function (item) {
                this.acto = item;
                this.$refs.actoFinalizado.show();
            },
            loadChart() {
                // google.charts.load('current', {'packages':['corechart']});
                // eslint-disable-next-line no-undef
                google.load('visualization', '1', {'packages':['corechart'], 'language': 'es'});
                // eslint-disable-next-line no-undef
                google.charts.setOnLoadCallback(this.drawCharts);
            },
            drawCharts(){
                this.drawChartCostos();
                this.drawChartCategorias();
                this.drawChartCategoriasIngresos();
            },
            drawChartCategorias() {

                var data = [['Categoría', 'Gastos']];

                if(this.dataCategorias.length != 0){
                    for(let i = 0; i < this.dataCategorias.length; i++){
                        var titulo = "Sin categoría";
                        let total = this.dataCategorias[i].importe;

                        this.gastosCategorias += total;

                        if(this.dataCategorias[i].categoria != undefined){
                            titulo = this.dataCategorias[i].categoria.nombre;
                        }
                        data.push([titulo, parseFloat(total)]);
                    }
                }

                // eslint-disable-next-line no-undef
                var dataChart = google.visualization.arrayToDataTable(data);

                var options = {
                    // title: 'Gastos por categorías'
                };

                // eslint-disable-next-line no-undef
                var chart = new google.visualization.PieChart(document.getElementById('categorias_chart'));

                chart.draw(dataChart, options);
            },
            drawChartCategoriasIngresos() {

                var data = [['Categoría', 'Ingresos']];

                if(this.dataCategoriasIngresos.length != 0){
                    for(let i = 0; i < this.dataCategoriasIngresos.length; i++){
                        var titulo = "Sin categoría";
                        let total = this.dataCategoriasIngresos[i].importe;

                        this.ingresosCategorias += total;

                        if(this.dataCategoriasIngresos[i].categoria != undefined){
                            titulo = this.dataCategoriasIngresos[i].categoria.nombre;
                        }
                        data.push([titulo, parseFloat(total)]);
                    }
                }

                // eslint-disable-next-line no-undef
                var dataChart = google.visualization.arrayToDataTable(data);

                var options = {
                    // title: 'Gastos por categorías'
                };

                // eslint-disable-next-line no-undef
                var chart = new google.visualization.PieChart(document.getElementById('categorias_ingresos_chart'));

                chart.draw(dataChart, options);
            },
            drawChartCostos() {
                var data = [['Mes', 'Ingresos', 'Gastos', 'Utilidad']];

                if(this.dataCostos.length != 0){
                    for(let fecha in this.dataCostos){
                        var gastos = 0;
                        var ingresos = 0;
                        if(this.dataCostos[fecha].s != undefined){
                            gastos = parseFloat(this.dataCostos[fecha].s);
                        }
                        if(this.dataCostos[fecha].i != undefined){
                            ingresos = parseFloat(this.dataCostos[fecha].i);
                        }

                        this.ingresos += ingresos;
                        this.gastos += gastos;

                        data.push([fecha, ingresos, gastos, (ingresos - gastos)]);
                    }

                }
                else{
                    data.push(["No hay registros", 0, 0, 0]);
                }

                // eslint-disable-next-line no-undef
                var dataCostos = google.visualization.arrayToDataTable(data);

                var options = {
                    curveType: 'function',
                    legend: { position: 'bottom' }
                };

                // eslint-disable-next-line no-undef
                var chart = new google.visualization.LineChart(document.getElementById('utilidad_chart'));

                chart.draw(dataCostos, options);
            }
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .reporte-utilidad{
            margin-top: 35px;
            border-radius: 10px;
            box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            background: #fff;
            border: 1px solid #dfe0eb;
            .header{
                position: relative;
                display: flex;
                border-bottom: 1px solid #dfe0eb;
                margin: 0;
                padding: 20px 15px;
                h2{
                    width: 100%;
                    font-size: 20px;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: left;
                    line-height: 32px;
                    margin: 0;
                }
            }
        }

        .buttons{
            .date-range{
                margin-right: 0;
            }
        }

        .content-actos{
            margin-bottom: 30px;

            .header-caso{
                position: relative;
                display: flex;
                box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
                background: #fff;
                border: 1px solid #dfe0eb;
                border-bottom: none;
                margin: 0;
                padding: 20px 15px;
                border-radius: 10px 10px 0 0;

                h2{
                    width: 100%;
                    font-size: 20px;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: left;
                    line-height: 32px;
                    margin: 0;
                }

                .buscador-content{
                    text-align: right;
                    width: 100%;
                }

            }

            .v-data-table{
                border-radius: 0 0 10px 10px;
            }

        }

        .totales-periodo{
            padding-top: 8px;
            h5{
                width: 100%;
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
                line-height: 32px;
                margin: 0;
            }
        }

        .content-cuentas{

            .item{
                .content{
                    background: #fff;
                    /*border-radius: 10px;*/
                    /*border-bottom: 1px solid #dfe0eb;*/
                    /*<!--box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);-->*/


                    .title{
                        padding: 10px 0;
                        /*border-bottom: 1px solid #dfe0eb;*/
                        text-align: center;
                        h2{
                            text-transform: capitalize;
                            font-size: 17px;
                            margin: 0;
                            padding: 0;
                            span{
                                font-weight: bold;
                            }
                        }
                    }

                    .description{
                        text-align: center;
                        padding: 20px 10px;

                        p{
                            font-size: 20px;
                            margin: 0;
                            padding: 0;
                        }
                    }

                }
            }
        }

    }



</style>
