<template>
    <div class="row-table clickable">
        <div ref="rowProveedor" class="row row-item" @click="toggleRow">
            <slot name="option"></slot>
            <div :class="(!imprimir ? 'col-1' : 'col-2 col-sm-2') + ' text-center' + (imprimirUrlCliente ? ' col-sm-2' : '')">
                <div v-if="dropDown" class="column-actions">
                    <div v-if="imprimirUrlCliente != undefined">
                        <a :href="imprimirUrlCliente" target="_blank" title="Cliente">
                            <i class="far fa-file-pdf btn-more"></i>
                        </a>
                    </div>
                    <div v-if="imprimirUrl != undefined">
                        <a :href="imprimirUrl" target="_blank" title="">
                            <i class="fa fa-print btn-more"></i>
                        </a>
                    </div>
                    <div v-if="proveedor && detailRow" @click="option">
                        <i class="fa fa-eye btn-more"></i>
                    </div>
                    <div v-else-if="!imprimirhead">&nbsp;</div>
                    <div v-if="imprimirhead">&nbsp;</div>
                    <div v-if="imprimirhead">&nbsp;</div>
                    <div v-show="!show" @click="toggle">
                        <i class="fa fa-chevron-down btn-more"></i>
                    </div>
                    <div v-show="show" @click="toggle">
                        <i class="fa fa-chevron-up btn-more"></i>
                    </div>
                </div>
                <div v-else @click="option">
                    <i class="fa fa-eye btn-more"></i>
                </div>
            </div>
        </div>
        <div v-show="show" class="subs-container">
            <slot name="suboptions"></slot>
        </div>
    </div>
</template>

<script>

import * as $ from 'jquery';

export default {
    name: 'CustomTable',
    components: {
    },
    props: {
        proveedor: Boolean(false),
        imprimirhead: Boolean(false),
        imprimirrow: Boolean(false),
        detail: Boolean(true),
        imprimirUrl: undefined,
        imprimirUrlCliente: undefined,
        clickrow: Boolean(false),
        dropDown: Boolean(false),
    },
    data: function(){
        return {
            imprimir: false,
            detailRow: this.detail == undefined ? true : this.detail,
            show: false,
        }
    },
    mounted() {

        if(this.imprimirhead || this.imprimirrow ){
            this.imprimir = true;
        }

        let vue = this;
        $(this.$refs.rowProveedor).find(".minimizar").click(function () {
            vue.toggle();
        });
    },
    methods: {
        toggle: function () {
            if(this.dropDown){
                this.show = !this.show;
                if(this.$parent != undefined){
                    this.$parent.changeShow();
                }
            }
        },
        toggleRow: function () {
            if(this.dropDown && this.clickrow){
                this.show = !this.show;
                if(this.$parent != undefined){
                    this.$parent.changeShow();
                }
            }
        },
        getShow: function () {
            return this.show;
        },
        hidden: function () {
            this.show = false;
        },
        setHidden: function (show) {
            this.show = show;
        },
        option: function (e) {
            this.$emit("option", e);
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .row-table{
        margin: 0;
        font-weight: bold;
        font-size: 14px;

        .column-actions{
            display: flex;
            div{
                width: 100%;
            }
        }

        &.clickable{
            cursor: pointer;
        }

        .row-item{
            padding-top: 0;
            border-bottom: 1px solid rgba(0, 0, 0, .12);
            margin: 0;

            padding-bottom: 0;

            &:hover{
                background: #EEEEEE;
            }

            &.subs {
                font-weight: normal;
                background: #f6f6f6;
                .nombre{
                    padding-left: 25px;

                    @media (max-width: 720px){
                        padding-left: 10px;
                    }
                }
                &.selected{
                    background: rgba(196, 192, 134, 0.5);
                    &:hover{
                        background: rgba(196, 192, 134, 0.5);
                    }
                }
            }

        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }


    }


</style>
