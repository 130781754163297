<template>
    <div id="app">

        <MenuLateral v-if="authToken != null">
            <MenuUser/>
            <ul class="nav-options">
                <li v-if="checkPrivilege('dashboard')" is="MenuItem" title="Inicio" icon="fa-home"
                    link="/inicio" @refresh="refresh"></li>
                <li v-if="checkPrivilege('empresas')" is="MenuItem" title="Empresas" icon="fa-building"
                    link="/empresas" @refresh="refresh"></li>
                <li v-if="checkPrivilege('actos')" is="MenuItem" title="Actos" icon="fa-lightbulb"
                    link="/actos" @refresh="refresh"></li>

                <li v-if="checkPrivilege('actos.revision')" is="MenuItem" title="Revisión Actos" icon="fa-clipboard-check"
                    link="/revision-actos" @refresh="refresh"></li>

                <li v-if="checkPrivilege('actos.finalizados')" is="MenuItem" title="Reporte de Actos" icon="fa-lightbulb"
                    link="/reporte-actos" @refresh="refresh"></li>

<!--                <li v-if="checkPrivilege('actos.finalizados')" is="MenuItem" title="Actos Finalizados" icon="fa-lightbulb"-->
<!--                    link="/actos-finalizados" @refresh="refresh"></li>-->

                <li v-if="checkPrivilege('notarios')" is="MenuItem" title="Fedatarios" icon="fa-user-shield"
                    link="/fedatarios" @refresh="refresh"></li>
                <li v-if="checkPrivilege('clientes')" is="MenuItem" title="Clientes" icon="fa-user-tie"
                    link="/clientes" @refresh="refresh"></li>
                <li v-if="checkPrivilege('proveedores-socios')" is="MenuItem" title="Proveedores y Socios" icon="fa-user-friends"
                    link="/proveedores-socios" @refresh="refresh"></li>
                <li v-if="checkPrivilege('servicios')" is="MenuItem" title="Servicios" icon="fa-briefcase"
                    link="/servicios" @refresh="refresh"></li>
                <li v-if="checkPrivilege('cuentas')" is="MenuItem" title="Cuentas" icon="fa-book"
                    link="/cuentas" @refresh="refresh"></li>

                <li v-if="checkPrivilege('gastos')" is="MenuItem" title="Gastos Predeterminados" icon="fa-list-ul"
                    link="/gastos-predeterminados" @refresh="refresh"></li>

                <li v-if="checkPrivilege('recibos')" is="MenuItem" title="Recibos" icon="fa-file-invoice"
                    link="/recibos" @refresh="refresh"></li>

                <li v-if="checkPrivilege('comisiones')" is="MenuItem" title="Comisiones" icon="fa-money-check-alt"
                    link="/comisiones"></li>

                <li v-if="checkPrivilege('cobranza')" is="MenuItem" title="Cobranza" icon="fa-dollar-sign"
                    link="/cobranza" @refresh="refresh"></li>

                <li v-if="checkPrivilege('denominaciones')" is="MenuItem" title="Denominaciones" icon="fa-align-justify"
                    link="/denominaciones" @refresh="refresh"></li>

                <li v-if="checkPrivilege('inventarios-menu')" is="MenuGroup" title="Inventario" icon="fa-check-square">
                    <div v-if="checkPrivilege('inventarios')" is="MenuItem" title="Existencias" icon="fa-circle" link="/existencias" @refresh="refresh"></div>
                    <div v-if="checkPrivilege('revisiones')" is="MenuItem" title="Revisiones" icon="fa-circle" link="/revisiones-inventario" @refresh="refresh"></div>
                </li>

                <li v-if="checkPrivilege('categorias')" is="MenuGroup" title="Categorías" icon="fa-cubes">
                    <div is="MenuItem" v-if="checkPrivilege('categorias-gastos')"
                        title="Gastos" icon="fa-circle" link="/categorias-gastos" @refresh="refresh"></div>

                    <div is="MenuItem" v-if="checkPrivilege('categorias-ingresos')"
                        link="/categorias-ingresos" title="Ingresos" icon="fa-circle" @refresh="refresh"></div>
                </li>

                <li v-if="checkPrivilege('usuarios')" is="MenuItemSeparator"></li>

                <li v-if="checkPrivilege('perfiles')" is="MenuItem" title="Perfiles" icon="fa-address-card"
                    link="/perfiles" @refresh="refresh"></li>

                <li v-if="checkPrivilege('grupos')" is="MenuItem" title="Grupos" icon="fa-users"
                    link="/grupos" @refresh="refresh"></li>

                <li v-if="checkPrivilege('usuarios')" is="MenuItem" title="Usuarios" icon="fa-user"
                    link="/usuarios" @refresh="refresh"></li>

                <li v-if="checkPrivilege('configuraciones')" is="MenuItem" title="Configuración" icon="fa-cog" link="/configuraciones"></li>

<!--                <li v-if="checkPrivilege('show.url')" class="button-web">-->
<!--                    <a @click="getUrlExternal()">Vínculo contable y fiscal</a>-->
<!--                </li>-->


            </ul>
        </MenuLateral>

        <div v-show="loader" :class="'preloader ' + (authToken != null ? 'add-menu' : 'content-view')">
            <div class="content">
                <div class="lds-roller">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
                <p>
                    Cargando
                </p>
            </div>
        </div>

        <div :class="authToken != null ? 'add-menu' : 'content-view'">
            <!--<transition name="router-anim" enter-active-class="animated fadeIn"-->
            <!--leave-active-class="animated fadeOut">-->
            <v-app>
                <router-view :key="routerKey" @authenticated="setAuthenticated"
                             @logout="logout"
                             @showLoader="showLoader"
                             @hideLoader="hideLoader"
                             @loadConfigs="loadConfigs"
                             @deleteComplete="deleteComplete"
                             @saveComplete="saveComplete"/>
            </v-app>
            <!--</transition>-->
        </div>
    </div>
</template>

<script>

    import MenuLateral from '@/components/LateralMenu/MenuLateral'
    import MenuUser from '@/components/LateralMenu/MenuUser'
    import MenuGroup from '@/components/LateralMenu/MenuGroup'
    import MenuItem from '@/components/LateralMenu/MenuItem'
    import MenuItemSeparator from '@/components/LateralMenu/MenuItemSeparator'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'App',
        components: {
            MenuLateral,
            MenuUser,
            MenuGroup,
            MenuItem,
            MenuItemSeparator
        },
        computed: {
            authToken(){
                return this.$store.getters.auth_token;
            }
        },
        data: function() {
            return {
                routerKey: 0,
                loader: false
            }
        },
        mounted(){
            if(this.$store.getters.auth_token != undefined){
                this.loadConfigs();
                this.loadUserPrivileges();
            }
        },
        methods: {
            getUrlExternal(){
                $.post(this.API_URL + "/api/account/external-url", function (response) {
                    if(response.success){
                        window.open(response.url);
                    }
                    else{
                        swal(response.message, "", "info");
                    }
                });
            },
            loadConfigs(){
                let vue = this;
                $.get(this.API_URL + "/api/configuraciones/fecha-edicion", function (response) {
                    vue.$store.commit("fechaEdicion", response.excerpt);
                });
            },
            loadUserPrivileges(){
              let vue = this;
              $.get(this.API_URL + "/api/account/privileges", function (response) {
                if(response.success){
                  vue.$store.commit("user", response.user);
                }
              });
            },
            refresh(){
                this.routerKey += 1;
            },
            hideLoader: function (){
                this.loader = false;
            },
            showLoader: function (){
                this.loader = true;
            },
            deleteComplete: function (complete) {
                $.post(this.API_URL + "/api/completes/delete", complete, function () {

                });
            },
            saveComplete: function (complete) {
                if(complete.value != undefined && complete.value != ""){
                    $.post(this.API_URL + "/api/completes/save", complete, function () {

                    });
                }
            },
            setAuthenticated(token, user, save = false) {
                this.$store.commit("auth_token", token);
                if(save) {
                    this.$store.commit("last_auth_token", token);
                }
                this.$store.commit("user", user);
                this.$router.replace({ name: "inicio" });

                $.ajaxSetup({
                    headers: {
                        'X-AUTH-TOKEN': this.$store.getters.auth_token,
                        'X-LAST-AUTH-TOKEN': this.$store.getters.last_auth_token
                    }
                });

                this.loadConfigs();
                this.loadUserPrivileges();

            },
            logout: function () {
                // let data = {auth_token: this.$store.getters.auth_token};

                // $.post(this.API_URL + "/api/logout", data, function (res) {
                // }, 'json');

                this.$store.commit("auth_token", null);
                this.$store.commit("user", null);
                this.$router.replace({ name: "login" });
            }
        }
    }
</script>

<style lang="scss">

    $accentColor : #b09953;
    $primaryColor : rgba(46, 30, 41, 1);

    html,body{
        height: 100%;
        overflow: auto !important;
        background: #f7f8fc;

        *{
            font-family: Muli, serif;
        }

    }
    #app {
        font-family: Muli, serif;
        /*-webkit-font-smoothing: antialiased;*/
        /*-moz-osx-font-smoothing: grayscale;*/
        color: #2c3e50;
        height: 100%;
        background: #f7f8fc;

        .normal-link{
            text-decoration: none;
            color: rgba(0, 0, 0, 0.87);
        }

        .button-web{
            text-align: center;
            padding: 10px 15px;
            a{
                text-decoration: none;
                font-size: 14px;
                display: block;
                background: rgba(68, 38, 59, 1);
                padding: 10px 15px;
                border-radius: 5px;
                margin-top: 15px;
                transition-duration: 0.3s;
                &:hover{
                    background:  rgba(68, 38, 59, 1);
                    -webkit-box-shadow: 2px 2px 8px 0px rgba(50, 50, 50, 0.4);
                    -moz-box-shadow:    2px 2px 8px 0px rgba(50, 50, 50, 0.4);
                    box-shadow:         2px 2px 8px 0px rgba(50, 50, 50, 0.4);
                }
            }
        }


      .preloader{
            position: fixed;
            z-index: 80;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.7);

            &.add-menu{
                .content{
                    left: calc(50% + 110px);
                }
            }

            .content{
                color: #fff;

                display: inline-block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                .lds-roller {
                    display: inline-block;
                    position: relative;
                    width: 64px;
                    height: 64px;
                }
                .lds-roller div {
                    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                    transform-origin: 32px 32px;
                }
                .lds-roller div:after {
                    content: " ";
                    display: block;
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #fff;
                    margin: -3px 0 0 -3px;
                }
                .lds-roller div:nth-child(1) {
                    animation-delay: -0.036s;
                }
                .lds-roller div:nth-child(1):after {
                    top: 50px;
                    left: 50px;
                }
                .lds-roller div:nth-child(2) {
                    animation-delay: -0.072s;
                }
                .lds-roller div:nth-child(2):after {
                    top: 54px;
                    left: 45px;
                }
                .lds-roller div:nth-child(3) {
                    animation-delay: -0.108s;
                }
                .lds-roller div:nth-child(3):after {
                    top: 57px;
                    left: 39px;
                }
                .lds-roller div:nth-child(4) {
                    animation-delay: -0.144s;
                }
                .lds-roller div:nth-child(4):after {
                    top: 58px;
                    left: 32px;
                }
                .lds-roller div:nth-child(5) {
                    animation-delay: -0.18s;
                }
                .lds-roller div:nth-child(5):after {
                    top: 57px;
                    left: 25px;
                }
                .lds-roller div:nth-child(6) {
                    animation-delay: -0.216s;
                }
                .lds-roller div:nth-child(6):after {
                    top: 54px;
                    left: 19px;
                }
                .lds-roller div:nth-child(7) {
                    animation-delay: -0.252s;
                }
                .lds-roller div:nth-child(7):after {
                    top: 50px;
                    left: 14px;
                }
                .lds-roller div:nth-child(8) {
                    animation-delay: -0.288s;
                }
                .lds-roller div:nth-child(8):after {
                    top: 45px;
                    left: 10px;
                }
                @keyframes lds-roller {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }

            }

        }

        .v-data-table{
            border-radius: 10px;
            border: 1px solid #dfe0eb;
            width: 100%;

            .v-data-table__wrapper{
                width: 100%;
            }

        }

        /*#root{*/
        /*position: fixed;*/
        /*width: inherit;*/
        /*}*/

        .table-material{

            tr{
                border-bottom: 1px solid #ced4da;
            }

            tbody{
                tr:last-child{
                    border: none;
                }
            }

        }

        .table-detail{
            width: 100%;
            max-width: 100%;
            font-size: 14px;
            margin-bottom: 22px;
            border-color: #e2e7eb;
            border-radius: 3px;
            thead{
                tr{
                    th{
                        padding: 10px 15px;
                        border-bottom: 1px solid #e2e7eb;
                    }
                }
            }

            tbody {
                tr{
                    td{
                        border-color: #e2e7eb;
                        padding: 10px 15px;
                    }
                }

                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }

            }

        }


        .btn-circle,
        .btn.btn-circle {
            display: inline-block;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            margin-left: 5px;
            color: #fff;
        }

        label{
            margin-bottom: .1rem;
        }

        a{
            cursor: pointer;
        }

        .btn-primary{
            border-radius: 50px;
            padding-top: 5px;
            padding-bottom: 5px;
            border-color: rgba(46, 30, 41, 1);
            background: rgba(46, 30, 41, 1);
        }

        .btn-primary-modal {
            color: #fff;
            padding-left: 20px;
            border-radius: 50px;
            padding-right: 20px;
            border-color: rgba(46, 30, 41, 1);
            background: rgba(46, 30, 41, 1);
        }


        .content-view{
            width: 100%;
            height: 100%;

        }

        .add-menu{
            padding-left: 220px;
            width: 100%;

            @media all and (max-width: 1200px){
                padding-left: 0;
                padding-top: 55px;
            }

        }

        .empresas {
            font-weight: 600;
            &.exceso{
                color: #ff5b57;
            }
        }

        .precio{
            &.positivo{
                color: #28a745;
            }
            &.negativo{
                color: #ff5b57;
            }
        }

        .date-range{
            display: inline-block;
            width: 240px;
            margin-right: 20px;
            @media all and (max-width: 768px) {
                margin-right: 0;
            }
        }

        .table-responsive{
            width: 100%;
            font-size: 14px;

            thead{
                width: 100%;
                tr{
                    width: 100%;
                }
                tr.noregistros{
                    th{
                        width: 50%;
                    }
                }
            }

            @media all and (max-width: 768px) {
                font-size: 12px;
            }
        }

        .header-section{
            position: relative;
            .title{
                display: inline-block;
                max-width: 50%;


                @media all and (max-width: 768px){
                    display: block;
                    max-width: 100%;
                    text-align: center;
                    padding-bottom: 5px;
                }

                h2{
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 25px;
                    margin: 0;
                }

                &.subtitle{
                    h2{
                        font-size: 15px;
                        line-height: 16px;

                        span{
                            font-weight: 400;
                        }

                    }
                }

            }
            .buttons{
                position: relative;
                float: right;
                text-align: right;

                .buscador-content{
                    display: inline-block;
                    margin-right: 10px;
                    &.no-margin{
                        margin-right: 0;
                    }
                    @media all and (max-width: 768px){
                        margin-right: 0;
                    }
                }

                @media all and (max-width: 768px){
                    float: none;
                    text-align: center;
                }

                .date-content{
                    display: inline-block;
                    width: 150px;
                    margin-right: 20px;
                }

                .button-add-top{
                    display: inline-block;
                    @media all and (max-width: 768px) {
                        display: none;
                    }

                }

                @media all and (max-width: 768px){
                    float: none;
                }

            }
        }

        .button-add-bottom{
            display: none;
            position: fixed;
            bottom: 25px;
            right: 25px;
            z-index: 10;

            @media all and (max-width: 768px) {
                display: block;
                right: 15px;
                bottom: 15px;
            }

            @media all and (max-width: 576px) {
                right: 5px;
                bottom: 15px;
            }

        }

        .content-section{
            /*background: #fff;*/
            /*border: 1px solid #dfe0eb;*/
            /*border-radius: 10px;*/
            margin-top: 20px;

            @media all and (max-width: 768px) {
                margin-top: 15px;
            }
            @media all and (max-width: 576px) {
                margin-top: 10px;
            }
        }

        .v-data-table{
            width: 100%;

            thead{
                tr{
                    th{
                        font-size: 14px;
                    }
                }
            }



            tbody {
                tr {
                    cursor: pointer;
                }
            }
            /*transition-duration: 0.3s;*/
            /*border-bottom: 1px solid #dfe0eb;*/
            /*td:first-child{*/
            /*padding-left: 25px;*/
            /*@media all and (max-width: 768px) {*/
            /*padding-left: 15px;*/
            /*}*/
            /*@media all and (max-width: 576px) {*/
            /*padding-left: 5px;*/
            /*}*/
            /*}*/
            /*td:last-child{*/
            /*padding-right: 25px;*/
            /*@media all and (max-width: 768px) {*/
            /*padding-right: 15px;*/
            /*}*/
            /*@media all and (max-width: 576px) {*/
            /*padding-right: 5px;*/
            /*}*/
            /*}*/
            /*td{*/
            /*font-weight: 500;*/
            /*font-size: 14px;*/
            /*padding: 15px 0 15px 5px;*/
            /*@media all and (max-width: 768px) {*/
            /*font-size: 13px;*/
            /*}*/
            /*@media all and (max-width: 576px) {*/
            /*font-size: 11px;*/
            /*}*/
            /*}*/

            /*&:hover{*/
            /*background: #f7f8ff;*/
            /*}*/

            /*}*/
            /*}*/

        }

        .status{
            font-size: 10px;
            text-transform: uppercase;
            background: #ccc;
            border-radius: 50px;
            padding: 5px 10px;
            width: 70px;
            margin: 0;
            text-align: center;
            line-height: 15px;
            font-weight: bold;

            @media all and (max-width: 768px) {
                font-size: 7px;
            }

            &.inactive, &.urgente{
                background: #f12b2c;
                color: #fff;
            }

            &.warning{
                background: #f59c1a;
                color: #fff;
            }

            &.normal-dias{
                background: #e0e0e0;

            }

            &.active, &.normal{
                background: #29cc97;
                color: #fff;
            }

            &.warning,&.urgente,&.normal-dias,&.normal{
                text-transform: lowercase;
              &.capitalize{
                text-transform: capitalize;
              }
            }



        }

        .list-material{
            padding: 0;
            margin: 0;
            list-style: none;

            li{
                position: relative;
                padding: 5px 0 5px 20px;
                &:before{
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    content: '●  ';
                }
            }
        }

        [type=number]{
            width: 100%;
        }

        .data-section{
            background: #fff;
            border-radius: 10px;
            border: 1px solid #dfe0eb;
            box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);

            &.with-header{
                border-radius: 0 0 10px 10px;
            }

        }

        .footer{
            text-align: right;
            padding: 10px 10px;
            border-top: 1px solid rgb(226, 231, 235);

            .buttons-link{
                text-align: right;
                padding: 10px 0;
                a{
                    /*text-decoration: none;*/
                    display: block;
                    border-radius: 50px;
                    /*max-width: 200px;*/
                    /*margin: auto;*/
                    position: relative;
                    /*color: #fff;*/
                    text-align: center;
                    /*padding: 8px 35px;*/
                    font-size: 15px;
                    /*border-color: #2e1e29;*/
                    /*background: #2e1e29;*/
                }
            }

            .paginater{
                display: inline-block;

                & > div{
                    display: inline-block;
                }

                .text{
                    padding-right: 20px;
                    font-size: 12px;
                    vertical-align: top;
                    p{
                        line-height: 41px;
                        margin: 0;
                        padding: 0;
                    }
                }

                .buttons{
                    button{
                        cursor: pointer;
                        outline: none;
                        box-shadow: none;
                        background: #f0f0f0;
                        position: relative;
                        margin: 5px;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 50%;

                        &.inactive{
                            cursor: inherit;
                            opacity: 0.5;
                        }

                        svg{
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }

                    }

                    .first, .last{
                        font-size: 10px;
                    }

                }

            }

        }


        .table-section{
            width: 100%;
            background: transparent;

            thead{
                tr{
                    color: rgba(0,0,0,.54);
                    font-size: 15px;
                    font-weight: bold;
                    border-bottom: 2px solid rgb(226, 231, 235);

                    th{
                        padding: 13px 5px;
                    }
                }
            }

            &.clickeable {
                tbody {
                    tr {
                        transition-duration: 0.3s;
                    }

                    tr:hover{
                        cursor: pointer;
                        background: #EEEEEE;
                    }
                }
            }

            tbody{
                tr{
                    font-size: 14px;
                    color: rgba(0,0,0,.87);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    td{
                        padding: 5px;
                    }
                }
            }

        }

        .btn-circle,
        .btn.btn-circle {
            position: relative;
            display: inline-block;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            margin-left: 5px;
            width: 30px;
            height: 30px;
            font-size: 14px;
            svg{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .btn-circle{
            color: #fff;
            &.btn-aplicar{
                color: #fff;
                background: #00acac;
                &:hover{
                    color: #fff;
                }
            }

            &.btn-editar{
                color: #fff;
                background: #f59c1a;
                &:hover{
                    color: #fff;
                }
            }

            &.btn-trash{
                color: #fff;
                background: #DF4656;
                &:hover{
                    color: #fff;
                }
            }
        }



    }

</style>
