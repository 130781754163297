<template>
    <div class="modal fade" ref="modal">
        <div class="modal-dialog">
            <form v-on:submit="submit" class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{ title }}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <!--<button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>-->
                    <slot name="footer"></slot>
                </div>
            </form>
        </div>
    </div>

</template>

<script>

import * as $ from 'jquery';

export default {
    name: 'ModalDetail',
    components: {
    },
    props: {
        title: String('')
    },
    data: function(){
        return {

        }
    },
    mounted() {
        let vue = this;
        $(this.$refs.modal).on('hide.bs.modal', function () {
            vue.$emit("close");
        });
    },
    methods: {
        show: function () {
            $(this.$refs.modal).modal("show");
        },
        hide: function () {
            $(this.$refs.modal).modal("hide");
        },
        submit: function (e) {
            this.$emit("submit", e)
        },

    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /*NAVIGATION BAR */

  .modal{
      overflow-y: auto;
  }

  /*------------ END KEYFRAMES  --------------*/
</style>
