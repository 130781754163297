<template>
    <div id="root">
        <div class="header-section">
            <div class="title">
                <h2>
                    Conceptos - {{ getTitle() }}
                </h2>
            </div>
        </div>

        {{ }}

        <div class="content-section">
            <v-data-table
                    :headers="headers"
                    :items="conceptos"
                    hide-default-header
                    item-key="table-empresas"
                    class="elevation-1" >

                <template v-slot:header="{ props: { headers } }">
                    <thead>
                    <tr>
                        <th v-for="header in headers"
                            :class="'text-left sortable ' + header.hide"
                            :key="header.value">
                            {{ header.text }}
                        </th>
                    </tr>
                    </thead>
                </template>



                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-if="items.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in items" :key="index" v-on:click="detalleRecibo(item)">
                        <td :class="headers[0].hide">{{ item.acto.empresa.nombre }}</td>
                        <td :class="headers[1].hide">
                            {{ item.descripcion }}
                        </td>
                        <td :class="headers[2].hide">
                            {{ item.cuenta.nombre }}
                        </td>
                        <td :class="headers[3].hide">
                            {{ item.importe | toCurrency }}
                        </td>
                        <td :class="headers[4].hide">
                            <!--<ButtonMore>-->
                                <!--<ButtonOptionItem  v-on:click="editarConcepto(item)" title="Editar" icon="fa-pencil-alt" />-->
                                <!--<ButtonOptionItem v-on:click="eliminarConcepto(item)" title="Eliminar" icon="fa-trash-alt" />-->
                            <!--</ButtonMore>-->
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>

        </div>

        <div class="float-saldo">
            <p>
                Total: {{ total | toCurrency }}
            </p>
        </div>

    </div>
</template>

<script>

    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'ActosConceptos',
        components: {
        },
        data: function(){
            return {
                title: "Nuevo recibo",
                headers:[
                    { text: 'Acto', align: 'left', value: 'nombre'},
                    { text: 'Descripción', align: 'left', value: 'descripcion'},
                    { text: 'Cuenta', align: 'left', value: 'cuenta', hide: 'hidden-sm-and-down'},
                    { text: 'Importe', value: 'importe', width: '10%' },
                    { text: '', value: 'acciones', width: '5%' },
                ],
                filtro: '',
                total: 0,
                conceptos: [],
                tipo: this.$route.meta.tipo,
                acto_id: this.$route.params.actoId,
            }
        },
        mounted() {
            this.$emit("showLoader");
            this.getConceptos();
        },
        methods: {
            getTitle: function () {
                var title = "Utilidad";
                if(this.tipo == "s"){
                    title = "Gastos"
                }
                else if(this.tipo == "i"){
                    title = "Ingreso"
                }
                return title;
            },
            getConceptos: function () {
                let vue = this;
                let data = {acto_id: this.acto_id, tipo: this.tipo};
                $.get(this.API_URL + "/api/conceptos/get", data, function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.conceptos = response.conceptos;
                        vue.total = response.total;
                    }
                    else{
                        vue.$router.push({ path: "/recibos" });
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },

        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px 100px 25px;
        width: 100%;
        height: 100%;

        .btn-aplicar{
            margin-right: 10px;
            display: inline-block;
            button{
                background: #28a745;
                border-color: #28a745;
            }
        }

        .content-form{
            background: #fff;
            padding: 16px;
            border-radius: 10px;
            border: 1px solid #dfe0eb;
            -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

            .no-padding{
                padding-top: 0;
                padding-bottom: 0;
            }
        }


        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px 100px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px 100px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .float-saldo{
            position: fixed;
            right: 20px;
            bottom: 20px;
            padding: 10px 30px;
            background: #c4c086;
            border-radius: 10px;

            @media all and (max-width: 768px) {
                left: 15px;
                right: inherit;
            }

            @media all and (max-width: 576px){
                left: 5px;
            }

            p{
                text-transform: uppercase;
                color: #fff;
                margin: 0;
                padding: 0;
                font-weight: 600;
                font-size: 18px;
            }
        }


    }

</style>
