<template>
    <div class="content-button">
        <button :style="dynamicStyle"
                @click="click">
            <i v-if="icon != null" :class="'fas ' + icon"></i>
            <p v-if="title != null">
                {{ title }}
            </p>
        </button>
    </div>
</template>

<script>

import * as $ from 'jquery';

export default {
    name: 'ModalButtonFooter',
    components: {
    },
    props: {
        title: null,
        icon: null,
        type: String('button'),
        color: null,
        background: null,
        disabled: Boolean(false)
    },
    data: function(){
        return {

        }
    },
    methods: {
        show: function () {
            $("#modal").modal("show");
        },
        hide: function () {
            $("#modal").modal("hide");
        },
        click: function () {
            if(!this.disabled){
                this.$emit("click", this)
            }
        }
    },
    computed : {
        dynamicStyle() {
            var opacity = 1;
            if(this.disabled){
                opacity = 0.7;
            }
            return 'background-color: ' + this.background + '; color: '+this.color+'; opacity: '+opacity+';';
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .content-button{
        background: #fff;

        &:hover{
            cursor: crosshair;
        }

    }
    button{

        &:focus{
            outline: none;
            box-shadow: none;
        }

        padding: 10px 0;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        p{
            display: inline-block;
            padding: 0 10px;
            margin: 0;
        }

        @media all and (max-width: 768px){
            font-size: 12px;
            p{
                display: block;
            }
        }

    }
</style>
