<template>
    <div class="combobox-search" v-bind:class="{'espcial-combo': special}">
        <label v-if="title != null">{{ title }}</label>
        <div class="content"
             v-click-outside="onClose">
            <input :id="id" v-model="filtro" type="text" class="form-control"
                   v-bind:class="{filtro: headerFiltro}"
                   :placeholder="!cargando ? placeholderText : 'Cargando...'"
                   ref="input"
                   @click="show = true"/>
            <ul v-show="show"
                :class="'items-list'">
                <li v-if="elementsFiltrados.length == 0" class="item no-results">
                    <div>
                        <p>
                            No hay resultados
                        </p>
                    </div>
                </li>
                <li v-else v-for="dato in elementsFiltrados" :class="'item ' +
                 (selected !== null && dato.key === selected.key ? 'active' : 'inactive')" v-on:click="setSelected(dato)"
                v-bind:key="dato.key">
                    <div>
                        <p>
                            {{ dato.title }}
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'SelectComboBoxSearch',
        props: {
            id: String,
            title: String,
            headerFiltro: Boolean,
            placeholder: null,
            special: Boolean(false),
            datainfo: Array,
            tag: String
        },
        data: function(){
            return {
                cargando: true,
                show: false,
                filtro: '',
                datos: [],
                selected: null,
                placeholderText: this.placeholder
            }
        },
        mounted() {

            // console.log(this.tag);

            if(this.datainfo != undefined){
                this.datos = this.datainfo
            }

            if(this.tag == "selectCliente"){
                if(this.getCliente() != undefined){
                    var cliente = this.getCliente();

                    let title = "";
                    if(cliente.nombre != null){
                        title = cliente.nombre;
                    }
                    else if(cliente.email){
                        title = cliente.email;
                    }

                    let clienteSelect = {};
                    clienteSelect.key = cliente.id;
                    clienteSelect.title = "#" + cliente.id + " -  " + title;
                    clienteSelect.item = cliente;



                    this.setSelected(clienteSelect);
                }
            }
        },
        methods: {
            setCargando (cargando){
                this.cargando = cargando;
            },
            onClose () {
                if(this.show){
                    this.show = false;
                    this.filtro = "";
                    // this.filtro = this.selected !== null ? this.selected.title : "";
                }
            },
            setData: function (data) {
                data.splice(0, 0, {key: null, title: this.placeholder});
                this.datos = data;
            },
            setIndex(index){
                this.show = false;
                this.selected = this.datos[index];
                this.placeholderText = this.datos[index].title;
                this.filtro = "";
                this.change();
            },
            setKey(key){
                for(var i = 0; i < this.datos.length; i++){
                    if(this.datos[i].key == key){
                        this.setSelected(this.datos[i], false);
                        break;
                    }
                }
            },
            getIndex(){
                return this.datos == undefined ? -1 : this.datos.indexOf(this.selected);
            },
            setSelected: function (selected, update = true) {
                this.show = false;
                this.selected = selected;
                this.placeholderText = this.selected.title;
                this.filtro = "";
                // this.filtro = this.selected.title;
                if(update){
                    this.change();
                }
            },
            getSelected: function () {
                return this.selected
            },
            change: function () {
                this.$emit("change");
            },
            removeItem: function () {
                this.selected = null;
                this.filtro = "";
                this.placeholderText = this.placeholder;
            }
        },
        computed: {
            elementsFiltrados: function(){
                let vue = this;
                return this.datos.filter(function (dt) {
                    return dt.title.toLowerCase().indexOf(vue.filtro.toLowerCase()) !== -1
                });
            }
        }


    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .combobox-search{

        .no-results{
            text-align: center;
        }

        &.espcial-combo{
            .content {
                .form-control{
                    height: 38px;
                    font-size: 12px;
                }

                .items-list{
                    font-size: 12px;
                }
            }
        }

        .content{
            position: relative;
            .form-control{
                outline: none;
                box-shadow: none;
                border-radius: 50px;
                /*padding-right: 90px;*/

                line-height: 32px;
                color: #000;
                font-size: 12px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0;

            }

            .filtro{
                height: 32px;
            }

            .btn-buscar{
                border-radius: 0 50px 50px 0;
                outline: none;
                background: var(--color-primary);
                position: absolute;
                right: 0;
                top: 0;
                color: #fff;
                font-size: 16px;
                width: 60px;
                height: 38px;
                text-transform: uppercase;
                padding: 0 20px 0 15px;
                font-weight: bold;
                border: none;

                &.small{
                    width: 35px;
                    padding: 0 10px 0 8px;
                }

            }

            .items-list{
                /*opacity: 0;*/
                /*visibility: hidden;*/
                margin: 0;
                position: absolute;
                top: 100%;
                left: 0;
                max-height: 350px;
                border: 1px solid #f0f0f0;
                border-radius: 10px;
                overflow-y: auto;
                min-width: 200px;
                background: #fff;
                padding: 0;
                list-style: none;
                z-index: 2;
                transition-duration: 0.3s;

                -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
                -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
                box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);

                &.show{
                    visibility: visible;
                    opacity: 1;
                }


                &::-webkit-scrollbar {
                    width: 12px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: rgba(0,0,0,0.2);
                    border-radius: 0 50px 50px 0;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #555;
                    border-radius: 0 50px 50px 0;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #444;
                }


                .item{
                    text-align: left;
                    cursor: pointer;
                    /*&:first-child{*/
                        /*div{*/
                            /*border-radius: 10px 10px 0 0;*/
                        /*}*/
                    /*}*/

                    /*&:last-child{*/
                        /*div{*/
                            /*border-radius: 0 0 10px 10px;*/
                        /*}*/
                    /*}*/

                    div{
                        cursor: pointer;
                        padding: 5px 10px;
                        transition-duration: 0.2s;
                        border-bottom: 1px solid #f0f0f0;

                        p{
                            margin: 0;
                            padding: 0;
                            font-size: 14px;
                        }

                    }

                    &.active{
                        div{
                            color: #fff;
                            background: rgba(196,192,134);
                        }
                    }

                    &:hover{
                        div{
                            color: #fff;
                            background: rgba(196,192,134);
                        }
                    }
                }

            }

        }


    }

</style>
