<template>
  <button :disabled="disabled" class="btn btn-agregar" v-on:click="click">
    <i v-if="icon != null" :class="'fa ' + icon"></i>
    {{ title }}
  </button>
</template>

<script>
export default {
    name: 'ButtonAdd',
    props: {
        disabled: Boolean,
        title: String,
        icon: null
    },
    methods: {
        click: function (e) {
            this.$emit("click", e)
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .btn-agregar{
    outline: none;
    display: inline-block;
    font-size: 11px;
    line-height: 30px;
    padding: 0 15px;
    color: #fff;
    border-color: rgba(46, 30, 41, 1);
    background: rgba(46, 30, 41, 1);
    border-radius: 50px;
    text-transform: uppercase;
    font-weight: bold;

    &:hover{
      color: #fff;
    }

  }

</style>
