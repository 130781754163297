
<template>
    <div id="root">
        <div id="backImage" ></div>
        <div id="backMask"></div>


        <div id="loginBox" >

            <div class="brand">
                <img src="../../assets/logo-web.png" alt="">
            </div>

            <form v-on:submit="doLogin" v-if="token == null && forgotPass == null">

                <FormInput :valueInput.sync="login.username" required="true"
                           name="username" title="Usuario" />

                <FormInput required="true" title="Contraseña"
                           type="password"
                           name="password"
                           :valueInput.sync="login.password"/>

                <a class="forgot-button" v-on:click="toggleForgot">
                    ¿Olvidaste tu contraseña?
                </a>

                <div class="form-group button-submit">
                    <ButtonLoader ref="buttonLogin" type="submit" title="Entrar" />
                </div>
            </form>

            <form v-on:submit="doVerify" autocomplete="off" v-if="token != null && forgotPass == null">

                <h6 class="info-text">Hemos enviado código de verificación a tu correo electrónico para confirmar tu identidad</h6>

                <FormInput :valueInput.sync="verify.pin"
                           required="true" placeholder="Ingresa tu código de verificación"
                           name="pin" />


                <FormCheck v-model="verify.save"
                           valueInput="true"
                           name="save"
                           checked="true"
                           title="Recordar el dispositivo" />


                <div class="form-group button-submit">
                    <ButtonLoader ref="buttonVerify" type="submit" title="Enviar" />
                </div>
            </form>

            <form v-on:submit="forgotPassword" v-if="forgotPass != null">

                <FormInput :valueInput.sync="forgot.email" required="true"
                           name="username" title="Correo electrónico" />

                <div class="form-group button-submit">
                    <ButtonLoader ref="buttonForgot" type="submit" title="Enviar" />
                </div>

                <a class="forgot-button text-center" v-on:click="toggleForgot">
                    Iniciar sesión
                </a>

            </form>



        </div>

    </div>
</template>

<script>
    import FormInput from '@/components/Form/FormInput'
    import FormCheck from '@/components/Form/FormCheck'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'LoginScreen',
        components: {
            FormInput,
            FormCheck,
            ButtonLoader
        },
        data: function(){
            return {
                token: null,
                forgotPass: null,
                forgot: {
                    email: ''
                },
                login: {
                    username: '',
                    password: ''
                },
                verify: {
                    pin: '',
                    auth_token: null,
                    save: null
                },
                user: {}
            }
        },
        beforeCreate(){
            if(this.$route.params.token != undefined){
                let vue = this;
                let data = {auth_token: this.$route.params.token};
                $.get(this.API_URL + "/api/user", data, function (response) {
                    if(response.success){
                        vue.$emit("authenticated", response.auth_token, response.user, true);
                    }
                    else{
                        location.href = "/login";
                    }
                });
            }
        },
        mounted() {
            this.$emit("hideLoader");
        },
        methods: {
            setToken: function(token, user){
                this.token = token;
                this.user = user;
            },
            toggleForgot: function () {
              this.forgotPass = this.forgotPass ? null : true;
              this.forgot = {
                    email: ''
                };
            },
            forgotPassword: function (e) {
                e.preventDefault();

                let vue = this;

                vue.$refs.buttonForgot.setShowLoader(true);
                $.post(this.API_URL + "/api/forgot-password", this.forgot, function (res) {
                    vue.$refs.buttonForgot.setShowLoader(false);
                    if(res.success){
                        swal(res.message, "", "success");
                        vue.toggleForgot();
                    }else{
                        swal(res.message, "", "info");
                    }
                }, 'json').fail(function (error) {
                    vue.$refs.buttonForgot.setShowLoader(false);
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                });

            },
            doLogin: function(e){
                e.preventDefault();
                let vue = this;

                vue.$refs.buttonLogin.setShowLoader(true);
                $.post(this.API_URL + "/api/auth", this.login, function (res) {
                    vue.$refs.buttonLogin.setShowLoader(false);
                    if(res.success){
                        if(vue.$store.getters.last_auth_token === res.auth_token){
                            vue.auth(res.auth_token, res.user, true);
                        }
                        else{
                            vue.setToken(res.auth_token, res.user);
                        }
                    }else{
                        swal(res.message, "", "info");
                    }
                }, 'json').fail(function (error) {
                    vue.$refs.buttonLogin.setShowLoader(false);
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                });
            },
            doVerify: function (e) {
                e.preventDefault();
                let vue = this;
                vue.$refs.buttonVerify.setShowLoader(true);
                $.ajaxSetup({
                    headers: {
                        'X-AUTH-TOKEN': this.token
                    }
                });
                $.post(this.API_URL + "/api/account/verify", this.verify, function (res) {
                    vue.$refs.buttonVerify.setShowLoader(false);
                    if(res.success){
                        vue.auth(vue.token, vue.user, vue.verify.save != null);
                    }else{
                        swal(res.message, "", "info");
                    }
                }, 'json').fail(function (error) {
                    vue.$refs.buttonVerify.setShowLoader(false);
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                });
            },
            auth: function (token, user, save = false) {
                this.$emit("authenticated", token, user, save);
            }
        }
    }
</script>

<style scoped lang="scss">
    #root{
        position: relative;
        height: 100%;

        .btn-login{
            background: red !important;
        }

        .button-submit{
            padding-top: 15px;
        }

        .forgot-button{
            display: block;
            font-size: 14px;
            color: #c4c086;
            font-weight: 600;
            padding: 0 0 5px 0;
        }

    }

    .container{
        position: relative;
        height: 100%;
    }

    #loginBox{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        height: auto;

        background: rgba(255, 255, 255, 255);

        border-radius: 10px;
        padding: 25px;

        .brand{
            width: 100%;
            text-align: center;
            img{
                width: 171px;
            }
            padding-bottom: 25px;
        }

        form{

            color: #333;

            > p {
                font-weight: bold;
                font-size: 28px;
            }

            .info-text{
                font-size: 13px;
                line-height: 15px;
                padding-bottom: 15px;
                text-align: center;
                color: #999;
            }

        }

        @media all and (max-width: 400px){
            width: 300px;
            padding: 15px;
        }
    }



    #backMask{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(46, 30, 41, 0.95);
    }

    #backImage{
        position: absolute;
        width: 100%;
        height: 100%;
        /*background: rgba(46, 30, 41, 1);*/
        background: url("../../assets/images/1.jpg");
        background-position: 50% 0;
        -webkit-background-size: contain;
        background-size: cover;
    }

</style>
