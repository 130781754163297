import { render, staticRenderFns } from "./RevisionesInventarios.vue?vue&type=template&id=d8f55188&scoped=true&"
import script from "./RevisionesInventarios.vue?vue&type=script&lang=js&"
export * from "./RevisionesInventarios.vue?vue&type=script&lang=js&"
import style0 from "./RevisionesInventarios.vue?vue&type=style&index=0&id=d8f55188&prod&lang=scss&"
import style1 from "./RevisionesInventarios.vue?vue&type=style&index=1&id=d8f55188&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8f55188",
  null
  
)

export default component.exports