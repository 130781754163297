<template>
    <div>
        <label v-if="title != null">{{ title }}</label>
        <div class="select-content">
            <select :required="required" :value="valueInput" class="form-control"
                    @input="update"
                    @change="change"
                    :name="name != null ? name : ''">
                <slot></slot>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FiltroSelect',
        model: {

        },
        props: {
            title: String,
            name: null,
            required: Boolean(false),
            valueInput: String('')
        },
        methods: {
            update: function (e) {
                this.$emit('update:valueInput', e.target.value)
            },
            change(e){
                this.$emit('change', e)
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .select-content{

        border-radius: 50px;
        padding: 0 5px;
        height: 32px;
        border: 1px solid #dcdde1;
        background: #fff;

        .form-control{
            font-size: 12px;
            position: relative;
            top: 5px;
            padding-top: 0;
            padding-bottom: 0;
            outline: none;
            //height: ;
            height: calc(1.5em + .75rem - 10px);
            box-shadow: none;
            border: none;

        }
    }


</style>
