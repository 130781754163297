<template>
  <div class="button-option">
        <button @click="click" class="btn btn-block btn-option">
            <i :class="'fas ' + icon "></i>
            <span>
                {{ title }}
            </span>
        </button>
  </div>
</template>

<script>
    export default {
        name: 'ButtonOptionItem',
        props: {
            title: String,
            icon: null,
        },
        data(){
            return{

            }
        },
        methods: {
            click: function () {
                this.$emit("click", this)
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .button-option{
        display: block;
        background: #fff;
        .btn-option{
            outline: none;
            box-shadow: none;
            color: #666;
            text-align: left;
            border-radius: 0;
            padding: 6px 20px;

            svg{
                width: 20px;
                text-align: center;
            }

            &:hover{
                background: #eee;
            }

            span{
                padding-left: 15px;
            }
        }

    }






</style>
