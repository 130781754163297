<template>
    <div class="modal fade" ref="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{ title }}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body" style="padding: 0;">
                    <slot name="body"></slot>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer" style="padding: 0;">
                    <!--<button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>-->
                    <slot name="footer"></slot>
                </div>

            </div>
        </div>
    </div>

</template>

<script>

import * as $ from 'jquery';

export default {
    name: 'ModalDetail',
    components: {
    },
    props: {
        title: String('')
    },
    data: function(){
        return {

        }
    },
    methods: {
        show: function () {
            $(this.$refs.modal).modal("show");
        },
        hide: function () {
            $(this.$refs.modal).modal("hide");
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .modal-footer{
        & > div:first-child button{
            border-bottom-left-radius: .3rem;
        }
        & > div:last-child button{
            border-bottom-right-radius: .3rem;
        }
    }
</style>
