<template>
    <div class="form-group">
        <label v-if="title != null">{{ title }}</label>
        <textarea v-model="valor" class="form-control"
                  ref="textArea"
                  v-on:keypress="keypress"
                  @input="update"
                  :required="required"
                  :rows="rows == null ? 3 : rows"
                  :placeholder="placeholder == null ? 'Ingresa ' + title.toLowerCase() : placeholder"
                  name="name != null ? name : ''"></textarea>
        <!--<input :required="required" :value="valueInput" :type="typeInput" :class="'form-control ' + type"-->

               <!--step="0.01"-->
               <!--:placeholder="placeholder == null ? 'Ingresa ' + title.toLowerCase() : placeholder"-->
               <!--:name="name != null ? name : ''">-->
    </div>
</template>

<script>
    export default {
        name: 'FormArea',
        model: {

        },
        props: {
            consume: null,
            title: String,
            name: null,
            rows: null,
            required: Boolean(false),
            placeholder: null,
            valueInput: String('')
        },
        data: function(){
            return {
                valor: this.valueInput,
                typeInput: this.type != null ?
                    this.type == 'currency' ?
                        'number' : this.type
                    : 'text'
            }
        },
        methods: {
            update: function (e) {
                this.$emit('update:valueInput', e.target.value)
            },
            setValue: function (value) {
                this.valor = value;
                this.$emit('update:valueInput', value);
                this.$forceUpdate();
            },
            keypress: function (evt) {
                // evt = evt || window.event;
                if(this.consume != undefined && this.consume != null){

                    if(evt.key == ','){
                        evt.preventDefault();
                    }

                }
                // console.log();
                // evt.cancelBubble = true;
                // if (typeof evt.stopPropagation != ',') {
                //     console.log("!11");
                //     evt.stopPropagation();
                // } else {
                //     console.log("!22");
                //
                // }
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .form-control{
        /*border-radius: 50px;*/
    }

    .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 0.7; /* Firefox */
    }

    .form-control:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 0.7; /* Firefox */
    }

    .form-control::-ms-input-placeholder  { /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 0.7; /* Firefox */
    }

    .form-control.currency{
        /*background: red;*/
    }

</style>
