<template>
  <div id="root">
    <div class="header-section">
      <div v-if="cliente == null" class="title">
        <h2>Estado de Cuenta</h2>
      </div>
      <div v-else class="title subtitle">
        <h2>
          Estado de Cuenta
          <br>
          <span>
              {{ cliente.nombre }} {{ cliente.apellidos }}
          </span>
        </h2>
      </div>
      <div class="buttons">
        <div class="select-check-content">
          <SelectCheck ref="selectFiltro"
                       v-on:change="filtrarChange"
                       placeholder="Todos los actos"/>
        </div>
        <div class="date-range">
          <DateRangePicker :icons="true"
                           :fija="true"
                           :dateInicio.sync="fechaInicio"
                           :dateFin.sync="fechaFin"
                           placeholderInicio="Fecha inicio"
                           placeholderFin="Fecha fin"
                           format="DD-MM-YYYY"
                           ref="fechas"
                           v-on:change="first(true)"/>
        </div>
        <div class="buscador-content">
          <InputSearchComplete align="right" :valueInput.sync="filtro"
                               table="empresas"
                               @click="searchClick"
                               title="Buscar" />
        </div>
        <div class="button-add-top">
          <ButtonOptions title="Imprimir todo" >
            <ButtonOptionItem v-on:click="imprimirCuenta(false)" title="Normal" icon="fa-print" />
            <ButtonOptionItem v-on:click="imprimirCuenta(true)" title="Desglosado" icon="fa-file-pdf" />
          </ButtonOptions>
        </div>

        <div class="button-export-top">
          <button class="btn btn-exportar" v-on:click="exportarCuenta()">
            <i class="fa fa-file-excel"></i>
            <span>
                Exportar
            </span>
          </button>
        </div>

      </div>
    </div>

    <div class="row justify-center saldos-list">
      <div class="col-12 col-sm-6 col-md-4">
        <div class="item">
          Actos
          <p>{{ precio | toCurrency }}</p>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <div class="item">
          Abonos
          <p>{{ abonos | toCurrency }}</p>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <div class="item">
          Saldo actual
          <p :class="'total-color' + (saldo < 0 ? ' green_text' : '') + (saldo > 0 ? ' red_text' : '')">
            {{ saldo > 0 ? '-' : '' }}{{ saldo < 0 ? '+' : '' }}{{ saldo | toCurrencyNormal }}
          </p>
        </div>
      </div>

    </div>

    <div class="content-section scroll-horizontal-enable">
      <CustomTable :imprimir="true">
        <template v-slot:header>
          <div class="col-5 col-sm-5">Acto</div>
          <div class="col-2 col-sm-2 text-center">Fecha vencimiento</div>
          <div class="col-1 col-sm-1 text-center">Precio</div>
          <div class="col-1 col-sm-1 text-center">Abonos</div>
          <div class="col-1 col-sm-1 text-center">Saldo</div>
        </template>
        <template v-slot:body>
          <div v-if="actosFinalizados.length == 0 && noActosFinalizados.length == 0"  class="col-12 text-center">
            Sin resultados
          </div>
          <TableRow v-if="noActosFinalizados.length != 0" :dropDown="true"
                    key="k"
                    :proveedor="true"
                    :detail="false"
                    :imprimirhead="true"
                    id="k"
                    ref="row-k">
            <template v-slot:option>
              <div class="col-7 col-sm-7 nombre minimizar">
                Sin acto asignado
              </div>
              <div class="col-1 col-sm-1 text-center minimizar">
              </div>
              <div class="col-1 col-sm-1 text-center minimizar">
              </div>
              <div class="col-1 col-sm-1 text-center minimizar">
              </div>
            </template>
            <template v-slot:suboptions>
              <div v-for="(concepto, index) in noActosFinalizados"
                   :key="index"
                   @click="clickReciboDetalle(concepto)"
                   class="row row-item subs clickable"
                   v-bind:class="{selected: selected(concepto.id)}">
                <div class="col-8 col-sm-8 nombre">
                                    <span v-if="concepto.recibo != null">
                                        {{ concepto.recibo.folio_letra }} -
                                    </span>
                  <span v-if="concepto.recibo.cliente != null">
                                        {{ concepto.recibo.cliente.nombre }} {{ concepto.recibo.cliente.apellidos }}
                                    </span>
                  <span v-else>{{ concepto.recibo.proveedor }}</span>
                  - {{ concepto.descripcion }}
                  <span v-if="concepto.cuenta_movimiento != null"> - {{ concepto.cuenta_movimiento.fecha_movimiento | dateFormatMX }}</span>
                </div>
                <div class="col-3 col-sm-1 text-center">
                  {{ concepto.importe | toCurrency }}
                </div>
              </div>
            </template>
          </TableRow>
          <TableRow v-for="(item, index) in actosFinalizados" :dropDown="actosFinalizados.length > 0"
                    :key="index"
                    :proveedor="true"
                    :imprimirrow="true"
                    :imprimirUrlCliente="API_URL+'/acto/'+item.id+'?cliente=si'"
                    v-on:option="detalleActo(item)"
                    :id="item.id"
                    :ref="`row-${item.id}`">
            <template v-slot:option>
              <div class="col-5 col-sm-5 nombre minimizar">
                {{ item.nombre }}
              </div>
              <div class="col-2 col-sm-2 text-center minimizar">
                <div v-if="item.fecha_pago == undefined">
                    {{ item.limite_pago | dateFormat }} &nbsp;
                    <span :class="'status ' + (item.dias_limite != undefined ? (item.dias_limite > 0 ? 'warning' : 'urgente') : 'normal-dias capitalize')">
                      <span v-if="item.dias_limite != undefined">{{ item.dias_limite }} d</span>
                      <span v-else>Sin finalizar</span>
                    </span>
                </div>
                <div v-else>
                    <span class="status normal">
                        <i class="fas fa-check"></i>
                    </span>
                </div>
              </div>
              <div class="col-1 col-sm-1 text-center minimizar">
                {{ item.precio | toCurrency }}
              </div>
              <div class="col-1 col-sm-1 text-center minimizar">
                                <span>
                                    {{ item.abonos | toCurrency }}
                                </span>
              </div>
              <div class="col-1 col-sm-1 text-center minimizar">
                <p v-if="(convertPrice(item.precio) - convertPrice(item.abonos)) != 0" :class="'total-color'
                + ((convertPrice(item.precio) - convertPrice(item.abonos)) < 0 ? ' green_text' : '')
                + ((convertPrice(item.precio) - convertPrice(item.abonos)) > 0 ? ' red_text' : '')">
                  {{ (convertPrice(item.precio) - convertPrice(item.abonos))  > 0 ? '-' : '+' }}{{ (item.precio - item.abonos) | toCurrencyNormal }}
                </p>
                <p v-else class="total-color">$0.00</p>
              </div>
            </template>
            <template v-slot:suboptions>
              <div v-for="(concepto, index) in item.recibo_ingresos_conceptos_finalizados"
                   :key="index"
                   @click="clickReciboDetalle(concepto)"
                   class="row row-item subs clickable"
                   v-bind:class="{selected: selected(concepto.id)}">
                <div class="col-8 col-sm-8 nombre">
                                    <span v-if="concepto.recibo != null">
                                        {{ concepto.recibo.folio_letra }} -
                                    </span>
                  <span v-if="concepto.recibo.cliente != null">
                                        {{ concepto.recibo.cliente.nombre }} {{ concepto.recibo.cliente.apellidos }}
                                    </span>
                  <span v-else>{{ concepto.recibo.proveedor }}</span>
                  - {{ concepto.descripcion }}
                  <span v-if="concepto.cuenta_movimiento != null"> - {{ concepto.cuenta_movimiento.fecha_movimiento | dateFormatMX }}</span>
                </div>
                <div class="col-3 col-sm-1 text-center">
                  {{ concepto.importe | toCurrency }}
                </div>
              </div>
            </template>
          </TableRow>
          <div class="footer pages">
            <div class="paginater">
              <div class="text">
                <p>
                  {{ page }} de {{ lastPage }}
                </p>
              </div>
              <div class="buttons">
                <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                  <i class="fas fa-angle-double-left"></i>
                </button>
                <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                  <i class="fas fa-angle-left"></i>
                </button>
                <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                  <i class="fas fa-angle-right"></i>
                </button>
                <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                  <i class="fas fa-angle-double-right"></i>
                </button>
              </div>
            </div>
          </div>
        </template>
      </CustomTable>

    </div>

    <ModalDetail ref="actoFinalizado" title="Acto Detalle">
      <template v-slot:body>
        <table class="table-detail">
          <tbody>
          <tr>
            <td>
              <b>Empresa</b>
            </td>
            <td>
              <span v-if="acto.empresa != null">{{ acto.empresa.nombre_completo }}</span>
              <span v-else>--</span>
            </td>
          </tr>
          <tr>
            <td>
              <b>Fedatario</b>
            </td>
            <td>
              <span v-if="acto.notario != null">{{ acto.notario.no_fedatario }}</span>
              <span v-else>--</span>
            </td>
          </tr>
          <tr>
            <td>
              <b>Servicio</b>
            </td>
            <td>
              <span v-if="acto.servicio != null">{{ acto.servicio.nombre }}</span>
              <span v-else>--</span>
            </td>
          </tr>
          <tr v-if="acto.categoria != null">
            <td>
              <b>Categoría de ingresos</b>
            </td>
            <td>
              <span>{{ acto.categoria.nombre }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <b>Fecha de inicio</b>
            </td>
            <td>
              <span>{{ acto.fecha_inicio | dateFormat }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <b>Fecha de fin</b>
            </td>
            <td>
              <span>{{ acto.fecha_fin | dateFormat }}</span>
            </td>
          </tr>
          <tr>
            <td><b>Fecha de vencimiento</b></td>
            <td v-if="acto.fecha_pago != undefined">
              Pagado
            </td>
            <td v-else>
              {{ acto.fecha_limite | dateFormat }} &nbsp;
              <span :class="'status ' + (acto.dias_limite != undefined ? (acto.dias_limite > 0 ? 'warning' : 'urgente') : 'normal-dias capitalize')">
                <span v-if="acto.dias_limite != undefined">{{ acto.dias_limite }} d</span>
                <span v-else>Sin finalizar</span>
              </span>
            </td>
          </tr>
          <tr v-if="checkPrivilege('actos.precios')">
            <td>
              <b>Precio de venta</b>
            </td>
            <td>
              <span>{{ acto.precio | toCurrency }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <b>Abonos</b>
            </td>
            <td>
              <a :href="'/actos-conceptos-ingresos_'+acto.id" target="_blank" class="normal-link">
                {{ acto.abonos | toCurrency }}
              </a>
            </td>
          </tr>

          <tr>
            <td>
              <b>Abonos</b>
            </td>
            <td>
                            <span v-if="acto.precio > 0">
                                {{ (acto.precio - acto.abonos) | toCurrency }}
                            </span>
              <span v-else>
                                {{ acto.precio | toCurrency }}
                            </span>
            </td>
          </tr>
          </tbody>
        </table>
      </template>
      <template v-slot:footer>
        <div class="col" style="padding: 0; margin: 0;">
          <a :href="API_URL+'/acto/'+acto.id+'?cliente=si'" class="btn-footer-link" target="_blank">
            <i class="fas fa-file-pdf"></i>
            <p>
              Imprimir
            </p>
          </a>
        </div>
      </template>
    </ModalDetail>

    <ModalDetail ref="reciboDetalle" title="Recibo Detalle">
      <template v-slot:body>
        <table class="table-detail">
          <tbody>
          <tr>
            <td>
              <b>Folio</b>
            </td>
            <td>
              {{ recibo.folio_letra }}
            </td>
          </tr>
          <tr>
            <td>
              <b>Usuario</b>
            </td>
            <td>
                            <span v-if="recibo.usuario != undefined">
                                {{ recibo.usuario.name }}
                            </span>
              <span v-else>--</span>
            </td>
          </tr>
          <tr>
            <td>
              <b>Tipo</b>
            </td>
            <td>
              {{ recibo.tipo == 'i' ? 'Ingreso' : 'Salida' }}
            </td>
          </tr>
          <tr v-if="recibo.tipo == 'i'">
            <td>
              <b>Cliente</b>
            </td>
            <td>
                            <span v-if="recibo.cliente != undefined">
                                {{ recibo.cliente.nombre }} {{ recibo.cliente.apellidos }}
                            </span>
              <span v-else>--</span>
            </td>
          </tr>
          <tr v-else>
            <td>
              <b>Proveedor</b>
            </td>
            <td>
                            <span>
                                {{ recibo.proveedor }}
                            </span>
            </td>
          </tr>
          <tr>
            <td>
              <b>Fecha</b>
            </td>
            <td>
              <span>{{ recibo.fecha | dateFormat }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <b>Total</b>
            </td>
            <td>
              <span>{{ recibo.total | toCurrency }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <b>Pagado</b>
            </td>
            <td>
                            <span :class="'status ' + (recibo.pagado  == 1 ? 'active' : 'inactive')" >
                                {{ recibo.pagado == 1 ? 'Si' : 'No' }}
                            </span>
            </td>
          </tr>
          </tbody>
        </table>
      </template>
    </ModalDetail>

    <div v-if="movimientosSeleccionados.length > 0" class="button-floting">
      <button class="btn-delete" @click="imprimirSeleccionados">
        <i class="fas fa-print"></i>
        <p>
          Imprimir seleccionados
        </p>
      </button>
    </div>

  </div>

</template>

<script>
import InputSearchComplete from '@/components/Filter/InputSearchComplete'
import CustomTable from '@/components/Table/CustomTable'
import TableRow from '@/components/Table/TableRow'
import ModalDetail from '@/components/Modal/ModalDetail'
import ButtonOptionItem from '@/components/ButtonOption/ButtonOptionItem'
import ButtonOptions from '@/components/ButtonOption/ButtonOptions'
import DateRangePicker from '@/components/DatePicker/DateRangePicker'
import SelectCheck from '@/components/Form/SelectCheck'
import * as $ from 'jquery';
import swal from 'sweetalert2';

export default {
  name: 'CuentaClienteScreen',
  components: {
    ButtonOptionItem,
    ButtonOptions,
    DateRangePicker,
    ModalDetail,
    CustomTable,
    TableRow,
    InputSearchComplete,
    SelectCheck
  },
  data: function(){
    return {
      precio: 0,
      abonos: 0,
      saldo: 0,
      cliente_id: this.$route.params.clienteId,
      page: 1,
      lastPage: 1,
      cliente: undefined,
      fechaInicio: '2018-01-01',
      fechaFin: '',
      filtro: '',
      actosFinalizados: [],
      noActosFinalizados: [],
      recibo: [],
      acto: [],
      clicks: 0,
      timer: null,
      delay: 250,
      refreshTable: 0,
      movimientosSeleccionados: [],
      filtroEstatus: [],
      selectFiltro: [
        {
          key: "pagados",
          title: "Pagados",
          checked: true
        },
        {
          key: "no_pagados",
          title: "No Pagados",
          checked: true
        }
      ],
    }
  },
  computed: {
  },
  mounted() {
    this.$emit("showLoader");
    this.$refs.selectFiltro.setData(this.selectFiltro);
    this.getActosFinalizados();
  },
  methods: {
    convertPrice(price){
      let options2 = {style: 'currency', currency: 'USD', minimumFractionDigits: 2};
      let formatter = new Intl.NumberFormat('en-US', options2);

      return Number(formatter.format(price).replace(/[^0-9.-]+/g,""));
    },
    urlfy(obj) {
      return Object
          .keys(obj)
          .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
          .join('&');
    },
    selected(item){
      return this.movimientosSeleccionados.indexOf(item) > -1;
    },
    clickReciboDetalle(item){
      this.clicks++;
      let id = item.id;
      if(this.clicks === 1) {
        var vue = this;
        this.timer = setTimeout(function() {
          vue.clicks = 0
        }, this.delay);

        let index = this.movimientosSeleccionados.indexOf(id);
        if(index > -1){
          this.movimientosSeleccionados.splice(index, 1);
        }
        else{
          this.movimientosSeleccionados.splice(0, 0, id);
        }
      } else{
        clearTimeout(this.timer);
        let index = this.movimientosSeleccionados.indexOf(id);
        if(index > -1){
          this.movimientosSeleccionados.splice(index, 1);
        }
        this.reciboDetalle(item.recibo);
        this.clicks = 0;
      }

      this.refreshTable++;

    },
    filtrarChange: function () {
      this.filtroEstatus = this.$refs.selectFiltro.getSelecteds();
      this.first(true);
    },
    imprimirSeleccionados(){
      let url = this.API_URL + "/cliente/cuenta/"+this.cliente_id;
      url += "?seleccionado="+this.movimientosSeleccionados;
      url += "&desglosar=true";
      window.open(url);
    },
    exportarCuenta(){
      let data = {filtro: this.filtro, data: btoa(this.$store.getters.auth_token),
        fecha_inicio: this.fechaInicio, fecha_fin: this.fechaFin};
      data = this.urlfy(data);
      window.open(this.API_URL + "/cobranza/exportar/"+this.cliente_id+"/?"+data);
    },
    imprimirCuenta(desglose){
      let url = this.API_URL + "/cliente/cuenta/"+this.cliente_id;
      url += "?fecha_inicio="+this.fechaInicio;
      url += "&fecha_fin="+this.fechaFin;
      url += "&filtro="+this.filtro;
      if(this.filtroEstatus.length == 1){
        url += "&estatus="+this.filtroEstatus[0];
      }
      console.log();
      if(desglose){
        url += "&desglosar=true";
      }
      window.open(url);
    },
    searchClick(){
      this.first(true);
    },
    next: function () {
      this.page += 1;
      if(this.page > this.lastPage){
        this.page = this.lastPage;
      }
      else{
        this.getActosFinalizados();
      }
    },
    prev: function () {
      this.page -= 1;
      if(this.page < 1){
        this.page = 1;
      }
      else{
        this.getActosFinalizados();
      }

    },
    last: function () {
      if(this.page != this.lastPage) {
        this.page = this.lastPage;
        this.getActosFinalizados();
      }
    },
    first: function (refresh) {
      if(this.page != 1) {
        this.page = 1;
        this.getActosFinalizados();
      }
      else if(refresh){
        this.page = 1;
        this.getActosFinalizados();
      }
    },
    detalleActo: function (item) {
      this.acto = item;
      this.$refs.actoFinalizado.show();
    },
    getActosFinalizados: function () {
      this.$emit("showLoader");
      let vue = this;
      vue.actosFinalizados = [];
      vue.noActosFinalizados = [];
      let data = {
        fecha_inicio: this.fechaInicio,
        cliente_id: this.cliente_id,
        page: this.page,
        filtro: this.filtro,
        fecha_fin: this.fechaFin,
        estatus: this.filtroEstatus
      };
      $.get(this.API_URL + "/api/clientes/cuenta", data, function (response) {
        vue.$emit("hideLoader");
        if(response.success){
          vue.precio = response.precio;
          vue.abonos = response.abonos;
          vue.saldo = response.saldo;
          vue.cliente = response.cliente;
          vue.page = response.actos.current_page;
          vue.lastPage = response.actos.last_page;
          vue.actosFinalizados = response.actos.data;
          vue.noActosFinalizados = response.no_actos;
          if(vue.fechaInicio === '' || vue.fechaFin === ''){
            vue.fechaFin = response.fecha_fin;
            vue.$refs.fechas.setDates("2018-01-01", response.fecha_fin);
          }
        }
        else{
          swal(response.message, '', 'info')
        }
      }, "json").fail(function (error) {
        vue.$emit("hideLoader");
        if(error.status === 401){
          swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
          vue.$emit("logout");
        }
        else{
          if(error.readyState == 0){
            swal("Se perdió la conexión a internet", "", "warning");
          }
          else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
            swal(error.responseJSON.message, "", "warning");
          }
          else{
            swal("Error inesperado", "", "warning");
          }
        }
      });
    },
    reciboDetalle(recibo){
      if(recibo != undefined){
        this.recibo = Object.assign({}, recibo);
        this.$refs.reciboDetalle.show();
      }
    }
  }
}
</script>

<style lang="scss">
  .content-section.scroll-horizontal-enable{
      .table-drop-down{
          overflow-x: auto;
          .row-table.clickable, .headers-table{
              min-width: 1000px;
          }
      }
  }
</style>

<style scoped lang="scss">


#root{
  background: #f7f8fc;
  padding: 30px 25px;
  width: 100%;
  height: 100%;

  padding-bottom: 100px;

  .totales-float{
    position: fixed;
    width: 100%;
    right: 20px;
    text-align: right;
    bottom: 20px;

    p{
      display: inline-block;
      text-transform: uppercase;
      color: #fff;
      padding: 10px 30px;
      background: #c4c086;
      border-radius: 10px;
      margin: 10px 10px 0 0;
      font-weight: 600;
      font-size: 16px;
    }

    @media all and (max-width: 768px) {
      left: 15px;
      right: inherit;
    }

    @media all and (max-width: 576px){
      left: 5px;
      p{
        font-size: 12px;
      }
    }

  }

  .negative{
    color: #ff5b57;
  }
  .positive{
    color: #28a745;
  }

  .btn-footer-link{
    display: block;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    background: #007bff;
    p{
      display: inline-block;
      padding: 0 10px;
      margin: 0;
    }

    @media all and (max-width: 768px){
      font-size: 12px;
      p{
        display: block;
      }
    }
  }

  .btn-group button {
    background-color: #4CAF50; /* Green background */
    border: 1px solid green; /* Green border */
    color: white; /* White text */
    padding: 10px 24px; /* Some padding */
    cursor: pointer; /* Pointer/hand icon */
    width: 50%; /* Set a width if needed */
    display: block; /* Make the buttons appear below each other */
  }

  .btn-group button:not(:last-child) {
    border-bottom: none; /* Prevent double borders */
  }

  /* Add a background color on hover */
  .btn-group button:hover {
    background-color: #3e8e41;
  }

  .button-save-content{
    width: 120px;
  }

  .select-filtro{
    margin-right: 10px;
    width: 120px;
    vertical-align: bottom;
    display: inline-block;
  }

  .select-check-content{
    display: inline-block;
    margin-right: 10px;
    top: -1px;
    position: relative;
    width: 160px;
    vertical-align: bottom;
  }

  @media all and (max-width: 768px){
    padding: 15px 15px;
    padding-bottom: 100px;
  }

  @media all and (max-width: 576px){
    padding: 10px 5px;
    padding-bottom: 150px;
  }

  .btn-more{
    color: #aaa;
    &:hover{
      color: #333;
    }
  }

  .button-floting{
    position: fixed;
    left: 245px;
    bottom: 35px;

    @media all and (max-width: 1200px){
      left: 25px;
    }

    .btn-delete{
      outline: none;
      padding: 10px 15px;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      background-color: #339AF0;
      color: rgb(255, 255, 255);
      p{
        display: inline-block;
        padding: 0 0 0 10px;
        margin: 0;
      }

    }
  }

  .button-export-top{
    display: inline-block;
    margin-left: 10px;
    .btn-exportar{
      outline: none;
      display: inline-block;
      font-size: 13px;
      line-height: 29px;
      padding: 0 15px;
      color: #fff;
      border-color: #217344;
      background: #217344;
      border-radius: 50px;
      text-transform: uppercase;
      font-weight: bold;

      span{
        margin-left: 10px;
        font-size: 11px;
      }

      &:hover{
        color: #fff;
      }
    }
  }

  .total-color{
    margin-bottom: 0;
    &.green_text{
      color: #29cc97;
    }
    &.red_text{
      color: #f12b2c;
    }
  }

  .saldos-list{
      position: relative;
      margin-top: 10px;
      text-align: center;
      width: calc(100% + 24px);
      .item {
        background: #fff;
        text-transform: uppercase;
        padding: 20px 10px;
        font-weight: 600;
        font-size: 14px;
        border-radius: 10px;
        border: 1px solid #dfe0eb;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

        p {
          margin: 0;
          padding: 0;
          font-size: 25px;
          margin-top: 5px;
        }

      }
  }

}

</style>
