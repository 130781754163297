<template>
  <div class="buscador-filtro" v-click-outside="onClose">
      <input :value="valueInput" type="text" :placeholder="title"
             ref="searchAutocomplete"
             @input="update"
             @keyup.enter="click">
      <button v-on:click="click"><i class="fa fa-search"></i></button>
      <ul v-show="show && elementsFiltrados.length > 0 && value != ''" :class="'items-list ' + align">
          <li v-for="(dato, index) in elementsFiltrados" :key="index" class="item">
              <div @click="setValueInput(dato)">
                  <p>
                      {{ dato.title }}
                  </p>
              </div>
          </li>
      </ul>
  </div>
</template>

<script>
import * as $ from 'jquery';
import swal from 'sweetalert2';
export default {
    name: 'InputSearchComplete',
    props: {
        title: String,
        align: String,
        table: String,
        type: null,
        valueInput: String
    },
    data(){
        return{
            elementsFiltrados: [],
            url: this.API_URL + "/api/completes/tables",
            value: "",
            show: false,
            listener: undefined
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init(){
            let vue = this;
            this.$refs.searchAutocomplete.addEventListener("keyup", () => {
                clearTimeout(vue.listener);
                vue.listener = setTimeout(function (){
                  vue.autocomplete();
                }, 500);
            });
        },
        deleteItem(item){
            this.elementsFiltrados.splice(this.elementsFiltrados.indexOf(item), 1);
            this.$emit('delete', item)
        },
        setValueInput(item){
            this.value = item.title;
            // this.$refs.searchAutocomplete.value = item.title;
            this.$emit('update:valueInput', this.value);
            this.click();
        },
        autocomplete(){
            let data = {table: this.table, value: this.value, };
            var vue = this;
            $.get(this.url, data, function (response) {
                vue.elementsFiltrados = response.results;
            }, "json").fail(function (error) {
                if(error.status === 401){
                    swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                    vue.$emit("logout");
                }
                else{
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                }
            });
        },
        update: function (e) {
            this.show = true;
            this.value = e.target.value;
            this.$emit('update:valueInput', e.target.value)
        },
        onClose () {
            this.show = false;
        },
        click: function () {
            this.elementsFiltrados = [];
            this.$emit("click", this);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .buscador-filtro{
        display: inline-block;
        position: relative;
        input{
            outline: none;
            box-shadow: none;
            border: 1px solid #dcdde1;
            width: 120px;
            height: 32px;
            font-size: 12px;
            border-radius: 50px;
            padding: 0 35px 0 15px;
            background: #ffffff;
            transition-duration: 0.3s;

            &:focus{
                outline: none;
                box-shadow: none;
                width: 150px;
            }

            &::placeholder {
                color: #1f1f21;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #1f1f21;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #1f1f21;
            }

        }
        button{
            position: absolute;
            right: 15px;
            font-size: 13px;
            top: 2px;
            height: 100%;
            background: transparent;
            border: none;
            color: #1f1f21;
            outline: none;
        }

        .items-list{
            margin: 2px 0 0 0;
            position: absolute;
            top: 100%;
            left: 0;
            max-height: 350px;
            border: 1px solid #f0f0f0;
            border-radius: 10px;
            overflow-y: auto;
            width: 250px;
            background: #fff;
            padding: 0;
            list-style: none;
            z-index: 2;
            transition-duration: 0.3s;
            -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
            -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
            box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);

            &.right{
                left: inherit;
                right: 0;
            }

            &.center{
                left: 50%;
                transform: translateX(-50%);
            }

            &.left{
                left: 0;
            }

            &::-webkit-scrollbar {
                width: 2px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: rgba(0,0,0,0.2);
                border-radius: 0 50px 50px 0;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #555;
                border-radius: 0 50px 50px 0;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #444;
            }

            .item{
                position: relative;
                text-align: left;
                cursor: pointer;

                .btn-buscar{
                    width: 30px;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    right: 0px;
                    transform: translateY(-50%);
                }

                div{
                    cursor: pointer;
                    padding: 10px 30px 10px 10px;
                    transition-duration: 0.2s;
                    border-bottom: 1px solid #f0f0f0;

                    p{
                        font-size: 12px;
                        margin: 0;
                        padding: 0;
                    }

                }

                &:hover{
                    div{
                        color: #fff;
                        background: rgba(196,192,134);
                    }
                }

                &.no-hover:hover{
                    div{
                        color: #2c3e50;
                        background: #fff;
                    }
                }

            }

        }

    }


</style>
