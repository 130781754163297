<template>
    <div id="root">
        <div class="button-add-bottom">
            <ButtonFloat v-if="checkPrivilege('gastos.crear')" v-on:click="nuevaGasto"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Gastos Predeterminados</h2>
            </div>
            <div class="buttons">
                <div class="select-clientes">
                    <SelectComboBoxSearch ref="tipos"
                                          :small="true"
                                          placeholder="Todos los tipos"
                                          @change="filtrarChange"/>
                </div>
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         table="gastos"
                                         @click="searchClick"
                                         title="Buscar" />
                </div>
                <div v-if="checkPrivilege('gastos.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="nuevaGasto" title="Nuevo +" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th v-for="(item, index) in headers" :width="item.width"
                            :key="index"
                            :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                            {{ item.text }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="gastos.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in gastos" :key="index" v-on:click="detalleGasto(item)">
                        <td :class="headers[0].hide">
                            <span v-if="item.tipo != null">
                                {{ item.tipo.nombre }}
                            </span>
                            <span v-else>Sin acto</span>
                        </td>
                        <td :class="headers[1].hide">
                            {{ item.descripcion }}
                        </td>
                        <td :class="headers[2].hide">
                            <span v-if="item.categoria != null">{{ item.categoria.nombre }}</span>
                        </td>
                        <td :class="headers[3].hide">
                            <span v-if="item.cuenta != null">{{ item.cuenta.nombre }}</span>
                        </td>
                        <td :class="headers[4].hide">
                            <span v-if="item.importe != null">{{ item.importe | toCurrency }}</span>
                        </td>
                        <td :class="headers[5].hide">
                            {{ item.updated_at | dateTimeFormatMX }}
                        </td>
                        <td :class="headers[6].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <ModalForm ref="gastoForm"
                   title="Gasto Predeterminados"
                   v-on:submit="saveGasto">
            <template v-slot:body>
                <input type="hidden" v-model="gasto.id">

                <FormSelect :valueInput.sync="gasto.tipo_id" name="tipo_id"
                            title="Tipo de acto">
                    <option :value="null">Sin acto</option>
                    <option v-for="(item, indexTipo) in actosTipos" :key="indexTipo"
                            :value="item.id">{{ item.nombre }}</option>
                </FormSelect>
                <FormInput :valueInput.sync="gasto.descripcion"
                           name="descripcion" title="Descripción"
                           :required="true"
                           @input="ordenarCategoriasSugerencias()"/>

                <ComboBoxSearch ref="cuentas" title="Cuentas (opcional)"
                                placeholder="Seleccionar cuenta"/>

                <ComboBoxSearchSubtitle ref="categoriaGasto" title="Categoría (opcional)"
                                        placeholder="Selecciona la categoría"/>

                <FormInput :valueInput.sync="gasto.importe" :required="false"
                           type="number"
                           name="importe" title="Importe (opcional)"
                           placeholder="$0.00"/>

            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonGasto" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>

        <ModalDetail ref="gastoDetalle" title="Gasto Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Tipo</b>
                        </td>
                        <td>
                            <span v-if="gasto.tipo != null">{{ gasto.tipo.nombre }}</span>
                            <span v-else>Sin acto</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Descripción</b>
                        </td>
                        <td>
                            {{ gasto.descripcion }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Categoría</b>
                        </td>
                        <td>
                            <span v-if="gasto.categoria != null">{{ gasto.categoria.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Cuenta</b>
                        </td>
                        <td>
                            <span v-if="gasto.cuenta != null">{{ gasto.cuenta.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Importe</b>
                        </td>
                        <td>
                            <span v-if="gasto.importe != null">{{ gasto.importe | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Última actualización</b>
                        </td>
                        <td>
                            {{ gasto.updated_at | dateTimeFormatMX }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>
                <div v-if="checkPrivilege('gastos.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editGasto"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div v-if="checkPrivilege('gastos.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteGasto"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>

    </div>
</template>

<script>
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ModalForm from '@/components/Modal/ModalForm'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import FormInput from '@/components/Form/FormInput'
    import ComboBoxSearch from '@/components/Form/ComboBoxSearch'
    import ComboBoxSearchSubtitle from '@/components/Form/ComboBoxSearchSubtitle'
    import FormSelect from '@/components/Form/FormSelect'
    import SelectComboBoxSearch from '@/components/Form/SelectComboBoxSearch'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'GastosScreen',
        components: {
            FormInput,
            ButtonFloat,
            InputSearchComplete,
            ButtonAdd,
            ModalForm,
            ModalDetail,
            ButtonLoader,
            ModalButtonFooter,
            ComboBoxSearch,
            ComboBoxSearchSubtitle,
            FormSelect,
            SelectComboBoxSearch
        },
        data: function(){
            return {
                headers: [
                    {text: 'Tipo', align: 'left', value: 'tipo'},
                    {text: 'Descripción', align: 'left', value: 'descripcion'},
                    {text: 'Categoría', align: 'left', value: 'categoria'},
                    {text: 'Cuenta', align: 'left', value: 'cuenta'},
                    {text: 'Importe', align: 'left', value: 'importe'},
                    {text: 'Última actualización', value: 'actualizacion', width: '200px', hide: 'text-center'},
                    {text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtroComputed: '',
                filtro: '',
                clientes: [],
                gastos: [],
                indexEdit: -1,
                gasto: {},

                page: 1,
                lastPage: 1,

                cuentas: [],
                categoria: [],
                actosTipos: [],

                tipoSelected: null
            }
        },
        mounted() {
            this.$emit("showLoader");
            this.getGastos();
            this.getActosTipos();
            if(this.checkPrivilege("gastos.crear") ||
                this.checkPrivilege("gastos.editar")){
                this.getCuentas();
                this.getCategoriasGastos();
            }
        },
        methods: {
            filtrarChange(){
                let tipo = this.$refs.tipos.getSelected();
                if(tipo != undefined){
                    this.tipoSelected = tipo.key;
                }
                else{
                    this.tipoSelected = null;
                }
                this.first(true);
            },
            searchClick(){
                this.page = 1;
                this.getGastos();
            },
            getGastos: function () {
                let vue = this;
                vue.gastos = [];

                let data = {page: this.page, filtro: this.filtro, tipo_id: this.tipoSelected};

                $.get(this.API_URL + "/api/gastos", data, function (response) {
                    vue.$emit("hideLoader");
                    if (response.success) {
                        vue.page = response.gastos.current_page;
                        vue.lastPage = response.gastos.last_page;
                        vue.gastos = response.gastos.data;
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            nuevaGasto: function () {
                this.gasto = {};
                this.indexEdit = -1;
                // this.$refs.empresas.removeItem();
                this.$refs.categoriaGasto.removeItem();
                this.$refs.cuentas.removeItem();
                this.ordenarCategoriasSugerencias();
                this.openModalGasto();
            },
            editGasto: function () {
                this.$refs.gastoDetalle.hide();
                this.$refs.categoriaGasto.removeItem();
                this.$refs.cuentas.removeItem();


                if(this.gasto.cuenta != null){
                    let cuenta = {
                        key: this.gasto.cuenta.id,
                        title: this.gasto.cuenta.nombre,
                        item: this.gasto.cuenta
                    };
                    this.$refs.cuentas.setSelected(cuenta);
                }

                if(this.gasto.categoria != null){
                    let cat = {
                        key: this.gasto.categoria.id,
                        title: this.gasto.categoria.nombre,
                        search: this.gasto.categoria.search,
                        item: this.gasto.categoria
                    };

                    this.$refs.categoriaGasto.setSelected(cat);
                }

                this.ordenarCategoriasSugerencias();
                this.openModalGasto();
            },
            openModalGasto: function () {
                this.$refs.gastoForm.show();
            },
            closeModalGasto: function(){
                this.$refs.gastoForm.hide();
            },
            detalleGasto: function (item) {
                this.indexEdit = this.gastos.indexOf(item);
                this.gasto = Object.assign({}, item);

                this.$refs.gastoDetalle.show();
            },
            saveGasto: function (e) {
                e.preventDefault();
                let data = new FormData();
                let cuenta = this.$refs.cuentas.getSelected();
                let categoria = this.$refs.categoriaGasto.getSelected();

                if(this.gasto.id != null){
                    data.append("id", this.gasto.id);
                }

                data.append("tipo_id", this.gasto.tipo_id);
                data.append("descripcion", this.gasto.descripcion);
                if(cuenta != null){
                    data.append("cuenta_id", cuenta.key);
                }
                if(categoria != null){
                    data.append("categoria_id", categoria.key);
                }

                if(this.gasto.importe != null){
                    data.append("importe", this.gasto.importe);
                }

                let vue = this;

                vue.$refs.buttonGasto.setShowLoader(true);

                $.ajax({
                    url: this.API_URL + "/api/gastos/save",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    type: 'POST', // For jQuery < 1.9
                    success: function(response){
                        vue.$refs.buttonGasto.setShowLoader(false);
                        if(response.success){
                            vue.page = 1;
                            vue.getGastos();
                            vue.gasto = {};
                            vue.indexEdit = -1;
                            vue.closeModalGasto();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }
                }).fail(function (error) {
                    vue.$refs.buttonGasto.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            deleteGasto: function () {
                let vue = this;
                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/gastos/delete", vue.gasto, function (response) {
                        if(response.success){
                            vue.gastos.splice(vue.indexEdit, 1);
                            vue.gasto = {};
                            vue.$refs.gastoDetalle.hide();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }

                    });
                });
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.getGastos();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.getGastos();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.getGastos();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.getGastos();
                }
                else if(refresh){
                    this.page = 1;
                    this.getGastos();
                }
            },
            getCuentas: function () {
                let vue = this;
                vue.cuentas = [];
                vue.$refs.cuentas.setCargando(true);
                $.get(this.API_URL + "/api/cuentas/getCuentas", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        for(let k in response.cuentas){
                            vue.cuentas[k] = {};
                            vue.cuentas[k].key = response.cuentas[k].id;
                            vue.cuentas[k].title = response.cuentas[k].nombre;
                            vue.cuentas[k].item = response.cuentas[k];
                            vue.cuentas[k].search = response.cuentas[k].search;
                        }
                        vue.$refs.cuentas.setData(vue.cuentas);
                        vue.$refs.cuentas.setCargando(false);

                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getCategoriasGastos: function () {
                let vue = this;
                vue.categoria = [];
                let data = {tipo: "gastos"};
                $.get(this.API_URL + "/api/categorias", data, function (response) {
                    if (response.success) {
                        vue.categorias = response.categorias;

                        var cats = [];
                        for(let k in vue.categorias){
                            cats[k] = {};
                            cats[k].key = vue.categorias[k].id;
                            cats[k].title = vue.categorias[k].nombre;
                            cats[k].subtitle = vue.categorias[k].descripcion;
                            cats[k].sugerencias = vue.categorias[k].sugerencias;
                            cats[k].search = vue.categorias[k].search;
                            cats[k].item = vue.categorias[k];
                        }

                        vue.$refs.categoriaGasto.setData(cats);
                        vue.$refs.categoriaGasto.setCargando(false);
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getActosTipos(){
                let vue = this;
                vue.actosTipos = [];
                $.get(this.API_URL + "/api/actos/tipos", function (response) {
                    if(response.success){
                        vue.actosTipos = response.actos_tipos;

                        let tipos = [];

                        for(let k in response.actos_tipos){
                            tipos[k] = {};
                            tipos[k].key = response.actos_tipos[k].id;
                            tipos[k].title = response.actos_tipos[k].nombre;
                            tipos[k].search = response.actos_tipos[k].nombre;
                        }

                        if(vue.$refs.tipos != undefined){
                            vue.$refs.tipos.setData(tipos);
                            vue.$refs.tipos.setCargando(false);
                        }

                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            ordenarCategoriasSugerencias(){
                this.$refs.categoriaGasto.buscarSugerencias(this.gasto.descripcion);
            },
        }
    }
</script>

<style lang="scss">
.barcode-view{
    video{
        width: 100%;
        height: 100vh;
    }
}
</style>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;

        }

        .button-export-top{
            display: inline-block;
            margin-right: 10px;
            @media all and (max-width: 768px){
                margin: 0 0 0 10px;
            }
            @media all and (max-width: 530px) {
                padding-top: 5px;
            }
        }

        .estatus{
            text-transform: capitalize;
        }

        .btn-exportar{
            outline: none;
            display: inline-block;
            font-size: 13px;
            line-height: 29px;
            padding: 0 15px;
            color: #fff;
            border-color: #217344;
            background: #217344;
            border-radius: 50px;
            text-transform: uppercase;
            font-weight: bold;

            span{
                font-size: 11px;
            }

            &:hover{
                color: #fff;
            }
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .barcode-view{
            position: fixed;
            left: 220px;
            top: 0;
            width: calc(100% - 220px);
            height: 100%;
            z-index: 12;
            background: #000;
            @media all and (max-width: 1200px){
                left: 0;
                width: 100%;
            }

            .botones-camara{
                position: absolute;
                bottom: 20px;
                width: 100%;
                text-align: center;
                left: 50%;
                transform: translateX(-50%);
                z-index: 10;
                .btn-cancelar{
                    background: #999;
                    border-color: #999;
                    padding-right: 25px;
                    padding-left: 25px;
                    margin-right: 10px;
                }

                .btn-enviar{
                    margin-left: 10px;
                    padding-right: 25px;
                    padding-left: 25px;
                    background: #28a745;
                    border-color: #28a745;
                }
            }
        }

        .revisar-btn{
            button{
                background: rgb(0, 123, 255);
                border-color: rgb(0, 123, 255);
            }
        }

        .select-clientes{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;
        }

    }



</style>
