<template>
    <li class="menu-option">
        <router-link :to="link" @click.native="refresh">
            <i :class="'icon fas ' + icon"></i>
            <span class="title-section">{{ title }}</span>
        </router-link>
    </li>
</template>

<script>


export default {
    name: 'MenuItem',
    model: {

    },
    props: {
        link: null,
        title: null,
        icon: null,
    },
    data: function(){
        return {
            openMenu: '',
        }
    },
    mounted() {

    },
    methods: {
        refresh(){
            this.$emit("refresh");
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /*NAVIGATION BAR */

  a:hover, a:focus{
    text-decoration: none ;
  }

  .menu-option{
      position: relative;

      a{
          display: block;
          position: relative;
          border-radius: 3px;
          color: #a4a6b3;
          padding: 7px 15px;
          font-size: 12px;
          font-weight: bold;
          /*border-top: 1px solid #333;*/

          &:hover {
              color: #c4c086;
              background-color: rgba(196,192,134,.05);
              &:before{
                  left: 0;
              }
          }

          &.router-link-active{
              color: #c4c086;
              background-color: rgba(196,192,134,.05);
              &:before{
                  left: 0;
              }
          }

          svg{
              width: 20px;
          }

          i{
              float: left;
              margin-right: 15px;
              line-height: 30px;
              width: 20px;
              text-align: center;
              color: #a9afbb;

              &:before{
                  font-size: 13px !important;
              }
          }

          span{
              margin: 0;
              line-height: 30px;
              font-size: 12px;
              position: relative;
              height: auto;
              padding-left: 15px;
              white-space: nowrap;
              font-weight: bold;
          }

          &:before{
              content: '';
              position: absolute;
              left: -4px;
              top: 0;
              width: 3px;
              height: 100%;
              background: #c4c086;
              transition-duration: 0.3s;
          }



      }

  }


</style>
