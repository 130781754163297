<template>
    <div id="root">
        <div v-if="checkPrivilege('cuenta-movimientos.crear')" class="button-add-bottom">
            <ButtonFloat v-on:click="addMovimiento"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Movimientos de {{ cuenta.nombre }}</h2>
            </div>
            <div class="buttons">
                <div class="select-check-content">
                    <SelectCheck ref="selectFiltro"
                                 v-on:change="filtrarChange"
                                 placeholder="Selecciona los tipos"/>
                </div>
                <div class="date-range" v-bind:class="{'no-margin' : !checkPrivilege('cuenta-movimientos.crear')}">
                    <DateRangePicker :icons="true"
                                     :dateInicio.sync="fechaInicio"
                                     :dateFin.sync="fechaFin"
                                     placeholderInicio="Fecha inicio"
                                     placeholderFin="Fecha fin"
                                     format="DD-MM-YYYY"
                                     ref="fechas"
                                     v-on:change="first(true)"/>
                </div>

                <div class="btn-imprimir">
                    <ButtonAdd v-on:click="pdfMovimientos" title="Imprimir" />
                </div>

                <div v-if="checkPrivilege('cuenta-movimientos.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="addMovimiento" title="Nuevo +" />
                </div>
            </div>
        </div>
        <div class="content-section">

            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th v-for="(item, index) in headers" :width="item.width"
                            :key="index"
                            :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                            {{ item.text }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="movimientos.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in movimientos" :key="index"
                        v-on:click="detalleMovimiento(item)">
                        <td :class="headers[0].hide">
                            <span v-if="item.concepto != undefined && item.concepto.acto != undefined">
                                {{ item.concepto.acto.nombre }}
                            </span>
                            <span v-else>--</span>
                        </td>
                        <td :class="headers[0].hide">
                            {{ item.usuario.name }}
                        </td>
                        <td :class="headers[1].hide">
                            {{ tipo(item.tipo) }}
                        </td>
                        <td :class="headers[2].hide">
                            {{ item.descripcion }}
                        </td>
                        <td :class="headers[3].hide">
                            <span :class="'precio ' + currencyStyle(item) ">
                                {{ currencyStyle(item) == "negativo" ? "-" : "" }}{{ item.importe | toCurrency }}
                            </span>
                        </td>
                        <td :class="headers[4].hide">
                            {{ item.fecha_movimiento | dateFormat }}
                        </td>
                        <td :class="headers[5].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalMovimiento">
            <div class="modal-dialog">
                <form v-on:submit="saveMovimiento" class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Movimiento</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>

                    <div>
                        <v-tabs v-model="tabMovimiento"
                                fixed-tabs
                                background-color="#f0f0f0">
                            <v-tab key="ingreso" >
                                Ingreso
                            </v-tab>
                            <v-tab key="salida" >
                                Salida
                            </v-tab>
                            <v-tab key="transferencia" >
                                Transferencia
                            </v-tab>
                        </v-tabs>

                        <div class="modal-body">

                            <ComboBoxSearch v-show="tabMovimiento === 2 || tabMovimiento === 1"
                                            ref="cuentaSalida" title="Cuenta de origen"
                                            placeholder="Selecciona cuenta de origen"/>

                            <ComboBoxSearch v-show="tabMovimiento === 2 || tabMovimiento === 0" ref="cuentaEntrada"
                                            title="Cuenta de destino"
                                            placeholder="Selecciona cuenta de destino"/>

                            <ComboBoxSearch v-show="tabMovimiento === 1"
                                            ref="categoriaGasto" title="Categoría"
                                            placeholder="Selecciona la categoría"/>

                            <ComboBoxSearch v-show="tabMovimiento === 0"
                                            ref="categoriaIngreso" title="Categoría"
                                            placeholder="Selecciona la categoría"/>


                            <FormInput :valueInput.sync="movimiento.importe"
                                       :required="true"
                                       type="number"
                                       name="importe" title="Importe"
                                       placeholder="0.0"/>

                            <FormInput :valueInput.sync="movimiento.descripcion"
                                       :required="true"
                                       name="descripcion" title="Descripción" />

                            <DatePicker styleClass="form-control" title="Fecha del movimiento" :date.sync="movimiento.fecha_movimiento"
                                        placeholder="dd-mm-yyyy"
                                        :limitDate="!checkPrivilege('ignorar-fecha')"
                                        format="DD-MM-YYYY"
                                        ref="fechaMovimiento" />

                            <FormFile :required="false"
                                      ref="comprobanteFile"
                                      :valueInput.sync="movimiento.comprobante"
                                      name="ine" title="Comprobante"/>

                        </div>
                    </div>
                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <!--<button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>-->
                        <div class="button-save-content">
                            <ButtonLoader ref="buttonMovimiento" type="submit" title="Guardar" />
                        </div>
                    </div>

                </form>
            </div>
        </div>

        <ModalDetail ref="movimientoDetalle" title="Movimiento Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr v-if="movimiento.concepto != undefined && movimiento.concepto.acto != undefined">
                        <td>
                            <b>Acto</b>
                        </td>
                        <td>
                            <span>{{ movimiento.concepto.acto.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Usuario</b>
                        </td>
                        <td>
                            <span v-if="movimiento.usuario != null">{{ movimiento.usuario.name }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Tipo</b>
                        </td>
                        <td>
                            {{ tipo(movimiento.tipo)  }}
                        </td>
                    </tr>
                    <tr v-if="movimiento.categoria != null">
                        <td>
                            <b>Categoría</b>
                        </td>
                        <td>
                            <span v-if="movimiento.categoria != null">{{ movimiento.categoria.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="movimiento.cuenta_salida != null">
                        <td>
                            <b>Cuenta Salida</b>
                        </td>
                        <td>
                            <span v-if="movimiento.cuenta_salida != null">{{ movimiento.cuenta_salida.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="movimiento.cuenta_salida != null">
                        <td>
                            <b>Saldo Cuenta Salida</b>
                        </td>
                        <td>
                            <span v-if="movimiento.cuenta_salida != null">{{ movimiento.saldo_cuenta_salida | toCurrency }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="movimiento.cuenta_entrada != null">
                        <td>
                            <b>Cuenta Entrada</b>
                        </td>
                        <td>
                            <span v-if="movimiento.cuenta_entrada != null">{{ movimiento.cuenta_entrada.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="movimiento.cuenta_entrada != null">
                        <td>
                            <b>Saldo Cuenta Entrada</b>
                        </td>
                        <td>
                            <span v-if="movimiento.cuenta_entrada != null">{{ movimiento.saldo_cuenta_entrada | toCurrency }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Importe</b>
                        </td>
                        <td>
                            <span>{{ movimiento.importe | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Descripción</b>
                        </td>
                        <td>
                            <span>{{ movimiento.descripcion }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha Movimiento</b>
                        </td>
                        <td>
                            <span>{{ movimiento.fecha_movimiento | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr v-if="movimiento.comprobante_url != null && movimiento.comprobante_url != undefined">
                        <td>
                            <b>Comprobante</b>
                        </td>
                        <td>
                            <a :href="movimiento.comprobante_url" target="_blank" class="btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="checkPrivilege('cuenta-movimientos.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteMovimiento"
                                       color="#fff"
                                       :disabled="!isEdicion()"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>


        <div class="float-saldo">
            <p>
                Total: {{ total | toCurrency }}
            </p>
        </div>

    </div>


</template>

<script>

    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ComboBoxSearch from '@/components/Form/ComboBoxSearch'
    import FormInput from '@/components/Form/FormInput'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import DatePicker from '@/components/DatePicker/DatePicker'
    import DateRangePicker from '@/components/DatePicker/DateRangePicker'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import FormFile from '@/components/Form/FormFile'
    import SelectCheck from '@/components/Form/SelectCheck'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'MovimientosScreen',
        components: {
            FormFile,
            ButtonFloat,
            DatePicker,
            SelectCheck,
            ButtonAdd,
            DateRangePicker,
            ComboBoxSearch,
            FormInput,
            ButtonLoader,
            ModalDetail,
            ModalButtonFooter
        },
        data: function(){
            return {
                total: 0,
                page: 1,
                lastPage: 1,
                filtro: '',
                fechaInicio: '2018-10-01',
                fechaFin: '',
                tabMovimiento: 0,
                cuenta_id: this.$route.params.cuentaId,
                cuenta: {},
                cuentas: [],
                headers: [
                    {text: 'Acto', value: 'acto', hide: 'hidden-sm-and-down'},
                    {text: 'Usuario', value: 'usuario', width: '15%', hide: 'hidden-sm-and-down'},
                    {text: 'Tipo', align: 'left', value: 'tipo'},
                    {text: 'Descripción', value: 'descripcion', width: '20%', hide: 'hidden-sm-and-down'},
                    {text: 'Importe', value: 'importe', width: '10%'},
                    {text: 'Fecha', value: 'fecha', width: '10%'},
                    {text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                movimientos: [],
                categoriaIngresos: [],
                movimiento: {},
                indexEdit: -1,
                date: new Date().toISOString().substr(0, 10),
                filtroTipo: [],
                selectFiltro: [
                    {
                        key: "s",
                        title: "Salida",
                        checked: true
                    },
                    {
                        key: "i",
                        title: "Ingreso",
                        checked: true
                    },
                    {
                        key: "t",
                        title: "Transferencia",
                        checked: true
                    }
                ],
            }
        },
        mounted() {
            this.$refs.selectFiltro.setData(this.selectFiltro);
            this.filtroTipo = this.$refs.selectFiltro.getSelecteds();
            this.$emit("showLoader");
            this.getCuentas();
            this.getMovimientos();
            this.getCategorias();
            this.getCategoriasIngresos();
        },
        computed: {
        },
        methods: {
            pdfMovimientos(){
                let parametros = "cuenta_id="+this.cuenta_id;
                parametros += "&fecha_inicio=" + this.fechaInicio;
                parametros += "&fecha_fin="+this.fechaFin;

                this.filtroTipo.forEach(function (item) {
                    parametros += "&tipos[]="+item;
                });

                window.open(this.API_URL+"/cuenta/movimientos?"+parametros);
            },
            isEdicion(){
                var d1 = Date.parse(this.movimiento.fecha_movimiento);
                var d2 = Date.parse(this.$store.getters.fechaEdicion);
                if(this.checkPrivilege('ignorar-fecha')){
                    return true;
                }
                else{
                    return d1 >= d2;
                }
            },
            filtrarChange: function () {
                this.filtroTipo = this.$refs.selectFiltro.getSelecteds();
                this.first(true);
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.getMovimientos();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.getMovimientos();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.getMovimientos();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.getMovimientos();
                }
                else if(refresh){
                    this.page = 1;
                    this.getMovimientos();
                }
            },
            currencyStyle: function (item) {
                var style = "positivo";
                if(item.cuenta_salida_id == this.cuenta_id){
                    style = "negativo";
                }
                return style;
            },
            tipo: function (t) {
                var tipo = "Transferencia";
                if(t == "s"){
                    tipo = "Salida";
                }
                else if(t == "i"){
                    tipo = "Ingreso";
                }

                return tipo;
            },
            getCategorias: function () {
                let vue = this;
                vue.categoria = [];
                let data = {tipo: "gastos"};
                $.get(this.API_URL + "/api/categorias", data, function (response) {
                    if (response.success) {
                        vue.categorias = response.categorias;

                        var cats = [];
                        for(let k in vue.categorias){
                            cats[k] = {};
                            cats[k].key = vue.categorias[k].id;
                            cats[k].title = vue.categorias[k].nombre;
                            cats[k].search = vue.categorias[k].search;
                        }

                        vue.$refs.categoriaGasto.setData(cats);
                        vue.$refs.categoriaGasto.setCargando(false);
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getCategoriasIngresos: function () {
                let vue = this;
                vue.categoriaIngresos = [];
                let data = {tipo: "ingresos"};
                $.get(this.API_URL + "/api/categorias", data, function (response) {
                    if (response.success) {
                        vue.categoriaIngresos = response.categorias;

                        var cats = [];
                        for(let k in vue.categoriaIngresos){
                            cats[k] = {};
                            cats[k].key = vue.categoriaIngresos[k].id;
                            cats[k].title = vue.categoriaIngresos[k].nombre;
                            cats[k].search = vue.categoriaIngresos[k].search;
                        }

                        vue.$refs.categoriaIngreso.setData(cats);
                        vue.$refs.categoriaIngreso.setCargando(false);
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getCuentas: function () {
                let vue = this;
                vue.cuentas = [];
                var data = {cuenta_id: vue.cuenta_id};
                $.get(this.API_URL + "/api/cuentas/getCuentas", data, function (response) {
                    if(response.success){
                        vue.cuentas = response.cuentas;
                        if(response.cuenta == undefined){
                            vue.$router.push({ name: "PageNotfound" });
                            return
                        }
                        vue.cuenta = response.cuenta;
                        vue.setDataComboBox();
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getMovimientos: function(){
                this.$emit("showLoader");
                let vue = this;
                let data ={
                    cuenta_id: this.cuenta_id,
                    fecha_inicio: this.fechaInicio,
                    fecha_fin: this.fechaFin,
                    page: this.page,
                    tipo: this.filtroTipo
                };

                vue.movimientos = [];
                $.get(this.API_URL + "/api/movimientos/getPage", data, function (response) {
                    vue.$emit("hideLoader");
                    vue.page = response.movimientos.current_page;
                    vue.lastPage = response.movimientos.last_page;
                    vue.movimientos = response.movimientos.data;
                    vue.total = response.total;
                    if(vue.fechaInicio == '' || vue.fechaFin == ''){
                        vue.fechaFin = response.fecha_fin;
                        vue.fechaInicio = response.fecha_inicio;
                        vue.$refs.fechas.setDates(response.fecha_inicio, response.fecha_fin);
                    }

                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            addMovimiento: function () {
                this.movimiento = {};
                this.$refs.fechaMovimiento.setDate('');
                this.$refs.categoriaGasto.removeItem();
                this.$refs.categoriaIngreso.removeItem();
                this.$refs.cuentaEntrada.removeItem();
                this.$refs.cuentaSalida.removeItem();
                this.openModalMovimientos();
            },
            setDataComboBox: function () {
                this.cuentasBox = [];
                for(let k in this.cuentas){
                    this.cuentasBox[k] = {};
                    this.cuentasBox[k].key = this.cuentas[k].id;
                    this.cuentasBox[k].title = this.cuentas[k].nombre;
                    this.cuentasBox[k].search = this.cuentas[k].search;
                }
                this.$refs.cuentaEntrada.setData(this.cuentasBox);
                this.$refs.cuentaEntrada.setCargando(false);
                this.$refs.cuentaSalida.setData(this.cuentasBox);
                this.$refs.cuentaSalida.setCargando(false);
            },
            detalleMovimiento: function (item) {
                this.indexEdit = this.movimientos.indexOf(item);
                this.movimiento = Object.assign({}, item);
                this.$refs.movimientoDetalle.show();
            },
            deleteMovimiento: function () {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    vue.movimiento.cuenta_id = vue.cuenta.id;
                    $.post(vue.API_URL + "/api/movimientos/delete", vue.movimiento, function (response) {
                        if(response.success){
                            vue.movimientos.splice(vue.indexEdit, 1);
                            vue.cuenta.saldo = response.saldo;
                            vue.$refs.movimientoDetalle.hide();

                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }

                    });
                });
            },
            openModalMovimientos: function(){
                this.$refs.comprobanteFile.reset();
                $("#modalMovimiento").modal("show");
            },
            closeModalMovimientos: function(){
                $("#modalMovimiento").modal("hide");
            },
            saveMovimiento: function (e) {
                e.preventDefault();

                let vue = this;

                if(this.tabMovimiento === 0 || this.tabMovimiento === 2){
                    let entrada = this.$refs.cuentaEntrada.getSelected();
                    if(entrada !== undefined && entrada != null){
                        this.movimiento.cuenta_entrada_id = entrada.key;
                    }
                    else{
                        swal("Selecciona la cuenta de destino", "", "info");
                        return;
                    }
                }

                if(this.tabMovimiento === 1 || this.tabMovimiento === 2){
                    let salida = this.$refs.cuentaSalida.getSelected();
                    if(salida !== undefined && salida != null){
                        this.movimiento.cuenta_salida_id = salida.key;
                    }
                    else{
                        swal("Selecciona la cuenta de origen", "", "info");
                        return;
                    }
                }

                if(this.tabMovimiento == 1){
                    let categoria = this.$refs.categoriaGasto.getSelected();
                    if(categoria != undefined && categoria != null){
                        this.movimiento.categoria_id = categoria.key;
                    }
                    else{
                        swal("Selecciona la categoría", "", "info");
                        return;
                    }
                }
                else if(this.tabMovimiento == 0){
                    let categoria = this.$refs.categoriaIngreso.getSelected();
                    if(categoria != undefined && categoria != null){
                        this.movimiento.categoria_id = categoria.key;
                    }
                    else{
                        swal("Selecciona la categoría", "", "info");
                        return;
                    }
                }

                vue.$refs.buttonMovimiento.setShowLoader(true);
                this.movimiento.cuenta_id = this.cuenta.id;

                let data = new FormData();
                if(this.movimiento.id != undefined){
                    data.append("id", this.movimiento.id);
                }
                if(this.movimiento.cuenta_salida_id != undefined) {
                    data.append("cuenta_salida_id", this.movimiento.cuenta_salida_id);
                }
                if(this.movimiento.categoria_id != undefined) {
                    data.append("categoria_id", this.movimiento.categoria_id);
                }
                if(this.movimiento.cuenta_entrada_id != undefined) {
                    data.append("cuenta_entrada_id", this.movimiento.cuenta_entrada_id);
                }
                if(this.movimiento.importe != undefined) {
                    data.append("importe", this.movimiento.importe);
                }
                if(this.movimiento.descripcion != undefined) {
                    data.append("descripcion", this.movimiento.descripcion);
                }
                if(this.movimiento.fecha_movimiento != undefined) {
                    data.append("fecha_movimiento", this.movimiento.fecha_movimiento);
                }

                if(this.movimiento.comprobante != undefined) {
                    data.append("comprobante", this.movimiento.comprobante);
                }


                $.ajax({
                    url: this.API_URL + "/api/movimientos/save",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    type: 'POST', // For jQuery < 1.9
                    success: function(response){
                        vue.$refs.buttonMovimiento.setShowLoader(false);
                        if(response.success){
                            if(response.movimiento.cuenta_entrada_id === vue.cuenta.id ||
                                response.movimiento.cuenta_salida_id === vue.cuenta.id){
                                vue.movimientos.splice(0, 0, response.movimiento);
                                vue.cuenta.saldo = response.saldo;
                            }

                            vue.movimiento = {};

                            vue.closeModalMovimientos();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }
                }).fail(function (error) {
                    vue.$refs.buttonMovimiento.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            }
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px 90px 25px;
        width: 100%;
        height: 100%;

        .header-section .buttons .date-range.no-margin{
            margin: 0;
        }

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            top: -1px;
            position: relative;
            vertical-align: bottom;
        }


        .date-picker{
            margin-right: 10px;
            & > .v-input{
                padding: 0;
                margin: 0;

                & > .v-input__control{
                    margin: 0;

                    & > .v-input__slot{
                        margin: 0;
                    }

                }

            }
        }

        .btn-imprimir{
            margin-right: 10px;
            display: inline-block;
            button{
                background: rgb(0, 123, 255);
                border-color: rgb(0, 123, 255);
            }
        }

        .button-save-content{
           width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px 90px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px 90px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .float-saldo{
            position: fixed;
            right: 20px;
            bottom: 20px;
            padding: 10px 30px;
            background: #c4c086;
            border-radius: 10px;

            @media all and (max-width: 768px) {
                left: 15px;
                right: inherit;
            }

            @media all and (max-width: 576px){
                left: 5px;
            }

            p{
                text-transform: uppercase;
                color: #fff;
                margin: 0;
                padding: 0;
                font-weight: 600;
                font-size: 18px;
            }
        }

    }



</style>
