<template>
    <div id="root">
        <div id="backImage" ></div>
        <div id="backMask"></div>


        <div id="loginBox" >

            <div class="brand">
                <img src="../../assets/logo-web.png" alt="">
            </div>

            <form v-on:submit="newPassword">

                <FormInput :valueInput.sync="forgot.password" required="true"
                           type="password"
                           name="password" title="Nueva contraseña" />

                <FormInput required="true" title="Confirmar contraseña"
                           type="password"
                           name="password_confirmation"
                           :valueInput.sync="forgot.password_confirmation"/>

                <div class="form-group button-submit">
                    <ButtonLoader ref="buttonForgot" type="submit" title="Cambiar" />
                </div>

                <router-link to="/login" class="forgot-button text-center">
                    Iniciar sesión
                </router-link>

            </form>



        </div>

    </div>
</template>

<script>

    import FormInput from '@/components/Form/FormInput'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'ForgotPasswordScreen',
        components: {
            FormInput,
            ButtonLoader
        },
        data: function(){
            return {
                forgot: {
                    token: this.$route.params.token,
                    password: '',
                    password_confirmation: ''
                }
            }
        },
        mounted() {
            this.validate();
        },
        methods: {
            setToken: function(token, user){
                this.token = token;
                this.user = user;
            },
            validate: function () {
                let vue = this;
                $.post(this.API_URL + "/api/validate-change-password", this.forgot, function (res) {
                    vue.$refs.buttonForgot.setShowLoader(false);
                    if(!res.success){
                        swal(res.message, "", "info");
                        vue.$router.replace({ name: "login" });
                    }
                }, 'json').fail(function (error) {
                    vue.$refs.buttonForgot.setShowLoader(false);
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                });
            },
            newPassword: function (e) {
                e.preventDefault();

                let vue = this;

                vue.$refs.buttonForgot.setShowLoader(true);
                $.post(this.API_URL + "/api/change-password", this.forgot, function (res) {
                    vue.$refs.buttonForgot.setShowLoader(false);
                    if(res.success){
                        //swal(res.message, "", "success");
                        vue.auth(res.auth_token, res.user, false);

                        //vue.$router.replace({ name: "login" });

                    }else{
                        swal(res.message, "", "info");
                    }
                }, 'json').fail(function (error) {
                    vue.$refs.buttonForgot.setShowLoader(false);
                    if(error.readyState == 0){
                        swal("Se perdió la conexión a internet", "", "warning");
                    }
                    else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                        swal(error.responseJSON.message, "", "warning");
                    }
                    else{
                        swal("Error inesperado", "", "warning");
                    }
                });

            },
            auth: function (token, user, save = false) {
                this.$emit("authenticated", token, user, save);
            }
        }
    }
</script>

<style scoped lang="scss">
    #root{
        position: relative;
        height: 100%;

        .button-submit{
            padding-top: 15px;
        }

        .forgot-button{
            display: block;
            font-size: 14px;
            color: #c4c086;
            font-weight: 600;
            padding: 0 0 5px 0;
        }

    }

    .container{
        position: relative;
        height: 100%;
    }

    #loginBox{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        height: auto;

        background: rgba(255, 255, 255, 255);

        border-radius: 10px;
        padding: 25px;

        .brand{
            width: 100%;
            text-align: center;
            img{
                width: 171px;
            }
            padding-bottom: 25px;
        }

        form{

            color: #333;

            > p {
                font-weight: bold;
                font-size: 28px;
            }

            .info-text{
                font-size: 13px;
                line-height: 15px;
                padding-bottom: 15px;
                text-align: center;
                color: #999;
            }

        }

        @media all and (max-width: 400px){
            width: 300px;
            padding: 15px;
        }
    }



    #backMask{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(46, 30, 41, 0.95);
    }

    #backImage{
        position: absolute;
        width: 100%;
        height: 100%;
        /*background: rgba(46, 30, 41, 1);*/
        background: url("../../assets/images/1.jpg");
        background-position: 50% 0;
        -webkit-background-size: contain;
        background-size: cover;
    }


</style>
