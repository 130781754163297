import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuetify from 'vuetify'
import jQuery from 'jquery';
import swal from 'sweetalert2';
import vClickOutside from 'v-click-outside';
import Lightbox from 'vue-easy-lightbox'
import moment from 'moment';
moment.locale('es');

Vue.use(Lightbox);
Vue.use(vClickOutside);
Vue.use(Vuetify);

import vuetify from '@/plugins/vuetify'

window.swal = swal;
window.moment = moment;
window.$ = jQuery;


jQuery.ajaxSetup({
    headers: {
        'X-AUTH-TOKEN': store.getters.auth_token,
        'X-LAST-AUTH-TOKEN': store.getters.last_auth_token
    }
});

switch(process.env.NODE_ENV){
    case "production":
        Vue.prototype.API_URL = "https://api.medjuridicos.com";
        break;
    case "testing":
        Vue.prototype.API_URL = "https://demo.api.medjuridicos.com";
        break;
    default:
        Vue.prototype.API_URL = "http://127.0.0.1:8000";
        break;
}

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'sweetalert2/dist/sweetalert2.all.min';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all';
import 'animate.css/animate.min.css';


import 'vuetify/dist/vuetify.min.css'
Vue.config.productionTip = false;

Vue.filter('number', function (value) {
    if(value == 0 || value == null){
        return "0";
    }
    return value;
});

Vue.filter('toCurrency', function (value) {
    let newVal = parseFloat(value);
    if(isNaN(newVal)){
        newVal = 0;
    }
    var formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2
    });
    return formatter.format(newVal);
});

Vue.filter('toCurrencyNormal', function (value) {
    let newVal = parseFloat(value);
    if(isNaN(newVal)){
        newVal = 0;
    }
    if(newVal < 0){
        newVal = newVal * -1;
    }
    var formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2
    });
    return formatter.format(newVal);
});



Vue.filter('dateFormat', function (value) {
    if(value === undefined || value === null){
        return value;
    }
    return moment(value).format("DD-MM-YYYY");
});

Vue.filter('dateFormatMX', function (value) {
    if(value === undefined || value === null){
        return value;
    }
    return moment(value).format("DD/MM/YYYY");
});

Vue.filter('dateTimeFormatMX', function (value) {
    if(value === undefined || value === null){
        return value;
    }
    return moment(value).format("DD/MM/YYYY HH:mm");
});

Vue.filter('mesFormat', function (value) {
    if(value === undefined || value === null){
        return value;
    }
    return moment(value).format("MMMM YYYY");
});


Vue.mixin({
    methods: {
        checkPrivilege: function (privilege) {
            let exist = false;
            let permisos = store.getters.user.profile.perfil_permiso;
            for (let i in permisos){
                let item = permisos[i];
                if(item.privilege == privilege){
                    exist = true;
                    break;
                }
            }
            return exist;
        }
    }
});

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App),
    icons: {
        iconfont: 'mdi',
    },
}).$mount('#app');


