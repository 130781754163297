<template>
    <div class="content-buttons"
         v-click-outside="onClose">
        <button type="button"  class="btn btn-nuevo" @click="show = 'show'">
            <i class="fas fa-ellipsis-v"></i>
        </button>
        <ul  :class="'list-options ' + show">
            <slot></slot>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'ButtonMore',
        props: {
            title: String
        },
        data(){
            return{
                show: false
            }
        },
        methods: {
            onClose () {
                if(this.show !== ''){
                    this.show = '';
                }
            },
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .content-buttons{
        position: relative;
        .btn-nuevo {
            outline: none;
            cursor: pointer;
            position: relative;
            outline: none;
            color: #aaa;
            z-index: 10;
            border: none;
            background: transparent;
            text-transform: uppercase;
            font-weight: bold;

            &:hover{
                color: #333;
            }


            span {
                line-height: 55px;
                font-size: 35px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .list-options{
            opacity: 0;
            visibility: hidden;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 10;
            width: 200px;
            background: #ffffff;
            border-radius: 5px;
            margin: 0;
            padding: 10px 0;
            list-style: none;
            text-align: left;
            transition-duration: 0.3s;

            -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
            -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
            box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);

            &.show{
                opacity: 1;
                visibility: visible;
            }

            .item{

            }

        }
    }



</style>
