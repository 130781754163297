<template>
    <div id="root">
        <div class="button-add-bottom">
            <ButtonFloat v-if="checkPrivilege('revisiones.crear')" v-on:click="nuevaRevision"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Revisiones inventario</h2>
            </div>
            <div class="buttons">
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         table="usuarios"
                                         @click="searchClick"
                                         title="Buscar" />
                </div>
                <div v-if="checkPrivilege('revisiones.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="nuevaRevision" title="Nueva +" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th v-for="(item, index) in headers" :width="item.width"
                            :key="index"
                            :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                            {{ item.text }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="revisiones.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in revisiones" :key="index" v-on:click="detalleRevision(item)"
                        :class="{finalizada: item.finalizada}">
                        <td :class="headers[0].hide">
                            {{ item.id }}
                        </td>
                        <td :class="headers[1].hide">
                            <span v-if="item.usuario != null">{{ item.usuario.name }}</span>
                        </td>
                        <td :class="headers[2].hide">
                            {{ item.updated_at | dateTimeFormatMX }}
                        </td>
                        <td :class="headers[3].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <ModalDetail ref="revisionDetalle" title="Revisión Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Usuario</b>
                        </td>
                        <td>
                            <span v-if="revision.usuario != null">{{ revision.usuario.name }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Última actualización</b>
                        </td>
                        <td>
                            {{ revision.updated_at | dateTimeFormatMX }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>
                <div v-if="checkPrivilege('revisiones.crear')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="revisionProductos(revision)"
                                       color="#fff"
                                       background="#00acac"
                                       title="Inventario"
                                       icon="fa-check-square" />
                </div>

                <div v-if="checkPrivilege('revisiones.eliminar') && !revision.finalizada" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteRevision"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>

    </div>
</template>

<script>
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'RevisionScreen',
        components: {
            ButtonFloat,
            InputSearchComplete,
            ButtonAdd,
            ModalDetail,
            ModalButtonFooter
        },
        data: function(){
            return {
                filtroEstatus: [],
                headers: [
                    {text: 'Folio', align: 'left', value: 'folio', width: '100px'},
                    {text: 'Usuario', align: 'left', value: 'usuario'},
                    {text: 'Última actualización', value: 'actualizacion', width: '200px', hide: 'text-center'},
                    {text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtroComputed: '',
                filtro: '',
                revisiones: [],
                indexEdit: -1,
                revision: {},

                page: 1,
                lastPage: 1,
            }
        },
        mounted() {
            this.$emit("showLoader");
            this.getRevisiones();
            // this.cameraStart();
        },
        methods: {
            nuevaRevision(){
                let vue = this;
                swal({
                    text: '¿Realmente deseas crear una nueva revisión?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    vue.$emit("showLoader");

                    $.post(vue.API_URL + "/api/revisiones/nueva", vue.inventario, function (response) {
                        vue.$emit("hideLoader");
                        if(response.success){
                            let revision = response.revision;
                            vue.revisionProductos(revision);
                            vue.getRevisiones();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        vue.$emit("hideLoader");
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }

                    });
                });
            },
            revisionProductos(revision){
                this.$router.push({ path: "/revisiones-inventario_"+revision.id });
                this.$refs.revisionDetalle.hide();
            },
            searchClick(){
                this.page = 1;
                this.getRevisiones();
            },
            getRevisiones: function () {
                let vue = this;
                vue.revisiones = [];

                let data = {page: this.page, filtro: this.filtro};

                $.get(this.API_URL + "/api/revisiones", data, function (response) {
                    vue.$emit("hideLoader");
                    if (response.success) {
                        vue.page = response.revisiones.current_page;
                        vue.lastPage = response.revisiones.last_page;
                        vue.revisiones = response.revisiones.data;
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            detalleRevision: function (item) {
                this.indexEdit = this.revisiones.indexOf(item);
                this.revision = Object.assign({}, item);

                this.$refs.revisionDetalle.show();
            },
            deleteRevision: function () {
                let vue = this;
                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/revisiones/delete", vue.revision, function (response) {
                        if(response.success){
                            vue.revisiones.splice(vue.indexEdit, 1);
                            vue.revision = {};
                            vue.$refs.revisionDetalle.hide();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
                });
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.getInventarios();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.getInventarios();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.getInventarios();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.getInventarios();
                }
                else if(refresh){
                    this.page = 1;
                    this.getInventarios();
                }
            },
        }
    }
</script>

<style lang="scss">
.barcode-view{
    video{
        width: 100%;
        height: 100vh;
    }
}
</style>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;

        }

        .button-export-top{
            display: inline-block;
            margin-right: 10px;
            @media all and (max-width: 768px){
                margin: 0 0 0 10px;
            }
            @media all and (max-width: 530px) {
                padding-top: 5px;
            }
        }

        .estatus{
            text-transform: capitalize;
        }

        .btn-exportar{
            outline: none;
            display: inline-block;
            font-size: 13px;
            line-height: 29px;
            padding: 0 15px;
            color: #fff;
            border-color: #217344;
            background: #217344;
            border-radius: 50px;
            text-transform: uppercase;
            font-weight: bold;

            span{
                font-size: 11px;
            }

            &:hover{
                color: #fff;
            }
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .barcode-view{
            position: fixed;
            left: 220px;
            top: 0;
            width: calc(100% - 220px);
            height: 100%;
            z-index: 12;
            background: #000;
            @media all and (max-width: 1200px){
                left: 0;
                width: 100%;
            }

            .botones-camara{
                position: absolute;
                bottom: 20px;
                width: 100%;
                text-align: center;
                left: 50%;
                transform: translateX(-50%);
                z-index: 10;
                .btn-cancelar{
                    background: #999;
                    border-color: #999;
                    padding-right: 25px;
                    padding-left: 25px;
                    margin-right: 10px;
                }

                .btn-enviar{
                    margin-left: 10px;
                    padding-right: 25px;
                    padding-left: 25px;
                    background: #28a745;
                    border-color: #28a745;
                }
            }
        }

        .revisar-btn{
            button{
                background: rgb(0, 123, 255);
                border-color: rgb(0, 123, 255);
            }
        }

        tr.finalizada{
            td{
                background: rgba(196, 192, 134, 0.5) !important;
            }
        }

    }



</style>
