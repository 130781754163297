<template>
    <div id="root">
        <div class="header-section">
            <div class="title">
                <h2>Reporte de Actos</h2>
            </div>
            <div class="buttons">
                <div class="select-check-content">
                    <SelectCheck ref="selectFiltro"
                                 v-on:change="filtrarChange"
                                 placeholder="Selecciona los estatus"/>
                </div>
                <div class="date-range">
                    <DateRangePicker :icons="true"
                                     :fija="true"
                                     :dateInicio.sync="fechaInicio"
                                     :dateFin.sync="fechaFin"
                                     placeholderInicio="Fecha inicio"
                                     placeholderFin="Fecha fin"
                                     format="DD-MM-YYYY"
                                     ref="fechas"
                                     v-on:change="first(true)"/>
                </div>
                <div class="buscador-content no-margin">
                    <InputSearchComplete align="right" :valueInput.sync="filtro"
                                         table="empresas"
                                         @click="searchClick"
                                         title="Buscar" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <CustomTable :imprimir="true">
                <template v-slot:header>
                    <div class="col-6 col-sm-7">Acto</div>
                    <div class="col-2 col-sm-1 text-center">Ingresos</div>
                    <div class="col-2 col-sm-1 text-center">Gastos</div>
                    <div class="col-2 col-sm-1 text-center">Utilidad</div>
                </template>
                <template v-slot:body>
                    <div v-if="actosFinalizados.length == 0"  class="col-12 text-center">
                        Sin resultados
                    </div>
                    <TableRow v-for="(item, index) in actosFinalizados" :dropDown="actosFinalizados.length > 0"
                              :key="index"
                              :proveedor="true"
                              :imprimirUrl="API_URL+'/acto/'+item.id"
                              :imprimirUrlCliente="API_URL+'/acto/'+item.id+'?cliente=si'"
                              v-on:option="detalleActo(item)"
                              :id="item.id"
                              :ref="`row-${item.id}`">
                        <template v-slot:option>
                            <div class="col-8 col-sm-7 nombre minimizar">
                                {{ item.nombre }}
                            </div>
                            <div class="col-3 col-sm-1 text-center minimizar">
                                {{ item.abonos | toCurrency }}
                            </div>
                            <div class="col-3 col-sm-1 text-center minimizar">
                                {{ item.gastos | toCurrency }}
                            </div>
                            <div class="col-3 col-sm-1 text-center minimizar">
                                {{ (item.abonos - item.gastos) | toCurrency }}
                            </div>
                        </template>
                        <template v-slot:suboptions>
                            <div v-for="(concepto, index) in item.recibo_conceptos_finalizados"
                                 :key="index"
                                 @click="reciboDetalle(concepto.recibo)"
                                 class="row row-item subs clickable">
                                <div class="col-8 col-sm-7 nombre">
                                    <span v-if="concepto.recibo != null">
                                        {{ concepto.recibo.folio_letra }} -
                                    </span>
                                    <span v-if="concepto.recibo.cliente != null">
                                        {{ concepto.recibo.cliente.nombre }} {{ concepto.recibo.cliente.apellidos }}
                                    </span>
                                    <span v-else>{{ concepto.recibo.proveedor }}</span>
                                    - {{ concepto.descripcion }}
                                </div>
                                <div class="col-3 col-sm-1 text-center">
                                    <span v-if="concepto.recibo.tipo == 'i'">
                                        {{ concepto.importe | toCurrency }}
                                    </span>
                                </div>
                                <div class="col-3 col-sm-1 text-center">
                                    <span v-if="concepto.recibo.tipo == 's'">
                                        {{ concepto.importe | toCurrency }}
                                    </span>
                                </div>
                                <div class="col-3 col-sm-1 text-center"></div>
                            </div>
                        </template>
                    </TableRow>
                    <div class="footer pages">
                        <div class="paginater">
                            <div class="text">
                                <p>
                                    {{ page }} de {{ lastPage }}
                                </p>
                            </div>
                            <div class="buttons">
                                <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                    <i class="fas fa-angle-double-left"></i>
                                </button>
                                <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                    <i class="fas fa-angle-left"></i>
                                </button>
                                <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                    <i class="fas fa-angle-right"></i>
                                </button>
                                <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                    <i class="fas fa-angle-double-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </CustomTable>

        </div>

        <ModalDetail ref="actoFinalizado" title="Acto Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Empresa</b>
                        </td>
                        <td>
                            <span v-if="acto.empresa != null">{{ acto.empresa.nombre_completo }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fedatario</b>
                        </td>
                        <td>
                            <span v-if="acto.notario != null">{{ acto.notario.no_fedatario }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Servicio</b>
                        </td>
                        <td>
                            <span v-if="acto.servicio != null">{{ acto.servicio.nombre }}</span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-if="acto.categoria != null">
                        <td>
                            <b>Categoría de ingresos</b>
                        </td>
                        <td>
                            <span>{{ acto.categoria.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha de inicio</b>
                        </td>
                        <td>
                            <span>{{ acto.fecha_inicio | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha de fin</b>
                        </td>
                        <td>
                            <span>{{ acto.fecha_fin | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('actos.precios')">
                        <td>
                            <b>Precio de venta</b>
                        </td>
                        <td>
                            <span>{{ acto.precio | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('dashboard.precios')">
                        <td>
                            <b>Ingresos</b>
                        </td>
                        <td>
                            <a :href="'/actos-conceptos-ingresos_'+acto.id" target="_blank" class="normal-link">
                                {{ acto.abonos | toCurrency }}
                            </a>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('dashboard.precios')">
                        <td>
                            <b>Gastos</b>
                        </td>
                        <td>
                            <a :href="'/actos-conceptos-gastos_'+acto.id" target="_blank" class="normal-link">
                                {{ acto.gastos | toCurrency }}
                            </a>
                        </td>
                    </tr>
                    <tr v-if="checkPrivilege('dashboard.precios')">
                        <td>
                            <b>Utilidad</b>
                        </td>
                        <td>
                            <a :href="'/actos-conceptos-utilidad_'+acto.id" target="_blank" class="normal-link">
                                {{ (acto.abonos - acto.gastos) | toCurrency }}
                            </a>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </template>
            <template v-slot:footer>
                <div class="col" style="padding: 0; margin: 0;">
                    <a :href="API_URL+'/acto/'+acto.id" class="btn-footer-link" target="_blank">
                        <i class="fas fa-print"></i>
                        <p>
                            Imprimir
                        </p>
                    </a>
                </div>
            </template>
        </ModalDetail>

        <ModalDetail ref="reciboDetalle" title="Recibo Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Folio</b>
                        </td>
                        <td>
                            {{ recibo.folio_letra }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Usuario</b>
                        </td>
                        <td>
                            <span v-if="recibo.usuario != undefined">
                                {{ recibo.usuario.name }}
                            </span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Tipo</b>
                        </td>
                        <td>
                            {{ recibo.tipo == 'i' ? 'Ingreso' : 'Salida' }}
                        </td>
                    </tr>
                    <tr v-if="recibo.tipo == 'i'">
                        <td>
                            <b>Cliente</b>
                        </td>
                        <td>
                            <span v-if="recibo.cliente != undefined">
                                {{ recibo.cliente.nombre }} {{ recibo.cliente.apellidos }}
                            </span>
                            <span v-else>--</span>
                        </td>
                    </tr>
                    <tr v-else>
                        <td>
                            <b>Proveedor</b>
                        </td>
                        <td>
                            <span>
                                {{ recibo.proveedor }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Fecha</b>
                        </td>
                        <td>
                            <span>{{ recibo.fecha | dateFormat }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Total</b>
                        </td>
                        <td>
                            <span>{{ recibo.total | toCurrency }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Pagado</b>
                        </td>
                        <td>
                            <span :class="'status ' + (recibo.pagado  == 1 ? 'active' : 'inactive')" >
                                {{ recibo.pagado == 1 ? 'Si' : 'No' }}
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
        </ModalDetail>


    </div>
</template>

<script>
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import CustomTable from '@/components/Table/CustomTable'
    import TableRow from '@/components/Table/TableRow'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import DateRangePicker from '@/components/DatePicker/DateRangePicker'
    import SelectCheck from '@/components/Form/SelectCheck'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'SociosScreen',
        components: {
            DateRangePicker,
            ModalDetail,
            CustomTable,
            TableRow,
            InputSearchComplete,
            SelectCheck
        },
        data: function(){
            return {
                page: 1,
                lastPage: 1,
                fechaInicio: '2018-01-01',
                fechaFin: '',
                filtro: '',
                actosFinalizados: [],
                recibo: [],
                acto: [],
                filtroEstatus: [],
                selectFiltro: [
                    {
                        key: "pendientes",
                        title: "Pendientes",
                        checked: true
                    },
                    {
                        key: "finalizados",
                        title: "Finalizados",
                        checked: true
                    }
                ],
            }
        },
        computed: {
        },
        mounted() {
            if(this.$route.params.estatus != undefined && this.$route.params.estatus == "pendientes"){
                this.selectFiltro[0].checked = false;
            }
            this.$refs.selectFiltro.setData(this.selectFiltro);
            this.$emit("showLoader");
            this.getActosFinalizados();
        },
        methods: {
            filtrarChange: function () {
                this.first(true);
            },
            searchClick(){
                this.first(true);
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.getActosFinalizados();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.getActosFinalizados();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.getActosFinalizados();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.getActosFinalizados();
                }
                else if(refresh){
                    this.page = 1;
                    this.getActosFinalizados();
                }
            },
            detalleActo: function (item) {
                this.acto = item;
                this.$refs.actoFinalizado.show();
            },
            getActosFinalizados: function () {
                this.filtroEstatus = this.$refs.selectFiltro.getSelecteds();
                this.$emit("showLoader");
                let vue = this;
                vue.actosFinalizados = [];
                let data = {
                    fecha_inicio: this.fechaInicio,
                    page: this.page,
                    filtro: this.filtro,
                    fecha_fin: this.fechaFin,
                    estatus: this.filtroEstatus
                };
                $.get(this.API_URL + "/api/actos/reporte", data, function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.page = response.actos.current_page;
                        vue.lastPage = response.actos.last_page;
                        vue.actosFinalizados = response.actos.data;
                        if(vue.fechaInicio === '' || vue.fechaFin === ''){
                            vue.$refs.fechas.setDates("2018-01-01", response.fecha_fin);
                        }
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            reciboDetalle(recibo){
                if(recibo != undefined){
                    this.recibo = Object.assign({}, recibo);
                    this.$refs.reciboDetalle.show();
                }
            }
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .btn-footer-link{
            display: block;
            text-align: center;
            padding: 10px 0;
            width: 100%;
            font-weight: 500;
            font-size: 14px;
            color: #fff;
            background: #007bff;
            p{
                display: inline-block;
                padding: 0 10px;
                margin: 0;
            }

            @media all and (max-width: 768px){
                font-size: 12px;
                p{
                    display: block;
                }
            }
        }

        .btn-group button {
            background-color: #4CAF50; /* Green background */
            border: 1px solid green; /* Green border */
            color: white; /* White text */
            padding: 10px 24px; /* Some padding */
            cursor: pointer; /* Pointer/hand icon */
            width: 50%; /* Set a width if needed */
            display: block; /* Make the buttons appear below each other */
        }

        .btn-group button:not(:last-child) {
            border-bottom: none; /* Prevent double borders */
        }

        /* Add a background color on hover */
        .btn-group button:hover {
            background-color: #3e8e41;
        }

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            top: -1px;
            position: relative;
            width: 160px;
            vertical-align: bottom;
        }

        .button-save-content{
            width: 120px;
        }

        .select-filtro{
            margin-right: 10px;
            width: 120px;
            vertical-align: bottom;
            display: inline-block;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }


    }



</style>
