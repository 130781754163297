<template>
    <div class="date-picker-style-element" :class="{disabled: disabled}">
        <label v-if="title != null">{{ title }}</label>
        <v-menu v-model="show"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
            <template v-slot:activator="{ on }">
                <div class="input-date" v-on="on" >
                    <v-text-field :value="dateFormat" :class="styleClass === undefined ? 'filter' : styleClass"
                                  type="text"
                           :placeholder="placeholder"
                           :clearable="!disabled"
                           @click:clear="clearDate()"
                           readonly></v-text-field>
                </div>
            </template>
            <v-date-picker
                    v-model="date"
                    ref="datePicker"
                    :min="dateLimit"
                    locale="es-MX"
                    :readonly="disabled"
                    @change="change"
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>

import moment from 'moment';
moment.locale('es');
export default {
    name: 'DatePicker',
    props: {
        title: String,
        placeholder: String,
        limitDate: Boolean,
        format: null,
        styleClass: null,
        dateInput: String,
        disabled: Boolean
    },
    filters: {
        dateFormat: function (value) {
            return moment(value).format("DD-MM-YYYY");
        },
    },
    data(){
        return{
            date: "",
            dateLimit: this.limitDate ? this.$store.getters.fechaEdicion : null,
            dateFormat: "",
            show: false
        }
    },
    watch: {
        dateInput: function () {
            this.date = this.dateInput;
        },
        date: function () {
            if(this.date != undefined){
                if(this.date == ""){
                    this.dateFormat = this.date;
                }
                else{
                    this.dateFormat = this.format != null && this.format !== undefined  ?
                        moment(this.date).format(this.format) : this.date;
                }
            }
            else{
                this.dateFormat = "";
            }
        }
    },
    mounted(){
    },
    methods: {
        clearDate(e){
            this.date = "";
            this.$emit("update:dateInput", this.date);
            this.$emit("change", e);
        },
        change: function (e) {
            this.show = false;
            this.$emit("update:dateInput", this.date);
            this.$emit("change", e)
        },
        setDate: function (date) {
            this.date = date;
        },
        getDate(){
            return this.date;
        },
        click: function (e) {
            this.$emit("click", e)
        },
        onClose: function () {
            this.show = false;
        }
    }

}
</script>

<style lang="scss">
.date-picker-style-element{
    .theme--light.v-text-field>.v-input__control>.v-input__slot:before{
        border-color: transparent !important;
    }
    .v-text-field>.v-input__control>.v-input__slot:after{
        transform: scaleX(0) !important;
    }

    &.disabled{
        .input-date{
            .v-input{
                background: #e9ecef;
            }
        }
    }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .v-text-field--placeholder.form-control{
        padding-top: 2px;
        .v-input__slot{
            margin: 0;
            &:before{
                opacity: 0;
            }
        }
    }

    .content-date{
        position: relative;

        .date-picker{
            position: absolute;
            right: 0;
            z-index: 20;
            top: 100%;
        }

    }

    .input-date{
        position: relative;
        .filter{
            outline: none;
            box-shadow: none;
            border: 1px solid #dcdde1;
            width: 100%;
            height: 32px;
            font-size: 12px;
            border-radius: 50px;
            padding: 0 15px;
            background: #ff0000;
            transition-duration: 0.3s;

            &:focus{
                outline: none;
                box-shadow: none;

            }

            &::placeholder {
                color: #1f1f21;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #1f1f21;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #1f1f21;
            }

        }

        .form-control{
            border-radius: 50px;
            margin-bottom: 1rem;
        }

        .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            opacity: 0.7; /* Firefox */
        }

        .form-control[readonly]{
            background: #fff;
        }

        .form-control:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            opacity: 0.7; /* Firefox */
        }

        .form-control::-ms-input-placeholder  { /* Chrome, Firefox, Opera, Safari 10.1+ */
            opacity: 0.7; /* Firefox */
        }


    }

</style>
