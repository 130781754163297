<template>
    <div id="root">
        <div class="button-add-bottom">
            <ButtonFloat v-if="checkPrivilege('denominaciones.crear')" v-on:click="nuevaDenominacion"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Denominaciones</h2>
            </div>
            <div class="buttons">
                <div class="select-check-content">
                    <SelectCheck ref="selectFiltro"
                                 v-on:change="filtrarChange"
                                 placeholder="Selecciona los estatus"/>
                </div>
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         table="denominaciones"
                                         @click="searchClick"
                                         title="Buscar" />
                </div>
                <div class="button-export-top">
                    <button class="btn btn-exportar" v-on:click="exportCSV(csvData)">
                        <i class="fa fa-file-excel"></i>
                        &nbsp;
                        <span>
                            Exportar
                        </span>
                    </button>
                </div>
                <div v-if="checkPrivilege('denominaciones.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="nuevaDenominacion" title="Nuevo +" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <v-data-table
                    :headers="headers"
                    :items="denominacionesFiltradas"
                    hide-default-header
                    item-key="tabla-notarios"
                    class="elevation-1" >

                <template v-slot:header="{ props: { headers } }">
                    <thead>
                    <tr>
                        <th v-for="header in headers"
                            :class="'text-left sortable ' + header.hide"
                            :key="header.value"
                            :width="header.width">
                            {{ header.text }}
                        </th>
                    </tr>
                    </thead>
                </template>


                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-if="items.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in items" :key="index" v-on:click="detalleDenominacion(item)">
                        <td :class="headers[0].hide">{{ item.nombre }}</td>
                        <td :class="headers[1].hide">
                            <span class="estatus">
                                {{ item.estatus }}
                            </span>
                        </td>
                        <td :class="headers[2].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>



        <ModalForm ref="denominacionForm"
                   title="Denominación"
                   v-on:submit="saveDenominacion">
            <template v-slot:body>
                <input type="hidden" v-model="denominacion.id">

                <FormInput :valueInput.sync="denominacion.nombre"
                           :required="true"
                           :consume="[',']"
                           name="denominacion" title="Denomincación" />

                <ComboBoxSearch ref="clientes" title="Cliente"
                                placeholder="Seleccionar cliente"/>

                <FormInput :valueInput.sync="denominacion.usuario"
                           :required="false"
                           name="usuario" title="Usuario" />


                <FormSelect :valueInput.sync="denominacion.estatus"
                            name="estatus" title="Estatus"
                            :required="false">
                    <option value="">Selecciona estatus</option>
                    <option value="disponible">Disponible</option>
                    <option value="apartada">Apartada</option>
                    <option value="utilizada">Utilizada</option>
                </FormSelect>

                <FormFile :required="false"
                          ref="permisoFile"
                           :valueInput.sync="denominacion.permiso"
                           name="permiso" title="Permiso"/>

            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonDenominacion" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>

        <ModalDetail ref="denominacionDetalle" title="Denominación Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Denominación</b>
                        </td>
                        <td>
                            <span>{{ denominacion.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Cliente</b>
                        </td>
                        <td>
                            <span v-if="denominacion.cliente != undefined">
                                {{ denominacion.cliente.nombre }} {{ denominacion.cliente.apellidos }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Usuario</b>
                        </td>
                        <td>
                            <span>{{ denominacion.usuario }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Estatus</b>
                        </td>
                        <td>
                            <span>{{ denominacion.estatus }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Permiso</b>
                        </td>
                        <td>
                            <a :href="denominacion.permiso_url" target="_blank" class="btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>

                <div v-if="checkPrivilege('denominaciones.editar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="editDenominacion"
                                       color="#fff"
                                       background="#f59c1a"
                                       title="Editar"
                                       icon="fa-pencil-alt" />
                </div>

                <div v-if="checkPrivilege('denominaciones.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteDenominacion"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>

    </div>
</template>

<script>
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ModalForm from '@/components/Modal/ModalForm'
    import FormInput from '@/components/Form/FormInput'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import FormFile from '@/components/Form/FormFile'
    import FormSelect from '@/components/Form/FormSelect'
    import ComboBoxSearch from '@/components/Form/ComboBoxSearch'
    import SelectCheck from '@/components/Form/SelectCheck'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'DenominacionesScreen',
        components: {
            ComboBoxSearch,
            SelectCheck,
            ButtonFloat,
            FormSelect,
            InputSearchComplete,
            FormInput,
            FormFile,
            ButtonAdd,
            ModalForm,
            ModalDetail,
            ButtonLoader,
            ModalButtonFooter
        },
        data: function(){
            return {
                filtroEstatus: [],
                selectFiltro: [
                    {
                        key: "disponible",
                        title: "Disponibles",
                        checked: true
                    },
                    {
                        key: "apartada",
                        title: "Apartadas",
                        checked: true
                    },
                    {
                        key: "utilizada",
                        title: "Utilizada",
                        checked: false
                    }
                ],
                headers: [
                    {text: 'Denominación', align: 'left', value: 'denominacion'},
                    {text: 'Estatus', value: 'Estatus'},
                    {text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtroComputed: '',
                filtro: '',
                clientes: [],
                denominaciones: [],
                indexEdit: -1,
                denominacion: {}
            }
        },
        computed: {
            denominacionesText () {
                let vue = this;
                return this.denominaciones.filter(function (deno) {
                    return deno.search.toLowerCase().indexOf(vue.filtroComputed.toLowerCase()) > -1;
                });

            },
            denominacionesFiltradas () {
                let vue = this;
                return this.denominacionesText.filter(function (deno) {
                    let filtro = false;
                    for(let i in vue.filtroEstatus){
                        if(deno.estatus.toLowerCase().indexOf(vue.filtroEstatus[i].toLowerCase()) > -1){
                            filtro = true;
                            break;
                        }
                    }

                    return filtro;
                });

            },
            csvData() {
                return this.denominaciones.filter(item => item.estatus.toLowerCase() === 'disponible').map(item => ({
                    id: item.id,
                    denominacion: item.nombre,
                    estatus: item.estatus
                }));
            }
        },
        mounted() {
            this.$emit("showLoader");
            if(this.$refs.selectFiltro != undefined){
              this.$refs.selectFiltro.setData(this.selectFiltro);
            }
            this.filtroEstatus = this.$refs.selectFiltro.getSelecteds();
            this.getClientes();
            this.getDenominaciones();
        },
        methods: {
            searchClick(){
                this.filtroComputed = this.filtro;
            },
            filtrarChange: function () {
                this.filtroEstatus = this.$refs.selectFiltro.getSelecteds();
            },
            getDenominaciones: function () {
                let vue = this;
                vue.denominaciones = [];
                $.get(this.API_URL + "/api/denominaciones/get", function (response) {
                    vue.$emit("hideLoader");
                    if (response.success) {
                        vue.denominaciones = response.denominaciones;
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            getClientes: function () {
                let vue = this;
                vue.$refs.clientes.setCargando(true);
                vue.clientes = [];
                $.get(this.API_URL + "/api/clientes/get", function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        for(let k in response.clientes){
                            vue.clientes[k] = {};
                            vue.clientes[k].key = response.clientes[k].id;
                            let apellidos = response.clientes[k].apellidos == null ?
                                "" : response.clientes[k].apellidos;
                            vue.clientes[k].title = response.clientes[k].nombre + " " + apellidos;
                            vue.clientes[k].search = response.clientes[k].search;
                        }
                        if(vue.$refs.clientes != undefined){
                          vue.$refs.clientes.setData(vue.clientes);
                          vue.$refs.clientes.setCargando(false);
                        }
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            nuevaDenominacion: function () {
                this.denominacion = {};
                this.indexEdit = -1;
                this.$refs.clientes.removeItem();
                this.openModalDenominacion();
            },
            editDenominacion: function () {
                this.$refs.denominacionDetalle.hide();

                if(this.denominacion.cliente != undefined){
                    let apellidos = this.denominacion.cliente.apellidos == null ?
                        "" : this.denominacion.cliente.apellidos;
                    let client = {
                        key: this.denominacion.cliente.id,
                        title: this.denominacion.cliente.nombre + " " + apellidos
                    };
                    this.$refs.clientes.setSelected(client);
                }
                else{
                    this.$refs.clientes.removeItem();
                }


                this.openModalDenominacion();
            },
            openModalDenominacion: function () {
                this.$refs.permisoFile.reset();
                this.$refs.denominacionForm.show();
            },
            closeModalDenominacion: function(){
                this.$refs.denominacionForm.hide();
            },
            detalleDenominacion: function (item) {
                this.indexEdit = this.denominaciones.indexOf(item);
                this.denominacion = Object.assign({}, item);

                this.$refs.denominacionDetalle.show();
            },
            saveDenominacion: function (e) {
                e.preventDefault();

                let data = new FormData();

                let client = this.$refs.clientes.getSelected();

                if(client != undefined && client != null){
                    data.append("cliente_id", client.key);
                }

                if(this.denominacion.id != undefined){
                    data.append("id", this.denominacion.id);
                }
                if(this.denominacion.nombre != undefined) {
                    data.append("nombre", this.denominacion.nombre);
                }
                if(this.denominacion.usuario != undefined) {
                    data.append("usuario", this.denominacion.usuario);
                }
                if(this.denominacion.estatus != undefined) {
                    data.append("estatus", this.denominacion.estatus);
                }
                if(this.denominacion.permiso != undefined) {
                    data.append("permiso", this.denominacion.permiso);
                }

                let vue = this;

                vue.$refs.buttonDenominacion.setShowLoader(true);

                $.ajax({
                    url: this.API_URL + "/api/denominaciones/save",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    type: 'POST', // For jQuery < 1.9
                    success: function(response){
                        vue.$refs.buttonDenominacion.setShowLoader(false);
                        if(response.success){
                            vue.denominacion = response.record;

                            if (vue.indexEdit > -1) {
                                Object.assign(vue.denominaciones[vue.indexEdit], vue.denominacion)
                            } else {
                                vue.denominaciones.splice(0, 0, vue.denominacion)
                            }
                            vue.denominacion = {};
                            vue.indexEdit = -1;
                            vue.closeModalDenominacion();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }
                }).fail(function (error) {
                    vue.$refs.buttonDenominacion.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            deleteDenominacion: function () {
                let vue = this;

                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/denominaciones/delete", vue.denominacion, function (response) {
                        if(response.success){
                            vue.denominaciones.splice(vue.indexEdit, 1);
                            vue.denominacion = {};
                            vue.$refs.denominacionDetalle.hide();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }

                    });
                });
            },
            exportCSV: function (arrData) {
                if(arrData.length == 0){
                    swal("No hay registros", "", "info");
                    return;
                }
                else{
                    let csvContent = "data:text/csv;charset=utf-8,";

                    csvContent += [
                        'denominacion',
                        ...arrData.map(item => '"' + item.denominacion + '"')
                    ]
                        .join("\n")
                        .replace(/(^\[)|(\]$)/gm, "");

                    const data = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", data);
                    link.setAttribute("download", "denominaciones.csv");
                    link.click();
                }
            }
        }
    }
</script>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;

        }

        .button-export-top{
            display: inline-block;
            margin-right: 10px;
            @media all and (max-width: 768px){
                margin: 0 0 0 10px;
            }
            @media all and (max-width: 530px) {
                padding-top: 5px;
            }
        }

        .estatus{
            text-transform: capitalize;
        }

        .btn-exportar{
            outline: none;
            display: inline-block;
            font-size: 13px;
            line-height: 29px;
            padding: 0 15px;
            color: #fff;
            border-color: #217344;
            background: #217344;
            border-radius: 50px;
            text-transform: uppercase;
            font-weight: bold;

            span{
                font-size: 11px;
            }

            &:hover{
                color: #fff;
            }
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

    }



</style>
