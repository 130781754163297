<template>
    <div id="root">
        <div class="button-add-bottom">
            <ButtonFloat v-if="checkPrivilege('inventarios.crear')" v-on:click="nuevaInventario"/>
        </div>
        <div class="header-section">
            <div class="title">
                <h2>Existencias</h2>
            </div>
            <div class="buttons">
<!--                <div class="select-check-content">-->
<!--                    <SelectCheck ref="selectFiltro"-->
<!--                                 v-on:change="filtrarChange"-->
<!--                                 placeholder="Selecciona los estatus"/>-->
<!--                </div>-->
                <div class="buscador-content">
                    <InputSearchComplete :valueInput.sync="filtro"
                                         table="empresas"
                                         @click="searchClick"
                                         title="Buscar" />
                </div>
                <div class="button-export-top">
                    <button class="btn btn-exportar" @click="exportarInventarios">
                        <i class="fa fa-file-excel"></i>
                        &nbsp;
                        <span>
                            Exportar
                        </span>
                    </button>
                </div>
<!--                <div v-if="checkPrivilege('inventarios.revisar')" class="button-export-top revisar-btn">-->
<!--                    <button class="btn btn-exportar" @click="cameraStart">-->
<!--                        <i class="fa fa-camera"></i>-->
<!--                        &nbsp;-->
<!--                        <span>-->
<!--                            Revisar-->
<!--                        </span>-->
<!--                    </button>-->
<!--                </div>-->
                <div v-if="checkPrivilege('inventarios.crear')" class="button-add-top">
                    <ButtonAdd v-on:click="nuevaInventario" title="Nueva +" />
                </div>
            </div>
        </div>
        <div class="content-section">
            <div class="data-section">
                <table class="table-section clickeable">
                    <thead>
                    <tr>
                        <th v-for="(item, index) in headers" :width="item.width"
                            :key="index"
                            :class="(item.hide != undefined ? item.hide : '')+ ' ' +
                             (item.align != undefined ? 'text-'+item.align : '')">
                            {{ item.text }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="inventarios.length === 0">
                        <td :colspan="headers.length" class="text-center">Sin resultados</td>
                    </tr>
                    <tr v-for="(item, index) in inventarios" :key="index" v-on:click="detalleInventario(item)">
                        <td :class="headers[0].hide">
                            <span v-if="item.acto != null">{{ item.acto.nombre }}</span>
                        </td>
                        <td :class="headers[1].hide">
                            {{ item.updated_at | dateTimeFormatMX }}
                        </td>
                        <td :class="headers[2].hide">
                            <a class="btn btn-circle btn-more">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="footer">
                    <div class="paginater">
                        <div class="text">
                            <p>
                                {{ page }} de {{ lastPage }}
                            </p>
                        </div>
                        <div class="buttons">
                            <button v-on:click="first" class="first" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-double-left"></i>
                            </button>
                            <button v-on:click="prev" v-bind:class="{inactive: page == 1}">
                                <i class="fas fa-angle-left"></i>
                            </button>
                            <button v-on:click="next" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-right"></i>
                            </button>
                            <button v-on:click="last" class="last" v-bind:class="{inactive: page >= lastPage}">
                                <i class="fas fa-angle-double-right"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <ModalForm ref="inventarioForm"
                   title="Existencia"
                   v-on:submit="saveInventario">
            <template v-slot:body>
                <input type="hidden" v-model="inventario.id">

                <ComboBoxSearchUrl ref="actos" title="Acto"
                                   :url="API_URL+'/api/actos'"
                                   placeholder="Selecciona un acto"/>

            </template>
            <template v-slot:footer>
                <div class="button-save-content">
                    <ButtonLoader ref="buttonInventario" type="submit" title="Guardar" />
                </div>
            </template>
        </ModalForm>

        <ModalDetail ref="inventarioDetalle" title="Existencia Detalle">
            <template v-slot:body>
                <table class="table-detail">
                    <tbody>
                    <tr>
                        <td>
                            <b>Acto</b>
                        </td>
                        <td>
                            <span v-if="inventario.acto != null">{{ inventario.acto.nombre }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Última actualización</b>
                        </td>
                        <td>
                            {{ inventario.updated_at | dateTimeFormatMX }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>
                <div v-if="checkPrivilege('inventarios.eliminar')" class="col" style="padding: 0; margin: 0;">
                    <ModalButtonFooter v-on:click="deleteInventario"
                                       color="#fff"
                                       background="#ff5b57"
                                       title="Eliminar"
                                       icon="fa-trash"/>
                </div>
            </template>
        </ModalDetail>

    </div>
</template>

<script>
    import InputSearchComplete from '@/components/Filter/InputSearchComplete'
    import ButtonAdd from '@/components/Filter/ButtonAdd'
    import ButtonLoader from '@/components/Form/ButtonLoader'
    import ModalForm from '@/components/Modal/ModalForm'
    import ModalDetail from '@/components/Modal/ModalDetail'
    import ModalButtonFooter from '@/components/Modal/ModalButtonFooter'
    import ButtonFloat from '@/components/ButtonFloat/ButtonFloat'
    import ComboBoxSearchUrl from '@/components/Form/ComboBoxSearchUrl'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'InventariosScreen',
        components: {
            ButtonFloat,
            InputSearchComplete,
            ButtonAdd,
            ModalForm,
            ModalDetail,
            ButtonLoader,
            ModalButtonFooter,
            ComboBoxSearchUrl
        },
        data: function(){
            return {
                headers: [
                    {text: 'Acto', align: 'left', value: 'acto'},
                    {text: 'Última actualización', value: 'actualizacion', width: '200px', hide: 'text-center'},
                    {text: '', value: 'acciones', width: '5%', hide: 'hidden-sm-and-down'},
                ],
                filtro: '',
                clientes: [],
                inventarios: [],
                indexEdit: -1,
                inventario: {},

                page: 1,
                lastPage: 1,
            }
        },
        mounted() {
            this.$emit("showLoader");
            this.getInventarios();
        },
        methods: {
            searchClick(){
                this.page = 1;
                this.getInventarios();
            },
            getInventarios: function () {
                let vue = this;
                vue.inventarios = [];

                let data = {page: this.page, filtro: this.filtro};

                $.get(this.API_URL + "/api/inventarios", data, function (response) {
                    vue.$emit("hideLoader");
                    if (response.success) {
                        vue.page = response.inventarios.current_page;
                        vue.lastPage = response.inventarios.last_page;
                        vue.inventarios = response.inventarios.data;
                    }
                    else {
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if (error.status === 401) {
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            nuevaInventario: function () {
                this.inventario = {};
                this.indexEdit = -1;
                this.$refs.actos.removeItem();
                this.openModalInventario();
            },
            openModalInventario: function () {
                this.$refs.inventarioForm.show();
            },
            closeModalInventario: function(){
                this.$refs.inventarioForm.hide();
            },
            detalleInventario: function (item) {
                this.indexEdit = this.inventarios.indexOf(item);
                this.inventario = Object.assign({}, item);

                this.$refs.inventarioDetalle.show();
            },
            saveInventario: function (e) {
                e.preventDefault();
                let data = new FormData();
                let acto = this.$refs.actos.getSelected();
                if(acto === undefined || acto === null){
                    swal("Selecciona un acto", "", "info");
                    return;
                }

                if(this.inventario.id != null){
                    data.append("id", this.inventario.id);
                }
                data.append("acto_id", acto.key);

                let vue = this;

                vue.$refs.buttonInventario.setShowLoader(true);

                $.ajax({
                    url: this.API_URL + "/api/inventarios/save",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    type: 'POST', // For jQuery < 1.9
                    success: function(response){
                        vue.$refs.buttonInventario.setShowLoader(false);
                        if(response.success){
                            vue.page = 1;
                            vue.getInventarios();
                            vue.inventario = {};
                            vue.indexEdit = -1;
                            vue.closeModalInventario();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }
                }).fail(function (error) {
                    vue.$refs.buttonInventario.setShowLoader(false);
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }

                });
            },
            deleteInventario: function () {
                let vue = this;
                swal({
                    text: '¿Realmente deseas eliminar el registro?',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(function () {
                    $.post(vue.API_URL + "/api/inventarios/delete", vue.inventario, function (response) {
                        if(response.success){
                            vue.inventarios.splice(vue.indexEdit, 1);
                            vue.inventario = {};
                            vue.$refs.inventarioDetalle.hide();
                        }
                        else{
                            swal(response.message, "", "info")
                        }
                    }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }

                    });
                });
            },
            next: function () {
                this.page += 1;
                if(this.page > this.lastPage){
                    this.page = this.lastPage;
                }
                else{
                    this.getInventarios();
                }
            },
            prev: function () {
                this.page -= 1;
                if(this.page < 1){
                    this.page = 1;
                }
                else{
                    this.getInventarios();
                }

            },
            last: function () {
                if(this.page != this.lastPage) {
                    this.page = this.lastPage;
                    this.getInventarios();
                }
            },
            first: function (refresh) {
                if(this.page != 1) {
                    this.page = 1;
                    this.getInventarios();
                }
                else if(refresh){
                    this.page = 1;
                    this.getInventarios();
                }
            },
            exportarInventarios(){
                let data = {filtro: this.filtro};
                data.data = btoa(this.$store.getters.auth_token);
                window.open(this.API_URL+"/inventario/exportar?"+new URLSearchParams(data).toString());
            }
        }
    }
</script>

<style lang="scss">
.barcode-view{
    video{
        width: 100%;
        height: 100vh;
    }
}
</style>

<style scoped lang="scss">


    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;

        }

        .button-export-top{
            display: inline-block;
            margin-right: 10px;
            @media all and (max-width: 768px){
                margin: 0 0 0 10px;
            }
            @media all and (max-width: 530px) {
                padding-top: 5px;
            }
        }

        .estatus{
            text-transform: capitalize;
        }

        .btn-exportar{
            outline: none;
            display: inline-block;
            font-size: 13px;
            line-height: 29px;
            padding: 0 15px;
            color: #fff;
            border-color: #217344;
            background: #217344;
            border-radius: 50px;
            text-transform: uppercase;
            font-weight: bold;

            span{
                font-size: 11px;
            }

            &:hover{
                color: #fff;
            }
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .barcode-view{
            position: fixed;
            left: 220px;
            top: 0;
            width: calc(100% - 220px);
            height: 100%;
            z-index: 12;
            background: #000;
            @media all and (max-width: 1200px){
                left: 0;
                width: 100%;
            }

            .botones-camara{
                position: absolute;
                bottom: 20px;
                width: 100%;
                text-align: center;
                left: 50%;
                transform: translateX(-50%);
                z-index: 10;
                .btn-cancelar{
                    background: #999;
                    border-color: #999;
                    padding-right: 25px;
                    padding-left: 25px;
                    margin-right: 10px;
                }

                .btn-enviar{
                    margin-left: 10px;
                    padding-right: 25px;
                    padding-left: 25px;
                    background: #28a745;
                    border-color: #28a745;
                }
            }
        }

        .revisar-btn{
            button{
                background: rgb(0, 123, 255);
                border-color: rgb(0, 123, 255);
            }
        }

    }



</style>
