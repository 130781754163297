<template>
    <div class="content-button"
         v-click-outside="onClose">
        <button ref="button" type="button"  class="btn btn-nuevo"
        @click="show = 'show'">
            <span>
                {{ title }}
            </span>
        </button>
        <ul  :class="'list-options ' + show">
            <slot></slot>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'ButtonOptions',
        props: {
            title: String
        },
        data(){
            return{
                show: false
            }
        },
        methods: {
            onClose () {
                if(this.show !== ''){
                    this.show = '';
                }
            },
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .content-button{
        position: relative;
        display: inline-block;

        .btn-nuevo{
            outline: none;
            box-shadow: none;
            display: inline-block;
            font-size: 11px;
            line-height: 30px;
            padding: 0 15px;
            color: #fff;
            border-color: rgba(46, 30, 41, 1);
            background: rgba(46, 30, 41, 1);
            border-radius: 50px;
            text-transform: uppercase;
            font-weight: bold;

            &:hover{
                color: #fff;
            }

        }

        .list-options{
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            right: 0;
            width: 200px;
            background: #ffffff;
            border-radius: 5px;
            margin: 0;
            z-index: 10;
            padding: 10px 0;
            list-style: none;
            text-align: left;
            transition-duration: 0.3s;

            -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
            -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
            box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);

            &.show{
                opacity: 1;
                visibility: visible;
            }

            .item{

            }

        }

    }






</style>
