<template>
    <div class="form-group combobox-search">
        <label v-if="title != null">{{ title }}</label>
        <div class="content"
             v-click-outside="onClose">
            <input ref="autocomplete"
                   type="text" class="form-control"
                   :placeholder="!cargando ? placeholder : 'Cargando...'"
                   :readonly="selected !== null"
                   @click="show = selected === null"/>
            <ul v-show="show"
                :class="'items-list'">
                <li v-if="datos.length == 0" class="item">
                    <div>
                        <p>
                            No hay resultados
                        </p>
                    </div>
                </li>
                <li v-else v-for="dato in datos" :class="'item ' +
                 (selected !== null && dato.key === selected.key ? 'active' : 'inactive')" v-on:click="setSelected(dato)"
                v-bind:key="dato.key">
                    <div>
                        <p>
                            {{ dato.title }}
                        </p>
                    </div>
                </li>
            </ul>
            <button v-if="selected != null" type="button" v-on:click="removeItem" class="btn-buscar">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
</template>

<script>
import * as $ from 'jquery';
import swal from 'sweetalert2';
    export default {
        name: 'ComboBoxSearchUrl',
        props: {
            title: String,
            url: String,
            placeholder: null
        },
        data: function(){
            return {
                cargando: false,
                show: false,
                datos: [],
                valueIn: "",
                extras: {},
                selected: null,
                listener: undefined
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init(){
                let vue = this;
                this.$refs.autocomplete.addEventListener("keyup", () => {
                    clearTimeout(vue.listener);
                    vue.listener = setTimeout(function (){
                        vue.autocomplete();
                    }, 500);
                });
            },
            autocomplete(){
                this.valueIn = this.$refs.autocomplete.value;
                this.extras.s = this.$refs.autocomplete.value;

                var vue = this;
                $.get(this.url, this.extras, function (response) {
                    vue.datos = response.results;
                }, "json").fail(function (error) {
                        if(error.status === 401){
                            swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                            vue.$emit("logout");
                        }
                        else{
                            if(error.readyState == 0){
                                swal("Se perdió la conexión a internet", "", "warning");
                            }
                            else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                                swal(error.responseJSON.message, "", "warning");
                            }
                            else{
                                swal("Error inesperado", "", "warning");
                            }
                        }
                    });
            },
            onClose () {
                if(this.show){
                    this.show = false;
                    this.$refs.autocomplete.value = this.selected !== null ? this.selected.title : "";
                }
            },
            setData: function (data) {
                this.datos = data;
            },
            removeData: function () {
                this.datos = [];
                this.removeItem();
            },
            setExtras: function (data) {
                this.extras = data;
                this.autocomplete();
            },
            getExtras: function () {
                return this.extras;
            },
            setSelected: function (selected) {
                this.show = false;
                this.selected = selected;
                this.$refs.autocomplete.value = this.selected.title;
                this.change();
            },
            getSelected: function () {
                return this.selected
            },
            change: function () {
                this.$emit("change");
            },
            removeItem: function () {
                this.selected = null;
                this.$refs.autocomplete.value = "";
                this.autocomplete();
                this.change();
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .combobox-search{

        .content{
            position: relative;
            .form-control{
                outline: none;
                box-shadow: none;
                border-radius: 50px;
                padding-right: 90px;

            }





            .btn-buscar{
                border-radius: 0 50px 50px 0;
                outline: none;
                background: #2e1e29;
                position: absolute;
                right: 0;
                top: 0;
                color: #fff;
                font-size: 16px;
                width: 60px;
                height: 38px;
                text-transform: uppercase;
                padding: 0 20px 0 15px;
                font-weight: bold;
                border: none;
            }

            .items-list{
                /*opacity: 0;*/
                /*visibility: hidden;*/
                margin: 0;
                position: absolute;
                top: 100%;
                left: 50%;
                max-height: 350px;
                transform: translateX(-50%);
                border: 1px solid #f0f0f0;
                border-radius: 10px;
                overflow-y: auto;
                width: 100%;
                background: #fff;
                padding: 0;
                list-style: none;
                z-index: 2;
                transition-duration: 0.3s;

                -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
                -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
                box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);

                &.show{
                    visibility: visible;
                    opacity: 1;
                }


                &::-webkit-scrollbar {
                    width: 12px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: rgba(0,0,0,0.2);
                    border-radius: 0 50px 50px 0;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #555;
                    border-radius: 0 50px 50px 0;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #444;
                }


                .item{

                    cursor: pointer;
                    /*&:first-child{*/
                        /*div{*/
                            /*border-radius: 10px 10px 0 0;*/
                        /*}*/
                    /*}*/

                    /*&:last-child{*/
                        /*div{*/
                            /*border-radius: 0 0 10px 10px;*/
                        /*}*/
                    /*}*/

                    div{
                        cursor: pointer;
                        padding: 5px 10px;
                        transition-duration: 0.2s;
                        border-bottom: 1px solid #f0f0f0;

                        p{
                            margin: 0;
                            padding: 0;
                        }

                    }

                    &.active{
                        div{
                            color: #fff;
                            background: rgba(196,192,134);
                        }
                    }

                    &:hover{
                        div{
                            color: #fff;
                            background: rgba(196,192,134);
                        }
                    }
                }

            }

        }


    }

</style>
