export default function auth ({ next, store, router, privilege }){
    if(store.getters.auth_token === null){
        return router.push({ path: '/login' });
    }

    let exist = false;

    if(privilege != "inicio"){

        let permisos = store.getters.user.profile.perfil_permiso;

        for (let i in permisos){
            let item = permisos[i];
            if(item.privilege == privilege){
                exist = true;
                break;
            }
        }

    }
    else{
        exist = true;
    }



    if(!exist){
        return router.push({ path: '/inicio' });
    }

    return next()
}