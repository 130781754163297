<template>
    <div id="root">
        <div class="header-section">
            <div class="title">
                <h2>Comisiones</h2>
            </div>
            <div class="buttons">
                <div class="date-range">
                    <DateRangePicker :icons="true"
                                     :dateInicio.sync="fechaInicio"
                                     :dateFin.sync="fechaFin"
                                     placeholderInicio="Fecha inicio"
                                     placeholderFin="Fecha fin"
                                     format="MMM YYYY"
                                     type="month"
                                     ref="fechas"
                                     v-on:change="getComisiones"/>
                </div>
            </div>
        </div>
        <div class="content-section">
            <div v-if="comisiones.length == 0" class="item-fecha">
                <div class="data-section">
                    <h3 class="text-center">No se encontraron resultados</h3>
                </div>
            </div>
            <div v-for="(comision, index) in comisiones" :key="index" class="item-fecha" @click="openDetalle(comision)">
                <div class="data-section">
                    <h3>{{ comision.fecha_inicio | mesFormat }}</h3>
                    <a class="btn btn-circle btn-more">
                        <i class="fas fa-eye"></i>
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import DateRangePicker from '@/components/DatePicker/DateRangePicker'
    import * as $ from 'jquery';
    import swal from 'sweetalert2';

    export default {
        name: 'ComisionesScreen',
        components: {
            DateRangePicker
        },
        data: function(){
            return {
                fechaInicio: '',
                fechaFin: '',
                comisiones: []
            }
        },
        mounted() {
            this.getComisiones();
        },
        methods: {
            getComisiones(){
                this.$emit("showLoader");
                let vue = this;
                vue.comisiones = [];
                let data = {
                    fecha_inicio: this.fechaInicio,
                    fecha_fin: this.fechaFin
                };
                $.get(this.API_URL + "/api/comisiones", data, function (response) {
                    vue.$emit("hideLoader");
                    if(response.success){
                        vue.comisiones = response.comisiones;
                        if(vue.fechaInicio === '' || vue.fechaFin === ''){
                            vue.fechaInicio = response.fecha_inicio;
                            vue.fechaFin = response.fecha_fin;
                            vue.$refs.fechas.setDates(response.fecha_inicio, response.fecha_fin);
                        }
                    }
                    else{
                        swal(response.message, '', 'info')
                    }
                }, "json").fail(function (error) {
                    vue.$emit("hideLoader");
                    if(error.status === 401){
                        swal("Tu sesión ha expirado, inicia sesión de nuevo", "", "info");
                        vue.$emit("logout");
                    }
                    else{
                        if(error.readyState == 0){
                            swal("Se perdió la conexión a internet", "", "warning");
                        }
                        else if(error.responseJSON != undefined && error.responseJSON.message != undefined){
                            swal(error.responseJSON.message, "", "warning");
                        }
                        else{
                            swal("Error inesperado", "", "warning");
                        }
                    }
                });
            },
            openDetalle(item){
                this.$router.push({ path: "/comisiones_"+item.id });
            }
        }
    }
</script>


<style scoped lang="scss">

    #root{
        background: #f7f8fc;
        padding: 30px 25px;
        width: 100%;
        height: 100%;

        .select-check-content{
            display: inline-block;
            margin-right: 10px;
            width: 160px;
            vertical-align: bottom;

        }

        .button-export-top{
            display: inline-block;
            margin-right: 10px;
            @media all and (max-width: 768px){
                margin: 0 0 0 10px;
            }
            @media all and (max-width: 530px) {
                padding-top: 5px;
            }
        }

        .estatus{
            text-transform: capitalize;
        }

        .btn-exportar{
            outline: none;
            display: inline-block;
            font-size: 13px;
            line-height: 29px;
            padding: 0 15px;
            color: #fff;
            border-color: #217344;
            background: #217344;
            border-radius: 50px;
            text-transform: uppercase;
            font-weight: bold;

            span{
                font-size: 11px;
            }

            &:hover{
                color: #fff;
            }
        }

        .button-save-content{
            width: 120px;
        }

        @media all and (max-width: 768px){
            padding: 15px 15px;
        }

        @media all and (max-width: 576px){
            padding: 10px 5px;
        }

        .btn-more{
            color: #aaa;
            &:hover{
                color: #333;
            }
        }

        .barcode-view{
            position: fixed;
            left: 220px;
            top: 0;
            width: calc(100% - 220px);
            height: 100%;
            z-index: 12;
            background: #000;
            @media all and (max-width: 1200px){
                left: 0;
                width: 100%;
            }

            .botones-camara{
                position: absolute;
                bottom: 20px;
                width: 100%;
                text-align: center;
                left: 50%;
                transform: translateX(-50%);
                z-index: 10;
                .btn-cancelar{
                    background: #999;
                    border-color: #999;
                    padding-right: 25px;
                    padding-left: 25px;
                    margin-right: 10px;
                }

                .btn-enviar{
                    margin-left: 10px;
                    padding-right: 25px;
                    padding-left: 25px;
                    background: #28a745;
                    border-color: #28a745;
                }
            }
        }

        .revisar-btn{
            button{
                background: rgb(0, 123, 255);
                border-color: rgb(0, 123, 255);
            }
        }

        .select-clientes{
            display: inline-block;
            margin-right: 20px;
            width: 160px;
            vertical-align: bottom;
        }

        .item-fecha{
            cursor: pointer;
            margin-bottom: 15px;
            &:hover{
                .btn-more{
                    color: #333;
                }
            }
            .data-section{
                position: relative;
                padding: 20px 15px;
                h3{
                    margin: 0;
                    font-size: 18px;
                    text-transform: capitalize;
                }
                .btn-more{
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .title{
            h2{
                line-height: 35px;
            }
        }

    }



</style>
